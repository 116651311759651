import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {RouterSetting} from "./config/router.config";
import {AppComponent} from "./component/app.component";
import {FindPasswordComponent} from "./component/findPassword/findPassword.component";
import {MgmtComponent} from "./component/mgmt/mgmt.component";
import {StreamingComponent} from "./component/mgmt/control/streaming.component";
import {LivemapComponent} from "./component/mgmt/control/livemap.component";
import {HttpService} from "./service/http.service";
import {JWTService} from "./service/jwt.service";
import {ShortScrollbarDirective} from "./directive/shortScrollbar.directive";
import {PopupScrollbarDirective} from "./directive/popupScrollbar.directive";
import {MessagePopupScrollbarDirective} from "./directive/messagePopupScrollbar.directive";
import {StatusDashboardScrollbarDirective} from "./directive/statusDashboardScrollbar.directive";
import {PopupService} from "./service/popup.service";
import {MapService} from "./service/map.service";
import {CanvasService} from "./service/canvas.service";
import {AccountService} from "./service/account.service";
import {SubmenuService} from "./service/submenu.service";
import {SettingsService} from "./service/settings.service";
import {MonitoringService} from "./service/monitoring.service";
import {AudioElementService} from "./service/audioElement.service";
import {MessageService} from "./service/message.service";
import {TooltipModule} from "ng2-tooltip-directive";
import {ChartsModule} from "ng2-charts";
import {ProgressService} from "./service/progress.service";
import {LoginModule} from "./component/login/login.module";
import {JoinModule} from "./component/join/join.module";
import {HttpTokenInterceptor} from "./service/http-token.interceptor";
import {GroupModule} from "./component/mgmt/group/group.module";
import {UserModule} from "./component/mgmt/user/user.module";
import {StatisticsModule} from "./component/mgmt/statistics/statistics.module";
import {ControlModule} from "./component/mgmt/control/control.module";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {ProfileModule} from "./component/mgmt/profile/profile.module";

@NgModule({
    declarations: [
        AppComponent,
        FindPasswordComponent,
        MgmtComponent,
        StreamingComponent,
        LivemapComponent,
        ShortScrollbarDirective,
        PopupScrollbarDirective,
        MessagePopupScrollbarDirective,
        StatusDashboardScrollbarDirective
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        RouterSetting,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        TooltipModule,
        ChartsModule,
        LoginModule,
        JoinModule,
        GroupModule,
        UserModule,
        ControlModule,
        StatisticsModule,
        ProfileModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
        HttpService,
        JWTService,
        PopupService,
        MapService,
        CanvasService,
        AccountService,
        SubmenuService,
        MonitoringService,
        SettingsService,
        AudioElementService,
        MessageService,
        ProgressService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(httpClient, "./assets/i18n/", ".json");
}
