import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MachineDto } from '../dto/machine/machine.dto';

@Injectable()
export class MonitoringService {
    private machineList: Subject<MachineDto[]> = new Subject<MachineDto[]>();
    public machineList$: any;

    private showOnlineList: Subject<boolean> = new Subject<boolean>();
    public showOnlineList$: any;

    constructor() {
        this.machineList$ = this.machineList.asObservable();
        this.showOnlineList$ = this.showOnlineList.asObservable();
    }

    public publishMachineList(machineList: MachineDto[]): void {
        this.machineList.next(machineList);
    }

    public publishShowOnlineList(showOnlineList: boolean): void {
        this.showOnlineList.next(showOnlineList);
    }
}