import { ElementRef } from '@angular/core';
import { Offset } from '../mission/offset';
import { Point } from '../mission/point';
import { MissionType } from '../../../../dto/mission/setting/mission.type';

export class Canvas {
    private nativeElement: HTMLCanvasElement;
    private canvasContext: CanvasRenderingContext2D;
    private canvasContextList: CanvasRenderingContext2D[] = [];

    constructor(canvasWrap: ElementRef, width: number, height: number) {
        this.nativeElement = <HTMLCanvasElement>canvasWrap.nativeElement;

        this.canvasContext = (<HTMLCanvasElement>canvasWrap.nativeElement).getContext("2d");
        this.canvasContext.canvas.width = width;
        this.canvasContext.canvas.height = height;
    }

    public draw(pointList: Point[], missionType: MissionType, isPointMoving: boolean): void {
        let isFirst: boolean = true;

        pointList.forEach((point, index) => {
            let offset: Offset = point.getOffset(isPointMoving);

            if (isFirst) {
                isFirst = false;

                this.canvasContext.beginPath();
                this.canvasContext.moveTo(offset.x, offset.y);
            } else {
                this.canvasContext.lineTo(offset.x, offset.y);
            }
        });

        if (missionType == MissionType.GRID || missionType == MissionType.POLYGON) {
            let offset: Offset = pointList[0].getOffset(isPointMoving);
            this.canvasContext.lineTo(offset.x, offset.y);

            this.canvasContext.fillStyle = "rgba(0, 208, 235, 0.4)";
            this.canvasContext.fill();
        }

        this.canvasContext.strokeStyle = "#ff3048";
        this.canvasContext.stroke();
    }

    public drawWayPoint(waypointList: Offset[]): void {
        if (waypointList.length == 0) {
            return;
        }

        let isFirst: boolean = true;
        waypointList.forEach(waypoint => {
            if (isFirst) {
                isFirst = false;

                this.canvasContext.beginPath();
                this.canvasContext.moveTo(waypoint.x, waypoint.y);
            } else {
                this.canvasContext.lineTo(waypoint.x, waypoint.y);
            }
        });

        this.canvasContext.strokeStyle = "#FFFFFF";
        this.canvasContext.stroke();
    }

    public drawWayPointForControl(pointList: Point[], missionType: MissionType, waypointList: Offset[]): void {
        let isFirst: boolean = true;

        pointList.forEach((point, index) => {
            let offset: Offset = point.getOffset(false);

            if (isFirst) {
                isFirst = false;

                this.canvasContext.beginPath();
                this.canvasContext.moveTo(offset.x, offset.y);
            } else {
                this.canvasContext.lineTo(offset.x, offset.y);
            }
        });

        if (missionType == MissionType.GRID || missionType == MissionType.POLYGON) {
            let offset: Offset = pointList[0].getOffset(false);
            this.canvasContext.lineTo(offset.x, offset.y);

            this.canvasContext.fillStyle = "rgba(0, 208, 235, 0.4)";
            this.canvasContext.fill();
        }

        this.canvasContext.strokeStyle = "#FF005E";
        this.canvasContext.stroke();

        if (missionType == MissionType.WAYPOINT || waypointList.length == 0) {
            return;
        }

        waypointList.forEach((waypoint, index) => {
            if (index == 0) {
                this.canvasContext.beginPath();
                this.canvasContext.moveTo(waypoint.x, waypoint.y);
            } else {
                this.canvasContext.lineTo(waypoint.x, waypoint.y);
            }
        });

        this.canvasContext.lineWidth = 3;
        this.canvasContext.strokeStyle = "#FFFFFF";
        this.canvasContext.stroke();

        waypointList.forEach((waypoint, index) => {
            this.canvasContext.beginPath();
            this.canvasContext.arc(waypoint.x, waypoint.y, 7, 0, (Math.PI / 180) * 360, false);
            this.canvasContext.fillStyle = "#FFFFFF";
            this.canvasContext.fill();

            this.canvasContext.beginPath();
            this.canvasContext.arc(waypoint.x, waypoint.y, 5, 0, (Math.PI / 180) * 360, false);
            this.canvasContext.fillStyle = "#33B776";
            this.canvasContext.fill();
        });
    }

    public drawPointList(pointList: any[]): void {
        if (pointList.length == 0) {
            return;
        }

        pointList.forEach((data, dataIdx) => {
            this.canvasContextList[dataIdx] = this.nativeElement.getContext("2d");
            this.canvasContextList[dataIdx].beginPath();

            data.list.forEach((point, pointIdx) => {
                if (pointIdx == 0) {
                    this.canvasContextList[dataIdx].moveTo(point.offsetX, point.offsetY);
                } else {
                    this.canvasContextList[dataIdx].lineTo(point.offsetX, point.offsetY);
                }
            });

            if (!data.isDistance && data.list.length > 2) {
                let firstPoint: Point = data.list[0];
                this.canvasContextList[dataIdx].lineTo(firstPoint.offsetX, firstPoint.offsetY);
                this.canvasContextList[dataIdx].fillStyle = "rgba(0, 208, 235, 0.4)";
                this.canvasContextList[dataIdx].fill();
            }

            this.canvasContextList[dataIdx].strokeStyle = "#ff3048";
            this.canvasContextList[dataIdx].stroke();
        });
    }

    public clear(): void {
        this.canvasContext.clearRect(0, 0, this.canvasContext.canvas.width, this.canvasContext.canvas.height);
    }

    public clearContextList(): void {
        if (this.canvasContextList.length > 0) {
            this.canvasContextList.forEach(context => {
                context.clearRect(0, 0, this.canvasContext.canvas.width, this.canvasContext.canvas.height);
            });
        }
    }
}
