import {Injectable} from "@angular/core";
import {HttpService} from "../../../service/http.service";
import {LoginDto} from "../../../dto/account/login.dto";
import {Observable} from "rxjs";
import {ResponseDto} from "../../../dto/response.dto";
import {LoginResponseDto} from "../../../dto/account/loginResponse.dto";
import {JSEncrypt} from "jsencrypt";
import {APIUrl} from "../../../constants/api.url";
import {HTTPCallBack} from "../../../service/http.callback";

@Injectable()
export class LoginService {
    private jsEncrypt: any = new JSEncrypt();

    constructor(private httpService: HttpService) {}

    public getPublicKey(): Observable<ResponseDto<string>> {
        return new Observable<ResponseDto<string>>(observer => {
            this.httpService.get(APIUrl.GET_KEY, new HTTPCallBack(
                response => {
                    observer.next(response);
                }, error => {
                    observer.error(error);
                }
            ));
        });
    }

    public encryptPassword(publicKey: string, plainPassword: string): string | null {
        let encryptedPassword: string;

        try {
            this.jsEncrypt.setPublicKey(publicKey);
            let password: string = plainPassword.trim();
            encryptedPassword = this.jsEncrypt.encrypt(password);
        } catch (error) {
            encryptedPassword = null;
        }

        return encryptedPassword;
    }

    public login(loginDto: LoginDto): Observable<ResponseDto<LoginResponseDto>> {
        return new Observable<ResponseDto<LoginResponseDto>>(observer => {
            this.httpService.post(APIUrl.LOGIN, loginDto, new HTTPCallBack(
                response => {
                    observer.next(response);
                }
            ));
        });
    }
}
