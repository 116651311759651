import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AccountService} from "../../../../service/account.service";
import {Observable} from "rxjs";
import {GroupDto} from "../../../../dto/group/group.dto";
import {ResponseDto} from "../../../../dto/response.dto";
import {APIUrl} from "../../../../constants/api.url";

@Injectable()
export class GroupTreeService {
    constructor(private accountService: AccountService, private httpClient: HttpClient) {}

    public getTreeData(): Observable<GroupDto> {
        return new Observable<GroupDto>(observer => {
            this.httpClient.get<ResponseDto<GroupDto>>(APIUrl.GROUP_TREE(this.accountService.getAccount().seq)).subscribe(
                response => {
                    if (response.isSuccess) {
                        observer.next(response.data);
                    }
                }
            );
        });
    }
}
