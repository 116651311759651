import { CoordinateDto } from 'src/app/dto/shapefile/coordinate.dto';

export class ShapeFileUtil {
    public static isAdjacentTheLine(pointList: CoordinateDto[], point: CoordinateDto): boolean {
        let tolerance: number = 5;
        let isAdjacent: boolean = false;

        pointList.some(ringPoint => {
            let diffX: number = Math.abs(ringPoint.offsetX - point.offsetX);
            let diffY: number = Math.abs(ringPoint.offsetY - point.offsetY);

            if (diffX <= tolerance && diffY <= tolerance) {
                isAdjacent = true;
                return true;
            }
        });

        return isAdjacent;
    }

    public static isLieOnThePolygon(pointList: CoordinateDto[], point: CoordinateDto): boolean {
        return this.isOnTheLine(pointList, point) || this.isOnThePolygon(pointList, point);
    }

    private static isOnTheLine(pointList: CoordinateDto[], intersection: CoordinateDto): boolean {
        let tolerance: number = 1e-8;
        let isFind: boolean = false;

        pointList.forEach((point, index) => {
            if (!isFind && index < pointList.length - 1) {
                let nextPoint: CoordinateDto = pointList[index + 1];
                let lineDistance: number = this.getDistance(point, nextPoint);
                let itopDistance: number = this.getDistance(intersection, point);
                let itonpDistance: number = this.getDistance(intersection, nextPoint);
                let diff: number = lineDistance - itopDistance - itonpDistance;

                if (Math.abs(diff) <= tolerance) {
                    isFind = true;
                }
            }
        });

        return isFind;
    }

    private static getDistance(point1: CoordinateDto, point2: CoordinateDto): number {
        return Math.sqrt(Math.pow(point2.offsetX - point1.offsetX, 2) + Math.pow(point2.offsetY - point1.offsetY, 2));
    }

    private static isOnThePolygon(pointList: CoordinateDto[], intersection: CoordinateDto): boolean {
        let isFirst: boolean = true;
        let isInside: boolean = false;
        let minX: number = 0;
        let minY: number = 0;
        let maxX: number = 0;
        let maxY: number = 0;

        pointList.forEach((point, index) => {
            if (isFirst) {
                isFirst = false;

                minX = point.offsetX;
                minY = point.offsetY;
                maxX = point.offsetX;
                maxY = point.offsetY;
            } else {
                minX = Math.min(minX, point.offsetX);
                minY = Math.min(minY, point.offsetY);
                maxX = Math.max(maxX, point.offsetX);
                maxY = Math.max(maxY, point.offsetY);
            }
        });

        if (intersection.offsetX < minX || intersection.offsetY < minY || intersection.offsetX > maxX || intersection.offsetY > maxY) {
            return false;
        }

        pointList.forEach((point, index) => {
            let nextPoint: CoordinateDto;
            if (index < pointList.length - 1) {
                nextPoint = pointList[index + 1];
            } else {
                nextPoint = pointList[0];
            }

            if ((point.offsetY > intersection.offsetY) != (nextPoint.offsetY > intersection.offsetY) && this.isInside(intersection, point, nextPoint)) {
                isInside = !isInside;
            }
        });

        return isInside;
    }

    private static isInside(intersection: CoordinateDto, point: CoordinateDto, nextPoint: CoordinateDto): boolean {
        return intersection.offsetX < (nextPoint.offsetX - point.offsetX) * (intersection.offsetY - point.offsetY) / (nextPoint.offsetY - point.offsetY) + point.offsetX;
    }
}