export class ShapeColorData {
    constructor(
        public list: ShapeColor[] = [],
    ) {
        list.push(new ShapeColor("rgba(34, 193, 239, .5)", "#22c1ef", "rgba(88, 249, 255, .5)", "#58f9ff"));
        list.push(new ShapeColor("rgba(0, 85, 255, .5)", "#0055ff", "rgba(35, 144, 255, .5)", "#2390ff"));
        list.push(new ShapeColor("rgba(139, 0, 255, .5)", "#8b00ff", "rgba(175, 126, 255, .5)", "#af7eff"));
        list.push(new ShapeColor("rgba(255, 0, 246, .5)", "#ff00f6", "rgba(255, 104, 250, .5)", "#ff68fa"));
        list.push(new ShapeColor("rgba(255, 132, 0, .5)", "#ff8400", "rgba(255, 157, 62, .5)", "#ff9d3e"));
        list.push(new ShapeColor("rgba(255, 194, 10, .5)", "#ffc20a", "rgba(255, 221, 83, .5)", "#ffdd53"));
        list.push(new ShapeColor("rgba(197, 223, 36, .5)", "#c5df24", "rgba(223, 255, 23, .5)", "#dfff17"));
        list.push(new ShapeColor("rgba(105, 208, 59, .5)", "#69d03b", "rgba(95, 255, 24, .5)", "#5fff18"));
        list.push(new ShapeColor("rgba(32, 177, 108, .5)", "#20b16c", "rgba(0, 232, 122, .5)", "#00e87a"));
        list.push(new ShapeColor("rgba(25, 208, 197, .5)", "#19d0c5", "rgba(16, 255, 241, .5)", "#10fff1"));
    }
}

export class ShapeColor {
    constructor(
        public normal: string = "",
        public normalLine: string = "",
        public select: string = "",
        public selectLine: string = "",
    ) {}
}