export enum AccountSearchCode {
    Email, Name
}

export namespace AccountSearchCode {
    export function getAccountSearchCodeLanguage(accountSearchCode: AccountSearchCode): string {
        if (accountSearchCode == AccountSearchCode.Email) {
            return "common.label.email";
        }

        return "common.label.name";
    }

    export function getAll(): AccountSearchCode[] {
        return [AccountSearchCode.Email, AccountSearchCode.Name];
    }
}
