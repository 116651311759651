import { Point } from '../../mission/mission/point';
import { MapService } from 'src/app/service/map.service';
import { CanvasService } from 'src/app/service/canvas.service';
import { Offset } from '../../mission/mission/offset';
import { Distance } from '../../mission/mission/distance';
import { AreaInfo } from './areaInfo';

export class MapFunction {
    public isEnable: boolean = false;
    private isDistance: boolean = true;
    private pointList: any[] = [];
    private nowIndex: number = 0;

    private mapService: MapService;
    private canvasService: CanvasService;

    constructor(mapService: MapService, canvasService: CanvasService) {
        this.mapService = mapService;
        this.canvasService = canvasService;
    }

    private getNewItem(): any {
        return {
            isDistance: this.isDistance,
            list: [],
            distanceList: [],
            totalDistance: new Distance(),
            areaInfo: new AreaInfo()
        };
    }

    public measureDistance(): void {
        this.isEnable = true;
        this.isDistance = true;

        if (this.pointList.length == 0) {
            this.nowIndex = 0;
        } else {
            ++this.nowIndex;
        }

        this.pointList[this.nowIndex] = this.getNewItem();
    }

    public measureArea(): void {
        this.isEnable = true;
        this.isDistance = false;

        if (this.pointList.length == 0) {
            this.nowIndex = 0;
        } else {
            ++this.nowIndex;
        }

        this.pointList[this.nowIndex] = this.getNewItem();
    }

    public addPoint(point: Point): void {
        if (this.pointList[this.nowIndex].isDistance) {
            this.setDistance(point);
        } else {
            this.setArea(this.nowIndex, point);
        }

        this.pointList[this.nowIndex].list.push(point);
        this.canvasService.canvas.drawPointList(this.pointList);
    }

    private setDistance(point: Point): void {
        let listLength: number = this.pointList[this.nowIndex].list.length;
        if (listLength < 1) {
            return;
        }

        let prevPoint: Point = this.pointList[this.nowIndex].list[listLength - 1];
        let nextPoint: Point = Object.assign(new Point(), point);

        let prevOffset: Offset = prevPoint.toOffset();
        let nextOffset: Offset = nextPoint.toOffset();

        let distancePoint: Distance = new Distance();
        distancePoint.setPoint(new Offset(
            prevOffset.x + (nextOffset.x - prevOffset.x) / 2,
            (prevOffset.y + (nextOffset.y - prevOffset.y) / 2) - 10
        ));

        let distance: number = this.mapService.map.getDistance(prevPoint, nextPoint);
        distancePoint.setDistance(distance);
        this.pointList[this.nowIndex].distanceList.push(distancePoint);

        this.pointList[this.nowIndex].totalDistance.setDistance(
            this.pointList[this.nowIndex].totalDistance.getPlainDistance() + distance
        );

        this.pointList[this.nowIndex].totalDistance.setPoint(new Offset(nextOffset.x, nextOffset.y + 20));
    }

    private setArea(listIndex: number, point: Point): void {
        if (this.pointList[listIndex].list.length < 2) {
            return;
        }

        let tempList: any[] = Object.assign([], this.pointList[listIndex].list);
        tempList.push(point);
        tempList.push(tempList[0]);

        let area: number = 0;

        for (let i = 0;i < tempList.length - 1;i++) {
            let point1: Point = tempList[i];
            let point2: Point = tempList[i + 1];

            area += this.toRadian(point2.coordX - point1.coordX) * (2 + Math.sin(this.toRadian(point1.coordY)) + Math.sin(this.toRadian(point2.coordY)));
        }

        let nextOffset: Offset = point.toOffset();
        let areaInfo: AreaInfo = new AreaInfo();
        areaInfo.setArea(Math.abs(area * Math.pow(6378137, 2) / 2));
        areaInfo.setPoint(new Offset(nextOffset.x, nextOffset.y + 20));

        this.pointList[listIndex].areaInfo = areaInfo;
    }

    private toRadian(degree: number): number {
        return degree * Math.PI / 180;
    }

    public changePointPosition(diffX: number, diffY: number): void {
        this.pointList.forEach(data => {
            data.list.forEach(point => {
                point.offsetX += diffX;
                point.offsetY += diffY;
            });

            if (data.isDistance) {
                data.distanceList.forEach(distance => {
                   distance.point.x += diffX;
                   distance.point.y += diffY;
                });

                data.totalDistance.point.x += diffX;
                data.totalDistance.point.y += diffY;
            } else {
                data.areaInfo.point.x += diffX;
                data.areaInfo.point.y += diffY;
            }
        });

        this.canvasService.canvas.drawPointList(this.pointList);
    }

    public resetPointPosition(): void {
        this.pointList.forEach(data => {
            data.list.forEach((point, pKey) => {
                let offset: Offset = this.mapService.map.convertPointToOffset(point);

                point.offsetX = offset.x;
                point.offsetY = offset.y;

                if (data.isDistance) {
                    if (pKey > 0) {
                        let distance: Distance = data.distanceList[pKey - 1];

                        let prevOffset: Offset = data.list[pKey - 1].toOffset();
                        let nextOffset: Offset = point.toOffset();

                        distance.setPoint(new Offset(
                            prevOffset.x + (nextOffset.x - prevOffset.x) / 2,
                            (prevOffset.y + (nextOffset.y - prevOffset.y) / 2) - 10
                        ));
                    }

                    let lastOffset: Offset = data.list[data.list.length - 1].toOffset();
                    data.totalDistance.setPoint(new Offset(lastOffset.x, lastOffset.y + 20));
                }
            });

            if (!data.isDistance) {
                let nextOffset: Offset = data.list[data.list.length - 1].toOffset();
                data.areaInfo.setPoint(new Offset(nextOffset.x, nextOffset.y + 20));
            }
        });

        this.canvasService.canvas.drawPointList(this.pointList);
    }

    public clear(): void {
        this.isEnable = false;
        this.nowIndex = 0;
        this.pointList = [];
        this.canvasService.canvas.clear();
    }

    public getIsEnable(): boolean {
        return this.isEnable;
    }

    public getIsDistance(): boolean {
        return this.isDistance;
    }

    public getPointList(): any[] {
        return this.pointList;
    }
}
