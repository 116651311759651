import { AppMessage } from './appMessage';
import { MissionControl } from './missionControl';

export class WebClientRequestDto {
    constructor(
        public accountEmail: string = "",
        public appMessage: AppMessage = new AppMessage(),
        public missionControl: MissionControl = new MissionControl(),
        public isStreamingStart: boolean = false,
        public audioUrl: string = ""
    ) {}
}