import { Injectable } from '@angular/core';
import { AccountDto } from '../dto/account/account.dto';
import { Subject } from 'rxjs';

@Injectable()
export class AccountService {
    private account: AccountDto;

    private refresh: Subject<boolean> = new Subject<boolean>();
    public refresh$: any;

    constructor() {
        this.refresh$ = this.refresh.asObservable();
    }

    public getAccount(): AccountDto {
        return this.account;
    }

    public setAccount(account: AccountDto): void {
        this.account = account;
    }

    public publishRefresh(isRefresh: boolean): void {
        this.refresh.next(isRefresh);
    }
}
