import {Component, QueryList, ViewChild} from "@angular/core";
import {saveAs} from "file-saver";
import {AccountDto} from "src/app/dto/account/account.dto";
import {StatisticsTabMenuCode} from "../data/statisticsTabMenu.code";
import {DistanceUnit} from "../../../../constants/distanceUnit";
import {AreaUnit} from "../../../../constants/areaUnit";
import {StatisticsDateTypeCode} from "../data/statisticsDateType.code";
import {MonthShortName} from "../data/monthShortName";
import {BaseChartDirective} from "ng2-charts";
import {StatisticsOption} from "../data/statisticsOption";
import {StatisticsChart} from "../data/statisticsChart";
import {LineChart} from "../data/lineChart";
import {BarChart} from "../data/barChart";
import {PieChart} from "../data/pieChart";
import {AccountSearchCode} from "../../../../dto/account/accountSearch.code";
import {AccountSearchDto} from "../../../../dto/account/accountSearch.dto";
import {GroupTreeComponent} from "../../../common/group-tree/group-tree.component";
import {FlightRecordOption} from "../data/flightRecordOption";
import {StreamingConfig} from "../../../../constants/streaming.config";
import {StreamingDto} from "../../../../dto/streaming/streaming.dto";
import {HttpService} from "../../../../service/http.service";
import {AccountService} from "../../../../service/account.service";
import {HTTPCallBack} from "src/app/service/http.callback";
import {APIUrl} from "../../../../constants/api.url";
import {ResponseDto} from "../../../../dto/response.dto";
import {StatisticsYearRangeData} from "../../../../dto/statistics/statisticsYearRangeData";
import {StatisticsChartDataDto} from "../../../../dto/statistics/statisticsChartDataDto";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'statisticsArea',
    templateUrl: '../../../../view/html/statistics.html',
    styleUrls: ['../../../../view/css/statistics.css']
})
export class StatisticsComponent {
    private accountDto: AccountDto;
    public tabMenuCode: typeof StatisticsTabMenuCode = StatisticsTabMenuCode;

    public distanceUnit: typeof DistanceUnit = DistanceUnit;
    public areaUnit: typeof AreaUnit = AreaUnit;

    public dateTypeCode: typeof StatisticsDateTypeCode = StatisticsDateTypeCode;
    public monthShortName: typeof MonthShortName = MonthShortName;
    public yearList: number[] = [];

    public isGroupData: boolean = true;
    @ViewChild(BaseChartDirective) chartList: QueryList<BaseChartDirective>;
    public lineStatisticsOption: StatisticsOption = new StatisticsOption();
    public barStatisticsOption: StatisticsOption = new StatisticsOption();
    public lineChart: StatisticsChart = new LineChart("line");
    public barChart: StatisticsChart = new BarChart("horizontalBar");
    public pieChart: StatisticsChart = new PieChart("outlabeledPie");

    public isDatasetDialogVisible: boolean = false;

    private accountSearchCode: typeof AccountSearchCode = AccountSearchCode;
    public accountSearchDto: AccountSearchDto = new AccountSearchDto();
    public accountListChecked: boolean = false;
    public accountList: AccountDto[] = [];

    public isFlightRecordVisible: boolean = false;

    @ViewChild("flightRecordGroupTree") public flightRecordGroupTree: GroupTreeComponent;
    @ViewChild("datasetGroupTree") public datasetGroupTree: GroupTreeComponent;

    private flightRecordOption: FlightRecordOption = new FlightRecordOption();

    public streamingFileUrl: any = StreamingConfig.fileUrl;
    public isStreamingListVisible: boolean = false;
    public isStreamingViewVisible: boolean = false;
    public streamingList: StreamingDto[] = [];
    public streamingItem: StreamingDto = null;

    constructor(
        private httpService: HttpService,
        private accountService: AccountService,
        private translateService: TranslateService) {

        this.accountDto = this.accountService.getAccount();

        this.initLineChart();
    }

    private initLineChart(): void {
        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            this.lineStatisticsOption.groupSeqList = res.data;
            this.getYearRangeData();
        }.bind(this);

        this.httpService.get(APIUrl.GROUP_SEQ_LIST(this.accountDto.seq), httpCallback);
    }

    private getYearRangeData(): void {
        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<StatisticsYearRangeData> = res;

            this.yearList = [];
            if (response.isSuccess) {
                for (let i = response.data.minYear;i <= response.data.maxYear;i++) {
                    this.yearList.push(i);
                }

                if (response.data.maxYear < this.flightRecordOption.year) {
                    this.yearList.push(this.flightRecordOption.year);
                }
            } else {
                this.yearList.push(new Date().getFullYear());
            }

            this.lineStatisticsOption.year = this.yearList[this.yearList.length - 1];
            this.barStatisticsOption.year = this.yearList[this.yearList.length - 1];
            this.getChartData();
        }.bind(this);

        this.httpService.get(APIUrl.STATISTICS_YEAR_RANGE(this.accountDto.seq), httpCallback);
    }

    private getChartData(): void {
        this.getLineChartData();

        if (this.isGroupData) {
            this.getBarChartData();
        } else {
            this.getPieChartData();
        }
    }

    public changeDataType(): void {
        this.isGroupData = !this.isGroupData;
        this.getChartData();
    }

    private getLineChartData(): void {
        this.lineStatisticsOption.isGroupData = this.isGroupData;

        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<StatisticsChartDataDto> = res;
            let data: StatisticsChartDataDto = Object.assign(new StatisticsChartDataDto(), response.data);

            if (response.isSuccess) {
                this.lineChart.setChartLabels(data.labelList);
                this.lineChart.setChartData(data.dataList);
                this.lineChart.setChartColors();
            }
        }.bind(this);

        this.httpService.post(APIUrl.STATISTICS_LINE_CHART_DATA(this.accountDto.seq), this.lineStatisticsOption, httpCallback);
    }

    private getBarChartData(): void {
        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<StatisticsChartDataDto> = res;
            let data: StatisticsChartDataDto = Object.assign(new StatisticsChartDataDto(), response.data);

            if (response.isSuccess) {
                switch (this.barStatisticsOption.selectedTabMenu) {
                    case StatisticsTabMenuCode.FLIGHT_TIME :
                        this.barChart.setChartLabels([this.translateService.instant("statistics.label.flight-time")]);
                        break;
                    case StatisticsTabMenuCode.FLIGHT_DISTANCE :
                        this.barChart.setChartLabels([this.translateService.instant("statistics.label.flight-distance")]);
                        break;
                    case StatisticsTabMenuCode.FLIGHT_AREA :
                        this.barChart.setChartLabels([this.translateService.instant("statistics.label.flight-area")]);
                        break;
                    default :
                        this.barChart.setChartLabels([""]);
                        break;
                }

                this.barChart.setChartData(data.dataList);
                this.barChart.setChartColors();
            }
        }.bind(this);

        this.httpService.post(APIUrl.STATISTICS_BAR_CHART_DATA(this.accountDto.seq), this.barStatisticsOption, httpCallback);
    }

    private getPieChartData(): void {
        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<StatisticsChartDataDto> = res;
            let chartDataDto: StatisticsChartDataDto = Object.assign(new StatisticsChartDataDto(), response.data);

            if (response.isSuccess) {
                this.pieChart.setChartLabels(chartDataDto.labelList);
                this.pieChart.setChartColors();

                let chartDataList: number[] = [];

                chartDataDto.dataList.forEach(data => {
                    chartDataList.push(data.data[0]);
                });

                this.pieChart.setPieChartData(chartDataList);
                this.pieChart.setChartOptions();
            }
        }.bind(this);

        httpCallback.error = function (): void {
            console.log("error!!!!!");
        };
        this.httpService.post(APIUrl.STATISTICS_PIE_CHART_DATA(this.accountDto.seq), this.barStatisticsOption, httpCallback);
    }

    public changeTabMenu(tabMenuCode: StatisticsTabMenuCode): void {
        this.lineStatisticsOption.selectedTabMenu = tabMenuCode;
        this.barStatisticsOption.selectedTabMenu = tabMenuCode;

        this.getChartData();
    }

    public refreshLineChart(): void {
        this.lineChart.setChartData([{}]);
        this.getLineChartData();
    }

    public showLineChartDataSet(): void {
        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<Object> = res;

            if (response.isSuccess) {
                this.isDatasetDialogVisible = true;
            } else {
                alert("Not a upper group manager.");
            }
        }.bind(this);

        this.httpService.get(APIUrl.STATISTICS_IS_UPPER_MANAGER(this.accountDto.seq), httpCallback);
    }

    public getUserList(): void {
        this.accountSearchDto.groupSeqList = this.datasetGroupTree.getCheckedGroupSeqs();
        if (this.accountSearchDto.groupSeqList.length == 0) {
            this.accountList = [];
            return;
        }

        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<AccountDto[]> = res;

            if (response.isSuccess) {
                this.accountList = response.data;

                this.accountList.forEach(account => {
                    this.lineStatisticsOption.accountSeqList.some(seq => {
                        if (account.seq == seq) {
                            account.isChecked = true;
                            return true;
                        }
                    });
                });
            }
        }.bind(this);

        this.httpService.post(APIUrl.MEMBER_LIST(this.accountDto.seq), this.accountSearchDto, httpCallback);
    }

    public setAccountListChecked(): void {
        this.lineStatisticsOption.accountSeqList = [];
        this.accountListChecked = !this.accountListChecked;

        this.accountList.forEach(account => {
            account.isChecked = this.accountListChecked;
            this.lineStatisticsOption.accountSeqList.push(account.seq);
        });
    }

    public setAccountChecked(account: AccountDto): void {
        account.isChecked = !account.isChecked;
        this.lineStatisticsOption.accountSeqList = [];

        this.accountList.forEach(account => {
            if (account.isChecked) {
                this.lineStatisticsOption.accountSeqList.push(account.seq);
            }
        });
    }

    public closeLineDatasetPopup(): void {
        this.lineStatisticsOption.groupSeqList = Object.assign([], this.accountSearchDto.groupSeqList);
        this.lineStatisticsOption.groupSeqList = this.lineStatisticsOption.groupSeqList.filter(
            (value, idx, arr) => arr.indexOf(value) === idx
        );

        this.accountListChecked = false;
        this.isDatasetDialogVisible = false;
    }

    public exportToExcel(isLineData: boolean): void {
        this.lineStatisticsOption.isGroupData = this.isGroupData;

        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let blob: Blob = new Blob([res], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.shet;charset=UTF-8"});
            saveAs(blob, "iGIS-Drone-Statistics.xlsx");
        };

        httpCallback.error = function (): void {
            alert("Fail to export the excel.");
        };

        this.httpService.postAndDownload(APIUrl.EXPORT_LINE_CHART_DATA(this.accountDto.seq, isLineData), this.lineStatisticsOption, httpCallback);
    }

    public showFlightRecord(): void {
        this.isFlightRecordVisible = true;
    }

    public closeFlightRecord(): void {
        this.isFlightRecordVisible = false;
    }

    public convertFileSize(fileSize: number): string {
        let giga: number = fileSize / Math.pow(1024, 3);
        if (giga > 1) {
            return giga.toFixed(1) + " GB";
        }

        let mega: number = fileSize / Math.pow(1024, 2);
        if (mega > 1) {
            return mega.toFixed(1) + " MB";
        }

        let kilo: number = fileSize / 1024;
        if (kilo > 1) {
            return kilo.toFixed(1) + " KB";
        }

        return fileSize + " byte";
    }

    public showStreamingListDialog(): void {
        this.isStreamingListVisible = true;
        this.getStreamingList();
    }

    public hideStreamingListDialog(): void {
        this.isStreamingListVisible = false;
    }

    private getStreamingList(): void {
        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<StreamingDto[]> = res;

            if (response.isSuccess) {
                this.streamingList = response.data;
            }
        }.bind(this);

        this.httpService.get(APIUrl.ACCOUNT_STREAMING_LIST(this.accountDto.seq), httpCallback);
    }

    public downloadStreamingFile(accountEmail: string, fileName: string): void {
        saveAs(StreamingConfig.fileUrl(accountEmail) + fileName, fileName);
    }

    public showStreamingViewDialog(streaming: StreamingDto): void {
        this.streamingItem = streaming;
        this.isStreamingViewVisible = true;
    }

    public hideStreamingViewDialog(): void {
        this.streamingItem = null;
        this.isStreamingViewVisible = false;
    }

    // check license
    public hasStreamingLicense(): boolean {
        return this.accountDto.licenseDto.useStreaming;
    }
}
