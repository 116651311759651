import {Component, ViewChild, ElementRef, AfterViewInit} from "@angular/core";
import { Router } from '@angular/router';
import { HttpService } from 'src/app/service/http.service';
import { FindPasswordValidator } from './findPasswordValidator';
import { AccountDto } from 'src/app/dto/account/account.dto';
import { APIUrl } from 'src/app/constants/api.url';
import { HTTPCallBack } from 'src/app/service/http.callback';
import { ResponseDto } from 'src/app/dto/response.dto';

@Component({
    selector: '',
    templateUrl: '../../view/html/findPassword.html',
    styleUrls: ['../../view/css/findPassword.css']
})
export class FindPasswordComponent implements AfterViewInit {
    @ViewChild("email") private emailElement: ElementRef;
    public accountDto: AccountDto;

    public findPasswordValidator: FindPasswordValidator;

    constructor(private router: Router, private httpService: HttpService) {
        this.accountDto = new AccountDto();
        this.findPasswordValidator = new FindPasswordValidator();
    }

    ngAfterViewInit() {
        this.emailElement.nativeElement.focus();
    }

    public findPassword(): void {
        if (this.accountDto.email == "") {
            this.findPasswordValidator.isEmailEmpty = true;
            this.emailElement.nativeElement.focus();
            return;
        }

        this.findPasswordValidator.isEmailEmpty = false;

        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<string> = res;

            if (response.isSuccess) {
                this.router.navigateByUrl("/");
            } else {
                this.findPasswordValidator.isEmailExist = false;
            }
        }.bind(this);

        this.httpService.get(APIUrl.FIND_PASSWORD(this.accountDto.email), httpCallback);
    }

    public moveLoginPage(): void {
        this.router.navigateByUrl("/");
    }
}
