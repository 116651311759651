import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {Phase} from "./data/phase.type";

@Component({
    templateUrl: "./view/join.component.html",
    styleUrls: ["./view/join.component.css"]
})
export class JoinComponent {
    public phase: typeof Phase;
    public nowPhase: Phase;

    constructor(private router: Router) {
        this.phase = Phase;
        this.nowPhase = Phase.TOS;
    }

    public movePhase(phase: Phase | null): void {
        if (phase == null) {
            this.router.navigateByUrl("");
        } else {
            this.nowPhase = phase;
        }
    }
}
