export enum DistanceUnit {
    FEET, METER, KILO_METER
}

export namespace DistanceUnit {
    export function getDisplayName(distanceUnit: DistanceUnit): string {
        switch (distanceUnit) {
            case DistanceUnit.FEET:
                return "ft";
            case DistanceUnit.METER:
                return "m";
            case DistanceUnit.KILO_METER:
                return "km";
        }
    }

    export function getAll(): DistanceUnit[] {
        return [
            DistanceUnit.FEET,
            DistanceUnit.METER,
            DistanceUnit.KILO_METER
        ];
    }
}
