import {Component, EventEmitter, Output, ViewChild} from "@angular/core";
import {MachineSearchCode} from "./data/machineSearch.code";
import {MachineListService} from "./service/machine-list.service";
import {AccountService} from "../../../../service/account.service";
import {MachineSearchDto} from "../../../../dto/machine/machineSearch.dto";
import {GroupTreeComponent} from "../../../common/group-tree/group-tree.component";
import {MachineDto} from "../../../../dto/machine/machine.dto";
import {PopupDto} from "../../../../dto/popup.dto";
import {PopupService} from "../../../../service/popup.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: "machine-list",
    templateUrl: "./view/machine-list.component.html"
})
export class MachineListComponent {
    public isVisible: boolean;
    public machineSearchDto: MachineSearchDto;
    public machineSearchCode: typeof MachineSearchCode;
    public machines: MachineDto[];

    @ViewChild(GroupTreeComponent) public groupTreeComponent: GroupTreeComponent;
    @Output() public moveEvent: EventEmitter<MachineDto>;

    constructor(
        private accountService: AccountService,
        private machineListService: MachineListService,
        private popupService: PopupService,
        private translateService: TranslateService) {

        this.isVisible = false;
        this.machineSearchDto = new MachineSearchDto();
        this.machineSearchCode = MachineSearchCode;
        this.machines = [];

        this.moveEvent = new EventEmitter<MachineDto>();
    }

    public show(): void {
        this.isVisible = true;
    }

    public getMachineSearchTypes(): string[] {
        let keys: string[] = Object.keys(MachineSearchCode);
        return keys.slice(keys.length / 2);
    }

    public getMachines(): void {
        this.machineSearchDto.groupSeqList = this.groupTreeComponent.getCheckedGroupSeqs();
        if (this.machineSearchDto.groupSeqList.length == 0) {
            this.machines = [];
            return;
        }

        this.machineListService.get(this.accountService.getAccount().seq, this.machineSearchDto).subscribe(
            machines => {
                this.machines = machines;
            }
        )
    }

    public moveTo(machine: MachineDto): void {
        this.moveEvent.emit(machine);
    }

    public setEditMode(machine: MachineDto): void {
        machine.isEdit = true;
    }

    public edit(machine: MachineDto): void {
        this.machineListService.edit(machine).subscribe(
            _ => {
                this.getMachines();
            }
        );
    }

    public delete(machine: MachineDto): void {
        let subscribe: any = this.popupService.response$.subscribe(
            response => {
                if (response) {
                    this.machineListService.delete(machine.seq);
                }

                subscribe.unsubscribe();
            }
        );

        let popupData: PopupDto = new PopupDto();
        popupData.isVisible = true;
        popupData.title = this.translateService.instant("drone-list.delete.title");
        popupData.message = this.translateService.instant("drone-list.delete.message.confirm-message");

        this.popupService.publishData(popupData);
    }

    public close(): void {
        this.isVisible = false;
    }
}
