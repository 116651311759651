import { MissionControlType } from './missionControlType';
import { MissionType } from '../mission/setting/mission.type';

export class MissionControl {
    constructor(
        public accountEmail: string = "",
        public controlType: MissionControlType = null,
        public missionType: MissionType = MissionType.FREE,
        public subject: string = "",
        public seq: number = 0,
        public is3d: boolean = false
    ) {}
}