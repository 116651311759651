import { Injectable } from "@angular/core";
import { Map } from '../component/mgmt/mission/map/map';
import { NaverMap } from '../component/mgmt/mission/map/naverMap';
import { Subject } from 'rxjs';
import { GoogleMap } from '../component/mgmt/mission/map/googleMap';
import { Point } from '../component/mgmt/mission/mission/point';

@Injectable()
export class MapService {
    public map: Map = null;
    public standardLatitude: number = 0;
    public standardLongitude: number = 0;
    public standardZoomLevel: number = 0;
    
    public isMapEnable: boolean = true;

    private refresh: Subject<boolean> = new Subject<boolean>();
    public refresh$: any;

    private mapChange: Subject<boolean> = new Subject<boolean>();
    public mapChange$: any;

    constructor() {
        this.refresh$ = this.refresh.asObservable();
        this.mapChange$ = this.mapChange.asObservable();
    }

    public initMap(isGoogleMap: boolean): void {
        if (isGoogleMap) {
            this.map = new GoogleMap();
            
            this.standardZoomLevel = 15;
            this.standardLatitude = 36.2824166;
            this.standardLongitude = 127.3546656;
        } else {
            this.map = new NaverMap();

            this.standardZoomLevel = 15;
            this.standardLatitude = 36.2824166;
            this.standardLongitude = 127.3546656;
        }
    }

    public isValidNaverMapPoint(point: Point): boolean {
        if (point.coordX < 124 || point.coordX > 132 || point.coordY < 33 || point.coordY > 39) {
            return false;
        }

        return true;
    }

    public getChangedZoomLevel(): number {
        let zoomLevel: number = this.map.getZoomLevel();

        if (zoomLevel <= 5) {
            return 0;
        } else if (zoomLevel > 19) {
            return 14;
        } else {
            return zoomLevel;
        }
    }

    public publishRefresh(): void {
        this.refresh.next();
    }

    public publishMapChange(): void {
        this.mapChange.next();
    }
}