import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: '',
    templateUrl: '../../../view/html/livemap.html'
})
export class LivemapComponent {
    public filePath: string = "";

    constructor(private activatedRoute: ActivatedRoute, private sanitaizer: DomSanitizer) {
        let encodedString: string = this.activatedRoute.snapshot.paramMap.get("filepath");
        this.filePath = atob(encodedString.split("").reverse().join(""));
    }

    public sanitaize(url: string): SafeUrl {
        return this.sanitaizer.bypassSecurityTrustUrl(url);
    }
}
