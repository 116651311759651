import {AfterContentInit, Directive, ElementRef} from "@angular/core";

@Directive({
    selector: "[autoFocus]"
})
export class AutoFocusDirective implements AfterContentInit {
    constructor(private elementRef: ElementRef) {}

    public ngAfterContentInit(): void {
        this.elementRef.nativeElement.focus();
        this.elementRef.nativeElement.select();
    }
}
