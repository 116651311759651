import {Component, EventEmitter, Output, ViewChild} from "@angular/core";
import {JoinDto} from "../../../../dto/account/join.dto";
import {InfoService} from "../../../join/phase/info/service/info.service";
import {GroupSelectComponent} from "../group-select/group-select.component";
import {GroupDto} from "../../../../dto/group/group.dto";
import {UserCreateService} from "./service/user-create.service";

@Component({
    selector: "user-create",
    templateUrl: "./view/user-create.component.html"
})
export class UserCreateComponent {
    public isVisible: boolean;
    public isCreate: boolean;

    public joinDto: JoinDto;
    public isAvailableEmail!: boolean;

    @ViewChild(GroupSelectComponent) public groupSelectComponent: GroupSelectComponent;
    @Output() public createdEvent: EventEmitter<void>;
    @Output() public closeEvent: EventEmitter<void>;

    constructor(private infoService: InfoService, private userCreateService: UserCreateService) {
        this.isVisible = false;
        this.createdEvent = new EventEmitter<void>();
        this.closeEvent = new EventEmitter<void>();
    }

    public show(isCreate: boolean, joinDto: JoinDto): void {
        this.isCreate = isCreate;
        this.joinDto = joinDto;
        this.isVisible = true;

        if (!this.isCreate) {
            this.isAvailableEmail = true;
        }
    }

    public checkEmail(): void {
        if (this.joinDto.email == undefined || this.joinDto.email.length == 0) {
            return;
        }

        this.infoService.checkEmail(this.joinDto.email).subscribe(
            isAvailable => {
                this.isAvailableEmail = isAvailable;
            }, _ => {
                this.isAvailableEmail = false;
            }
        );
    }

    public clearCheckEmail(): void {
        this.isAvailableEmail = undefined;
    }

    public showGroupSelectDialog(): void {
        this.groupSelectComponent.show();
    }

    public setGroup(groupDto: GroupDto): void {
        this.joinDto.groupSeq = groupDto.seq;
        this.joinDto.groupName = groupDto.name;
    }

    public submit(): void {
        if (this.isCreate) {
            this.infoService.signUp(this.joinDto).subscribe(
                isSuccess => {
                    if (isSuccess) {
                        this.close();
                    }
                }
            );
        } else {
            this.userCreateService.edit(this.joinDto).subscribe(
                _ => {
                    this.createdEvent.emit();
                    this.close();
                }
            );
        }
    }

    public close(): void {
        this.isVisible = false;
        this.groupSelectComponent.close();
        this.closeEvent.emit();
    }
}
