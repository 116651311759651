import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let request;
        let token: string | null = localStorage.getItem("token");

        if (token) {
            request = req.clone({
                headers: req.headers.set("jwt-token", token)
            });
        } else {
            request = req.clone();
        }

        return next.handle(request).pipe(catchError(error => {
            if (error.status === 401) {
                this.router.navigateByUrl("");
            }

            return throwError(error);
        }));
    }
}
