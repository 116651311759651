export enum MissionType {
    FREE = 0,
    WAYPOINT = 1,
    GRID = 2,
    POLYGON = 3
}

export namespace MissionType {
    export function getMissionTypeLanguage(missionType: MissionType): string {
        switch (missionType) {
            case MissionType.WAYPOINT:
                return "control.mission.type.mission-type.waypoint";
            case MissionType.GRID:
                return "control.mission.type.mission-type.grid";
            case MissionType.POLYGON:
                return "control.mission.type.mission-type.polygon";
            default:
                return "";
        }
    }

    export function getAll(): MissionType[] {
        return [MissionType.WAYPOINT, MissionType.GRID, MissionType.POLYGON];
    }
}
