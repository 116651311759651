import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {MachineSearchDto} from "../../../../../dto/machine/machineSearch.dto";
import {Observable} from "rxjs";
import {MachineDto} from "../../../../../dto/machine/machine.dto";
import {ResponseDto} from "../../../../../dto/response.dto";
import {APIUrl} from "../../../../../constants/api.url";

@Injectable()
export class MachineListService {
    constructor(private httpClient: HttpClient) {}

    public get(accountSeq: number, machineSearchDto: MachineSearchDto): Observable<MachineDto[]> {
        return new Observable<MachineDto[]>(observer => {
            this.httpClient.post<ResponseDto<MachineDto[]>>(APIUrl.MACHINE_LIST(accountSeq), machineSearchDto).subscribe(
                response => {
                    if (response.isSuccess) {
                        observer.next(response.data);
                    }
                }
            );
        });
    }

    public edit(machine: MachineDto): Observable<void> {
        return new Observable<void>(observer => {
            this.httpClient.post<ResponseDto<string>>(APIUrl.EDIT_MACHINE(machine.seq), machine).subscribe(
                response => {
                    if (response.isSuccess) {
                        observer.next();
                    }
                }
            );
        });
    }

    public delete(machineSeq: number): Observable<void> {
        return new Observable<void>(observer => {
            this.httpClient.delete<ResponseDto<string>>(APIUrl.DELETE_MACHINE(machineSeq)).subscribe(
                response => {
                    if (response.isSuccess) {
                        observer.next();
                    }
                }
            );
        });
    }
}
