import {NgModule} from "@angular/core";
import {LoginComponent} from "./login.component";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {LoginService} from "./service/login.service";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [
        LoginComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule
    ],
    providers: [
        LoginService
    ],
    exports: [
        LoginComponent
    ]
})
export class LoginModule {}
