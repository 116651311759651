import {Component} from "@angular/core";
import {FlightMissionData} from "../../../../../dto/statistics/flightRecord/flightMissionData";
import {MissionType} from "../../../../../dto/mission/setting/mission.type";
import {WeatherDto} from "../../../../../dto/mission/weather/weather.dto";
import {LightningCode} from "../../../../../dto/mission/weather/lightning.code";
import {PrecipitationCode} from "../../../../../dto/mission/weather/precipitation.code";
import {SkyCode} from "../../../../../dto/mission/weather/sky.code";
import {WindCode} from "../../../../../dto/mission/weather/wind.code";
import {MissionShapeFileDto} from "../../../../../dto/shapefile/missionShapefile.dto";
import {CoordinateType} from "../../../../../dto/shapefile/coordinate.type";
import {saveAs} from "file-saver";
import {DetailDialogService} from "./service/detail-dialog.service";
import {PopupService} from "../../../../../service/popup.service";
import {PopupDto} from "../../../../../dto/popup.dto";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: "detail-dialog",
    templateUrl: "./view/detail-dialog.component.html",
    styleUrls: ["./view/detail-dialog.component.css"]
})
export class DetailDialogComponent {
    public isVisible: boolean;
    public day: string;
    public flightMissions: FlightMissionData[];

    public isSpatialDataTypeSelectVisible: boolean = false;
    public isCoordinateSelectVisible: boolean = false;
    public selectedShapeFile: MissionShapeFileDto = new MissionShapeFileDto();
    public coordinateType: CoordinateType = CoordinateType.GRS80_EPSG_5186;

    constructor(
        private detailDialogService: DetailDialogService,
        private popupService: PopupService,
        private translateService: TranslateService) {

        this.isVisible = false;
        this.day = "";
        this.flightMissions = [];

        this.isSpatialDataTypeSelectVisible = false;
        this.isCoordinateSelectVisible = false;
        this.selectedShapeFile = new MissionShapeFileDto();
        this.coordinateType = CoordinateType.GRS80_EPSG_5186;
    }

    public setData(day: string, flightMissions: FlightMissionData[]): void {
        this.isVisible = true;
        this.day = day;
        this.flightMissions = flightMissions;
    }

    public getMissionTypeClass(typeCode: number): string {
        return MissionType[typeCode];
    }

    public getWeatherString(weatherDto: WeatherDto): string {
        let lightningCode: number = +LightningCode[weatherDto.lightningCode];
        let precipitationCode: number = +PrecipitationCode[weatherDto.precipitationCode];
        let skyCode: number = +SkyCode[weatherDto.skyCode];

        if (lightningCode == LightningCode.HIGH) {
            return LightningCode[lightningCode];
        }

        if (precipitationCode != PrecipitationCode.NONE) {
            return PrecipitationCode[precipitationCode];
        }

        if (skyCode != SkyCode.NONE) {
            return SkyCode[skyCode];
        }

        return "Unk";
    }

    public getWindString(weatherDto: WeatherDto): string {
        let windCode: number = +WindCode[weatherDto.windCode];

        if (windCode == WindCode.N2) {
            return "N";
        }

        return WindCode[windCode];
    }

    public getKpIndexClass(kpIndex: number): string {
        if (kpIndex < 4) {
            return "good";
        } else if (kpIndex == 4) {
            return "soso";
        } else {
            return "bad";
        }
    }

    public toggleSpatialDataTypeSelectVisible(missionHistorySeq: number = 0, missionName: string = ""): void {
        this.isSpatialDataTypeSelectVisible = !this.isSpatialDataTypeSelectVisible;

        if (this.isSpatialDataTypeSelectVisible) {
            this.selectedShapeFile = new MissionShapeFileDto();
            this.selectedShapeFile.missionHistorySeq = missionHistorySeq;
            this.selectedShapeFile.missionName = missionName;
        }
    }

    public downloadKmlFile(): void {
        this.detailDialogService.downloadKml(this.selectedShapeFile.missionHistorySeq).subscribe(
            kml => {
                if (kml.size == 0) {
                    let alertData: PopupDto = new PopupDto();
                    alertData.title = this.translateService.instant("statistics.flight-history.download-dialog.title");
                    alertData.message = this.translateService.instant("statistics.flight-history.download-dialog.message.kml-download-fail-guide");
                    alertData.isVisible = true;

                    this.popupService.publishAlert(alertData);
                } else {
                    let blob: Blob = new Blob([kml], {type: "octet/stream"});
                    saveAs(blob, this.selectedShapeFile.missionName + ".zip");
                }

                this.selectedShapeFile = new MissionShapeFileDto();
            }
        );
    }

    public showCoordinateSelectVisible(): void {
        this.isCoordinateSelectVisible = true;
    }

    public hideCoordinateSelectVisible(): void {
        this.selectedShapeFile = new MissionShapeFileDto();
        this.isCoordinateSelectVisible = false;
    }

    public getCoordinateList(): string[] {
        let keys: string[] = Object.keys(CoordinateType);
        return keys.slice(keys.length / 2);
    }

    public downloadShapeFile(): void {
        this.detailDialogService.downloadShapeFile(this.selectedShapeFile.missionHistorySeq, this.coordinateType).subscribe(
            shapeFile => {
                if (shapeFile.size == 0) {
                    let alertData: PopupDto = new PopupDto();
                    alertData.title = this.translateService.instant("statistics.flight-history.download-dialog.title");
                    alertData.message = this.translateService.instant("statistics.flight-history.download-dialog.message.shape-download-fail-guide");
                    alertData.isVisible = true;

                    this.popupService.publishAlert(alertData);
                } else {
                    let blob: Blob = new Blob([shapeFile], {type: "octet/stream"});
                    saveAs(blob, "shapeFiles.zip");
                }
            }
        );
    }

    public close(): void {
        this.isVisible = false;
    }
}
