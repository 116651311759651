import {NgModule} from "@angular/core";
import {ProfileComponent} from "./profile.component";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {ProfileService} from "./service/profile.service";

@NgModule({
    declarations: [ProfileComponent],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule
    ],
    providers: [ProfileService],
    exports: [ProfileComponent]
})
export class ProfileModule {}
