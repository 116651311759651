import { Label, BaseChartDirective } from 'ng2-charts';
import { ChartDataSets } from 'chart.js';

export abstract class StatisticsChart {
    protected chartType: string = "";
    protected chartLabels: Label[] = [];
    protected chartData: ChartDataSets[] = [{}];
    protected pieChartData: number[] = [];
    protected chartColors: any[] = [{}];
    protected chartLegend: boolean = true;
    protected chartOptions: any = {};
    protected chartPlugins: any = [];

    protected chartComponent: BaseChartDirective;

    constructor(chartType: string) {
        this.chartType = chartType;
        this.chartOptions = {
            responseive: true,
            maintainAspectRatio: false
        };
    }

    public getChartType(): string {
        return this.chartType;
    }

    public getChartLabels(): Label[] {
        return this.chartLabels;
    }

    public setChartLabels(chartLabels: Label[]): void {
        this.chartLabels = chartLabels;
    }

    public getChartData(): ChartDataSets[] {
        return this.chartData;
    }

    public setChartData(chartData: ChartDataSets[]): void {
        this.chartData = chartData;
    }

    public getPieChartData(): number[] {
        return this.pieChartData;
    }

    public setPieChartData(chartData: number[]): void {
        this.pieChartData = chartData;
    }

    public getChartLegend(): boolean {
        return this.chartLegend;
    }

    public setChartLegend(chartLegend: boolean): void {
        this.chartLegend = chartLegend;
    }

    public setChartComponent(chartComponent: BaseChartDirective): void {
        this.chartComponent = chartComponent;
    }

    public getChartOptions(): boolean {
        return this.chartOptions;
    }

    public getChartPlugins(): any {
        return this.chartPlugins;
    }

    public abstract getChartColors(): any[];
    public abstract setChartColors(): void;
    public abstract setChartOptions(): void;
    public abstract setChartPlugins(): void;
}