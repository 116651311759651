export enum StatisticsDateTypeCode {
    Monthly, Weekly, Daily
}

export namespace StatisticsDateTypeCode {
    export function getStatisticsDateTypeCodeLanguage(statisticsDateTypeCode: StatisticsDateTypeCode): string {
        switch (statisticsDateTypeCode) {
            case StatisticsDateTypeCode.Monthly:
                return "statistics.label.monthly";
            case StatisticsDateTypeCode.Weekly:
                return "statistics.label.weekly";
            case StatisticsDateTypeCode.Daily:
                return "statistics.label.daily";
        }
    }

    export function getAll(): StatisticsDateTypeCode[] {
        return [
            StatisticsDateTypeCode.Monthly,
            StatisticsDateTypeCode.Weekly,
            StatisticsDateTypeCode.Daily
        ];
    }
}
