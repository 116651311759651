import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {APIUrl} from "../../../../../../constants/api.url";
import {CoordinateType} from "../../../../../../dto/shapefile/coordinate.type";

@Injectable()
export class DetailDialogService {
    constructor(private httpClient: HttpClient) {}

    public downloadKml(missionHistorySeq: number): Observable<Blob> {
        return new Observable<Blob>(observer => {
            this.httpClient.get(APIUrl.KML_DOWNLOAD_WIDTH_STARTEND(missionHistorySeq), {responseType: "blob"}).subscribe(
                kml => {
                    observer.next(kml);
                }
            );
        });
    }

    public downloadShapeFile(missionHistorySeq: number, coordinateType: CoordinateType): Observable<Blob> {
        return new Observable<Blob>(observer => {
            this.httpClient.get(APIUrl.SHAPEFILE_DOWNLOAD_WITH_STARTEND(missionHistorySeq, coordinateType), {responseType: "blob"}).subscribe(
                shapeFile => {
                    observer.next(shapeFile);
                }
            );
        });
    }
}
