import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {ControlComponent} from "./control.component";
import {MatTooltipModule} from "@angular/material";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ContextMenuModule} from "ngx-contextmenu";
import {DbfHorizontalScrollbarDirective} from "../../../directive/dbfHorizontalScrollbar.directive";
import {DbfVerticalScrollbarDirective} from "../../../directive/dbfVerticalScrollbar.directive";
import {SlideshowModule} from "ng-simple-slideshow";
import {MatSliderModule} from "@angular/material/slider";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {AngularDraggableModule} from "angular2-draggable";
import {DragToSelectModule} from "ngx-drag-to-select";
import {ScrollBarSharedModule} from "../../common/scroll-bar.shared.module";
import {MachineListModule} from "./machine/machine-list.module";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [
        ControlComponent,
        DbfHorizontalScrollbarDirective,
        DbfVerticalScrollbarDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        MachineListModule,
        ContextMenuModule.forRoot(),
        AngularDraggableModule,
        DragToSelectModule.forRoot(),
        SlideshowModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatTooltipModule,
        BrowserAnimationsModule,
        ScrollBarSharedModule,
        TranslateModule
    ],
    exports: [
        ControlComponent
    ]
})
export class ControlModule {}
