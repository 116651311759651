import { SkyCode } from './sky.code';
import { PrecipitationCode } from './precipitation.code';
import { LightningCode } from './lightning.code';
import { WindCode } from './wind.code';

export class WeatherDto {
    constructor(
        public skyCode: SkyCode = SkyCode.NONE,
        public precipitationCode: PrecipitationCode = PrecipitationCode.NONE,
        public lightningCode: LightningCode = LightningCode.NONE,
        public temperature: number = 0,
        public windCode: WindCode = WindCode.N,
        public windSpeed: number = 0
    ) {}
}