export class LivemapDto {
    constructor(
        public latitude: number = 0,
        public longitude: number = 0,
        public yaw: number = 0,
        public altitude: number = 0,
        public width: number = 0,
        public height: number = 0,
        public fileName: string = ""
    ) {}
}