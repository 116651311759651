import { Offset } from '../../mission/mission/offset';

export class AreaInfo {
    private point: Offset = new Offset();
    private area: number;
    private unitName: string = "m²";

    public setPoint(point: Offset): void {
        this.point = point;
    }

    public getPoint(): Offset {
        return this.point;
    }

    public setArea(area: number): void {
        if (area > 1000000) {
            this.unitName = "km²";
            this.area = +(area / 1000000).toFixed(2);
        } else {
            this.area = area;
        }
    }

    public getArea(): number {
        return this.area;
    }

    public getUnitName(): string {
        return this.unitName;
    }
}