export enum DirectionCode {
    AUTO = 0,
    START_DIRECTION = 1,
    NORTH = 2,
    NORTH_EAST = 3,
    EAST = 4,
    SOUTH_EAST = 5,
    SOUTH = 6,
    SOUTH_WEST = 7,
    WEST = 8,
    NORTH_WEST = 9
}

export namespace DirectionCode {
    export function getDirectionCodeLanguage(directionCode: DirectionCode): string {
        switch (directionCode) {
            case DirectionCode.AUTO:
                return "control.mission.type.direction-type.auto";
            case DirectionCode.START_DIRECTION:
                return "control.mission.type.direction-type.start-direction";
            case DirectionCode.NORTH:
                return "control.mission.type.direction-type.north";
            case DirectionCode.NORTH_EAST:
                return "control.mission.type.direction-type.north-east";
            case DirectionCode.EAST:
                return "control.mission.type.direction-type.east";
            case DirectionCode.SOUTH_EAST:
                return "control.mission.type.direction-type.south-east";
            case DirectionCode.SOUTH:
                return "control.mission.type.direction-type.south";
            case DirectionCode.SOUTH_WEST:
                return "control.mission.type.direction-type.south-west";
            case DirectionCode.WEST:
                return "control.mission.type.direction-type.west";
            case DirectionCode.NORTH_WEST:
                return "control.mission.type.direction-type.north-west";
        }
    }

    export function getAll(): DirectionCode[] {
        return [
            DirectionCode.AUTO, DirectionCode.START_DIRECTION, DirectionCode.NORTH, DirectionCode.NORTH_EAST,
            DirectionCode.EAST, DirectionCode.SOUTH_EAST, DirectionCode.SOUTH, DirectionCode.SOUTH_WEST,
            DirectionCode.WEST, DirectionCode.NORTH_WEST
        ];
    }
}
