import { timer } from 'rxjs';
import { Offset } from '../mission/offset';
import { Point } from '../mission/point';
import { Map } from './map';

declare const google: any;

export class GoogleMap extends Map {
    public getAddressOfCenterPoint(callback: any): void {}
    public getAddreessOfPoint(callback: any, latitude: number, longitude: number): void {}

    public loadMap(containerId: string, latitude: number, longitude: number, zoomLevel: number): void {
        this.map = new google.maps.Map(
            document.getElementById(containerId),
            {
                zoom: zoomLevel,
                center: {
                    lat: latitude,
                    lng: longitude
                },
                disableDefaultUI: true,
                options: {
                    gestureHandling: "greedy"
                }
            }
        );

        timer(1000).subscribe(() => {
            this.setCenterOffset();
        });
    }

    public setMapType(isNormal: boolean): void {
        if (isNormal) {
            this.map.setMapTypeId("roadmap");
        } else {
            this.map.setMapTypeId("hybrid");
        }
    }

    public moveTo(point: Point): void {
        let zoomLevel: number = (this.map.getZoom() >= 17)? this.map.getZoom() : 17;
        let newCenter: any = new google.maps.LatLng(point.coordY, point.coordX);

        this.map.panTo(newCenter);
        this.map.setZoom(zoomLevel);
    }

    public moveToBoundary(leftBottom: Point, rightTop: Point): void {}

    public getDistance(point1: Point, point2: Point): number {
        const R: number = this.getEarthRadius(point1.coordY);
        const radianPerDegree: number = Math.PI / 180;
        const dLat: number = (point2.coordY - point1.coordY) * radianPerDegree;
        const dLng: number = (point2.coordX - point1.coordX) * radianPerDegree;

        let exp1: number = Math.pow(Math.sin(dLat / 2), 2);
        let exp2: number = Math.cos(point1.coordY * radianPerDegree) * Math.cos(point2.coordY * radianPerDegree);
        let exp3: number = Math.pow(Math.sin(dLng / 2), 2);

        let exp4: number = exp1 + exp2 * exp3;
        let exp5: number = 2 * Math.atan2(Math.sqrt(exp4), Math.sqrt(1 - exp4));

        return R * exp5 * 1000;
    }

    private getEarthRadius(latitude: number): number {
        const equaterRadius: number = 6378.137;
        const polesRadius: number = 6356.752;
        const latCosine: number = Math.cos(latitude * Math.PI / 180);
        const latSine: number = Math.sin(latitude * Math.PI / 180);

        let exp1: number = Math.pow(Math.pow(equaterRadius, 2) * latCosine, 2);
        let exp2: number = Math.pow(Math.pow(polesRadius, 2) * latSine, 2);
        let exp3: number = Math.pow(equaterRadius * latCosine, 2);
        let exp4: number = Math.pow(polesRadius * latSine, 2);

        return Math.sqrt((exp1 + exp2) / (exp3 + exp4));
    }

    public convertPointToOffset(point: Point): Offset {
        let coord: any = new google.maps.LatLng(point.coordY, point.coordX);
        let offset: any = this.map.getProjection().fromLatLngToPoint(coord);

        let topRightOffset: any = this.map.getProjection().fromLatLngToPoint(this.map.getBounds().getNorthEast());
        let bottomLeftOffset: any = this.map.getProjection().fromLatLngToPoint(this.map.getBounds().getSouthWest());
        let scale: number = Math.pow(2, this.map.getZoom());

        return new Offset(
            (offset.x - bottomLeftOffset.x) * scale,
            (offset.y - topRightOffset.y) * scale
        );
    }

    public convertCoordToOffset(point: Offset): Offset {
        let coord: any = new google.maps.LatLng(point.y, point.x);
        let offset: any = this.map.getProjection().fromLatLngToPoint(coord);

        let topRightOffset: any = this.map.getProjection().fromLatLngToPoint(this.map.getBounds().getNorthEast());
        let bottomLeftOffset: any = this.map.getProjection().fromLatLngToPoint(this.map.getBounds().getSouthWest());
        let scale: number = Math.pow(2, this.map.getZoom());

        return new Offset(
            (offset.x - bottomLeftOffset.x) * scale,
            (offset.y - topRightOffset.y) * scale
        );
    }

    public convertOffsetToCoord(offset: Offset): Offset {
        let topRightOffset: any = this.map.getProjection().fromLatLngToPoint(this.map.getBounds().getNorthEast());
        let bottomLeftOffset: any = this.map.getProjection().fromLatLngToPoint(this.map.getBounds().getSouthWest());
        var scale = 1 << this.map.getZoom();

        let nOffset: any = new google.maps.Point(
            offset.x / scale + bottomLeftOffset.x,
            offset.y / scale + topRightOffset.y
        );

        let coord: any = this.map.getProjection().fromPointToLatLng(nOffset);

        return new Offset(coord.lng(), coord.lat());
    }

    public setCenterOffset(): void {
        let point: any = this.map.getCenter();
        let offset: any = this.map.getProjection().fromLatLngToPoint(point);

        let topRightOffset: any = this.map.getProjection().fromLatLngToPoint(this.map.getBounds().getNorthEast());
        let bottomLeftOffset: any = this.map.getProjection().fromLatLngToPoint(this.map.getBounds().getSouthWest());
        let scale: number = Math.pow(2, this.map.getZoom());

        this.centerPoint = new Point(
            point.lng(),
            point.lat(),
            (offset.x - bottomLeftOffset.x) * scale,
            (offset.y - topRightOffset.y) * scale,
            0,
            0
        );
    }

    public getContainerOffset(): Offset {
        // not used in google maps
        return new Offset();
    }

    public clearEventListeners(): void {
        if (this.mapListener.clickListener != null) {
            google.maps.event.removeListener(this.mapListener.clickListener);
            this.mapListener.clickListener = null;
        }

        if (this.mapListener.centerChangedListener != null) {
            google.maps.event.removeListener(this.mapListener.centerChangedListener);
            this.mapListener.centerChangedListener = null;
        }

        if (this.mapListener.zoomChangedListener != null) {
            google.maps.event.removeListener(this.mapListener.zoomChangedListener);
            this.mapListener.zoomChangedListener = null;
        }

        if (this.mapListener.mouseupListener != null) {
            google.maps.event.removeListener(this.mapListener.mouseupListener);
            this.mapListener.mouseupListener = null;
        }
    }

    public setClickEventListener(callback: any): void {
        this.mapListener.clickListener = google.maps.event.addListener(this.map, "click", function (event: any): void {
            let offset: any = this.map.getProjection().fromLatLngToPoint(event.latLng);

            let topRightOffset: any = this.map.getProjection().fromLatLngToPoint(this.map.getBounds().getNorthEast());
            let bottomLeftOffset: any = this.map.getProjection().fromLatLngToPoint(this.map.getBounds().getSouthWest());
            let scale: number = Math.pow(2, this.map.getZoom());

            offset.x = (offset.x - bottomLeftOffset.x) * scale;
            offset.y = (offset.y - topRightOffset.y) * scale;

            callback(
                {
                    x: event.latLng.lng(),
                    y: event.latLng.lat()
                },
                offset
            );
        }.bind(this));
    }

    public setMapMoveEventListener(callback: any): void {
        this.mapListener.centerChangedListener = google.maps.event.addListener(this.map, "center_changed", function (event: any) {
            let centerPoint: Point = this.getCenterPoint();

            let topRightOffset: any = this.map.getProjection().fromLatLngToPoint(this.map.getBounds().getNorthEast());
            let bottomLeftOffset: any = this.map.getProjection().fromLatLngToPoint(this.map.getBounds().getSouthWest());
            let scale: number = Math.pow(2, this.map.getZoom());

            let nowOffset: any = this.map.getProjection().fromLatLngToPoint(this.map.getCenter());
            let nowPoint: Point = new Point(
                this.map.getCenter().lng(),
                this.map.getCenter().lat(),
                (nowOffset.x - bottomLeftOffset.x) * scale,
                (nowOffset.y - topRightOffset.y) * scale
            );

            callback(new Offset(centerPoint.offsetX, centerPoint.offsetY), nowPoint);
        }.bind(this));
    }

    public setZoomingEventListener(callback: any): void {
        // not working in google maps
    }

    public setZoomChangedEventListener(callback: any): void {
        this.mapListener.zoomChangedListener = google.maps.event.addListener(this.map, "zoom_changed", function (event: any) {
            callback(this.map.getZoom());
        }.bind(this));
    }

    public getZoomLevel(): number {
        return this.map.getZoom();
    }

    public setZoomLevel(level: number): void {
        this.map.setZoom(level);
    }

    public setMouseUpEventListener(callback: any): void {
        this.mapListener.mouseupListener = google.maps.event.addListener(this.map, "mouseup", function (event: any) {
            callback();
        });
    }

    public getOffsetXPerMeter(lngPerMeter: number): number {
        let lng1: any = new google.maps.LatLng(this.centerPoint.coordY, this.centerPoint.coordX + lngPerMeter);
        let lng2: any = new google.maps.LatLng(this.centerPoint.coordY, this.centerPoint.coordX + lngPerMeter * 2);

        let bottomLeftOffset: any = this.map.getProjection().fromLatLngToPoint(this.map.getBounds().getSouthWest());
        let scale: number = Math.pow(2, this.map.getZoom());

        let offset1: any = this.map.getProjection().fromLatLngToPoint(lng1);
        let offset2: any = this.map.getProjection().fromLatLngToPoint(lng2);

        return Math.abs(
            (offset2.x - bottomLeftOffset.x) * scale - (offset1.x - bottomLeftOffset.x) * scale
        );
    }

    public getOffsetYPerMeter(latPerMeter: number): number {
        let lat1: any = new google.maps.LatLng(this.centerPoint.coordY + latPerMeter, this.centerPoint.coordX);
        let lat2: any = new google.maps.LatLng(this.centerPoint.coordY + latPerMeter * 2, this.centerPoint.coordX);

        let topRightOffset: any = this.map.getProjection().fromLatLngToPoint(this.map.getBounds().getNorthEast());
        let scale: number = Math.pow(2, this.map.getZoom());

        let offset1: any = this.map.getProjection().fromLatLngToPoint(lat1);
        let offset2: any = this.map.getProjection().fromLatLngToPoint(lat2);

        return Math.abs(
            (offset2.y - topRightOffset.y) * scale - (offset1.y - topRightOffset.y) * scale
        );
    }

    public getRectanglePointList(latPerMeter: number, lngPerMeter: number, radius: number): Point[] {
        let resultList: Point[] = [];
        let pointList: any[] = [
            new google.maps.LatLng(this.centerPoint.coordY - (latPerMeter * radius), this.centerPoint.coordX - (lngPerMeter * radius)),
            new google.maps.LatLng(this.centerPoint.coordY - (latPerMeter * radius), this.centerPoint.coordX + (lngPerMeter * radius)),
            new google.maps.LatLng(this.centerPoint.coordY + (latPerMeter * radius), this.centerPoint.coordX + (lngPerMeter * radius)),
            new google.maps.LatLng(this.centerPoint.coordY + (latPerMeter * radius), this.centerPoint.coordX - (lngPerMeter * radius))
        ];

        let topRightOffset: any = this.map.getProjection().fromLatLngToPoint(this.map.getBounds().getNorthEast());
        let bottomLeftOffset: any = this.map.getProjection().fromLatLngToPoint(this.map.getBounds().getSouthWest());
        let scale: number = Math.pow(2, this.map.getZoom());

        pointList.forEach(point => {
            let offset: any = this.map.getProjection().fromLatLngToPoint(point);

            resultList.push(
                new Point(
                    point.lng(),
                    point.lat(),
                    (offset.x - bottomLeftOffset.x) * scale,
                    (offset.y - topRightOffset.y) * scale,
                    0,
                    0
                )
            );
        });

        return resultList.slice().reverse();
    }

    public getMeterPerPixel(): number {
        let topRightOffset: any = this.map.getProjection().fromLatLngToPoint(this.map.getBounds().getNorthEast());
        let bottomLeftOffset: any = this.map.getProjection().fromLatLngToPoint(this.map.getBounds().getSouthWest());
        var scale = 1 << this.map.getZoom();

        let point1: any = new google.maps.Point(bottomLeftOffset.x, topRightOffset.y);
        let point2: any = new google.maps.Point(1 / scale + bottomLeftOffset.x, topRightOffset.y);

        let coord1: any = this.map.getProjection().fromPointToLatLng(point1);
        let coord2: any = this.map.getProjection().fromPointToLatLng(point2);

        return this.getDistance(
            new Point(coord1.lng(), coord1.lat()),
            new Point(coord2.lng(), coord2.lat())
        );
    }
}
