import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {JoinDto} from "../../../../../dto/account/join.dto";
import {Observable} from "rxjs";
import {ResponseDto} from "../../../../../dto/response.dto";
import {APIUrl} from "../../../../../constants/api.url";

@Injectable()
export class UserCreateService {
    constructor(private httpClient: HttpClient) {}

    public edit(joinDto: JoinDto): Observable<void> {
        return new Observable<void>(observer => {
            this.httpClient.post<ResponseDto<string>>(APIUrl.EDIT_MEMBER, joinDto).subscribe(
                response => {
                    if (response.isSuccess) {
                        observer.next();
                    }
                }
            );
        });
    }
}
