import {Component} from "@angular/core";
import {StatisticsSummaryService} from "./service/statistics-summary.service";
import {SummaryDto} from "../../../../dto/statistics/summaryDto";
import {StatisticsTabMenuCode} from "../data/statisticsTabMenu.code";

@Component({
    selector: "summary",
    templateUrl: "./view/statistics-summary.component.html",
    styleUrls: ["./view/statistics-summary.component.css"]
})
export class StatisticsSummaryComponent {
    public statisticsTabMenuCode: typeof StatisticsTabMenuCode;
    public summaryDto: SummaryDto;

    constructor(private statisticsSummaryService: StatisticsSummaryService) {
        this.statisticsTabMenuCode = StatisticsTabMenuCode;
    }

    ngOnInit() {
        this.getData();
    }

    private getData(): void {
        this.statisticsSummaryService.getData().subscribe(summaryDto => this.summaryDto = summaryDto);
    }
}
