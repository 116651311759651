import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MessageService {
    private received: Subject<boolean> = new Subject<boolean>();
    public received$: any;

    constructor() {
        this.received$ = this.received.asObservable();
    }

    public publishReceived(received: boolean): void {
        this.received.next(received);
    }
}