import {Figure} from "../mission/polygon/figure";
import {MissionDto} from "src/app/dto/mission/mission.dto";
import {MissionType} from "src/app/dto/mission/setting/mission.type";
import {MapService} from "src/app/service/map.service";
import {WaypointFigure} from "../mission/polygon/waypointFigure";
import {LatLng} from "../mission/polygon/latLng";
import {GridFigure} from "../mission/polygon/gridFigure";
import {PolygonFigure} from "../mission/polygon/polygonFigure";
import {HttpService} from "src/app/service/http.service";
import {HTTPCallBack} from "src/app/service/http.callback";
import {ResponseDto} from "src/app/dto/response.dto";
import {PointDto} from "src/app/dto/mission/point.dto";
import {Offset} from "../mission/mission/offset";
import {Point} from "../mission/mission/point";
import {APIUrl} from "src/app/constants/api.url";
import {PointListDto} from "src/app/dto/mission/pointList.dto";
import {WaypointOption} from "../mission/polygon/waypointOption";
import {WaypointService} from "../mission/polygon/waypointService";

export class MissionArea {
    public machineSeq: number;
    private fov: number;
    public figure: Figure;
    public mission: MissionDto;

    private mapService: MapService;
    private httpService: HttpService;
    private waypointService: WaypointService;
    private latLng: LatLng;

    private waypointList: Offset[];

    private missionLoadCallback: any;

    constructor(machineSeq: number, fov: number, mapService: MapService, httpService: HttpService, mission: MissionDto, missionLoadCallback: any) {
        this.machineSeq = machineSeq;
        this.fov = fov;
        this.mapService = mapService;
        this.httpService = httpService;
        this.mission = mission;
        this.missionLoadCallback = missionLoadCallback;

        this.latLng = new LatLng();
        this.latLng.calc(mapService.map.getCenterPoint());

        this.waypointService = new WaypointService(this.mapService.map);

        this.setFigure();
        this.setMissionData();
    }

    private setFigure(): void {
        switch (this.mission.typeCode) {
            case MissionType.WAYPOINT :
                this.figure = new WaypointFigure(this.mapService.map, this.latLng, null, null);
                break;
            case MissionType.GRID :
                this.figure = new GridFigure(this.mapService.map, this.latLng, null, null);
                break;
            case MissionType.POLYGON :
                this.figure = new PolygonFigure(this.mapService.map, this.latLng, null, null);
                break;
            default :
                // none
                break;
        }
    }

    private setMissionData(): void {
        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<PointListDto> = res;

            if (response.isSuccess) {
                let pointList: PointDto[] = [];
                let cornorPointList: Point[] = [];

                if (this.mission.typeCode == MissionType.WAYPOINT) {
                    pointList = JSON.parse(response.data.pointData);
                } else {
                    pointList = JSON.parse(response.data.coordinateData);
                }

                let mapContainer: Offset = this.mapService.map.getContainerOffset() || new Offset();

                pointList.forEach((item, key) => {
                    let point: Point = new Point(item.lng, item.lat, 0, 0, 0, 0, item.altitude, item.gimbalAngle, item.point_action);
                    let offset: Offset = this.mapService.map.convertPointToOffset(point);

                    if (this.mission.typeCode == MissionType.WAYPOINT) {
                        point.offsetX = offset.x + mapContainer.x;
                        point.offsetY = offset.y + mapContainer.y;

                        cornorPointList.push(point);
                    } else {
                        if (this.mission.typeCode == MissionType.POLYGON) {
                            point.offsetX = offset.x + mapContainer.x;
                            point.offsetY = offset.y + mapContainer.y;

                            cornorPointList.push(point);
                        } else {
                            if (key % 2 == 0) {
                                point.offsetX = offset.x + mapContainer.x;
                                point.offsetY = offset.y + mapContainer.y;

                                cornorPointList.push(point);
                            }
                        }
                    }
                });

                if (response.data.isMachineData && this.mission.typeCode == MissionType.GRID) {
                    cornorPointList.push(cornorPointList.shift());
                }

                this.figure.setCornorPointList(cornorPointList);

                if (this.mission.typeCode == MissionType.POLYGON) {
                    if (response.data.isMachineData) {
                        this.figure.setPolygonAngle(this.mission.angle * -1);
                    } else {
                        this.figure.setPolygonAngle(this.mission.angle);
                    }
                }

                this.setMissionWayPoint();
            }
        }.bind(this);

        httpCallback.error = function (): void {
            // error occured
        };

        this.httpService.get(APIUrl.MISSION_DATA(this.mission.seq), httpCallback);
    }

    public setMissionWayPoint(): void {
        if (this.figure.cornorPointList.length == 0) {
            return;
        }

        if (this.mission.typeCode == MissionType.WAYPOINT) {
            this.missionLoadCallback(this);
            return;
        }

        let pointList: Offset[] = this.figure.getCornorPointListByOffset();

        if (this.mission.typeCode == MissionType.GRID) {
            this.figure.setAngle();
        }

        let waypointOption: WaypointOption = new WaypointOption(
            pointList, this.figure.getAngle(), this.mission.missionAngle,
            this.mission.frontRatio / 100, this.mission.sideRatio / 100,
            this.mission.altitude, this.fov, this.mission.aspectRatioTypeCode, this.latLng
        );

        this.waypointList = this.waypointService.make(waypointOption);
        this.missionLoadCallback(this);
    }

    public getCornorPointList(): Point[] {
        return this.figure.getCornorPointList();
    }

    public getWaypointList(): Offset[] {
        return this.waypointList;
    }

    public getStartEndPoint(): Offset[] {
        return [this.waypointList[0], this.waypointList[this.waypointList.length - 1]];
    }
}
