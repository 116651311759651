import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {JoinRequestDto} from "../dto/join-request.dto";
import {APIUrl} from "../../../../../constants/api.url";
import {ResponseDto} from "../../../../../dto/response.dto";

@Injectable()
export class InfoService {
    constructor(private httpClient: HttpClient) {}

    public checkEmail(email: string): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.httpClient.get<ResponseDto<void>>(APIUrl.EMAIL_CHECK(email)).subscribe(
                response => {
                    observer.next(response.isSuccess);
                }, _ => {
                    observer.next(false);
                }
            );
        });
    }

    public signUp(joinRequestDto: JoinRequestDto): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.httpClient.put<ResponseDto<string>>(APIUrl.JOIN, joinRequestDto).subscribe(
                response => {
                    observer.next(response.isSuccess);
                }, _ => {
                    observer.error();
                }
            );
        });
    }
}
