export enum AspectRatioType {
    RATIO_4TO3,
    RATIO_16TO9,
    RATIO_3TO2,
    RATIO_5TO4
}

export const AspectRatioTypeLabel = new Map<number, string>([
    [AspectRatioType.RATIO_4TO3, "4:3"] ,
    [AspectRatioType.RATIO_16TO9, "16:9"] ,
    [AspectRatioType.RATIO_3TO2, "3:2"],
    [AspectRatioType.RATIO_5TO4, "5:4"]
]);

export const WidthRatio = new Map<number, number>([
    [AspectRatioType.RATIO_4TO3, 4],
    [AspectRatioType.RATIO_16TO9, 16],
    [AspectRatioType.RATIO_3TO2, 3],
    [AspectRatioType.RATIO_5TO4, 5],
]);

export const HeightRatio = new Map<number, number>([
    [AspectRatioType.RATIO_4TO3, 3],
    [AspectRatioType.RATIO_16TO9, 9],
    [AspectRatioType.RATIO_3TO2, 2],
    [AspectRatioType.RATIO_5TO4, 4]
]);