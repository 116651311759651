import { Offset } from './offset';

declare const isect: any;

export class CrossLine {
    constructor(
        public start: Offset = new Offset(),
        public end: Offset = new Offset(),
        public intersectList: Offset[] = []
    ) {}

    public checkIntersectPoint(target: CrossLine[]): void {
        let lineList: any = [];
        lineList.push({
            from: {x: this.start.x, y: this.start.y},
            to: {x: this.end.x, y: this.end.y}
        });

        target.forEach(point => {
            lineList.push({
                from: {x: point.start.x, y: point.start.y},
                to: {x: point.end.x, y: point.end.y}
            });
        });

        let findedList: any[] = isect.sweep(lineList).run();
        findedList.forEach(item => {
            this.intersectList.push(new Offset(
                item.point.x, item.point.y
            ));
        });

        this.intersectList.reverse();
    }

    public sort(lastWaypoint: Offset): void {
        if (lastWaypoint == null) {
            lastWaypoint = this.intersectList[0];
        }

        let distanceToStart: number = this.getDistance(lastWaypoint, this.intersectList[0]);
        let distanceToEnd: number = this.getDistance(lastWaypoint, this.intersectList[this.intersectList.length - 1]);

        if (distanceToStart == 0 && distanceToEnd == 0) {
            return;
        }

        if (distanceToEnd < distanceToStart) {
            this.intersectList.reverse();
        }
    }

    private getDistance(point1: Offset, point2: Offset): number {
        try {
            return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
        } catch (error) {
            return 0;
        }
    }
}
