import { MissionSearchCode } from './missionSearch.code';
import { MissionType } from './setting/mission.type';

export class MissionSearchDto {
    constructor(
        public text: string = "",
        public missionType: MissionType = MissionType.FREE,
        public searchCode: MissionSearchCode = MissionSearchCode.Name,
        public groupSeqList: number[] = []
    ) {}
}