import {Paho} from "ng2-mqtt/mqttws31";
import {MqttConfig} from "src/app/constants/mqtt.config";
import {AppMessage} from "src/app/dto/control/appMessage";
import {MissionControl} from "src/app/dto/control/missionControl";
import {WebClientRequestDto} from "src/app/dto/control/webClientRequest";
import {WebServerResponse} from "src/app/dto/control/webServerResponse";
import {MachineDto} from "src/app/dto/machine/machine.dto";
import {RandomUtil} from "./random.util";

export class MqttService {
    public client: any = null;
    private machine: MachineDto = null;

    public messageList: AppMessage[] = [];
    public appMessage: AppMessage = new AppMessage();

    private dialogScrollToBottom: any;
    private statusCallback: any;
    private controlCallback: any;
    private selfMissionStartCallback: any;
    private messageReceivedCallback: any;
    private streamingCallback: any;
    private livemapCallback: any;
    private audioCallback: any;

    public init(machine: MachineDto, dialogScrollToBottom: any): void {
        this.machine = machine;
        this.appMessage.accountEmail = machine.accountEmail;
        this.dialogScrollToBottom = dialogScrollToBottom;

        this.client = new Paho.MQTT.Client(MqttConfig.BROKER_URL, MqttConfig.BROKER_PORT, "web-" + RandomUtil.randomString(10));
        this.setOnMessageArrivedListener();

        this.client.connect({
            // ent
            onSuccess: this.setOnConnectionListener.bind(this), useSSL: true,
            // dev
            // onSuccess: this.setOnConnectionListener.bind(this), useSSL: false,
            onFailure: (err) => {
                console.log(err);
            }
        });
    }

    public isConnected(): boolean {
        if (this.client != null && this.client.isConnected()) {
            return true;
        }

        return false;
    }

    public disconnect(): void {
        if (this.client != null && this.isConnected()) {
            this.client.unsubscribe(MqttConfig.TOPIC_PREFIX + this.machine.accountEmail);
            this.client.disconnect();
        }
    }

    private setOnConnectionListener(): void {
        this.client.subscribe(MqttConfig.TOPIC_PREFIX + this.machine.accountEmail);
        this.client.subscribe(MqttConfig.TOPIC_PREFIX + this.machine.accountEmail + "/livemap");
    }

    public setStatusSubscriber(): void {
        this.client.subscribe(MqttConfig.TOPIC_PREFIX + this.machine.accountEmail + "/droneStatus");
    }

    public clearStatusSubscriber(): void {
        this.client.unsubscribe(MqttConfig.TOPIC_PREFIX + this.machine.accountEmail + "/droneStatus");
    }

    public setControlCallback(controlCallback: any): void {
        this.controlCallback = controlCallback;
    }

    public setStatusCallback(statusCallback: any): void {
        this.statusCallback = statusCallback;
    }

    public setSelfMissionStartCallback(selfMissionStartCallback: any): void {
        this.selfMissionStartCallback = selfMissionStartCallback;
    }

    public setMessageReceivedCallback(messageReceivedCallback: any): void {
        this.messageReceivedCallback = messageReceivedCallback;
    }

    public setStreamingCallback(streamingCallback: any): void {
        this.streamingCallback = streamingCallback;
    }

    public setAudioCallback(audioCallback: any): void {
        this.audioCallback = audioCallback;
    }

    private addAudioMessage(fromApp: boolean): void {
        this.endAudioMessage(fromApp);

        let audioMessage: AppMessage = new AppMessage();
        audioMessage.fromApp = fromApp;
        audioMessage.accountEmail = this.machine.accountEmail;
        audioMessage.isAudio = true;
        audioMessage.isAudioPlay = true;
        audioMessage.startStreamingTimeInterval();

        let date: Date = new Date();
        let hour: string = (date.getHours() > 9) ? "" + date.getHours() : "0" + date.getHours();
        let min: string = (date.getMinutes() > 9) ? "" + date.getMinutes() : "0" + date.getMinutes();
        let sec: string = (date.getSeconds() > 9) ? "" + date.getSeconds() : "0" + date.getSeconds();
        audioMessage.sendTime = hour + ":" + min + ":" + sec;
        audioMessage.sendUnixTime = new Date().getTime() / 1000 | 0;

        this.messageList.push(audioMessage);
        this.dialogScrollToBottom();
    }

    private endAudioMessage(fromApp: boolean): void {
        this.messageList.forEach(message => {
            if (message.isAudio && message.isAudioPlay && message.fromApp == fromApp) {
                message.isAudioPlay = false;
                message.stopStreamingTimeInterval();
            }
        });
    }

    public setLivemapCallback(livemapCallback: any): void {
        this.livemapCallback = livemapCallback;
    }

    private setOnMessageArrivedListener(): void {
        this.client.onMessageArrived = (message: Paho.MQTT.Message) => {
            let response: WebServerResponse = JSON.parse(message.payloadString);

            if (response.result) {
                let responseType: string = "" + response.responseType;

                switch (responseType) {
                    case "MESSAGE" :
                        response.appMessage.sendUnixTime = new Date().getTime() / 1000 | 0;
                        this.messageReceivedCallback();
                        this.messageList.push(response.appMessage);
                        this.dialogScrollToBottom();
                        break;
                    case "DRONE_STATUS" :
                        this.statusCallback(response.droneStatusList);
                        break;
                    case "MISSION_CONTROL" :
                        this.controlCallback(response.missionControlResponse);
                        break;
                    case "SELF_MISSION_START_RES" :
                        this.selfMissionStartCallback(response.mission);
                        break;
                    case "STREAMING_START" :
                        this.streamingCallback(response.streamingOk, true);
                        break;
                    case "STREAMING_STOP" :
                        this.streamingCallback(response.streamingOk, false);
                        break;
                    case "AUDIO_START" :
                        this.addAudioMessage(true);
                        this.audioCallback(true);
                        break;
                    case "AUDIO_STOP" :
                        this.endAudioMessage(true);
                        this.audioCallback(false);
                        break;
                    case "LIVEMAP" :
                        this.livemapCallback(response.liveMapData);
                        break;
                    default :
                        break;
                }
            }
        };
    }

    private publishMessage(): void {
        try {
            let webClientRequest: WebClientRequestDto = new WebClientRequestDto();
            webClientRequest.appMessage = this.appMessage;
            let json: string = JSON.stringify(webClientRequest);

            let packet: any = new Paho.MQTT.Message(json);
            packet.destinationName = MqttConfig.TOPIC_PREFIX + "message"
            this.client.send(packet);
        } catch (_) {}
    }

    public setMessageData(isGroup: boolean): void {
        if (this.appMessage.text == "") {
            return;
        }

        let hour: string = (new Date().getHours() < 10) ? "0" + new Date().getHours() : "" + new Date().getHours();
        let min: string = (new Date().getMinutes() < 10) ? "0" + new Date().getMinutes() : "" + new Date().getMinutes();
        let sec: string = (new Date().getSeconds() < 10) ? "0" + new Date().getSeconds() : "" + new Date().getSeconds();
        let nowTime: string = hour + ":" + min + ":" + sec;
        this.appMessage.sendTime = nowTime;

        this.publishMessage();

        if (!isGroup) {
            this.messageList.push(Object.assign(new AppMessage(), this.appMessage));
            this.dialogScrollToBottom();
        }

        this.appMessage.text = "";
    }

    public publishControl(missionControl: MissionControl): void {
        if (!this.isConnected()) {
            this.init(this.machine, this.dialogScrollToBottom);
        }

        let webClientRequest: WebClientRequestDto = new WebClientRequestDto();
        webClientRequest.missionControl = missionControl;
        let json: string = JSON.stringify(webClientRequest);

        let packet: any = new Paho.MQTT.Message(json);
        packet.destinationName = MqttConfig.TOPIC_PREFIX + "missionControl"
        this.client.send(packet);
    }

    public publishStreaming(isStart: boolean): void {
        let webClientRequest: WebClientRequestDto = new WebClientRequestDto();
        webClientRequest.accountEmail = this.machine.accountEmail;
        webClientRequest.isStreamingStart = isStart;

        let json: string = JSON.stringify(webClientRequest);

        let packet: any = new Paho.MQTT.Message(json);
        packet.destinationName = MqttConfig.TOPIC_PREFIX + "streaming"
        this.client.send(packet);
    }

    public publishAudioStreaming(isStart: boolean, audioUrl: string): void {
        let webClientRequest: WebClientRequestDto = new WebClientRequestDto();
        webClientRequest.accountEmail = this.machine.accountEmail;
        webClientRequest.isStreamingStart = isStart;
        webClientRequest.audioUrl = audioUrl;

        let json: string = JSON.stringify(webClientRequest);

        let packet: any = new Paho.MQTT.Message(json);
        packet.destinationName = MqttConfig.TOPIC_PREFIX + "audioStreaming"
        this.client.send(packet);
    }
}
