import {Component, EventEmitter, Input, Output} from "@angular/core";
import {GroupDto} from "../../../dto/group/group.dto";
import {GroupTreeService} from "./service/group-tree.service";

@Component({
    selector: "group-tree",
    templateUrl: "./view/group-tree.component.html",
    styleUrls: ["./view/group-tree.component.css"]
})
export class GroupTreeComponent {
    @Input() public isSingleCheck;
    @Output() public checkedEvent: EventEmitter<void>;

    private checkedGroup: GroupDto | null;
    public groupTree: GroupDto;

    constructor(private groupTreeService: GroupTreeService) {
        this.checkedGroup = null;
        this.groupTree = new GroupDto();
        this.checkedEvent = new EventEmitter<void>();
    }

    ngOnInit() {
        this.setGroupTree();
    }

    private setGroupTree(): void {
        this.groupTreeService.getTreeData().subscribe(
            groupTree => {
                this.groupTree = Object.assign(new GroupDto(), groupTree);
                this.groupTree.init();

                if (!this.isSingleCheck) {
                    this.setChecked(this.groupTree);
                }
            }
        );
    }

    public getCheckedGroupSeqs(): number[] {
        return this.groupTree.getCheckedSeqList();
    }

    public getCheckedGroup(): GroupDto | null {
        return this.checkedGroup;
    }

    public setChecked(groupDto: GroupDto): void {
        if (this.isSingleCheck) {
            this.groupTree.setChecked(false, groupDto.seq);
            groupDto.setCheckedOne();

            this.checkedGroup = groupDto.isChecked? groupDto : null;
        } else {
            groupDto.setChecked(!groupDto.isChecked);
        }

        this.checkedEvent.emit();
    }
}
