import { MissionType } from 'src/app/dto/mission/setting/mission.type';
import { AccountDto } from '../account/account.dto';
import { AspectRatioType } from './setting/aspectRatio.type';
import { CameraType } from './setting/camera.type';
import { DirectionCode } from './setting/direction.code';
import { FinishActionCode } from './setting/finishAction.code';
import { PointActionCode } from './setting/pointAction.code';

export class MissionDto {
    constructor(
        public seq: number = 0,
        public name: string = "",
        public address: string = "",
        public isPublic: boolean = false,
        public typeCode: MissionType | null = null,
        public cameraTypeCode: CameraType = CameraType.PHANTOM_4,
        public aspectRatioTypeCode: AspectRatioType = AspectRatioType.RATIO_5TO4,
        public repeat: number = 1,
        public speed: number = 6.0,
        public gimbalAngle: number = 90,
        public altitude: number = 100,
        public frontRatio: number = 80,
        public sideRatio: number = 70,
        public directionCode: DirectionCode = DirectionCode.AUTO,
        public endActionCode: FinishActionCode = FinishActionCode.GO_HOME,
        public pointActionCode: PointActionCode = PointActionCode.NON_STOP,
        public angle: number = 0,
        public missionAngle: number = 0,
        public is3d: boolean = false,
        public coordinateData: string = "",
        public pointData: string = "",
        public totalDistance: number = 0,
        public totalArea: number = 0,
        public width: number = 0,
        public height: number = 0,
        public fov: number = 84,
        public centerLatitude: number = 0,
        public centerLongitude: number = 0,
        public accountDto: AccountDto = null,
        public isMachineData: boolean = false,
        public regTime: string = "",
        public isOverwrite: boolean = false,
        public isChecked: boolean = false
    ) {}
}
