import { StatisticsTabMenuCode } from './statisticsTabMenu.code';
import { StatisticsDateTypeCode } from './statisticsDateType.code';
import { MonthShortName } from './monthShortName';

export class StatisticsOption {
    constructor(
        public selectedTabMenu: StatisticsTabMenuCode = StatisticsTabMenuCode.FLIGHT_TIME,
        public isGroupData: boolean = true,
        public dateTypeCode: StatisticsDateTypeCode = StatisticsDateTypeCode.Monthly,
        public year: number = 0,
        public month: MonthShortName = MonthShortName.Jan,
        public groupSeqList: number[] = [],
        public accountSeqList: number[] = []
    ) {
        this.month = new Date().getMonth();
    }
}