export enum CameraType {
    PHANTOM_3,
    PHANTOM_4,
    MAVIC_2_PRO,
    MAVIC_2_ZOOM,
    MAVIC_2_ENTERPRISE,
    MAVIC_2_ENTERPRISE_DUAL,
    MAVIC_2_ENTERPRISE_ADVANCED,
    X3,
    X4S,
    X5,
    X5S,
    X5R,
    Z30,
    H20_WIDE,
    H20_ZOOM,
    H20_THERMAL,
    ZENMUSE_P1_24mm,
    ZENMUSE_P1_35mm,
    ZENMUSE_P1_50mm,
    OTHER_LIDAR
}

export const CameraTypeFov = new Map<number, number>([
    [CameraType.PHANTOM_3, 94],
    [CameraType.PHANTOM_4, 84],
    [CameraType.MAVIC_2_PRO, 77],
    [CameraType.MAVIC_2_ZOOM, 83],
    [CameraType.MAVIC_2_ENTERPRISE, 82.6],
    [CameraType.MAVIC_2_ENTERPRISE_DUAL, 85],
    [CameraType.MAVIC_2_ENTERPRISE_ADVANCED, 84],
    [CameraType.X3, 94],
    [CameraType.X4S, 84],
    [CameraType.X5, 72],
    [CameraType.X5S, 72],
    [CameraType.X5R, 72],
    [CameraType.Z30, 63.7],
    [CameraType.H20_WIDE, 82.9],
    [CameraType.H20_ZOOM, 66.4],
    [CameraType.H20_THERMAL, 40.6],
    [CameraType.ZENMUSE_P1_24mm, 84],
    [CameraType.ZENMUSE_P1_35mm, 63.5],
    [CameraType.ZENMUSE_P1_50mm, 46.8],
    [CameraType.OTHER_LIDAR, 84]
]);
