import { Injectable } from "@angular/core";
import { DistanceUnit } from '../constants/distanceUnit';
import { AreaUnit } from '../constants/areaUnit';
import { SettingsDto } from '../dto/settings/settings.dto';

@Injectable()
export class SettingsService {
    public distanceUnit: DistanceUnit = DistanceUnit.KILO_METER;
    public areaUnit: AreaUnit = AreaUnit.SQUARE_METER;
    public isGoogleMap: boolean = true;
    public isNormalMap: boolean = true;
    public isAutoControl: boolean = true;

    public toDto(): SettingsDto {
        return new SettingsDto(
            this.distanceUnit, this.areaUnit, this.isGoogleMap, this.isNormalMap, this.isAutoControl
        );
    }
}
