import {Component, Input} from "@angular/core";
import {StatisticsTabMenuCode} from "../../data/statisticsTabMenu.code";
import {SummaryTimeItemDto} from "../../../../../dto/statistics/summaryTimeItemDto";
import {SummaryItemDto} from "../../../../../dto/statistics/summaryItemDto";
import {TranslateService} from "@ngx-translate/core";
import {SettingsService} from "../../../../../service/settings.service";
import {DistanceUnit} from "../../../../../constants/distanceUnit";
import {AreaUnit} from "../../../../../constants/areaUnit";
import {SummaryItemService} from "./service/summary-item.service";

@Component({
    selector: "summary-item",
    templateUrl: "./view/summary-item.component.html",
    styleUrls: ["./view/summary-item.component.css"]
})
export class SummaryItemComponent {
    @Input() public statisticsTabMenuOfItem: StatisticsTabMenuCode;
    @Input() public item: SummaryTimeItemDto | SummaryItemDto;

    public isVisible: boolean;
    public statisticsTabMenuCode: typeof StatisticsTabMenuCode;
    public distanceUnit: typeof DistanceUnit = DistanceUnit;
    public areaUnit: typeof AreaUnit = AreaUnit;

    constructor(
        private summaryItemService: SummaryItemService,
        private settingsService: SettingsService,
        private translateService: TranslateService) {

        this.statisticsTabMenuCode = StatisticsTabMenuCode;
    }

    ngOnInit(): void {
        this.isVisible = this.statisticsTabMenuOfItem != null && this.item != null;
    }

    public getTitle(): string {
        switch (this.statisticsTabMenuOfItem) {
            case StatisticsTabMenuCode.FLIGHT_TIME:
                return this.translateService.instant("statistics.label.flight-time");
            case StatisticsTabMenuCode.FLIGHT_DISTANCE:
                return this.translateService.instant("statistics.label.flight-distance");
            case StatisticsTabMenuCode.FLIGHT_AREA:
                return this.translateService.instant("statistics.label.flight-area");
        }
    }

    public getCSSClass(): string {
        switch (this.statisticsTabMenuOfItem) {
            case StatisticsTabMenuCode.FLIGHT_TIME:
                return "_time";
            case StatisticsTabMenuCode.FLIGHT_DISTANCE:
                return "_distance";
            case StatisticsTabMenuCode.FLIGHT_AREA:
                return "_area";
        }
    }

    public isSelectedUnit(unit: DistanceUnit | AreaUnit): boolean {
        switch (this.statisticsTabMenuOfItem) {
            case StatisticsTabMenuCode.FLIGHT_DISTANCE:
                return this.settingsService.distanceUnit == unit;
            case StatisticsTabMenuCode.FLIGHT_AREA:
                return this.settingsService.areaUnit == unit;
        }
    }

    public changeUnit(unit: DistanceUnit | AreaUnit): void {
        switch (this.statisticsTabMenuOfItem) {
            case StatisticsTabMenuCode.FLIGHT_DISTANCE:
                this.settingsService.distanceUnit = unit as DistanceUnit;
                break;
            case StatisticsTabMenuCode.FLIGHT_AREA:
                this.settingsService.areaUnit = unit as AreaUnit;
                break;
        }

        this.summaryItemService.changeUnit(this.settingsService.toDto());
    }

    public getUnitAppliedValue(value: number): number {
        switch (this.statisticsTabMenuOfItem) {
            case StatisticsTabMenuCode.FLIGHT_DISTANCE:
                return this.getDistanceUnitAppliedValue(value);
            case StatisticsTabMenuCode.FLIGHT_AREA:
                return this.getAreaUnitAppliedValue(value);
        }
    }

    private getDistanceUnitAppliedValue(value: number): number {
        switch (this.settingsService.distanceUnit) {
            case DistanceUnit.FEET:
                return +(value * 3.28084).toFixed(2);
            case DistanceUnit.METER:
                return +value.toFixed(2);
            case DistanceUnit.KILO_METER:
                return +(value / 1000).toFixed(2);
        }
    }

    private getAreaUnitAppliedValue(value: number): number {
        switch (this.settingsService.areaUnit) {
            case AreaUnit.SQUARE_METER:
                return +value.toFixed(2);
            case AreaUnit.HECTARE:
                return +(value / 10000).toFixed(2);
            case AreaUnit.SQUARE_KILO_METER:
                return +(value / 1000000).toFixed(2);
        }
    }

    public getUnitName(): string {
        switch (this.statisticsTabMenuOfItem) {
            case StatisticsTabMenuCode.FLIGHT_DISTANCE:
                return DistanceUnit.getDisplayName(this.settingsService.distanceUnit)
            case StatisticsTabMenuCode.FLIGHT_AREA:
                return AreaUnit.getDisplayName(this.settingsService.areaUnit);
        }
    }
}
