import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AccountSearchDto} from "../../../../../dto/account/accountSearch.dto";
import {Observable} from "rxjs";
import {AccountDto} from "../../../../../dto/account/account.dto";
import {ResponseDto} from "../../../../../dto/response.dto";
import {APIUrl} from "../../../../../constants/api.url";
import {LicenseCheckDto} from "../../../../../dto/license/licenseCheck.dto";

@Injectable()
export class UserService {
    constructor(private httpClient: HttpClient) {}

    public get(accountSeq: number, accountSearchDto: AccountSearchDto): Observable<AccountDto[]> {
        return new Observable<AccountDto[]>(observer => {
            this.httpClient.post<ResponseDto<AccountDto[]>>(APIUrl.MEMBER_LIST(accountSeq), accountSearchDto).subscribe(
                response => {
                    if (response.isSuccess) {
                        observer.next(response.data);
                    }
                }
            );
        });
    }

    public checkUserLimit(accountSeq: number): Observable<ResponseDto<LicenseCheckDto>> {
        return new Observable<ResponseDto<LicenseCheckDto>>(observer => {
            this.httpClient.get<ResponseDto<LicenseCheckDto>>(APIUrl.CHECK_USER_LIMIT(accountSeq)).subscribe(
                response => {
                    observer.next(response);
                }
            );
        });
    }

    public delete(accountSeq: number, accounts: AccountDto[]): Observable<void> {
        return new Observable<void>(observer => {
            this.httpClient.post<ResponseDto<string>>(APIUrl.REMOVE_MEMBER(accountSeq), accounts).subscribe(
                response => {
                    if (response.isSuccess) {
                        observer.next();
                    }
                }
            );
        });
    }

    public updateGroupSetting(accounts: AccountDto[]): Observable<void> {
        return new Observable<void>(observer => {
            this.httpClient.post<ResponseDto<string>>(APIUrl.GROUP_SETTING, accounts).subscribe(
                response => {
                    if (response.isSuccess) {
                        observer.next();
                    }
                }
            );
        });
    }

    public updatePermission(accounts: AccountDto[]): Observable<void> {
        return new Observable<void>(observer => {
            this.httpClient.post<ResponseDto<string>>(APIUrl.PERMISSION_SETTING, accounts).subscribe(
                response => {
                    if (response.isSuccess) {
                        observer.next();
                    }
                }
            );
        });
    }
}
