import {Component, EventEmitter, Output, ViewChild} from "@angular/core";
import {AccountDto} from "src/app/dto/account/account.dto";
import {AccountSearchCode} from "src/app/dto/account/accountSearch.code";
import {AccountSearchDto} from "src/app/dto/account/accountSearch.dto";
import {JoinDto} from "src/app/dto/account/join.dto";
import {PopupDto} from "src/app/dto/popup.dto";
import {AccountService} from "src/app/service/account.service";
import {PopupService} from "src/app/service/popup.service";
import {SubmenuService} from "src/app/service/submenu.service";
import {UserCreateComponent} from "../create/user-create.component";
import {GroupTreeComponent} from "../../../common/group-tree/group-tree.component";
import {GroupSettingComponent} from "../group-setting/group-setting.component";
import {UserService} from "./service/user.service";
import {PermissionComponent} from "../permission/permission.component";
import {GroupService} from "../../group/base/service/group.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: "user",
    templateUrl: "./view/user.html",
    styleUrls: ["./view/user.css"]
})
export class UserComponent {
    public isVisible: boolean;

    public accountSearchDto: AccountSearchDto;
    public accountSearchCode: typeof AccountSearchCode;
    public isAccountListChecked: boolean;
    public accounts: AccountDto[];

    @ViewChild(UserCreateComponent) public userCreateComponent: UserCreateComponent;
    @ViewChild(GroupTreeComponent) public groupTreeComponent: GroupTreeComponent;
    @ViewChild(GroupSettingComponent) public groupSettingComponent: GroupSettingComponent;
    @ViewChild(PermissionComponent) public permissionComponent: PermissionComponent;

    @Output() closeEvent: EventEmitter<void>;

    constructor(
        private accountService: AccountService,
        private userService: UserService,
        private submenuService: SubmenuService,
        private groupService: GroupService,
        private popupService: PopupService,
        private translateService: TranslateService) {

        this.isVisible = false;
        this.accountSearchDto = new AccountSearchDto();
        this.accountSearchCode = AccountSearchCode;
        this.isAccountListChecked = false;
        this.accounts = [];

        this.closeEvent = new EventEmitter<void>();
        this.setSubmenuSubscriber();
    }

    public getUserList(): void {
        this.accountSearchDto.groupSeqList = this.groupTreeComponent.getCheckedGroupSeqs();
        if (this.accountSearchDto.groupSeqList.length == 0) {
            this.accounts = [];
            return;
        }

        this.userService.get(this.accountService.getAccount().seq, this.accountSearchDto).subscribe(
            accountList => {
                this.accounts = accountList;
            }
        );
    }

    public toggleAccountListChecked(): void {
        this.accounts.forEach(account => account.isChecked = this.isAccountListChecked);
    }

    public confirmDeleteMember(accountDto: AccountDto): void {
        this.confirmDelete([accountDto]);
    }

    public confirmDeleteMemberList(): void {
        let targetList: AccountDto[] = [];
        this.accounts.forEach(account => {
            if (account.isChecked) {
                targetList.push(Object.assign(new AccountDto(), account));
            }
        });

        if (targetList.length == 0) {
            let alertData: PopupDto = new PopupDto();
            alertData.title = this.translateService.instant("user.popup.delete.title");
            alertData.message = this.translateService.instant("user.popup.delete.select-message");
            alertData.isVisible = true;

            this.popupService.publishAlert(alertData);
            return;
        }

        this.confirmDelete(targetList);
    }

    private confirmDelete(accounts: AccountDto[]): void {
        let popupData: PopupDto = new PopupDto();
        popupData.isVisible = true;
        popupData.title = this.translateService.instant("user.popup.delete.title");
        popupData.message = this.translateService.instant("user.popup.delete.confirm-message");

        let responseSubscriber: any = this.popupService.response$.subscribe(
            response => {
                if (response) {
                    this.delete(accounts);
                }

                responseSubscriber.unsubscribe();
            }
        );

        this.popupService.publishData(popupData);
    }

    public delete(accounts: AccountDto[]): void {
        this.userService.delete(this.accountService.getAccount().seq, accounts).subscribe(
            _ => {
                this.getUserList();

                let popupData: PopupDto = new PopupDto();
                popupData.isVisible = true;
                popupData.title = this.translateService.instant("user.popup.delete.title");
                popupData.message = this.translateService.instant("user.popup.delete.done-message");
                popupData.isCancelButtonVisible = false;

                this.popupService.publishData(popupData);
            }
        );
    }

    private setSubmenuSubscriber(): void {
        this.submenuService.isAccountInfoMenu$.subscribe(
            _ => {
                this.closeAllDialog();
                this.isVisible = true;
            }
        );

        this.submenuService.isAccountCreateMenu$.subscribe(
            _ => {
                this.closeAllDialog();
                this.checkUserLimit();
            }
        );

        this.submenuService.isAccountGroupSettingMenu$.subscribe(
            _ => {
                this.closeAllDialog();
                this.groupSettingComponent.show();
            }
        );

        this.submenuService.isAccountPermissionSettingMenu$.subscribe(
            _ => {
                this.closeAllDialog();
                this.permissionComponent.show();
            }
        );
    }

    private closeAllDialog(): void {
        this.isVisible = false;
        this.userCreateComponent.isVisible = false;
        this.groupSettingComponent.isVisible = false;
        this.permissionComponent.isVisible = false;
    }

    public close(): void {
        this.isVisible = false;
        this.closeEvent.emit();
    }

    private checkUserLimit(): void {
        this.userService.checkUserLimit(this.accountService.getAccount().seq).subscribe(
            response => {
                if (response.isSuccess && !response.data.isUserLimit) {
                    let joinDto: JoinDto = new JoinDto();
                    joinDto.adminSeq = this.accountService.getAccount().seq;

                    this.userCreateComponent.show(true, joinDto);
                } else {
                    let alertData: PopupDto = new PopupDto();
                    alertData.title = this.translateService.instant("user.popup.create.title");
                    alertData.message = this.translateService.instant("user.popup.create.unable-message");
                    alertData.isVisible = true;

                    this.popupService.publishAlert(alertData);
                }
            }
        );
    }

    public showUserEditDialog(accountDto: AccountDto): void {
        this.groupService.getData(accountDto.groupSeq).subscribe(
            groupDto => {
                this.userCreateComponent.show(
                    false,
                    new JoinDto(
                        accountDto.email,
                        accountDto.name,
                        accountDto.password,
                        this.accountService.getAccount().seq,
                        accountDto.groupSeq,
                        groupDto.name
                    )
                );
            }
        );
    }
}
