import { ElementRef, QueryList } from '@angular/core';
import { PointActionCode } from 'src/app/dto/mission/setting/pointAction.code';
import { Map } from '../map/map';
import { Offset } from '../mission/offset';
import { Point } from '../mission/point';
import { LatLng } from './latLng';

export abstract class Figure {
    protected mapService: Map;
    protected latLng: LatLng;

    protected cornorPointElList: QueryList<ElementRef>;

    // temp
    public cornorPointList: Point[];
    protected cornorPointListBackup: Point[];

    protected centerPointElList: QueryList<ElementRef>;
    protected centerPointList: Offset[];

    private movePointOffset: Offset;
    private rotatePointOffset: Offset;

    protected angle: number;

    private midLatitude: number = 0;
    private midLongitude: number = 0;

    // for waypoint figure
    public selectedPointIndex: number = -1;

    constructor(mapService: Map, latLng: LatLng, cornorPointElList: QueryList<ElementRef>, centerPointElList: QueryList<ElementRef>) {
        this.mapService = mapService;
        this.latLng = latLng;

        this.movePointOffset = new Offset();
        this.rotatePointOffset = new Offset();

        this.cornorPointElList = cornorPointElList;
        this.centerPointElList = centerPointElList;

        this.init();
    }

    public init(): void {
        this.cornorPointList = [];
        this.centerPointList = [];
        this.selectedPointIndex = -1;
    }

    public setPoint(): void {
        this.setCornorPoint();
    }

    private setCornorPoint(): void {
        this.cornorPointList = this.mapService.getRectanglePointList(this.latLng.getLatPerMeter(), this.latLng.getLngPerMeter(), 100);
        this.setAngle();

        this.setCenterPoint();
    }

    public resetCornorPointOffset(): void {
        this.cornorPointList.forEach(point => {
            let offset: Offset = this.mapService.convertPointToOffset(point);

            point.offsetX = offset.x;
            point.offsetY = offset.y;
        });
    }

    public resetCornorPointListCoord(): void {
        let mapContainer: Offset = this.mapService.getContainerOffset();

        this.cornorPointList.forEach((point, key) => {
            let offset: Offset;

            if (mapContainer == null) {
                offset = new Offset(point.offsetX, point.offsetY);
            } else {
                offset = new Offset(point.offsetX - mapContainer.x, point.offsetY - mapContainer.y);
            }

            let coord: Offset = this.mapService.convertOffsetToCoord(offset);

            point.coordX = coord.x;
            point.coordY = coord.y;
        });
    }

    public resetCornorPointCoord(index: number): void {
        let mapContainer: Offset = this.mapService.getContainerOffset() || new Offset();
        let offset: Offset = new Offset(this.cornorPointList[index].offsetX - mapContainer.x, this.cornorPointList[index].offsetY - mapContainer.y);
        let coord: Offset = this.mapService.convertOffsetToCoord(offset);

        this.cornorPointList[index].coordX = coord.x;
        this.cornorPointList[index].coordY = coord.y;
    }

    public changeCornorPointOffset(diffX: number, diffY: number): void {
        this.cornorPointList.forEach(point => {
            point.offsetX += diffX;
            point.offsetY += diffY;
        });
    }

    public addCornorPoint(index: number, point: Point): void {
        this.cornorPointList.splice(index + 1, 0, point);
    }

    public getFirstCornorPointAction(): PointActionCode {
        if (this.cornorPointList.length > 0) {
            return this.cornorPointList[0].pointActionCode;
        }

        return PointActionCode.NON_STOP;
    }

    public getFirstCornorAltitude(): PointActionCode {
        if (this.cornorPointList.length > 0) {
            return this.cornorPointList[0].altitude;
        }

        return 150;
    }

    public getFirstCornorGimbalAngle(): PointActionCode {
        if (this.cornorPointList.length > 0) {
            return this.cornorPointList[0].gimbalAngle;
        }

        return 90;
    }

    public setCenterPoint(): void {
        this.centerPointList = [];
        this.cornorPointList.forEach((point, index) => {
            let offset1: Offset = point.toOffset();
            let offset2: Offset;

            if (index == this.cornorPointList.length - 1) {
                offset2 = this.cornorPointList[0].toOffset();
            } else {
                offset2 = this.cornorPointList[index + 1].toOffset();
            }

            this.centerPointList.push(new Offset(
                offset1.x + (offset2.x - offset1.x) / 2,
                offset1.y + (offset2.y - offset1.y) / 2
            ));
        });
    }

    public resetCenterPoint(exceptIndex: number = -1): void {
        this.cornorPointList.forEach((point, index) => {
            if (index != exceptIndex) {
                let offset1: Offset = point.toOffset();
                let offset2: Offset;

                if (index == this.cornorPointList.length - 1) {
                    offset2 = this.cornorPointList[0].toOffset();
                } else {
                    offset2 = this.cornorPointList[index + 1].toOffset();
                }

                if (offset1 != undefined && offset2 != undefined) {
                    this.centerPointList[index].x = offset1.x + (offset2.x - offset1.x) / 2;
                    this.centerPointList[index].y = offset1.y + (offset2.y - offset1.y) / 2;
                }
            }
        });
    }

    public setControlPointOffset(): void {
        if (this.cornorPointList.length < 1) {
            return;
        }

        if (this.cornorPointList.length < 2) {
            this.movePointOffset.x = this.cornorPointList[0].offsetX;
            this.rotatePointOffset.x = this.cornorPointList[0].offsetX;
            this.movePointOffset.y = this.cornorPointList[0].offsetY + 40;
            this.rotatePointOffset.y = this.cornorPointList[0].offsetY + 70;
            return;
        }

        let minX: number = 0;
        let maxX: number = 0;
        let midX: number = 0;
        let maxY: number = 0;

        let isFirst: boolean = true;
        this.cornorPointList.forEach(point => {
            if (isFirst) {
                isFirst = false;

                minX = point.offsetX;
                maxX = point.offsetX;
                maxY = point.offsetY;
            } else {
                minX = (minX > point.offsetX) ? point.offsetX : minX;
                maxX = (maxX < point.offsetX) ? point.offsetX : maxX;

                maxY = (maxY < point.offsetY) ? point.offsetY : maxY;
            }
        });

        midX = minX + (maxX - minX) / 2;

        this.movePointOffset.x = midX;
        this.rotatePointOffset.x = midX;
        this.movePointOffset.y = maxY + 40;
        this.rotatePointOffset.y = maxY + 75;
    }

    public getCenterCoords(): Point {
        let centerPoint: Point = new Point();

        if (this.cornorPointList.length < 2) {
            centerPoint.coordX = this.cornorPointList[0].coordX;
            centerPoint.coordY = this.cornorPointList[0].coordY;

            return centerPoint;
        }

        let minX: number = 0;
        let maxX: number = 0;
        let minY: number = 0;
        let maxY: number = 0;

        let isFirst: boolean = true;
        this.cornorPointList.forEach(point => {
            if (isFirst) {
                isFirst = false;

                minX = point.coordX;
                maxX = point.coordX;
                minY = point.coordY;
                maxY = point.coordY;
            } else {
                minX = (minX > point.coordX) ? point.coordX : minX;
                maxX = (maxX < point.coordX) ? point.coordX : maxX;

                minY = (minY > point.coordY) ? point.coordY : minY;
                maxY = (maxY < point.coordY) ? point.coordY : maxY;
            }
        });

        centerPoint.coordX = minX + (maxX - minX) / 2;
        centerPoint.coordY = minY + (maxY - minY) / 2;

        return centerPoint;
    }

    public getCornorPointList(): Point[] {
        return this.cornorPointList;
    }

    public setCornorPointList(cornorPointList: Point[]): void {
        this.cornorPointList = cornorPointList;
    }

    public getCornorPointListByOffset(): Offset[] {
        let offsetList: Offset[] = [];

        this.cornorPointList.forEach(point => {
            offsetList.push(point.toOffset());
        });

        return offsetList;
    }

    public backup(): void {
        this.cornorPointListBackup = [];
        this.cornorPointList.forEach(point => {
            this.cornorPointListBackup.push(Object.assign(new Point(), point));
        });
    }

    public restore(): void {
        this.cornorPointList = this.cornorPointListBackup.slice();
        this.cornorPointListBackup = [];
    }

    public getCenterPointList(): Offset[] {
        return this.centerPointList;
    }

    public setCenterPointList(centerPointList: Offset[]): void {
        this.centerPointList = centerPointList;
    }

    public getAngle(): number {
        return this.angle;
    }

    public setAngle(): void {
        let offset1: Offset = this.cornorPointList[0].toOffset();
        let offset2: Offset = this.cornorPointList[1].toOffset();

        let radian: number = Math.atan2(offset2.y - offset1.y, offset2.x - offset1.x);
        this.angle = radian * 180 / Math.PI;
    }

    public setPolygonAngle(angle: number): void {
        this.angle = angle;
    }

    public getMovePointOffset(): Offset {
        return this.movePointOffset;
    }

    public getRotatePointOffset(): Offset {
        return this.rotatePointOffset;
    }

    protected getCornorPointTranslateValue(index: number): Offset {
        let elementList: any[] = this.cornorPointElList.toArray();
        let style: any = window.getComputedStyle(elementList[index].nativeElement);
        let matrix: any = new WebKitCSSMatrix(style.webkitTransform);

        return new Offset(matrix.m41, matrix.m42);
    }

    protected getCenterPointTranslateValue(index: number): Offset {
        let pointList: any[] = this.centerPointElList.toArray();
        let style: any = window.getComputedStyle(pointList[index].nativeElement);
        let matrix: any = new WebKitCSSMatrix(style.webkitTransform);

        return new Offset(matrix.m41, matrix.m42);
    }

    public onMovePointMoving(translateOffset: Offset): void {
        this.cornorPointList.forEach(point => {
            point.translateX = translateOffset.x;
            point.translateY = translateOffset.y;
        });

        this.cornorPointElList.toArray().forEach(point => {
            point.nativeElement.style.transform
                = "translate(" + translateOffset.x + "px, " + translateOffset.y + "px)";
        });
    }

    public onMovePointMoveEnd(): void {
        this.cornorPointElList.toArray().forEach(point => {
            point.nativeElement.style.transform = "translate(0px, 0px)";
        });

        this.cornorPointList.forEach(point => {
            point.resetOffsetByTranslate();
        });

        this.resetCornorPointListCoord();
    }

    public onRotatePointMoving(event: any): void {
        let midOffset: Offset = this.getCenterOffsetOfCornorPointList();

        let radian: number = (event.x * -1) * Math.PI / 180;
        let cos: number = +Math.cos(radian).toFixed(15);
        let sin: number = +Math.sin(radian).toFixed(15);

        this.cornorPointList.forEach((point, index) => {
            let rotateX: number = (point.offsetX - midOffset.x) * cos - (point.offsetY - midOffset.y) * sin + midOffset.x;
            let rotateY: number = (point.offsetX - midOffset.x) * sin + (point.offsetY - midOffset.y) * cos + midOffset.y;

            point.translateX = rotateX - point.offsetX;
            point.translateY = rotateY - point.offsetY;

            let pointEl: ElementRef = this.cornorPointElList.toArray()[index];
            pointEl.nativeElement.style.transform
                = "translate(" + point.translateX + "px, " + point.translateY + "px)";
        });
    }

    public onRotatePointMoveEnd(): void {
        this.cornorPointElList.toArray().forEach(point => {
            point.nativeElement.style.transform = "translate(0px, 0px)";
        });

        this.cornorPointList.forEach(point => {
            point.resetOffsetByTranslate();
        });

        this.resetCornorPointListCoord();
    }

    private getCenterOffsetOfCornorPointList(): Offset {
        let minX: number = 0;
        let maxX: number = 0;
        let minY: number = 0;
        let maxY: number = 0;

        let isFirst: boolean = true;
        this.cornorPointList.forEach(point => {
            if (isFirst) {
                isFirst = false;

                minX = point.offsetX;
                maxX = point.offsetX;
                minY = point.offsetY;
                maxY = point.offsetY;
            } else {
                minX = (minX > point.offsetX) ? point.offsetX : minX;
                maxX = (maxX < point.offsetX) ? point.offsetX : maxX;

                minY = (minY > point.offsetY) ? point.offsetY : minY;
                maxY = (maxY < point.offsetY) ? point.offsetY : maxY;
            }
        });

        return new Offset(minX + (maxX - minX) / 2, minY + (maxY - minY) / 2);
    }

    abstract changeAngle(angle: number): void;
    abstract preProcessForMoveCornorPoint(index: number): void;
    abstract preProcessForMoveCenterPoint(index: number): void;
    abstract onCornorPointMove(index: number): void;
    abstract onCornorPointMoveEnd(index: number): void;
    abstract onCenterPointMove(index: number, event: any): void;
    abstract onCenterPointMoveEnd(): void;

    abstract selectPoint(index: number): void;
    abstract deletePoint(): void;
    abstract getSelectedPointIndex(): number;
}
