import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {interval, timer} from "rxjs";
import {APIUrl} from "src/app/constants/api.url";
import {AreaUnit} from "src/app/constants/areaUnit";
import {DistanceUnit} from "src/app/constants/distanceUnit";
import {MenuCode} from "src/app/constants/menu.code";
import {SubMenuCode} from "src/app/constants/subMenu.code";
import {AccountDto} from "src/app/dto/account/account.dto";
import {PermissionCode} from "src/app/dto/account/permission.code";
import {LicenseType} from "src/app/dto/license/license.type";
import {MachineDto} from "src/app/dto/machine/machine.dto";
import {PopupDto} from "src/app/dto/popup.dto";
import {ResponseDto} from "src/app/dto/response.dto";
import {SettingsDto} from "src/app/dto/settings/settings.dto";
import {AccountService} from "src/app/service/account.service";
import {CanvasService} from "src/app/service/canvas.service";
import {HTTPCallBack} from "src/app/service/http.callback";
import {HttpService} from "src/app/service/http.service";
import {MapService} from "src/app/service/map.service";
import {MonitoringService} from "src/app/service/monitoring.service";
import {PopupService} from "src/app/service/popup.service";
import {SettingsService} from "src/app/service/settings.service";
import {SubmenuService} from "src/app/service/submenu.service";
import {Canvas} from "./mission/canvas/canvas";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: "",
    templateUrl: "../../view/html/mgmt.html",
    styleUrls: ["../../view/css/mgmt.css"]
})
export class MgmtComponent implements AfterViewInit, OnDestroy {
    public accountDto: AccountDto;

    public isMenuExpand: boolean = true;
    public menuCode: typeof MenuCode = MenuCode;
    public subMenuCode: typeof SubMenuCode = SubMenuCode;

    public selectedMenu: MenuCode = MenuCode.NONE;
    public selectedSubMenu: SubMenuCode = SubMenuCode.NONE;

    public popupData: PopupDto;
    public alertData: PopupDto;
    public progressData: PopupDto;

    private isInit: boolean = true;

    @ViewChild("canvasWrap") canvasWrap: ElementRef;

    public isProfileVisible: boolean = false;

    // monitor
    private monitorInterval: any = null;
    public totalCount: number = 0;
    public onlineCount: number = 0;

    constructor(
        public mapService: MapService,
        private canvasService: CanvasService,
        private popupService: PopupService,
        private router: Router,
        private httpService: HttpService,
        private accountService: AccountService,
        private submenuService: SubmenuService,
        private monitoringService: MonitoringService,
        private settingsService: SettingsService,
        private translateService: TranslateService) {

        this.getAccountData();
        this.setPopupSubscriber();
        this.setAccountSubscriber();
    }

    ngOnDestroy() {
        try {
            this.accountService.refresh$.unsubscribe();
            this.popupService.data$.unsubscribe();
            this.popupService.alert$.unsubscribe();

            if (this.monitorInterval != null) {
                this.monitorInterval.unsubscribe();
                this.monitorInterval = null;
            }
        } catch (error) {}
    }

    private setMonitorInterval(): void {
        if (this.monitorInterval != null) {
            return;
        }

        this.monitorInterval = interval(1000 * 3).subscribe(() => {
            let httpCallback: HTTPCallBack = new HTTPCallBack();
            httpCallback.response = function (res: any): void {
                let response: ResponseDto<MachineDto[]> = res;

                if (response.isSuccess) {
                    let machineList: MachineDto[] = response.data;
                    let onlineCount: number = 0;

                    this.monitoringService.publishMachineList(machineList);
                    machineList.forEach(machine => {
                        if (machine.machineStatusDto.isConnect) {
                            ++onlineCount;
                        }
                    });

                    this.totalCount = machineList.length;
                    this.onlineCount = onlineCount;
                }
            }.bind(this);

            this.httpService.get(APIUrl.MACHINE_LIST_FOR_CONTROL(this.accountDto.groupSeq, this.accountDto.seq), httpCallback);
        });
    }

    private setAccountSubscriber(): void {
        this.accountService.refresh$.subscribe(
            _ => {
                this.getAccountData();
            }
        );
    }

    private getAccountData(): void {
        let loginEmail: string = localStorage.getItem("loginId");

        if (loginEmail == null) {
            this.logout();
            return;
        }

        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<AccountDto> = res;

            if (response.isSuccess) {
                this.accountDto = response.data;
                this.accountService.setAccount(response.data);

                if (this.isInit) {
                    this.isInit = false;
                    this.setMonitorInterval();
                    this.loadSettings();
                }
            }
        }.bind(this);

        httpCallback.error = function (): void {
            this.logout();
        }.bind(this);

        this.httpService.get(APIUrl.ACCOUNT_DATA_BY_EMAIL(loginEmail), httpCallback);
    }

    private loadSettings(): void {
        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<SettingsDto> = res;

            if (response.isSuccess) {
                let settings: SettingsDto = response.data;
                this.settingsService.distanceUnit = settings.distanceUnit;
                this.settingsService.areaUnit = settings.areaUnit;
                this.settingsService.isGoogleMap = settings.isGoogleMap;
                this.settingsService.isNormalMap = settings.isNormalMap;
                this.settingsService.isAutoControl = settings.isAutoControl;
            } else {
                this.settingsService.distanceUnit = DistanceUnit.KILO_METER;
                this.settingsService.areaUnit = AreaUnit.SQUARE_METER;
                this.settingsService.isGoogleMap = true;
                this.settingsService.isNormalMap = true;
                this.settingsService.isAutoControl = true;
            }

            this.loadMap();
        }.bind(this);

        this.httpService.get(APIUrl.GET_SETTINGS(this.accountDto.seq), httpCallback);
    }

    private setPopupSubscriber(): void {
        this.popupData = new PopupDto();
        this.alertData = new PopupDto();
        this.progressData = new PopupDto();

        this.popupService.data$.subscribe(
            data => {
                this.popupData = data;
            }
        );

        this.popupService.alert$.subscribe(
            data => {
                this.alertData = data;
            }
        );

        this.popupService.progress$.subscribe(
            data => {
                this.progressData = data;
            }
        );
    }

    ngAfterViewInit() {
        this.setCanvas();
    }

    private setCanvas(): void {
        this.canvasService.canvas = new Canvas(this.canvasWrap, window.innerWidth, window.innerHeight);
    }

    private loadMap(): void {
        this.mapService.initMap(this.settingsService.isGoogleMap);

        this.mapService.map.loadMap(
            "map",
            this.mapService.standardLatitude,
            this.mapService.standardLongitude,
            7
        );

        this.mapService.map.setMapType(this.settingsService.isNormalMap);
    }

    public isAdminAccount(): boolean {
        if (this.accountDto == null) {
            return false;
        }

        if (this.accountDto.permissionCode == PermissionCode.ADMIN) {
            return true;
        }

        return false;
    }

    public toggleMenu(): void {
        this.isMenuExpand = !this.isMenuExpand;
    }

    public changeMenu(menuCode: MenuCode): void {
        if (this.selectedMenu == menuCode) {
            return;
        }

        if (menuCode == MenuCode.CONTROL) {
            if (this.accountDto.authCode == 0) {
                this.alertData.title = this.translateService.instant("menu.change-menu.alert.title.permission");
                this.alertData.message = this.translateService.instant("menu.change-menu.alert.message.permission-denied");
                this.alertData.isVisible = true;
                return;
            }

            if (this.accountDto.licenseDto.code != LicenseType.A3) {
                this.alertData.title = this.translateService.instant("menu.change-menu.alert.title.license");
                this.alertData.message = this.translateService.instant("menu.change-menu.alert.message.no-valid-license");
                this.alertData.isVisible = true;
                return;
            }

            this.selectedSubMenu = SubMenuCode.NONE;
        }

        if (menuCode == MenuCode.GROUP) {
            this.selectedSubMenu = SubMenuCode.GROUP_INFO;
        }

        if (this.selectedMenu == MenuCode.STATISTICS && menuCode != MenuCode.STATISTICS) {
            this.selectedMenu = menuCode;
            timer(300).subscribe(() => {
                this.loadMap();
                this.mapService.publishRefresh();
            });
        } else {
            if (menuCode == MenuCode.STATISTICS) {
                this.selectedSubMenu = SubMenuCode.NONE;
            }

            this.selectedMenu = menuCode;
            timer(200).subscribe(() => {
                this.mapService.publishRefresh();
            });
        }

        if (this.selectedMenu == MenuCode.NONE) {
            this.selectedSubMenu = SubMenuCode.NONE;
        }
    }

    public showSubMenu(menuCode: MenuCode, subMenuFunction: string): void {
        if (this.selectedMenu != menuCode) {
            this.changeMenu(menuCode);
        }

        timer(100).subscribe(() => {
            this[subMenuFunction]();
        });
    }

    private showAccountInfo(): void {
        this.selectedSubMenu = SubMenuCode.ACCOUNT_INFO;
        this.submenuService.publishIsAccountInfoMenu();
    }

    private showAccountGroupSetting(): void {
        this.selectedSubMenu = SubMenuCode.GROUP_SETTING;
        this.submenuService.publishIsAccountGroupSettingMenu();
    }

    private showAccountPermissionSetting(): void {
        this.selectedSubMenu = SubMenuCode.PERMISSION;
        this.submenuService.publishIsAccountPermissionSettingMenu();
    }

    private showAccountCreate(): void {
        this.selectedSubMenu = SubMenuCode.NEW_USER;
        this.submenuService.publishIsAccountCreateMenu();
    }

    private showDrone(): void {
        this.selectedSubMenu = SubMenuCode.DRONE;
        this.submenuService.publishIsDroneMenu();
    }

    public logout(): void {
        if (this.monitorInterval != null) {
            this.monitorInterval.unsubscribe();
            this.monitorInterval = null;
        }

        localStorage.removeItem("loginId");
        this.router.navigateByUrl("");
    }

    public cancel(): void {
        this.popupService.publishResponse(false);
        this.close("popupData");
    }

    public confirm(): void {
        this.popupData.isVisible = false;
        this.popupService.publishResponse(true);
    }

    public close(popupData: string): void {
        this[popupData] = new PopupDto();
    }

    public showProfileDialog(): void {
        this.isProfileVisible = true;
    }

    public hideProfileDialog(): void {
        this.isProfileVisible = false;
    }
}
