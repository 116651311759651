export class ShapeFileValidation {
    constructor (
        public isSHPFileExist: boolean = false,
        public isSHXFileExist: boolean = false,
        public isDBFFileExist: boolean = false,
        public isCPGFileExist: boolean = false,
        public isPRJFileExist: boolean = false
    ) {}

    public isValid(): boolean {
        return this.isSHPFileExist && this.isSHXFileExist && this.isDBFFileExist;
    }
}