import { Offset } from '../mission/offset';

export class Rectangle {
    constructor(
        public top: number = 0,
        public bottom: number = 0,
        public left: number = 0,
        public right: number = 0
    ) { }

    public getCenterOffset(): Offset {
        let offset: Offset = new Offset();
        offset.x = this.left + (this.right - this.left) / 2;
        offset.y = this.bottom + (this.top - this.bottom) / 2;

        return offset;
    }

    public getWidth(): number {
        return this.right - this.left;
    }

    public getHeight(): number {
        return this.bottom - this.top;
    }
}