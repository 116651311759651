export class RandomUtil {
    public static randomString(length: number): string {
        const str: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
        let result: string = "";

        for (let i: number = 0;i < length;i++) {
            result += str.charAt(window.crypto.getRandomValues(new Uint32Array(1))[0] % str.length);
        }

        return result;
    }
}
