import {NgModule} from "@angular/core";
import {MachineListComponent} from "./machine-list.component";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {MachineListService} from "./service/machine-list.service";
import {GroupTreeModule} from "../../../common/group-tree/group-tree.module";
import {TranslateModule} from "@ngx-translate/core";
import {MatTooltipModule} from "@angular/material";

@NgModule({
    declarations: [
        MachineListComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        GroupTreeModule,
        TranslateModule,
        MatTooltipModule
    ],
    providers: [
        MachineListService
    ],
    exports: [
        MachineListComponent
    ]
})
export class MachineListModule {}
