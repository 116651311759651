export enum MonthShortName {
    Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec
}

export namespace MonthShortName {
    export function getMonthShortName(monthShortName: MonthShortName): string {
        switch (monthShortName) {
            case MonthShortName.Jan:
                return "statistics.label.month.january";
            case MonthShortName.Feb:
                return "statistics.label.month.february";
            case MonthShortName.Mar:
                return "statistics.label.month.march";
            case MonthShortName.Apr:
                return "statistics.label.month.april";
            case MonthShortName.May:
                return "statistics.label.month.may";
            case MonthShortName.Jun:
                return "statistics.label.month.june";
            case MonthShortName.Jul:
                return "statistics.label.month.july";
            case MonthShortName.Aug:
                return "statistics.label.month.august";
            case MonthShortName.Sep:
                return "statistics.label.month.september";
            case MonthShortName.Oct:
                return "statistics.label.month.october";
            case MonthShortName.Nov:
                return "statistics.label.month.november";
            case MonthShortName.Dec:
                return "statistics.label.month.december";
        }
    }

    export function getAll(): MonthShortName[] {
        return [
            MonthShortName.Jan, MonthShortName.Feb, MonthShortName.Mar, MonthShortName.Apr,
            MonthShortName.May, MonthShortName.Jun, MonthShortName.Jul, MonthShortName.Aug,
            MonthShortName.Sep, MonthShortName.Oct, MonthShortName.Nov, MonthShortName.Dec
        ];
    }
}
