import { Directive, ElementRef, OnInit } from '@angular/core';

declare var $: any;

@Directive({
    selector: 'scrollbar-horizontal-dbf',
    host: {'class': 'dbflist_horizontal_scroll'}
})
export class DbfHorizontalScrollbarDirective implements OnInit {
    element: ElementRef;

    constructor(element: ElementRef) {
        this.element = element;
    }

    ngOnInit() {
        $(this.element.nativeElement).mCustomScrollbar({
            theme: "minimal-dark",
            axis: "x",
            scrollButtons: {enable:true},
            scrollInertia: 0,
            advanced: {
                updateOnContentResize: true
            }
        });
    }
}