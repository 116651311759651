export enum AreaUnit{
    SQUARE_METER, HECTARE, SQUARE_KILO_METER
}

export namespace AreaUnit {
    export function getDisplayName(areaUnit: AreaUnit): string {
        switch (areaUnit) {
            case AreaUnit.SQUARE_METER:
                return "m²";
            case AreaUnit.HECTARE:
                return "ha";
            case AreaUnit.SQUARE_KILO_METER:
                return "km²";
        }
    }

    export function getAll(): AreaUnit[] {
        return [
            AreaUnit.SQUARE_METER,
            AreaUnit.HECTARE,
            AreaUnit.SQUARE_KILO_METER
        ];
    }
}
