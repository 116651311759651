import { Figure } from './figure';
import { Offset } from '../mission/offset';
import { Point } from '../mission/point';

export class WaypointFigure extends Figure {
    public setPoint(): void {
        this.cornorPointList = [];
    }

    public addCornorPoint(index: number, point: Point): void {
        super.addCornorPoint(index, point);

        this.selectedPointIndex = this.cornorPointList.length - 1;
    }

    public selectPoint(index: number): void {
        this.selectedPointIndex = index;
    }

    public deletePoint(): void {
        if (this.cornorPointList.length == 1) {
            super.init();
        }

        this.cornorPointList.splice(this.selectedPointIndex, 1);

        if (this.selectedPointIndex >= this.cornorPointList.length) {
            this.selectedPointIndex = this.cornorPointList.length - 1;
        }
    }

    public getSelectedPointIndex(): number {
        return this.selectedPointIndex;
    }

    public onCornorPointMove(index: number): void {
        let translateValue: Offset = this.getCornorPointTranslateValue(index);
        this.cornorPointList[index].translateX = translateValue.x;
        this.cornorPointList[index].translateY = translateValue.y;
    }

    public onCornorPointMoveEnd(index: number): void {
        this.cornorPointElList.toArray()[index].nativeElement.style.transform = "translate(0px, 0px)";
        this.cornorPointList[index].resetOffsetByTranslate();

        this.resetCornorPointCoord(index);
    }

    public changeAngle(angle: number): void { }
    public preProcessForMoveCornorPoint(index: number): void { }
    public preProcessForMoveCenterPoint(index: number): void { }
    public onCenterPointMove(index: number, event: any): void { }
    public onCenterPointMoveEnd(): void { }
}