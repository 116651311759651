import { PointActionCode } from './setting/pointAction.code';

export class PointDto {
    constructor(
        public title: string = "",
        public lat: number = 0,
        public lng: number = 0,
        public altitude: number = 150,
        public gimbalAngle: number = 90,
        public point_action: PointActionCode = PointActionCode.NON_STOP
    ) {}
}