import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';
import { PopupDto } from '../dto/popup.dto';

@Injectable()
export class PopupService {
    private data: Subject<PopupDto> = new Subject<PopupDto>();
    public data$: any;

    private response: Subject<boolean> = new Subject<boolean>();
    public response$: any;

    private alert: Subject<PopupDto> = new Subject<PopupDto>();
    public alert$: any;

    private progress: Subject<PopupDto> = new Subject<PopupDto>();
    public progress$: any;

    constructor() {
        this.data$ = this.data.asObservable();
        this.response$ = this.response.asObservable();
        this.alert$ = this.alert.asObservable();
        this.progress$ = this.progress.asObservable();
    }

    public publishData(data: PopupDto): void {
        this.data.next(data);
    }

    public publishResponse(response: boolean): void {
        this.response.next(response);
    }

    public publishAlert(alert: PopupDto): void {
        this.alert.next(alert);
    }

    public publishProgress(progress: PopupDto): void {
        this.progress.next(progress);
    }
}
