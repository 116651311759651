import {Component, EventEmitter, Output} from "@angular/core";
import {Phase} from "../../base/data/phase.type";
import {JoinRequestDto} from "./dto/join-request.dto";
import {InfoService} from "./service/info.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: "sign-up-info",
    templateUrl: "./view/info.component.html",
    styleUrls: ["./view/info.component.css"]
})
export class InfoComponent {
    @Output() public movePhaseEvent: EventEmitter<Phase | null>;

    public joinRequestDto: JoinRequestDto;
    public isAvailableEmail!: boolean;
    public failedMessage: string;

    constructor(private infoService: InfoService, private translateService: TranslateService) {
        this.movePhaseEvent = new EventEmitter<Phase | null>();
        this.joinRequestDto = new JoinRequestDto();
    }

    public checkEmail(): void {
        if (this.joinRequestDto.email == undefined || this.joinRequestDto.email.length == 0) {
            return;
        }

        this.infoService.checkEmail(this.joinRequestDto.email).subscribe(
            isAvailable => {
                this.isAvailableEmail = isAvailable;
            }, _ => {
                this.isAvailableEmail = false;
            }
        );
    }

    public clearCheckEmail(): void {
        this.isAvailableEmail = undefined;
    }

    public movePrev(): void {
        this.movePhaseEvent.emit(Phase.TOS);
    }

    public isValid(): boolean {
        if (this.isAvailableEmail
            && this.joinRequestDto.email && 0 < this.joinRequestDto.email.length
            && this.joinRequestDto.name && 0 < this.joinRequestDto.name.length
            && this.joinRequestDto.password && 0 < this.joinRequestDto.password.length) {

            return true;
        }

        return false;
    }

    public signUp(): void {
        if (this.isValid()) {
            let joinRequestDto: JoinRequestDto = Object.assign(new JoinRequestDto(), this.joinRequestDto);
            this.infoService.signUp(joinRequestDto).subscribe(
                isSuccess => {
                    if (isSuccess) {
                        this.movePhaseEvent.emit(Phase.Done);
                    } else {
                        this.failedMessage = this.translateService.instant("signup.message.signup-failed");
                    }
                }, _ => {
                    this.failedMessage = this.translateService.instant("signup.message.try-again");
                }
            );
        }
    }
}
