import {Component, EventEmitter, Output, ViewChild} from "@angular/core";
import {AccountDto} from "../../../../dto/account/account.dto";
import {GroupTreeComponent} from "../../../common/group-tree/group-tree.component";
import {AccountService} from "../../../../service/account.service";
import {UserService} from "../base/service/user.service";
import {AccountSearchDto} from "../../../../dto/account/accountSearch.dto";
import {GroupDto} from "../../../../dto/group/group.dto";

@Component({
    selector: "group-setting",
    templateUrl: "./view/group-setting.component.html"
})
export class GroupSettingComponent {
    public isVisible: boolean;

    public isNowAccountsChecked: boolean;
    public isNewAccountsChecked: boolean;
    public nowAccounts: AccountDto[];
    public newAccounts: AccountDto[];

    @ViewChild("nowGroupTree") public nowGroupTreeComponent: GroupTreeComponent;
    @ViewChild("newGroupTree") public newGroupTreeComponent: GroupTreeComponent;

    @Output() public closeEvent: EventEmitter<void>;

    constructor(private accountService: AccountService, private userService: UserService) {
        this.isVisible = false;
        this.isNowAccountsChecked = false;

        this.closeEvent = new EventEmitter<void>();
    }

    public show(): void {
        this.isVisible = true;

        this.isNowAccountsChecked = false;
        this.isNewAccountsChecked = false;
        this.nowAccounts = [];
        this.newAccounts = [];
    }

    public getNowAccounts(): void {
        this.isNowAccountsChecked = false;

        let accountSearchDto: AccountSearchDto = new AccountSearchDto();
        accountSearchDto.groupSeqList = this.nowGroupTreeComponent.getCheckedGroupSeqs();

        this.userService.get(this.accountService.getAccount().seq, accountSearchDto).subscribe(
            accounts => {
                this.nowAccounts = accounts;
            }
        );
    }

    public toggleNowAccountsChecked(isChecked: boolean = this.isNowAccountsChecked): void {
        this.nowAccounts.forEach(account => account.isChecked = isChecked);
    }

    public getNewAccounts(): AccountDto[] {
        if (!this.newGroupTreeComponent || this.newGroupTreeComponent.getCheckedGroup() == null) {
            return [];
        }

        return this.newAccounts.filter(account => account.groupSeq == this.newGroupTreeComponent.getCheckedGroup().seq);
    }

    public uncheckAllNewAccounts(): void {
        this.isNewAccountsChecked = false;
        this.newAccounts.forEach(account => account.isChecked = false);
    }

    public toggleNewAccountsChecked(): void {
        this.getNewAccounts().forEach(account => account.isChecked = this.isNewAccountsChecked);
    }

    public moveToNewGroup(): void {
        let newGroup: GroupDto = this.newGroupTreeComponent.getCheckedGroup();
        if (newGroup) {
            this.nowAccounts.forEach(nowAccount => {
                if (nowAccount.isChecked) {
                    let isExist: boolean = this.newAccounts.some(newAccount => {
                        if (newAccount.seq == nowAccount.seq) {
                            newAccount.groupSeq = newGroup.seq;
                            return true;
                        }

                        return false;
                    });

                    if (!isExist) {
                        let account: AccountDto = Object.assign(new AccountDto(), nowAccount);
                        account.isChecked = false;
                        account.groupSeq = newGroup.seq;

                        this.newAccounts.push(account);
                    }
                }
            });

            this.isNowAccountsChecked = false;
            this.toggleNowAccountsChecked();
        }
    }

    public removeFromNewGroup(): void {
        let temp: AccountDto[] = [];

        this.newAccounts.forEach(account => {
            if (!account.isChecked) {
                temp.push(account);
            }
        });

        this.newAccounts = temp;
    }

    public submit(): void {
        if (this.newAccounts.length == 0) {
            return;
        }

        this.userService.updateGroupSetting(this.newAccounts).subscribe(
            _ => {
                this.close();
            }
        );
    }

    public close(): void {
        this.isVisible = false;
        this.closeEvent.emit();
    }
}
