import { Point } from '../mission/point';

export class LatLng {
    private radius: number;
    private latPerMeter: number;
    private lngPerMeter: number;

    public getLatPerMeter(): number {
        return this.latPerMeter;
    }

    public getLngPerMeter(): number {
        return this.lngPerMeter;
    }

    public calc(centerPoint: Point): void {
        this.setEarthRadius(centerPoint.coordY);

        this.latPerMeter = this.getDegreeOfMeter(0, centerPoint.coordX, 1, centerPoint.coordX);
        this.lngPerMeter = this.getDegreeOfMeter(centerPoint.coordY, 0, centerPoint.coordY, 1);
    }

    private setEarthRadius(latitude: number): void {
        const equaterRadius: number = 6378.137;
        const polesRadius: number = 6356.752;
        const latCosine: number = Math.cos(latitude * Math.PI / 180);
        const latSine: number = Math.sin(latitude * Math.PI / 180);

        let exp1: number = Math.pow(Math.pow(equaterRadius, 2) * latCosine, 2);
        let exp2: number = Math.pow(Math.pow(polesRadius, 2) * latSine, 2);
        let exp3: number = Math.pow(equaterRadius * latCosine, 2);
        let exp4: number = Math.pow(polesRadius * latSine, 2);

        this.radius = Math.sqrt((exp1 + exp2) / (exp3 + exp4));
    }

    // using haversine formula
    private getDegreeOfMeter(lat1: number, lng1: number, lat2: number, lng2: number): number {
        const radianPerDegree: number = Math.PI / 180;
        const dLat: number = (lat2 - lat1) * radianPerDegree;
        const dLng: number = (lng2 - lng1) * radianPerDegree;

        let exp1: number = Math.pow(Math.sin(dLat / 2), 2);
        let exp2: number = Math.cos(lat1 * radianPerDegree) * Math.cos(lat2 * radianPerDegree);
        let exp3: number = Math.pow(Math.sin(dLng / 2), 2);

        let exp4: number = exp1 + exp2 * exp3;
        let exp5: number = 2 * Math.atan2(Math.sqrt(exp4), Math.sqrt(1 - exp4));

        return 1 / (this.radius * exp5) / 1000;
    }
}