import {AudioCallbackCode} from "./audioCallback.code";
import {StreamingConfig} from "src/app/constants/streaming.config";
import {interval, Subscription, timer} from "rxjs";
import {RandomUtil} from "../random.util";

export class AudioStreaming {
    private callback: any;

    private constraints: any = {};
    private streamName: string = "";
    private userData: any = {};
    private streamInfo: any = {};

    private audioIndex: number = -1;
    private audioStream: any = null;

    private webSocketConnection: any = null;
    private peerConnection: any = null;

    private sdpOutput: any;

    private streamCheckTimer: Subscription;

    // streaming time
    private hour: number = 0;
    private min: number = 0;
    private sec: number = 0;
    private streamingTimeInterval: any;

    constructor(callback: any) {
        this.constraints = {
            video : false,
            audio : true
        };

        this.streamInfo = {
            applicationName : StreamingConfig.audioApplicationName,
            streamName : "",
            sessionId : "[empty]"
        };

        this.userData = {param1 : "value1"};
        this.callback = callback;
    }

    public setStreamName(streamName: string): void {
        this.streamName = streamName;
    }

    public publish(): void {
        if (this.streamName == null || this.streamName.length == 0) {
            this.generateStreamName();
            if (this.streamName == null || this.streamName.length == 0) {
                this.callback(AudioCallbackCode.STOP);
            }
        } else {
            this.streamInfo.streamName = this.streamName;
        }

        this.getMicroPhone();
    }

    private generateStreamName(): void {
        this.streamName = RandomUtil.randomString(10);
        this.streamInfo.streamName = this.streamName;
    }

    public getStreamName(): string {
        return this.streamName;
    }

    private getMicroPhone(): void {
        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia(this.constraints)
                .then((stream) => {
                    this.audioStream = stream;
                    this.connectToWebSocket();
                })
                .catch((error) => {
                    this.callback(AudioCallbackCode.ERROR);
                })
        } else {
            this.callback(AudioCallbackCode.ERROR);
        }
    }

    private connectToWebSocket(): void {
        if (this.webSocketConnection != null) {
            return;
        }

        this.webSocketConnection = new WebSocket(StreamingConfig.audioSendUrl);
        this.webSocketConnection.binaryType = StreamingConfig.webSocketBinaryType;

        this.webSocketConnection.onopen = this.websocketOpened();

        this.webSocketConnection.onmessage = function (event: any): void {
            this.messageReceived(event, this);
        }.bind(this);

        this.webSocketConnection.onclose = function(): void {
            // nothing
        };

        this.webSocketConnection.onerror = function(event: any): void {
            this.callback(AudioCallbackCode.ERROR);
        }.bind(this);
    }

    private websocketOpened(): void {
        // for wait the websocket opened
        setTimeout(() => {
            this.peerConnection = new RTCPeerConnection({'iceServers': []});

            let audioTrackList: any[] = this.audioStream.getTracks();
            audioTrackList.forEach(audioTrack => {
                this.peerConnection.addTrack(audioTrack, this.audioStream);
            });

            this.peerConnection.createOffer(
                function (description: any): void {
                    this.setConnectionDescription(description, this);
                }.bind(this),
                function (error: any): void {
                    this.callback(AudioCallbackCode.ERROR);
                }.bind(this)
            );
        }, 500);
    }

    private setConnectionDescription(description: any, self: any): void {
        let enhanceData: any = new Object();
        enhanceData.audioBitrate = StreamingConfig.audioBitrate;
        description.sdp = self.enhanceSessionDescriptionProtocol(description.sdp, enhanceData);

        self.peerConnection.setLocalDescription(
            description,
            function (): void {
                self.webSocketConnection.send(
                    '{"direction":"publish", "command":"sendOffer", "streamInfo":' + JSON.stringify(self.streamInfo)
                    + ', "sdp":' + JSON.stringify(description)
                    + ', "userData":' + JSON.stringify(self.userData) + '}'
                );
            },
            function (): void {
                self.callback(AudioCallbackCode.ERROR);
            }
        );
    }

    private enhanceSessionDescriptionProtocol(sdp: string, enhanceData: any): string {
        let sdpLineList: string[] = sdp.split(/\r\n/);
        let sdpSection: string = "header";
        let hitMID: boolean = false;
        let result: string = "";

        if (sdp.includes("THIS_IS_SDPARTA")) {
            sdpLineList.forEach(sdpLine => {
                if (sdpLine.length > 0) {
                    if (this.isValidSdpLine(sdpLine)) {
                        result += sdpLine;
                        result += "\r\n";
                    }
                }
            });

            result = this.addAudio(result);
            sdp = result;
            sdpLineList = sdp.split(/\r\n/);
            result = "";
        }

        for (let sdpIndex in sdpLineList) {
            let sdpLine = sdpLineList[sdpIndex];

            if (sdpLine.length <= 0) {
                continue;
            }

            if (sdpLine.indexOf("m=audio") ==0 && this.audioIndex !=-1) {
                let audioMLineList: any = sdpLine.split(" ");
                result += audioMLineList[0] + " " + audioMLineList[1] + " " + audioMLineList[2] + " " + audioMLineList + "\r\n";
                continue;
            }

            result += sdpLine;

            if (sdpLine.indexOf("m=audio") === 0) {
                sdpSection = "audio";
                hitMID = false;
            } else if (sdpLine.indexOf("a=rtpmap") == 0) {
                sdpSection = "bandwidth";
                hitMID = false;
            }

            if (sdpLine.indexOf("a=mid:") === 0 || sdpLine.indexOf("a=rtpmap") == 0) {
                if (!hitMID) {
                    if ("audio".localeCompare(sdpSection) == 0) {
                        if (enhanceData.audioBitrate != undefined) {
                            result += "\r\nb=CT:" + (enhanceData.audioBitrate);
                            result += "\r\nb=AS:" + (enhanceData.audioBitrate);
                        }

                        hitMID = true;
                    }
                }
            }

            result += "\r\n";
        }

        return result;
    }

    private isValidSdpLine(sdpLine: string): boolean {
        if (sdpLine.startsWith("a=rtpmap") || sdpLine.startsWith("a=rtcp-fb") || sdpLine.startsWith("a=fmtp")) {
            let chunkList: string[] = sdpLine.split(":");

            if (chunkList.length > 1) {
                let number: any = chunkList[1].split(" ");
                if (!isNaN(number[0])) {
                    if (!number[1].startsWith("http") && !number[1].startsWith("ur")) {
                        let currentString = this.sdpOutput[number[0]];
                        if (!currentString) {
                            currentString = "";
                        }

                        currentString += sdpLine + "\r\n";
                        this.sdpOutput[number[0]] = currentString;

                        return false;
                    }
                }
            }
        }

        return true;
    }

    private addAudio(sdpResult: string): string {
        let audioLine: string = this.getAudioLine();
        let sdpLineList: string[] = sdpResult.split(/\r\n/);
        let result: string = "";
        let done: boolean = false;

        sdpLineList.forEach(sdpLine => {
            if (sdpLine.length > 0) {
                result += sdpLine;
                result += "\r\n";

                if ("a=rtcp-mux".localeCompare(sdpLine) == 0 && !done) {
                    result += audioLine;
                    done = true;
                }
            }
        });

        return result;
    }

    private getAudioLine(): string {
        let result: string = "";

        this.sdpOutput.forEach((line, idx) => {
            let lineInUse: string = this.sdpOutput[idx];
            result += line;

            if (lineInUse.includes(StreamingConfig.audioCodec)) {
                this.audioIndex = idx;
                return lineInUse;
            }
        });

        return result;
    }

    private messageReceived(event: any, self: any): void {
        let json = JSON.parse(event.data);
        let status = Number(json["status"]);

        if (status != 200) {
            self.callback(AudioCallbackCode.ERROR);
        } else {
            let sdpData = json["sdp"];
            if (sdpData !== undefined) {
                self.peerConnection.setRemoteDescription(
                    new RTCSessionDescription(sdpData),
                    function (): void {},
                    function (error: any): void {
                        self.callback(AudioCallbackCode.ERROR);
                    }
                );
            }

            let iceCandidateList = json["iceCandidates"];
            if (iceCandidateList !== undefined) {
                iceCandidateList.forEach(iceCandidate => {
                    self.peerConnection.addIceCandidate(new RTCIceCandidate(iceCandidate));
                });
            }
        }

        if (self.webSocketConnection != null) {
            self.webSocketConnection.close();
        }

        self.webSocketConnection = null;
        self.callback(AudioCallbackCode.READY);
        self.startCheckStream();
    }

    private startCheckStream(): void {
        this.streamCheckTimer = timer(0, 1000).subscribe(() => {
            if (this.audioStream == null || !this.audioStream.active) {
                this.callback(AudioCallbackCode.ERROR);
            }
        });
    }

    private stopCheckStream(): void {
        this.streamCheckTimer.unsubscribe();
    }

    public stop(): void {
        if (this.peerConnection != null) {
            this.peerConnection.close();
            this.peerConnection = null;
        }

        if (this.webSocketConnection != null) {
            this.webSocketConnection.close();
            this.webSocketConnection = null;
        }

        try {
            this.stopCheckStream();
            this.audioStream.getTracks().forEach(track => {
                track.stop();
            });
        } catch (exception) {}

        this.callback(AudioCallbackCode.STOP);
    }

    public startStreamingTimeInterval(): void {
        if (this.streamingTimeInterval != null) {
            this.stopStreamingTimeInterval();
        }

        this.streamingTimeInterval = interval(1000).subscribe(() => {
            if (this.sec == 59) {
                this.sec = 0;

                if (this.min == 59) {
                    this.min = 0;
                    ++this.hour;
                } else {
                    ++this.min;
                }
            } else {
                ++this.sec;
            }
        });
    }

    public stopStreamingTimeInterval(): void {
        try {
            this.streamingTimeInterval.unsubscribe();
            this.streamingTimeInterval = null;

            this.hour = 0;
            this.min = 0;
            this.sec = 0;
        } catch (e) {}
    }

    public getStreamingTime(): string {
        let hour: string = (this.hour > 9)? "" + this.hour : "0" + this.hour;
        let min: string = (this.min > 9)? "" + this.min : "0" + this.min;
        let sec: string = (this.sec > 9)? "" + this.sec : "0" + this.sec;

        return hour + ":" + min + ":" + sec;
    }
}
