import { IImage } from 'ng-simple-slideshow';
import { interval, timer } from 'rxjs';
import { AudioCallbackCode } from 'src/app/component/mgmt/control/audioStreaming/audioCallback.code';
import { AudioStreaming } from 'src/app/component/mgmt/control/audioStreaming/audioStreaming';
import { MqttService } from 'src/app/component/mgmt/control/mqtt.service';
import { Area } from 'src/app/component/mgmt/mission/mission/area';
import { Offset } from 'src/app/component/mgmt/mission/mission/offset';
import { Point } from 'src/app/component/mgmt/mission/mission/point';
import { APIUrl } from 'src/app/constants/api.url';
import { LiveMapConfig } from 'src/app/constants/livemap.config';
import { StreamingConfig } from 'src/app/constants/streaming.config';
import { HTTPCallBack } from 'src/app/service/http.callback';
import { HttpService } from 'src/app/service/http.service';
import { MapService } from 'src/app/service/map.service';
import { MessageService } from 'src/app/service/message.service';
import { DroneStatus } from '../control/droneStatus';
import { LiveMapDataDto } from '../control/liveMapData.dto';
import { MissionControl } from '../control/missionControl';
import { MissionControlResponse } from '../control/missionControlResponse';
import { MissionControlType } from '../control/missionControlType';
import { LivemapDto } from '../livemap/livemap.dto';
import { MissionDto } from '../mission/mission.dto';
import { MissionHistoryDto } from '../mission/missionHistory.dto';
import { MachineStatusDto } from './machineStatus.dto';
import { LicenseDto } from '../license/license.dto';
import { WowzaWebRTCPlayer } from '@nutbutterfly/wowza-webrtc-player';
import { AspectRatioType } from '../mission/setting/aspectRatio.type';

// refactoring
export class MachineDto {
    constructor (
        public seq: number = 0,
        public name: string = "",
        public serialNumber: string = "",
        public typeName: string = "",
        public accountSeq: number = 0,
        public accountEmail: string = "",
        public accountName: string = "",
        public groupSeq: number = 0,
        public regTime: string = "",

        public isStatusVisible: boolean = false,
        public machineStatusDto: MachineStatusDto = new MachineStatusDto(),
        public fov: number = 84,
        public aspectRatioTypeCode: AspectRatioType = AspectRatioType.RATIO_5TO4,

        public isEdit: boolean = false,

        public isPopupMode: boolean = false,
        public hasMissingMessage: boolean = false,
        public mqttService: MqttService = new MqttService(),

        public isPathTracing: boolean = false,
        public statusList: DroneStatus[] = [],
        public realTimeInterval: any = null,

        public httpService: HttpService = null,

        public mapService: MapService = null,
        public isSelected: boolean = false,
        public mission: MissionDto = new MissionDto(),
        public missionControlType: MissionControlType = null,
        public nextMissionControlType: MissionControlType = null,
        public isDirectlyMissionStart: boolean = false,

        public hasFailControl: boolean = false,

        public isStreaming: boolean = false,
        public isStreamingPIPMode: boolean = false,
        public webRTCPlayer: WowzaWebRTCPlayer = null,
        public webRTCPipPlayer: WowzaWebRTCPlayer = null,

        // for visible filter
        public isVisibleChecked: boolean = false,

        // livemap
        public livemapCallback: any = null,
        public isLivemapVisible: boolean = true,
        public livemapImageList: IImage[] = [],

        // audio
        public audioJsPlayer: any = null,
        public isAudioViewVisible: boolean = false,
        public isAudioSend: boolean = false,
        public audioStreaming: AudioStreaming = null,

        public messageService: MessageService = null,

        public zIndex: number = 10,

        // license
        public adminLicenseDto: LicenseDto = null,

        private packetsReceived: number = 0,
        private checkInterval: any = null,
        private readonly PLAY_DELAY: number = 500,
        private readonly PACKET_CHECK_INTERVAL: number = 2000
    ) {}

    public initMqtt(dialogScrollToBottom: any, missionStopCallback: any, livemapCallback: any, adminLicenseDto: LicenseDto): void {
        if (this.mqttService.isConnected()) {
            return;
        }

        this.adminLicenseDto = adminLicenseDto;
        this.livemapCallback = livemapCallback;

        this.mqttService = new MqttService();
        this.mqttService.init(this, dialogScrollToBottom);

        this.mqttService.setControlCallback(
            function(missionControlResponse: MissionControlResponse): void {
                let controlTypeString: string = "" + missionControlResponse.controlType;
                let controlType: MissionControlType = MissionControlType[controlTypeString];

                if (controlType == MissionControlType.READY) {
                    if (missionControlResponse.isAuth && missionControlResponse.isValidMission) {
                        // refactoring
                        if (!this.isDirectlyMissionStart) {
                            return;
                        }

                        this.missionControlType = controlType;

                        this.setControlType(MissionControlType.START);
                        this.publishControl();
                    } else {
                        this.hasFailControl = true;
                        this.isDirectlyMissionStart = false;
                    }
                } else {
                    if (missionControlResponse.isControlOk) {
                        this.missionControlType = controlType;

                        if (this.missionControlType == MissionControlType.START) {
                            this.startMission();
                        }

                        if (this.missionControlType == MissionControlType.STOP || this.missionControlType == MissionControlType.RETURN_TO_HOME) {
                            this.mission = new MissionDto();
                            this.missionControlType = null;

                            missionStopCallback(this.seq, this.accountEmail);
                            this.isDirectlyMissionStart = false;
                        }
                    } else {
                        this.hasFailControl = true;
                        this.isDirectlyMissionStart = false;
                    }
                }
            }.bind(this)
        );

        this.mqttService.setStatusCallback(
            function(statusList: DroneStatus[]): void {
                statusList.forEach(status => {
                    if (this.statusList.length > 20) {
                        this.statusList.shift();
                    }

                    this.statusList.push(status);
                });
            }.bind(this)
        );

        this.mqttService.setSelfMissionStartCallback(
            function(mission: MissionDto): void {
                this.mission = Object.assign(new MissionDto(), mission);
                this.missionControlType = MissionControlType.START;

                if (this.mission != null) {
                    this.fov = this.mission.fov;
                }
            }.bind(this)
        );

        this.mqttService.setMessageReceivedCallback(
            function(): void {
                if (!this.isPopupMode) {
                    this.hasMissingMessage = true;
                }

                this.messageService.publishReceived(true);
            }.bind(this)
        );

        this.mqttService.setStreamingCallback(
            function(result: boolean, isStart: boolean): void {
                if (result) {
                    this.hasFailControl = false;

                    if (isStart && this.adminLicenseDto.useStreaming) {
                        this.isStreaming = true;

                        timer(500).subscribe(() => {
                            this.init();
                        });
                    } else {
                        this.clearStreaming();
                    }
                } else {
                    this.hasFailControl = true;
                }
            }.bind(this)
        );

        this.mqttService.setAudioCallback(() => {});

        this.mqttService.setLivemapCallback(
            function(liveMapData: LiveMapDataDto): void {
                if (!this.adminLicenseDto.useLivemap) {
                    return;
                }

                let offset: Offset = this.getOffset(liveMapData.latitude, liveMapData.longitude);
                liveMapData.offsetX = offset.y;
                liveMapData.offsetY = offset.x;

                let imageCoverage: Area = this.getImageCoverage(liveMapData.altitude, liveMapData.imageWidth, liveMapData.imageHeight);
                let meterPerPixel: number = this.mapService.map.getMeterPerPixel();

                let mappedWidth: number = imageCoverage.width / meterPerPixel;
                let mappedHeight: number = imageCoverage.height / meterPerPixel;

                liveMapData.imageWidth *= (mappedWidth / liveMapData.imageWidth);
                liveMapData.imageHeight *= (mappedHeight / liveMapData.imageHeight);

                liveMapData.offsetX -= mappedHeight / 2;
                liveMapData.offsetY -= mappedWidth / 2;

                this.livemapImageList.push({
                    url: liveMapData.fileName,
                    clickAction: () => this.setLivemapFullScreenMode(liveMapData.fileName)
                });

                liveMapData.machine = this;
                this.livemapCallback(liveMapData);
            }.bind(this)
        );
    }

    // audio
    public clearAudioPlayer(): void {
        try {
            this.audioJsPlayer.pause();
        } catch (error) {}
    }

    // livemap
    public setLivemapImageList(livemapList: LivemapDto[]): void {
        this.livemapImageList = [];

        livemapList.forEach(livemap => {
            let liveMapData: LiveMapDataDto = new LiveMapDataDto(
                null, livemap.latitude, livemap.longitude, 0, 0, livemap.yaw, livemap.altitude, livemap.fileName, livemap.width, livemap.height, true
            );

            let offset: Offset = this.getOffset(liveMapData.latitude, liveMapData.longitude);
            liveMapData.offsetX = offset.y;
            liveMapData.offsetY = offset.x;

            let imageCoverage: Area = this.getImageCoverage(liveMapData.altitude, liveMapData.imageWidth, liveMapData.imageHeight);
            let meterPerPixel: number = this.mapService.map.getMeterPerPixel();

            let mappedWidth: number = imageCoverage.width / meterPerPixel;
            let mappedHeight: number = imageCoverage.height / meterPerPixel;

            liveMapData.imageWidth *= (mappedWidth / liveMapData.imageWidth);
            liveMapData.imageHeight *= (mappedHeight / liveMapData.imageHeight);

            liveMapData.offsetX -= mappedHeight / 2;
            liveMapData.offsetY -= mappedWidth / 2;

            this.livemapImageList.push({
                url: liveMapData.fileName,
                clickAction: () => this.setLivemapFullScreenMode(liveMapData.fileName)
            });

            liveMapData.machine = this;
            this.livemapCallback(liveMapData);
        });
    }

    private getImageCoverage(altitude: number, imageWidth: number, imageHeight: number): Area {
        let width: number = 2 * (altitude / Math.tan((90 - this.fov / 2.0) * Math.PI / 180)) * (imageWidth / Math.sqrt(Math.pow(imageWidth, 2) + Math.pow(imageHeight, 2)));
        let height: number = 2 * (altitude / Math.tan((90 - this.fov / 2.0) * Math.PI / 180)) * (imageHeight / Math.sqrt(Math.pow(imageWidth, 2) + Math.pow(imageHeight, 2)));

        return new Area(width, height);
    }

    private setLivemapFullScreenMode(filePath: string): void {
        let url: string = LiveMapConfig.fullScreenUrl;
        url += encodeURIComponent(btoa(filePath).split("").reverse().join(""));

        window.open(url);
    }

    public init(): void {
        let element: any = document.querySelector("#normalStreamingEl");
        this.webRTCPlayer = new WowzaWebRTCPlayer(element, {
            sdpUrl: StreamingConfig.URL,
            applicationName: StreamingConfig.APPLICATION_NAME,
            // streamName: this.accountEmail
            streamName: this.accountEmail
        });

        timer(this.PLAY_DELAY).subscribe(() => {
            this.play();
        });

        if (this.checkInterval != null) {
            this.checkInterval.unsubscribe();
            this.checkInterval = null;
        }

        this.check();
    }

    private async play() {
        this.packetsReceived = 0;

        try {
            await this.webRTCPlayer.playRemote();
        } catch (error) {}
    }

    public async stop(player: WowzaWebRTCPlayer) {
        await player.stop();
    }

    public check(): void {
        this.checkInterval = interval(this.PACKET_CHECK_INTERVAL).subscribe(() => {
            let peerConnection: RTCPeerConnection = this.webRTCPlayer.getPeerConnection();

            if (peerConnection == null) {
                this.init();
            } else {
                peerConnection.getStats(null).then(stats => {
                    stats.forEach(report => {
                        if (report.type == "transport") {
                            if (this.packetsReceived == report.packetsReceived
                                || (report.packetsReceived - this.packetsReceived <= 5)) {

                                this.init();
                            } else {
                                this.packetsReceived = report.packetsReceived;
                            }
                        }
                    });
                });
            }
        });
    }

    // get the real-time status
    public setRealTimeInterval(): void {
        if (this.realTimeInterval != null) {
            return;
        }

        this.realTimeInterval = interval(90).subscribe(() => {
            this.setRealTimeStatus();
        });
    }

    public clearRealTimeInterval(): void {
        if (this.realTimeInterval != null) {
            this.statusList = [];
            this.realTimeInterval.unsubscribe();
            this.realTimeInterval = null;
        }
    }

    private setRealTimeStatus(): void {
        let status: DroneStatus = this.statusList.shift();

        if (status == undefined || status.latitude == 0 || status.longitude == 0) {
            return;
        }

        this.machineStatusDto.latitude = status.latitude;
        this.machineStatusDto.longitude = status.longitude;
        this.machineStatusDto.rotate = status.rotate;

        let offset: Offset = this.getOffset(status.latitude, status.longitude);
        this.machineStatusDto.offsetX = offset.x;
        this.machineStatusDto.offsetY = offset.y;


        this.machineStatusDto.batteryStatusList = status.batteryStatusList;
        this.machineStatusDto.speed = status.speed;
        this.machineStatusDto.distance = status.distance;
        this.machineStatusDto.altitude = status.altitude;
    }

    private getOffset(latitude: number, longitude: number): Offset {
        let result: Offset = new Offset();

        if (longitude == 0 || latitude == 0) {
            result.x = 0;
            result.y = 0;
        } else {
            let point: Point = new Point(longitude, latitude);
            let offset: Offset = this.mapService.map.convertPointToOffset(point);
            let containerOffset: Offset = this.mapService.map.getContainerOffset() || new Offset();

            result.x = offset.x + containerOffset.x;
            result.y = offset.y + containerOffset.y;
        }

        return result;
    }

    // refactoring
    public setControlType(missionControlType: MissionControlType): void {
        switch (missionControlType) {
            case MissionControlType.TAKE_OFF :
                if (this.missionControlType == MissionControlType.TAKE_OFF) {
                    this.nextMissionControlType = MissionControlType.LAND;
                } else {
                    this.nextMissionControlType = MissionControlType.TAKE_OFF;
                }
                break;
            case MissionControlType.START :
                if (this.mission.seq == 0) {
                    this.missionControlType = null;
                } else {
                    if (this.missionControlType == MissionControlType.READY || this.missionControlType == MissionControlType.PAUSE) {
                        this.nextMissionControlType = MissionControlType.START;
                    } else {
                        this.nextMissionControlType = MissionControlType.READY;
                        this.isDirectlyMissionStart = true;
                    }
                }
                break;
            default :
                this.nextMissionControlType = missionControlType;
                break;
		}
    }

    public publishControl(is3d: boolean = false): void {
        let missionControl: MissionControl = new MissionControl();
        missionControl.controlType = this.nextMissionControlType;
        missionControl.accountEmail = this.accountEmail;

        if (missionControl.controlType == MissionControlType.READY) {
            missionControl.missionType = this.mission.typeCode;
            missionControl.subject = this.mission.name;
            missionControl.seq = this.mission.seq;
            missionControl.is3d = is3d;
        }

        this.hasFailControl = false;
        this.mqttService.publishControl(missionControl);
    }

    private startMission(): void {
        if (this.mission == null || this.mission.seq == 0) {
            return;
        }

        let missionHistoryDto: MissionHistoryDto = new MissionHistoryDto();
        missionHistoryDto.machineSeq = this.seq;
        missionHistoryDto.missionSeq = this.mission.seq;

        this.httpService.put(APIUrl.MISSION_START(this.accountSeq), missionHistoryDto, new HTTPCallBack());
    }

    public publishStreaming(isStart: boolean): void {
        this.mqttService.publishStreaming(isStart);
    }

    public clearStreaming(): void {
        if (this.checkInterval != null) {
            this.checkInterval.unsubscribe();
            this.checkInterval = null;
        }

        this.isStreaming = false;

        if (this.isStreamingPIPMode) {
            this.isStreamingPIPMode = false;
        }

        if (this.webRTCPlayer != null) {
            this.stop(this.webRTCPlayer);
            this.webRTCPlayer = null;
        }

        if (this.webRTCPipPlayer != null) {
            this.stop(this.webRTCPipPlayer);
            this.webRTCPipPlayer = null;
        }

        this.streamingStop();
    }

    private streamingStop(): void {
        if (this.httpService == null) {
            return;
        }

        this.httpService.get(APIUrl.STREAMING_STOP(this.accountSeq),  new HTTPCallBack());
    }

    public setAudioStreaming(): void {
        if (this.audioStreaming == null) {
            this.audioStreaming = new AudioStreaming(
                function (audioCallbackCode: AudioCallbackCode): void {
                    switch (audioCallbackCode) {
                        case AudioCallbackCode.READY :
                            this.audioStreaming.startStreamingTimeInterval();
                            this.mqttService.addAudioMessage(false);
                            this.publishAudioStreaming(true);
                            break;
                        case AudioCallbackCode.STOP :
                            this.audioStreaming.stopStreamingTimeInterval();
                            this.mqttService.endAudioMessage(false);
                            this.isAudioSend = false;
                            this.publishAudioStreaming(false);
                            break;
                        case AudioCallbackCode.ERROR :
                            this.audioStreaming.stop();
                            break;
                        default :
                            // none
                            break;
                    }
                }.bind(this)
            );

            this.audioStreaming.setStreamName(this.accountEmail + "_S");
        }

        this.audioStreaming.publish();
    }

    public stopAudioStreaming(): void {
        if (this.audioStreaming != null) {
            this.audioStreaming.stop();
        }
    }

    public publishAudioStreaming(isStart: boolean): void {
        this.mqttService.publishAudioStreaming(isStart, StreamingConfig.audioStreamName(this.audioStreaming.getStreamName()));
    }
}
