export enum FinishActionCode {
    NO_ACTION = 0,
    GO_HOME = 1,
    AUTO_LAND = 2,
    GO_FIRST_WAYPOINT = 3,
    CONTINUE_UNTIL_END = 4
}

export namespace FinishActionCode {
    export function getFinishActionCodeLanguage(finishActionCode: FinishActionCode): string {
        switch (finishActionCode) {
            case FinishActionCode.NO_ACTION:
                return "control.mission.type.finish-action-type.no-action";
            case FinishActionCode.GO_HOME:
                return "control.mission.type.finish-action-type.go-home";
            case FinishActionCode.AUTO_LAND:
                return "control.mission.type.finish-action-type.auto-land";
            case FinishActionCode.GO_FIRST_WAYPOINT:
                return "control.mission.type.finish-action-type.go-first-waypoint";
            case FinishActionCode.CONTINUE_UNTIL_END:
                return "control.mission.type.finish-action-type.continue-until-end";
        }
    }

    export function getAll(): FinishActionCode[] {
        return [
            FinishActionCode.NO_ACTION, FinishActionCode.GO_HOME, FinishActionCode.AUTO_LAND,
            FinishActionCode.GO_FIRST_WAYPOINT, FinishActionCode.CONTINUE_UNTIL_END
        ];
    }
}
