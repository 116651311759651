import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {JSEncrypt} from "jsencrypt";
import {AccountDto} from "../../../../dto/account/account.dto";
import {ResponseDto} from "../../../../dto/response.dto";
import {APIUrl} from "../../../../constants/api.url";
import {HttpClient} from "@angular/common/http";
import {ProfileDto} from "../dto/profile.dto";

@Injectable()
export class ProfileService {
    constructor(private httpClient: HttpClient) {}

    public getPublicKey(): Observable<string> {
        return new Observable<string>(observer => {
            this.httpClient.get<ResponseDto<string>>(APIUrl.GET_KEY).subscribe(
                response => {
                    observer.next(response.data);
                }, _ => {
                    observer.error();
                }
            );
        });
    }

    public encryptPassword(publicKey: string, plainPassword: string): string | null {
        try {
            let jsEncrypt: any = new JSEncrypt();
            jsEncrypt.setPublicKey(publicKey);
            let password: string = plainPassword.trim();

            return jsEncrypt.encrypt(password);
        } catch (error) {
            return null;
        }
    }

    public checkPassword(accountDto: AccountDto): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.httpClient.post<ResponseDto<boolean>>(APIUrl.CHECK_PASSWORD, accountDto).subscribe(
                response => {
                    observer.next(response.isSuccess);
                }, _ => {
                    observer.error();
                }
            );
        });
    }

    public editProfile(accountSeq: number, profileDto: ProfileDto): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.httpClient.put<ResponseDto<boolean>>(APIUrl.PROFILE(accountSeq), profileDto).subscribe(
                response => {
                    observer.next(response.isSuccess);
                }, _ => {
                    observer.error();
                }
            );
        });
    }
}
