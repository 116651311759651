import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SubmenuService {
    private isAccountInfoMenu: Subject<void> = new Subject<void>();
    public isAccountInfoMenu$: any;

    private isAccountCreateMenu: Subject<void> = new Subject<void>();
    public isAccountCreateMenu$: any;

    private isAccountGroupSettingMenu: Subject<void> = new Subject<void>();
    public isAccountGroupSettingMenu$: any;

    private isAccountPermissionSettingMenu: Subject<void> = new Subject<void>();
    public isAccountPermissionSettingMenu$: any;

    private isDroneMenu: Subject<void> = new Subject<void>();
    public isDroneMenu$: any;

    constructor() {
        this.isAccountInfoMenu$ = this.isAccountInfoMenu.asObservable();
        this.isAccountCreateMenu$ = this.isAccountCreateMenu.asObservable();
        this.isAccountGroupSettingMenu$ = this.isAccountGroupSettingMenu.asObservable();
        this.isAccountPermissionSettingMenu$ = this.isAccountPermissionSettingMenu.asObservable();
        this.isDroneMenu$ = this.isDroneMenu.asObservable();
    }

    public publishIsAccountInfoMenu(): void {
        this.isAccountInfoMenu.next();
    }

    public publishIsAccountCreateMenu(): void {
        this.isAccountCreateMenu.next();
    }

    public publishIsAccountGroupSettingMenu(): void {
        this.isAccountGroupSettingMenu.next();
    }

    public publishIsAccountPermissionSettingMenu(): void {
        this.isAccountPermissionSettingMenu.next();
    }

    public publishIsDroneMenu(): void {
        this.isDroneMenu.next();
    }
}
