import {Component, EventEmitter, Output} from "@angular/core";
import {Phase} from "../../base/data/phase.type";

@Component({
    selector: "sign-up-tos",
    templateUrl: "./view/tos.component.html",
    styleUrls: ["./view/tos.component.css"]
})
export class TosComponent {
    @Output() public movePhaseEvent: EventEmitter<Phase | null>;

    public isTosAgree!: boolean;
    public isPiaAgree!: boolean;

    constructor() {
        this.movePhaseEvent = new EventEmitter<Phase | null>();
    }

    public isAgree(): boolean {
        return this.isTosAgree && this.isPiaAgree;
    }

    public movePrev(): void {
        this.movePhaseEvent.emit();
    }

    public moveNext(): void {
        if (this.isAgree()) {
            this.movePhaseEvent.emit(Phase.Information);
        }
    }
}
