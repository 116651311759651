import {ChangeDetectorRef, Component, ElementRef, OnDestroy, QueryList, ViewChild, ViewChildren} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";
import {ContextMenuComponent, ContextMenuService} from "ngx-contextmenu";
import {SelectContainerComponent} from "ngx-drag-to-select";
import {fromEvent, Subscription, timer} from "rxjs";
import {APIUrl} from "src/app/constants/api.url";
import {MapControlType} from "src/app/constants/mapControl.type";
import {MissionSettingType} from "src/app/constants/missionSetting.type";
import {ShapeColorData} from "src/app/constants/shapeColorData";
import {StreamingConfig} from "src/app/constants/streaming.config";
import {AccountDto} from "src/app/dto/account/account.dto";
import {ImagePathListDto} from "src/app/dto/control/imagePathList.dto";
import {LiveMapDataDto} from "src/app/dto/control/liveMapData.dto";
import {MissionControlType} from "src/app/dto/control/missionControlType";
import {PoiItem} from "src/app/dto/control/poiItem";
import {PoiResult} from "src/app/dto/control/poiResult";
import {PoiSearchCode} from "src/app/dto/control/poiSearchCode";
import {PoiSearchData} from "src/app/dto/control/poiSearchData";
import {GroupDto} from "src/app/dto/group/group.dto";
import {LivemapDto} from "src/app/dto/livemap/livemap.dto";
import {LivemapListDto} from "src/app/dto/livemap/livemapList.dto";
import {MachineDto} from "src/app/dto/machine/machine.dto";
import {MissionDto} from "src/app/dto/mission/mission.dto";
import {MissionSearchCode} from "src/app/dto/mission/missionSearch.code";
import {MissionSearchDto} from "src/app/dto/mission/missionSearch.dto";
import {PointDto} from "src/app/dto/mission/point.dto";
import {PointListDto} from "src/app/dto/mission/pointList.dto";
import {AspectRatioType, AspectRatioTypeLabel} from "src/app/dto/mission/setting/aspectRatio.type";
import {CameraType, CameraTypeFov} from "src/app/dto/mission/setting/camera.type";
import {DirectionCode} from "src/app/dto/mission/setting/direction.code";
import {FinishActionCode} from "src/app/dto/mission/setting/finishAction.code";
import {MissionType} from "src/app/dto/mission/setting/mission.type";
import {PointActionCode} from "src/app/dto/mission/setting/pointAction.code";
import {TempPointDto} from "src/app/dto/mission/tempPoint.dto";
import {AddressDto} from "src/app/dto/mission/weather/address.dto";
import {EMFDto} from "src/app/dto/mission/weather/emf.dto";
import {LightningCode} from "src/app/dto/mission/weather/lightning.code";
import {PrecipitationCode} from "src/app/dto/mission/weather/precipitation.code";
import {SkyCode} from "src/app/dto/mission/weather/sky.code";
import {WeatherDto} from "src/app/dto/mission/weather/weather.dto";
import {WindCode} from "src/app/dto/mission/weather/wind.code";
import {PopupDto} from "src/app/dto/popup.dto";
import {ResponseDto} from "src/app/dto/response.dto";
import {CoordinateDto} from "src/app/dto/shapefile/coordinate.dto";
import {CoordinateType} from "src/app/dto/shapefile/coordinate.type";
import {MissionShapeFileDto} from "src/app/dto/shapefile/missionShapefile.dto";
import {ShapeType} from "src/app/dto/shapefile/shape.type";
import {ShapeFileDto} from "src/app/dto/shapefile/shapeFile.dto";
import {ShapeFileDataDto} from "src/app/dto/shapefile/shapeFileData.dto";
import {StreamingDto} from "src/app/dto/streaming/streaming.dto";
import {AccountService} from "src/app/service/account.service";
import {AudioElementService} from "src/app/service/audioElement.service";
import {CanvasService} from "src/app/service/canvas.service";
import {HTTPCallBack} from "src/app/service/http.callback";
import {HttpService} from "src/app/service/http.service";
import {MapService} from "src/app/service/map.service";
import {MessageService} from "src/app/service/message.service";
import {MonitoringService} from "src/app/service/monitoring.service";
import {PopupService} from "src/app/service/popup.service";
import {ProgressService} from "src/app/service/progress.service";
import {SettingsService} from "src/app/service/settings.service";
import {SubmenuService} from "src/app/service/submenu.service";
import {Distance} from "../mission/mission/distance";
import {Offset} from "../mission/mission/offset";
import {Point} from "../mission/mission/point";
import {Figure} from "../mission/polygon/figure";
import {GridFigure} from "../mission/polygon/gridFigure";
import {LatLng} from "../mission/polygon/latLng";
import {PolygonFigure} from "../mission/polygon/polygonFigure";
import {WaypointFigure} from "../mission/polygon/waypointFigure";
import {WaypointOption} from "../mission/polygon/waypointOption";
import {WaypointService} from "../mission/polygon/waypointService";
import {ClusterService} from "./cluster/cluster.service";
import {VisibleFilterService} from "./filter/visibleFilter.service";
import {MapFunction} from "./mapFunction/mapFunction";
import {MissionArea} from "./missionArea";
import {ShapeFileUtil} from "./shapefile/shapeFile.util";
import {ShapeFileValidation} from "./shapefile/shapeFileValidation";
import {MachineListComponent} from "./machine/machine-list.component";
import {TranslateService} from "@ngx-translate/core";
import {saveAs} from "file-saver";

@Component({
    selector: 'controlArea',
    templateUrl: '../../../view/html/control.html',
    styleUrls: ['../../../view/css/control.css']
})
export class ControlComponent implements OnDestroy {
    public isPanelVisible: boolean;
    public isMapChanged: boolean;
    public isMapMoved: boolean;
    private isPointMoving: boolean;
    private isInit: boolean = true;

    private accountDto: AccountDto;
    public machine: MachineDto = new MachineDto();
    public cameraTypeFov: typeof CameraTypeFov = CameraTypeFov;

    @ViewChildren("chatScrollList") private chatScrollList: QueryList<ElementRef>;
    @ViewChildren("streamingDialogList") private streamingDialogList: QueryList<ElementRef>;

    // zoom info
    public zoomLevel: number = 0;

    // context menu
    @ViewChild("singleControlMenu") private singleControlMenu: ContextMenuComponent;
    @ViewChild("singleAutoControlMenu") private singleAutoControlMenu: ContextMenuComponent;
    @ViewChild("overlapControlMenu") private overlapControlMenu: ContextMenuComponent;
    public overlappedMachineList: MachineDto[] = [];

    // control panel
    public mapControlType: typeof MapControlType = MapControlType;
    public nowMapControlType: MapControlType = MapControlType.MOVE_MAP;
    public isMapControlTypeVisible: boolean = false;

    @ViewChild(SelectContainerComponent) private selectContainer: SelectContainerComponent;
    public isSelectedMachineExist: boolean = false;

    // set the mission - admin
    public isAdminMissionDialogVisible: boolean = false;
    private adminMissionSearchDto: MissionSearchDto = new MissionSearchDto();
    private adminMissionList: MissionDto[] = [];
    private isAdminMissionSelected: boolean = false;

    // set the mission - member
    public isMissionDialogVisible: boolean = false;
    private missionSearchCode: typeof MissionSearchCode = MissionSearchCode;
    private missionSearchDto: MissionSearchDto = new MissionSearchDto();
    private missionList: MissionDto[] = [];

    // mission control
    public missionControlType: typeof MissionControlType = MissionControlType;
    private controlTargetList: MachineDto[] = [];
    public isIs3dPopupVisible: boolean = false;

    // mission area
    public missionAreaList: MissionArea[] = [];

    private readonly MAX_WAYPOINT_LENGTH: number = 99;

    // streaming
    @ViewChild("panelScroll") private panelScrollElement: ElementRef;
    @ViewChild("pipStreaming") private pipStreamingElement: ElementRef;

    // cluster
    @ViewChild("clusterWrap") private clusterWrap: ElementRef;
    private clusterService: ClusterService = null;

    // visible filter
    private visibleFilterService: VisibleFilterService;

    // monitoring
    public machineListSubscriber: any = null;
    public onlineListSubscriber: any = null;

    private mapRefreshSubscriber: any = null;
    private mapChangeSubscriber: any = null;

    // map sub-function
    private subscription: Subscription;
    public mapFunction: MapFunction;

    // streaming list
    public streamingFileUrl: string = StreamingConfig.fileUrl;
    public isStreamingListVisible: boolean = false;
    public streamingList: StreamingDto[] = [];

    private treeData: GroupDto = new GroupDto();

    // livemap list
    @ViewChild("livemapSlider") private livemapSlider: any;
    public isLivemapListVisible: boolean = false;
    public livemapHistoryList: LivemapListDto[] = [];

    public isMapTypeVisible: boolean = false;

    // shapefile load
    public shapeFileDtoList: ShapeFileDto[] = [];
    @ViewChildren("shapeCanvasWrap") shapeCanvasWrap: QueryList<ElementRef>;
    private shapeCanvasContextList: CanvasRenderingContext2D[] = [];

    public isShapeMenuVisible: boolean = false;
    public isShapeLayerVisible: boolean = false;

    private selectedShapeFileIndex: number = -1;
    private selectedShapeIndex: number = -1;

    public shapeColorData: ShapeColorData = new ShapeColorData();
    public isSpatialDataSelectDialogVisible: boolean = false;
    public isKmlFileLoadDialogVisible: boolean = false;
    @ViewChild("kmlFileSelector") kmlFileSelector: ElementRef;
    public targetKmlFile: any;

    public isShapeFileLoadDialogVisible: boolean = false;
    @ViewChild("shapeFileSelector") shapeFileSelector: ElementRef;
    public targetShapeFileList: any[] = [];
    public shapeFileValidation: ShapeFileValidation = new ShapeFileValidation();
    public shapeFileCharset: string = "UTF-8";
    public shapeFileEPSG: number = 4326;

    public isShapeFileCharsetDialogVisible: boolean = false;
    public isShapeFileEPSGDialogVisible: boolean = false;

    public isAllDbfDataDialogVisible: boolean = false;
    public isDbfDataDialogVisible: boolean = false;
    public targetShapeFileIndex: number = -1;

    public isSpatialDataDownloadSelectDialogVisible: boolean = false;

    // shapefile list
    public isMissionShapeFileListVisible: boolean = false;
    public isCoordinateSelectVisible: boolean = false;
    public missionShapeFileList: MissionShapeFileDto[] = [];
    public selectedShapeFile: MissionShapeFileDto = null;
    public coordinateType: CoordinateType = CoordinateType.GRS80_EPSG_5186;

    private selectedMission: MissionDto = null;

    @ViewChild("shapeFileMenu") private shapeFileMenu: ContextMenuComponent;
    @ViewChild("nonShapeFileMenu") private nonShapeFileMenu: ContextMenuComponent;

    @ViewChild(MachineListComponent) private machineListComponent: MachineListComponent;

    // weather
    public emfDto: EMFDto;
    private addressDto: AddressDto;
    public weatherDto: WeatherDto;

    private weatherServiceTimer: Subscription = null;

    // for mission tab
    public isDroneTabVisible: boolean = true;
    public isMachineVisible: boolean = true;
    public isMissionListVisible: boolean = false;

    public missionType: typeof MissionType = MissionType;

    private figure: Figure;
    @ViewChildren("cornorPointList") cornorPointElList: QueryList<ElementRef>;
    @ViewChildren("centerPointList") centerPointElList: QueryList<ElementRef>;

    @ViewChild("movePointEl") movePointEl: ElementRef;
    private movePoint: Point = new Point();

    @ViewChild("rotatePointEl") rotatePointEl: ElementRef;
    private rotatePoint: Point = new Point();

    private latLng: LatLng = new LatLng();

    private waypointService: WaypointService;
    private waypointList: Offset[] = [];

    public distancePointList: Distance[] = [];

    // settings
    public missionSettingType: typeof MissionSettingType = MissionSettingType;
    private directionCode: typeof DirectionCode = DirectionCode;
    private finishActionCode: typeof FinishActionCode = FinishActionCode;
    private pointActionCode: typeof PointActionCode = PointActionCode;
    public missionDto: MissionDto = new MissionDto();

    public interval: number = 0;
    public distancePerPoint: number = 0;

    public mgmtMissionList: MissionDto[] = [];

    // poi
    public poiSearchData: PoiSearchData = new PoiSearchData();
    public poiSearchCode: typeof PoiSearchCode = PoiSearchCode;
    public poiResult: PoiResult = new PoiResult();

    constructor(
        private httpService: HttpService,
        private messageService: MessageService,
        public mapService: MapService,
        private canvasService: CanvasService,
        private accountService: AccountService,
        private popupService: PopupService,
        private sanitizer: DomSanitizer,
        private monitoringService: MonitoringService,
        private settingsService: SettingsService,
        private audioElementService: AudioElementService,
        private changeDetector: ChangeDetectorRef,
        private contextMenuService: ContextMenuService,
        private progressService: ProgressService,
        private submenuService: SubmenuService,
        private translateService: TranslateService) {

        this.isPanelVisible = false;
        this.isMapChanged = false;
        this.isMapMoved = false;

        this.accountDto = this.accountService.getAccount();
        this.setMapRefreshSubscriber();
        this.setMapChangeSubscriber();

        this.mapFunction = new MapFunction(this.mapService, this.canvasService);
        this.getTreeData();

        if (!this.accountDto.licenseDto.useRemoteControl) {
            this.settingsService.isAutoControl = false;
        }

        this.settingsService.isAutoControl = true;
        this.setSubmenuSubscriber();

        this.addressDto = new AddressDto();
        this.weatherDto = new WeatherDto();
        this.setWeatherTimer();

        // emf
        this.emfDto = new EMFDto();
        this.getEMFData();

        this.waypointService = new WaypointService(this.mapService.map);
    }

    ngOnInit() {
        this.subscription = fromEvent<KeyboardEvent>(document, "keydown").subscribe(event => {
            if (event.keyCode == 27) {
                if (this.mapFunction.isEnable) {
                    this.mapFunction.isEnable = false;
                }
            }
        });
    }

    ngAfterViewInit() {
        this.setMonitoringSubscribe();
    }

    ngOnDestroy() {
        this.canvasService.canvas.clear();

        if (this.visibleFilterService != null) {
            this.visibleFilterService.clearVisibleList();
        }

        if (this.machineListSubscriber != null) {
            this.machineListSubscriber.unsubscribe();
        }

        if (this.onlineListSubscriber != null) {
            this.onlineListSubscriber.unsubscribe();
        }

        if (this.mapRefreshSubscriber != null) {
            this.mapRefreshSubscriber.unsubscribe();
        }

        if (this.mapChangeSubscriber != null) {
            this.mapChangeSubscriber.unsubscribe();
        }

        this.missionAreaList = [];
        this.unsetSubmenuSubscriber();
    }

    public isAutoControl(): boolean {
        return this.settingsService.isAutoControl;
    }

    private setSettings(): void {
        this.httpService.post(APIUrl.SET_SETTINGS(this.accountDto.seq), this.settingsService.toDto(), new HTTPCallBack());
    }

    private setMapRefreshSubscriber(): void {
        this.mapRefreshSubscriber = this.mapService.refresh$.subscribe(() => {
            this.mapService.map.clearEventListeners();
        });
    }

    private setMapChangeSubscriber(): void {
        this.mapChangeSubscriber = this.mapService.mapChange$.subscribe(() => {
            this.setMapEventListener();
        });
    }

    private setMonitoringSubscribe(): void {
        this.machineListSubscriber = this.monitoringService.machineList$.subscribe(
            machineList => {
                if (this.isInit) {
                    this.visibleFilterService = new VisibleFilterService(
                        true, machineList, this.mapService, this.httpService, this.messageService, this.audioElementService, this.chatScrollList,
                        function (machineSeq: number): void {
                            this.canvasService.canvas.clear();

                            this.missionAreaList.forEach((missionArea, key) => {
                                if (missionArea.machineSeq == machineSeq) {
                                    this.missionAreaList.splice(key, 1);
                                }
                            });

                            this.drawLine();
                        }.bind(this),
                        function (): void {
                            this.moveLastLivemapSlide();
                        }.bind(this),
                        this.accountDto.licenseDto
                    );

                    if (this.clusterWrap != null) {
                        this.visibleFilterService.setContainerSize(
                            this.clusterWrap.nativeElement.offsetWidth,
                            this.clusterWrap.nativeElement.offsetHeight
                        );
                    }

                    this.initMapService();
                    this.isInit = false;
                } else {
                    this.visibleFilterService.setMachineData(machineList);
                    this.visibleFilterService.setOffset(false);
                }

                if (this.clusterService == null) {
                    this.clusterService = new ClusterService(this.mapService, this.settingsService);
                }

                this.clusterService.setZoomLevel(this.zoomLevel);
                this.clusterService.setClusterList(Object.assign([], this.visibleFilterService.getTotalList()));
            }
        );
    }

    private getTreeData(): void {
        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<GroupDto> = res;

            if (response.isSuccess) {
                this.treeData = Object.assign(new GroupDto(), response.data);
                this.treeData.init();
                this.treeData.setChecked(true);
            }
        }.bind(this);

        this.httpService.get(APIUrl.GROUP_TREE(this.accountDto.seq), httpCallback);
    }

    private initMapService(): void {
        this.mapService.map.setCenterOffset();
        this.setMapEventListener();
        this.zoomLevel = this.mapService.map.getZoomLevel();

        // for visible filter
        this.visibleFilterService.setZoomLevel(this.zoomLevel);
        this.visibleFilterService.setOffset(false);
    }

    private setMapEventListener(): void {
        this.mapService.map.setClickEventListener((coord: any, offset: any) => {
            // mission waypoint
            if (!this.isDroneTabVisible && this.missionDto.typeCode == MissionType.WAYPOINT) {
                if (this.MAX_WAYPOINT_LENGTH < this.figure.getCornorPointList().length) {
                    let alertData: PopupDto = new PopupDto();
                    alertData.title = this.translateService.instant("control.mission.add-point.title");
                    alertData.message = this.translateService.instant("control.mission.add-point.message.max-length-guide", {maxWaypointLength: this.MAX_WAYPOINT_LENGTH});
                    alertData.isVisible = true;

                    this.popupService.publishAlert(alertData);
                    return;
                }

                let pointActionCode: PointActionCode = this.figure.getFirstCornorPointAction();
                let altitude: number = this.figure.getFirstCornorAltitude();
                let gimbalAngle: number = this.figure.getFirstCornorGimbalAngle();

                let point: Point = new Point(coord.x, coord.y, offset.x, offset.y, 0, 0, altitude, gimbalAngle, pointActionCode);
                this.figure.addCornorPoint(this.figure.getCornorPointList().length - 1, point);

                this.canvasService.canvas.clear();
                this.drawMissionLine();
                this.setControlPointOffset();
                this.setDistancePointList();
            }

            // map functions
            if (this.mapFunction.isEnable) {
                this.mapFunction.addPoint(new Point(coord.x, coord.y, offset.x, offset.y));
            }
        });

        this.mapService.map.setMapMoveEventListener(function (prevOffset: Offset, nowPoint: Point): void {
            this.isMapMoved = true;
            this.visibleFilterService.setOffset(false);

            if (this.selectContainer != undefined) {
                this.selectContainer.update();
            }

            this.canvasService.canvas.clear();

            let diffX: number = prevOffset.x - nowPoint.offsetX;
            let diffY: number = prevOffset.y - nowPoint.offsetY;

            if (this.settingsService.isGoogleMap) {
                this.missionAreaList.forEach(missionArea => {
                    missionArea.figure.resetCornorPointOffset();
                    missionArea.setMissionWayPoint();
                });
            } else {
                this.missionAreaList.forEach(missionArea => {
                    missionArea.figure.changeCornorPointOffset(diffX, diffY);
                    missionArea.setMissionWayPoint();
                });
            }

            this.mapService.map.setCenterPoint(nowPoint);

            if (this.clusterService != null) {
                this.clusterService.changeClusterOffset(diffX, diffY);
            }

            this.mapFunction.changePointPosition(diffX, diffY);

            // for mission
            if (this.figure == undefined || this.figure.getCornorPointList().length == 0) {
                return;
            }

            if (this.settingsService.isGoogleMap) {
                this.resetOffset();

                if (this.missionDto.typeCode == MissionType.GRID || this.missionDto.typeCode == MissionType.POLYGON) {
                    this.figure.resetCenterPoint();
                }

                this.setDistancePointList();
                this.setControlPointOffset();
                this.setMissionWayPoint();
            } else {
                this.changeOffsetByDiff(diffX, diffY);
                this.changeControlOffsetByDiff(diffX, diffY);

                if (this.missionDto.typeCode == MissionType.GRID || this.missionDto.typeCode == MissionType.POLYGON) {
                    this.figure.resetCenterPoint();
                    this.setMissionWayPoint();
                }

                this.setDistancePointList();
                this.setControlPointOffset();
            }

            if (!this.changeDetector["destroyed"]) {
                this.changeDetector.detectChanges();
            }
        }.bind(this));

        this.mapService.map.setMouseUpEventListener(function (): void {
            // for mission
            if (this.isMapMoved) {
                this.isMapMoved = false;

                if (this.figure != undefined && this.figure.getCornorPointList().length != 0) {
                    if (this.missionDto.typeCode == MissionType.GRID || this.missionDto.typeCode == MissionType.POLYGON) {
                        this.setMissionWayPoint();
                    }
                }
            }

            // origin
            this.convertShapeFileListCoordinateToOffset();
            this.setShapeFileCanvas();

            this.setWeatherTimer();
        }.bind(this));

        this.mapService.map.setZoomingEventListener(function (): void {
            this.isMapChanged = true;
            this.canvasService.canvas.clear();
        }.bind(this));

        this.mapService.map.setZoomChangedEventListener(function (zoomLevel: number): void {
            this.zoomLevel = zoomLevel;
            this.setWeatherTimer();

            this.isMapChanged = false;
            this.isMapMoved = false;

            this.visibleFilterService.setZoomLevel(zoomLevel);
            this.visibleFilterService.setOffset(true);

            if (this.selectContainer != undefined) {
                this.selectContainer.update();
            }

            this.canvasService.canvas.clear();

            // for mission
            this.missionAreaList.forEach(missionArea => {
                missionArea.figure.resetCornorPointOffset();
                missionArea.setMissionWayPoint();
            });

            // cluster
            if (this.zoomLevel < this.mapService.standardZoomLevel && this.clusterService != null) {
                this.clusterService.setZoomLevel(this.zoomLevel);
                this.clusterService.setClusterList(Object.assign([], this.visibleFilterService.getTotalList()));
            }

            this.mapFunction.resetPointPosition();

            this.convertShapeFileListCoordinateToOffset();
            this.setShapeFileCanvas();

            // for mission mgmt
            if (this.figure == undefined || this.figure.getCornorPointList().length == 0) {
                return;
            }

            this.resetOffset();

            if (this.missionDto.typeCode == MissionType.GRID || this.missionDto.typeCode == MissionType.POLYGON) {
                this.figure.resetCenterPoint();
            }

            this.setDistancePointList();
            this.setControlPointOffset();
            this.setMissionWayPoint();
        }.bind(this));
    }

    public getMachineList(): MachineDto[] {
        if (this.visibleFilterService == undefined) {
            return [];
        }

        return this.visibleFilterService.getVisibleList();
    }

    public getBatteryStatus(): number {
        if (this.machine.machineStatusDto.batteryStatusList == null || this.machine.machineStatusDto.batteryStatusList.length == 0) {
            return 0;
        } else {
            return +this.machine.machineStatusDto.batteryStatusList[0].percentage;
        }
    }

    public getBatteryStatusClassName(): string {
        if (this.machine.machineStatusDto.batteryStatusList == null || this.machine.machineStatusDto.batteryStatusList.length == 0) {
            return "";
        } else {
            let percentage: number = +this.machine.machineStatusDto.batteryStatusList[0].percentage;

            if (percentage >= 80) {
                return "full";
            } else if (percentage >= 30) {
                return "half";
            } else {
                return "empty";
            }
        }
    }

    public convertSpeed(speed: string): string {
        return (+speed).toFixed(2)
    }

    private showPanel(machine: MachineDto, isClick: boolean): void {
        this.visibleFilterService.getVisibleList().forEach(item => {
            if (item.seq != machine.seq) {
                item.isSelected = false;
                item.zIndex = 10;
            } else {
                item.zIndex = 11;
            }
        });

        if (isClick && this.machine.seq == machine.seq) {
            this.machine.isSelected = false;
            this.machine = new MachineDto();
            this.isSelectedMachineExist = false;

            return;
        }

        if (this.machine.seq != machine.seq) {
            this.machine.clearStreaming();
        }

        machine.isSelected = true;

        this.machine = machine;
        this.isPanelVisible = true;
        this.isSelectedMachineExist = true;
    }

    public togglePanel(): void {
        if (!this.isPanelVisible && this.machine.seq == 0) {
            this.isDroneTabVisible = false;
        }

        this.isPanelVisible = !this.isPanelVisible;
    }

    public sendAppMessage(machine: MachineDto): void {
        machine.mqttService.setMessageData(false);
    }

    private setChatDialogOffset(index: number, event: any): void {
        let element: any = document.getElementById("chatDialog" + index);
        let style: any = window.getComputedStyle(element);
        let matrix: any = new WebKitCSSMatrix(style.webkitTransform);

        let diffBottom: number = (matrix.m42 * -1);
        let diffLeft: number = matrix.m41;

        if (typeof element.style.bottom == "string") {
            let bottom: number = +element.style.bottom.split("px")[0];
            diffBottom += bottom;
        }

        if (typeof element.style.left == "string") {
            let left: number = +element.style.left.split("px")[0];
            diffLeft += left;
        }

        element.style.bottom = diffBottom + "px";
        element.style.left = diffLeft + "px";
    }

    public showMessageDialog(machine: MachineDto): void {
        machine.hasMissingMessage = false;
        machine.isPopupMode = true;
    }

    public hideMessageDialog(machine: MachineDto): void {
        machine.isPopupMode = false;
    }

    public togglePathTracing(machine: MachineDto): void {
        machine.isPathTracing = !machine.isPathTracing;

        if (machine.isPathTracing) {
            machine.setRealTimeInterval();
            machine.mqttService.setStatusSubscriber();
            this.goToDroneLocation(machine);
        } else {
            machine.clearRealTimeInterval();
            machine.mqttService.clearStatusSubscriber();
        }
    }

    public toggleStreaming(machine: MachineDto): void {
        if (machine.isStreaming) {
            machine.publishStreaming(false);
        } else {
            machine.publishStreaming(true);
        }
    }

    public setFullScreenMode(): void {
        if (this.machine.isStreaming) {
            let url: string = StreamingConfig.fullScreenUrl;
            url += btoa(this.machine.accountEmail);

            window.open(url);
        }
    }

    public toggleLivemapVisible(): void {
        this.machine.isLivemapVisible = !this.machine.isLivemapVisible;
    }

    public getLivemapList(): LiveMapDataDto[] {
        if (this.visibleFilterService == undefined) {
            return [];
        }

        return this.visibleFilterService.getLivemapDataList();
    }

    public downloadLivemap(livemapList?: LivemapDto[]): void {
        let imagePathListDto: ImagePathListDto = new ImagePathListDto(this.machine.accountEmail);

        if (livemapList == undefined) {
            this.machine.livemapImageList.forEach(item => {
                if (item.url.length > 0) {
                    let chunkList: string[] = item.url.split("/");
                    imagePathListDto.list.push(chunkList[chunkList.length - 1]);
                }
            });
        } else {
            livemapList.forEach(livemap => {
                if (livemap.fileName.length > 0) {
                    let chunkList: string[] = livemap.fileName.split("/");
                    imagePathListDto.list.push(chunkList[chunkList.length - 1]);
                }
            });
        }

        this.livemapDownloadRequest(imagePathListDto);
    }

    private livemapDownloadRequest(imagePathListDto: ImagePathListDto): void {
        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let blob: Blob = new Blob([res], { type: "octet/stream" });
            saveAs(blob, "Livemap.zip");
        };

        this.httpService.postAndDownload(APIUrl.LIVEMAP_DOWNLOAD, imagePathListDto, httpCallback);
    }

    public showLivemapListDialog(): void {
        this.isLivemapListVisible = true;
        this.getLivemapHistoryList();
    }

    private getLivemapHistoryList(): void {
        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<LivemapListDto[]> = res;

            if (response.isSuccess) {
                this.livemapHistoryList = response.data;
            }
        }.bind(this);

        this.httpService.get(APIUrl.LIVEMAP_LIST(this.machine.accountSeq, this.machine.seq), httpCallback);
    }

    public moveToLivemap(livemapListDto: LivemapListDto): void {
        let point: Point = this.getLivemapCenterCoord(livemapListDto.livemapList);

        if (point.coordX != 0 && point.coordY != 0) {
            this.mapService.map.moveTo(point);
            this.machine.setLivemapImageList(livemapListDto.livemapList);
            this.isLivemapListVisible = false;

            this.changeDetector.detectChanges();
            this.livemapSlider.onSlide(-1);
        }
    }

    private moveLastLivemapSlide(): void {
        this.changeDetector.detectChanges();

        if (this.livemapSlider != undefined) {
            this.livemapSlider.onSlide(-1);
        }
    }

    private getLivemapCenterCoord(livemapList: LivemapDto[]): Point {
        let minX: number = 0;
        let maxX: number = 0;
        let minY: number = 0;
        let maxY: number = 0;

        let isFirst: boolean = true;
        livemapList.forEach(item => {
            if (isFirst) {
                isFirst = false;

                minX = item.longitude;
                maxX = item.longitude;
                minY = item.latitude;
                maxY = item.latitude;
            } else {
                minX = (minX > item.longitude) ? item.longitude : minX;
                maxX = (maxX < item.longitude) ? item.longitude : maxX;

                minY = (minY > item.latitude) ? item.latitude : minY;
                maxY = (maxY < item.latitude) ? item.latitude : maxY;
            }
        });

        return new Point(
            minX + (maxX - minX) / 2,
            minY + (maxY - minY) / 2
        );
    }

    private setDroneSelection(selectedMachineList: MachineDto[]): void {
        if (this.visibleFilterService == null) {
            return;
        }

        this.isSelectedMachineExist = false;

        this.visibleFilterService.getVisibleList().forEach(machine => {
            machine.isSelected = false;

            selectedMachineList.forEach(selectedMachine => {
                if (machine.seq == selectedMachine.seq) {
                    machine.isSelected = true;
                    this.isSelectedMachineExist = true;
                }
            });
        });
    }

    public droneRightClick(event: any, drone: MachineDto): void {
        event.preventDefault();
        event.stopPropagation();

        this.findOverlappedMachineList(
            drone.machineStatusDto.offsetX + event.layerX,
            drone.machineStatusDto.offsetY + event.layerY - 16
        );

        this.changeDetector.detectChanges();

        if (this.overlappedMachineList.length > 1) {
            this.contextMenuService.show.next({
                contextMenu: this.overlapControlMenu,
                event: event,
                item: event
            });
        } else {
            this.showPanel(drone, false);

            if (this.isAutoControl()) {
                this.contextMenuService.show.next({
                    contextMenu: this.singleAutoControlMenu,
                    event: event,
                    item: drone
                });
            } else {
                this.contextMenuService.show.next({
                    contextMenu: this.singleControlMenu,
                    event: event,
                    item: drone
                });
            }
        }
    }

    public showContextMenu(event: any, index: number): void {
        let drone: MachineDto = this.overlappedMachineList[index];

        this.showPanel(drone, false);
        if (this.isAutoControl()) {
            this.contextMenuService.show.next({
                contextMenu: this.singleAutoControlMenu,
                event: event.item,
                item: drone
            });
        } else {
            this.contextMenuService.show.next({
                contextMenu: this.singleControlMenu,
                event: event.item,
                item: drone
            });
        }
    }

    private findOverlappedMachineList(offsetX: number, offsetY: number): void {
        this.overlappedMachineList = [];

        this.getMachineList().forEach(machine => {
            let machineOffsetX: number = machine.machineStatusDto.offsetX;
            let machineOffsetY: number = machine.machineStatusDto.offsetY;

            if (machineOffsetX <= (offsetX + 16) && (offsetX - 16) <= machineOffsetX
                && machineOffsetY <= (offsetY + 16) && (offsetY - 16) <= machineOffsetY) {

                this.overlappedMachineList.push(machine);
            }
        });
    }

    public toggleMapControlTypeVisible(): void {
        this.isMapControlTypeVisible = !this.isMapControlTypeVisible;
    }

    public setMapControlType(mapControlType: MapControlType): void {
        this.isMapControlTypeVisible = false;

        if (mapControlType == MapControlType.SELECT_DRONE && this.zoomLevel < this.mapService.standardZoomLevel) {
            let alertData: PopupDto = new PopupDto();
            alertData.title = this.translateService.instant("control.side-button.selection-mode.title.drone");
            alertData.message = this.translateService.instant("control.side-button.selection-mode.message.select-drone-guide");
            alertData.isVisible = true;

            this.popupService.publishAlert(alertData);
            return;
        }

        if (mapControlType == MapControlType.SELECT_SHAPE && this.shapeFileDtoList.length == 0) {
            let alertData: PopupDto = new PopupDto();
            alertData.title = this.translateService.instant("control.side-button.selection-mode.title.gis");
            alertData.message = this.translateService.instant("control.side-button.selection-mode.message.select-gis-guide");
            alertData.isVisible = true;

            this.popupService.publishAlert(alertData);
            return;
        }

        this.nowMapControlType = mapControlType;
    }

    public getSelectModeClassName(): string {
        switch (this.nowMapControlType) {
            case MapControlType.MOVE_MAP:
                return "selectmovemap";
            case MapControlType.SELECT_DRONE:
                return "selectdrone";
            case MapControlType.SELECT_SHAPE:
                return "selectshape";
            default:
                return "";
        }
    }

    public toggleMapTypeVisible(): void {
        this.isMapTypeVisible = !this.isMapTypeVisible;
    }

    public setMapType(isGoogleMap: boolean, isNormalMap: boolean): void {
        this.isMapTypeVisible = false;

        if (this.settingsService.isGoogleMap != isGoogleMap) {
            this.mapService.isMapEnable = false;

            timer(200).subscribe(() => {
                this.mapService.publishRefresh();
                this.mapService.isMapEnable = true;

                timer(300).subscribe(() => {
                    this.settingsService.isGoogleMap = isGoogleMap;

                    let centerPoint: Point = Object.assign(new Point(), this.mapService.map.getCenterPoint());
                    this.zoomLevel = this.mapService.getChangedZoomLevel();
                    this.mapService.initMap(isGoogleMap);

                    if (!isGoogleMap) {
                        if (!this.mapService.isValidNaverMapPoint(centerPoint)) {
                            centerPoint.coordX = this.mapService.standardLongitude;
                            centerPoint.coordY = this.mapService.standardLatitude;
                        }
                    }

                    this.mapService.map.loadMap(
                        "map",
                        centerPoint.coordY,
                        centerPoint.coordX,
                        this.zoomLevel
                    );

                    timer(1000).subscribe(() => {
                        this.mapService.publishMapChange();

                        this.settingsService.isNormalMap = isNormalMap;
                        this.mapService.map.setMapType(this.settingsService.isNormalMap);
                        this.setSettings();

                        this.clusterService = null;

                        this.missionAreaList = [];
                        this.visibleFilterService.getVisibleList().forEach(machine => {
                            this.drawMissionArea(machine);
                        });
                    });
                });
            });
        } else {
            this.settingsService.isGoogleMap = isGoogleMap;
            this.settingsService.isNormalMap = isNormalMap;
            this.mapService.map.setMapType(this.settingsService.isNormalMap);
            this.setSettings();
        }
    }

    public getMapTypeClassName(): string {
        if (!this.settingsService.isGoogleMap && this.settingsService.isNormalMap) {
            return "nm_type01";
        } else {
            return "nm_type02";
        }
    }

    public isNormalMap(): boolean {
        return this.settingsService.isNormalMap;
    }

    public showMissionDialog(machine: MachineDto): void {
        if (machine.mission.seq == 0) {
            this.getAdminMissionList();

            if (this.accountDto.seq == machine.accountSeq) {
                this.isAdminMissionDialogVisible = true;
            } else {
                this.getMissionList(machine);
                this.isMissionDialogVisible = true;
            }
        } else {
            machine.mission = new MissionDto();
            machine.missionControlType = null;

            this.canvasService.canvas.clear();
            this.missionAreaList.forEach((missionArea, key) => {
                if (missionArea.machineSeq == machine.seq) {
                    this.missionAreaList.splice(key, 1);
                }
            });

            this.drawLine();
        }
    }

    public getCameraTypeList(): any[] {
        let keys: string[] = Object.keys(CameraType);
        return keys.slice(keys.length / 2);
    }

    public getAspectRatioTypeList(): any[] {
        let keys: string[] = Object.keys(AspectRatioType);
        return keys.slice(keys.length / 2);
    }

    public getAspectRatioTypeLabel(type: string): string {
        return AspectRatioTypeLabel.get(AspectRatioType[type]);
    }

    public onAdminMissionSearchKeyUp(event: any): void {
        if (event.keyCode == 13) {
            this.getAdminMissionList();
        }
    }

    public onMissionSearchKeyUp(event: any): void {
        if (event.keyCode == 13) {
            this.getMissionList(null);
        }
    }

    private getAdminMissionList(): void {
        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<MissionDto[]> = res;

            if (response.isSuccess) {
                this.adminMissionList = response.data;
            }
        }.bind(this);

        this.httpService.post(APIUrl.MISSION_SEARCH_OF_MACHINE(this.accountDto.seq), this.adminMissionSearchDto, httpCallback);
    }

    private getMissionList(machine: MachineDto): void {
        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<MissionDto[]> = res;

            if (response.isSuccess) {
                this.missionList = response.data;
            }
        }.bind(this);

        if (machine == null) {
            this.httpService.post(APIUrl.MISSION_SEARCH_OF_MACHINE(this.machine.accountSeq), this.missionSearchDto, httpCallback);
        } else {
            this.httpService.post(APIUrl.MISSION_SEARCH_OF_MACHINE(machine.accountSeq), this.missionSearchDto, httpCallback);
        }
    }

    public getMissionTypeClassName(typeCode: number): string {
        return MissionType[typeCode];
    }

    public setMissionChecked(missionSeq: number, isAdminMissionSelected: boolean): void {
        this.isAdminMissionSelected = isAdminMissionSelected;

        if (this.isAdminMissionSelected) {
            this.adminMissionList.forEach(mission => {
                if (mission.seq != missionSeq) {
                    mission.isChecked = false;
                }
            });

            this.missionList.forEach(mission => {
                mission.isChecked = false;
            });
        } else {
            this.adminMissionList.forEach(mission => {
                mission.isChecked = false;
            });

            this.missionList.forEach(mission => {
                if (mission.seq != missionSeq) {
                    mission.isChecked = false;
                }
            });
        }
    }

    public setMission(): void {
        this.showMissionSetting();
        let selectedMission: MissionDto = this.getValidSelectedMission();

        if (selectedMission == null) {
            let alertData: PopupDto = new PopupDto();
            alertData.title = this.translateService.instant("control.mission.set-mission.title");
            alertData.message = this.translateService.instant("control.mission.set-mission.message.select-near-mission-guide");
            alertData.isVisible = true;

            this.popupService.publishAlert(alertData);
            return;
        }

        this.machine.mission = selectedMission;
        this.isAdminMissionDialogVisible = false;

        if (this.isAutoControl()) {
            this.showMissionArea(this.machine);
        }
    }

    public setMemberMission(): void {
        this.showMissionSetting();
        let selectedMission: MissionDto = this.getValidSelectedMission();

        if (selectedMission == null) {
            let alertData: PopupDto = new PopupDto();
            alertData.title = this.translateService.instant("control.mission.set-mission.title");
            alertData.message = this.translateService.instant("control.mission.set-mission.message.select-near-mission-guide");
            alertData.isVisible = true;

            this.popupService.publishAlert(alertData);
            return;
        }

        if (this.isAdminMissionSelected) {
            this.copyAdminMissionToMember(selectedMission);
        } else {
            this.machine.mission = selectedMission;
            this.isMissionDialogVisible = false;

            if (this.isAutoControl()) {
                this.showMissionArea(this.machine);
            }
        }
    }

    private getValidSelectedMission(): MissionDto {
        let selectedMission: MissionDto = null;

        if (this.isAdminMissionSelected) {
            this.adminMissionList.some(mission => {
                if (mission.isChecked) {
                    if (this.isNearMission(mission)) {
                        selectedMission = Object.assign(new MissionDto(), mission);
                    }

                    return true;
                }
            });
        } else {
            this.missionList.some(mission => {
                if (mission.isChecked) {
                    if (this.isNearMission(mission)) {
                        selectedMission = Object.assign(new MissionDto(), mission);
                    }

                    return true;
                }
            });
        }

        return selectedMission;
    }

    private copyAdminMissionToMember(selectedMission: MissionDto): void {
        let adminMission: MissionDto = Object.assign(new MissionDto(), selectedMission);
        adminMission.name = "_" + adminMission.name;
        adminMission.isMachineData = false;
        adminMission.isOverwrite = false;

        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<PointListDto> = res;

            if (response.isSuccess) {
                adminMission.coordinateData = response.data.coordinateData;
                adminMission.pointData = response.data.pointData;

                this.makeCopiedMemberMission(adminMission);
            }
        }.bind(this);

        this.httpService.get(APIUrl.MISSION_DATA(adminMission.seq), httpCallback);
    }

    private makeCopiedMemberMission(missionDto: MissionDto): void {
        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<number> = res;

            if (response.isSuccess) {
                missionDto.seq = response.data;
                this.machine.mission = missionDto;

                if (this.isAutoControl()) {
                    this.showMissionArea(this.machine);
                    this.isMissionDialogVisible = false;
                }
            }
        }.bind(this);

        this.httpService.put(APIUrl.MAKE_FORMATION_FLYING_MISSION(this.machine.accountSeq), missionDto, httpCallback);
    }

    private isNearMission(mission: MissionDto): boolean {
        let machinePoint: Point = new Point(this.machine.machineStatusDto.longitude, this.machine.machineStatusDto.latitude);
        let missionPoint: Point = new Point(mission.centerLongitude, mission.centerLatitude);

        if (this.mapService.map.getDistance(machinePoint, missionPoint) > 3000) {
            return false;
        }

        return true;
    }

    public showPopup(variable: string): void {
        this[variable] = true;
    }

    public closePopup(variable: string): void {
        this[variable] = false;
    }

    // refactoring
    public control(missionControlType: MissionControlType): void {
        let hasReadyControl: boolean = false;

        this.visibleFilterService.getVisibleList().forEach(machine => {
            if (machine.isSelected) {
                machine.setControlType(missionControlType);
                if (machine.nextMissionControlType == MissionControlType.READY) {
                    hasReadyControl = true;
                }

                this.controlTargetList.push(machine);
            }
        });

        if (hasReadyControl) {
            if (this.machine.mission.pointActionCode == PointActionCode.PHOTO_INTERVAL
                || this.machine.mission.pointActionCode == PointActionCode.PHOTO_DISTANCE_INTERVAL) {
                this.missionReady(false);
            } else {
                this.isIs3dPopupVisible = true;
            }
        } else {
            this.controlTargetList.forEach(machine => {
                machine.publishControl();
            });

            this.controlTargetList = [];
        }
    }

    private isDisableControl(controlType: MissionControlType): boolean {
        switch (controlType) {
            case MissionControlType.TAKE_OFF:
                if (this.machine.missionControlType == null) {
                    return false;
                }

                if (this.machine.missionControlType != MissionControlType.READY
                    && this.machine.missionControlType != MissionControlType.START
                    && this.machine.missionControlType != MissionControlType.PAUSE
                    && this.machine.missionControlType != MissionControlType.TAKE_OFF
                    && this.machine.machineStatusDto.altitude > 0.5) {

                    return false;
                }
                break;
            case MissionControlType.LAND:
                if (this.machine.missionControlType != MissionControlType.READY
                    && this.machine.missionControlType != MissionControlType.START
                    && this.machine.missionControlType != MissionControlType.PAUSE
                    && this.machine.missionControlType != MissionControlType.LAND) {

                    return false;
                }
                break;
            case MissionControlType.START:
                if (this.machine.missionControlType == null) {
                    return false;
                }

                if (this.machine.missionControlType != MissionControlType.START) {
                    return false;
                }
                break;
            case MissionControlType.PAUSE:
            case MissionControlType.STOP:
                if (this.machine.missionControlType == MissionControlType.START) {
                    return false;
                }
                break;
            case MissionControlType.RETURN_TO_HOME:
                return false;
            default:
                // nothing
                break;
        }

        return true;
    }

    public missionReady(is3d: boolean): void {
        this.controlTargetList.forEach(machine => {
            machine.publishControl(is3d);
        });

        this.controlTargetList = [];
        this.isIs3dPopupVisible = false;

        if (this.isAutoControl()) {
            this.togglePathTracing(this.machine);
            this.toggleStreaming(this.machine);
        }
    }

    public goToDroneLocation(machine: MachineDto): void {
        let point: Point = new Point(machine.machineStatusDto.longitude, machine.machineStatusDto.latitude);

        if (point.coordX != 0 && point.coordY != 0) {
            this.mapService.map.moveTo(point);
        }
    }

    public showMissionArea(machine: MachineDto): void {
        if (machine.mission == null || machine.mission.seq == 0) {
            let alertData: PopupDto = new PopupDto();
            alertData.title = this.translateService.instant("control.drone.show-mission.title");
            alertData.message = this.translateService.instant("control.drone.show-mission.message.empty-mission-guide");
            alertData.isVisible = true;

            this.popupService.publishAlert(alertData);
            return;
        }

        this.missionAreaList.forEach((missionArea, key) => {
            if (missionArea.machineSeq == machine.seq) {
                this.missionAreaList.splice(key, 1);
                this.canvasService.canvas.clear();
            }
        });

        this.drawMissionArea(machine);
        this.goToDroneLocation(machine);
    }

    public hideMissionArea(machine: MachineDto): void {
        if (machine.mission == null || machine.mission.seq == 0) {
            return;
        }

        this.missionAreaList.forEach((missionArea, key) => {
            if (missionArea.machineSeq == machine.seq) {
                this.missionAreaList.splice(key, 1);
            }
        });

        this.drawLine();
    }

    private drawMissionArea(machine: MachineDto): void {
        let missionLoadCallback: any = function (missionArea: MissionArea) {
            let isExist: boolean = false;
            this.missionAreaList.some(item => {
                if (item.machineSeq == missionArea.machineSeq) {
                    isExist = true;
                    return true;
                }
            });

            if (!isExist) {
                this.missionAreaList.push(missionArea);
            }

            this.drawLine();
        }.bind(this);

        new MissionArea(machine.seq, machine.fov, this.mapService, this.httpService, machine.mission, missionLoadCallback);
    }

    private drawLine(): void {
        if (this.zoomLevel < this.mapService.standardZoomLevel) {
            this.canvasService.canvas.clear();
        } else {
            this.canvasService.canvas.clear();

            this.missionAreaList.forEach(missionArea => {
                this.canvasService.canvas.drawWayPointForControl(missionArea.getCornorPointList(), missionArea.mission.typeCode, missionArea.getWaypointList());
            });
        }
    }

    public showStreamingListDialog(): void {
        this.isStreamingListVisible = true;
        this.getStreamingList();
    }

    public getStreamingList(): void {
        this.streamingFileUrl = StreamingConfig.fileUrl(this.machine.accountEmail);

        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<StreamingDto[]> = res;

            if (response.isSuccess) {
                this.streamingList = response.data;
            }
        }.bind(this);

        this.httpService.get(APIUrl.MACHINE_STREAMING_LIST(this.machine.seq), httpCallback);
    }

    public convertFileSize(fileSize: number): string {
        let giga: number = fileSize / Math.pow(1024, 3);
        if (giga > 1) {
            return giga.toFixed(1) + " GB";
        }

        let mega: number = fileSize / Math.pow(1024, 2);
        if (mega > 1) {
            return mega.toFixed(1) + " MB";
        }

        let kilo: number = fileSize / 1024;
        if (kilo > 1) {
            return kilo.toFixed(1) + " KB";
        }

        return fileSize + " byte";
    }

    public downloadStreamingFile(fileName: string): void {
        saveAs(StreamingConfig.fileUrl(this.accountDto.email) + fileName, fileName);
    }

    public showMissionHistoryDialog(): void {
        this.isMissionShapeFileListVisible = true;
        this.getMissionHistory();
    }

    private getMissionHistory(): void {
        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<MissionShapeFileDto[]> = res;

            if (response.isSuccess) {
                this.missionShapeFileList = response.data;
            }
        }.bind(this);

        this.httpService.get(APIUrl.SHAPEFILE_LIST(this.machine.seq), httpCallback);
    }

    // download mission data
    public toggleSpatialDataDownloadSelectDialogVisible(selectedShapeFile: MissionShapeFileDto): void {
        this.isSpatialDataDownloadSelectDialogVisible = !this.isSpatialDataDownloadSelectDialogVisible;

        if (this.isSpatialDataDownloadSelectDialogVisible) {
            this.selectedShapeFile = selectedShapeFile;
        }
    }

    public toggleMissionSpatialDataDownloadSelectDialogVisible(missionDto: MissionDto): void {
        this.isSpatialDataDownloadSelectDialogVisible = !this.isSpatialDataDownloadSelectDialogVisible;

        if (this.isSpatialDataDownloadSelectDialogVisible) {
            this.selectedMission = Object.assign(new MissionDto(), missionDto);
        }
    }

    // download mission data to kml format
    public downloadKmlFile(): void {
        if (this.selectedShapeFile == null && this.selectedMission == null) {
            return;
        }

        let isMissionData: boolean = (this.selectedMission == null)? false : true;
        let fileName: string = "";

        if (isMissionData) {
            fileName = this.selectedMission.name + ".zip";
        } else {
            fileName = this.selectedShapeFile.missionName + ".zip";
        }

        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = (res: any): void => {
            if (res.byteLength == 0) {
                console.log("*********** invalid file ************");
            } else {
                let blob: Blob = new Blob([res], { type: "octet/stream" });
                saveAs(blob, fileName);
            }

            this.selectedShapeFile = new MissionShapeFileDto();
        }

        httpCallback.error = function (): void {
            console.log("****** download kml error **********");
        };

        if (isMissionData) {
            this.httpService.getAndDownload(APIUrl.KML_DOWNLOAD(this.selectedMission.seq), httpCallback);
        } else {
            this.httpService.getAndDownload(APIUrl.KML_DOWNLOAD_WIDTH_STARTEND(this.selectedShapeFile.missionHistorySeq), httpCallback);
        }
    }

    // download mission data to shp format
    public toggleCoordinateSelectVisible(): void {
        this.isCoordinateSelectVisible = !this.isCoordinateSelectVisible;
    }

    public getCoordinateList(): string[] {
        let keys: string[] = Object.keys(CoordinateType);
        return keys.slice(keys.length / 2);
    }

    public downloadShapeFile(): void {
        if (this.selectedShapeFile == null && this.selectedMission == null) {
            return;
        }

        let isMissionData: boolean = (this.selectedMission == null)? false : true;
        let fileName: string = "";

        if (isMissionData) {
            fileName = this.selectedMission.name + ".zip";
        } else {
            fileName = this.selectedShapeFile.missionName + ".zip";
        }

        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = (res: any): void => {
            if (res.byteLength == 0) {
                console.log("*********** invalid file ************");
            } else {
                let blob: Blob = new Blob([res], { type: "octet/stream" });
                saveAs(blob, fileName);
            }

            this.isCoordinateSelectVisible = false;
        }

        httpCallback.error = function (): void {
            console.log("****** download shape file error **********");
        };

        if (isMissionData) {
            this.httpService.getAndDownload(APIUrl.SHAPEFILE_DOWNLOAD(this.selectedMission.seq, this.coordinateType), httpCallback);
        } else {
            this.httpService.getAndDownload(APIUrl.SHAPEFILE_DOWNLOAD_WITH_STARTEND(this.selectedShapeFile.missionHistorySeq, this.coordinateType), httpCallback);
        }
    }

    // audio
    public showAudioViewVisible(machine: MachineDto): void {
        machine.isAudioViewVisible = true;
    }

    public hideAudioViewVisible(machine: MachineDto): void {
        machine.isAudioViewVisible = false;
    }

    public toggleAudioSend(machine: MachineDto): void {
        machine.isAudioSend = !machine.isAudioSend;

        if (machine.isAudioSend) {
            machine.setAudioStreaming();
        } else {
            machine.stopAudioStreaming();
        }
    }

    public toggleSpatialDataSelectDialogVisible(): void {
        this.isSpatialDataSelectDialogVisible = !this.isSpatialDataSelectDialogVisible;
    }

    public toggleKmlFileLoadDialogVisible(): void {
        this.isKmlFileLoadDialogVisible = !this.isKmlFileLoadDialogVisible;

        if (!this.isKmlFileLoadDialogVisible) {
            this.kmlFileSelector.nativeElement.value = null;
            this.targetKmlFile = null;
        }
    }

    public showKmlFileBrowser(): void {
        this.kmlFileSelector.nativeElement.value = null;

        let element: HTMLElement = document.getElementById("kmlFileSelector");
        element.click();
    }

    public onSelectKmlFiles(event: any): void {
        if (event.target.files.length == 0) {
            return;
        }

        this.targetKmlFile = event.target.files[0];
    }

    public loadKmlFile(): void {
        if (this.targetKmlFile == null) {
            let alertData: PopupDto = new PopupDto();
            alertData.title = this.translateService.instant("control.side-button.gis.load-kml.title");
            alertData.message = this.translateService.instant("control.side-button.gis.load-kml.message.select-file-guide");
            alertData.isVisible = true;

            this.popupService.publishAlert(alertData);
            return;
        }

        this.progressService.init(
            this.translateService.instant("control.side-button.gis.load-kml.title"),
            this.translateService.instant("control.side-button.gis.message.loading")
        );

        const formData: FormData = new FormData();
        formData.append("kml", this.targetKmlFile, this.targetKmlFile.name);

        this.selectedShapeIndex = -1;

        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<ShapeFileDto> = res;

            if (response.isSuccess) {
                let shapeFileDto: ShapeFileDto = Object.assign(new ShapeFileDto(), response.data);
                this.mapService.map.moveToBoundary(
                    new Point(shapeFileDto.leftBottomCoord.longitude, shapeFileDto.leftBottomCoord.latitude),
                    new Point(shapeFileDto.rightTopCoord.longitude, shapeFileDto.rightTopCoord.latitude)
                );

                shapeFileDto = this.convertShapeFileCoordinateToOffset(shapeFileDto);
                this.shapeFileDtoList.push(shapeFileDto);
                this.selectedShapeFileIndex = this.shapeFileDtoList.length - 1;

                this.isMapMoved = false;
                this.setShapeFileCanvas();

                this.isKmlFileLoadDialogVisible = false;
                this.isShapeLayerVisible = true;
            } else {
                let alertData: PopupDto = new PopupDto();
                alertData.title = this.translateService.instant("control.side-button.gis.load-kml.title");
                alertData.message = this.translateService.instant("control.side-button.gis.message.invalid-file-guide");
                alertData.isVisible = true;

                this.popupService.publishAlert(alertData);
            }

            this.targetKmlFile = null;
            this.progressService.stop();
        }.bind(this);

        httpCallback.error = function (): void {
            this.progressService.stop();
        }.bind(this);
        this.httpService.post(APIUrl.KML_LOAD, formData, httpCallback);
    }

    public toggleShapeFileLoadDialogVisible(): void {
        this.isShapeFileLoadDialogVisible = !this.isShapeFileLoadDialogVisible;
    }

    public showFileBrowser(): void {
        this.shapeFileSelector.nativeElement.value = null;

        let element: HTMLElement = document.getElementById("shapeFileSelector");
        element.click();
    }

    public onSelectShapeFiles(event: any): void {
        if (event.target.files.length > 5) {
            return;
        }

        this.shapeFileValidation = new ShapeFileValidation();
        this.targetShapeFileList = event.target.files;
    }

    public clearSelectShapeFiles(): void {
        this.targetShapeFileList = [];
    }

    public checkShapeFiles(): void {
        for (let targetShapeFile of this.targetShapeFileList) {
            let chunk: string = targetShapeFile.name.split(".");

            if (chunk[chunk.length - 1].toLowerCase() == "shp") {
                this.shapeFileValidation.isSHPFileExist = true;
            } else if (chunk[chunk.length - 1].toLowerCase() == "shx") {
                this.shapeFileValidation.isSHXFileExist = true;
            } else if (chunk[chunk.length - 1].toLowerCase() == "dbf") {
                this.shapeFileValidation.isDBFFileExist = true;
            } else if (chunk[chunk.length - 1].toLowerCase() == "cpg") {
                this.shapeFileValidation.isCPGFileExist = true;
            } else if (chunk[chunk.length - 1].toLowerCase() == "prj") {
                this.shapeFileValidation.isPRJFileExist = true;
            }
        }

        if (this.targetShapeFileList.length == 0 || !this.shapeFileValidation.isValid()) {
            let alertData: PopupDto = new PopupDto();
            alertData.title = this.translateService.instant("control.side-button.gis.load-shape.title");
            alertData.message = this.translateService.instant("control.side-button.gis.load-shape.message.select-file-guide");
            alertData.isVisible = true;

            this.popupService.publishAlert(alertData);
            return;
        }

        if (this.shapeFileValidation.isCPGFileExist && this.shapeFileValidation.isPRJFileExist) {
            this.loadShapeFile();
        } else {
            this.setShapeFileDefaultSettings();
        }
    }

    public hideShapeFileCharsetDialog(): void {
        this.isShapeFileCharsetDialogVisible = false;
    }

    public onCharsetChange(event: any): void {
        this.shapeFileCharset = event.target.value;
    }

    public hideShapeFileEPSGDialog(): void {
        this.isShapeFileEPSGDialogVisible = false;
    }

    public setShapeFileDefaultSettings(): void {
        this.isShapeFileCharsetDialogVisible = false;
        this.isShapeFileEPSGDialogVisible = false;

        if (!this.shapeFileValidation.isCPGFileExist) {
            this.isShapeFileCharsetDialogVisible = true;
        } else if (!this.shapeFileValidation.isPRJFileExist) {
            this.isShapeFileEPSGDialogVisible = true;
        } else {

            this.loadShapeFile();
        }
    }

    public loadShapeFile(): void {
        this.progressService.init(
            this.translateService.instant("control.side-button.gis.load-shape.title"),
            this.translateService.instant("control.side-button.gis.message.loading")
        );

        const formData: FormData = new FormData();
        for (let targetShapeFile of this.targetShapeFileList) {
            formData.append("shape", targetShapeFile, targetShapeFile.name);
        }

        this.selectedShapeIndex = -1;

        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<ShapeFileDto> = res;

            if (response.isSuccess) {
                let shapeFileDto: ShapeFileDto = Object.assign(new ShapeFileDto(), response.data);
                this.mapService.map.moveToBoundary(
                    new Point(shapeFileDto.leftBottomCoord.longitude, shapeFileDto.leftBottomCoord.latitude),
                    new Point(shapeFileDto.rightTopCoord.longitude, shapeFileDto.rightTopCoord.latitude)
                );

                shapeFileDto = this.convertShapeFileCoordinateToOffset(shapeFileDto);
                this.shapeFileDtoList.push(shapeFileDto);
                this.selectedShapeFileIndex = this.shapeFileDtoList.length - 1;

                this.isMapMoved = false;
                this.setShapeFileCanvas();

                this.isShapeFileLoadDialogVisible = false;
                this.isShapeLayerVisible = true;
            } else {
                let alertData: PopupDto = new PopupDto();
                alertData.title = this.translateService.instant("control.side-button.gis.load-shape.title");
                alertData.message = this.translateService.instant("control.side-button.gis.message.invalid-file-guide");
                alertData.isVisible = true;

                this.popupService.publishAlert(alertData);
            }

            this.targetShapeFileList = [];
            this.progressService.stop();
        }.bind(this);

        httpCallback.error = function (): void {
            this.progressService.stop();
        }.bind(this);
        this.httpService.post(APIUrl.SHAPEFILE_LOAD(this.shapeFileEPSG, this.shapeFileCharset), formData, httpCallback);
    }

    public clearShapefileList(): void {
        if (this.shapeCanvasContextList != null && this.shapeCanvasContextList.length > 0) {
            this.shapeCanvasContextList.forEach(context => {
                context.clearRect(0, 0, context.canvas.width, context.canvas.height);
            });
        }

        this.shapeCanvasContextList = [];
        this.shapeFileDtoList = [];
        this.selectedShapeFileIndex = -1;
        this.selectedShapeIndex = -1;

        this.isShapeLayerVisible = false;
        this.nowMapControlType = MapControlType.MOVE_MAP;
    }

    private convertShapeFileCoordinateToOffset(shapeFileDto: ShapeFileDto): ShapeFileDto {
        this.changeDetector.detectChanges();
        let containerOffset: Offset = this.mapService.map.getContainerOffset();

        shapeFileDto.list.forEach(data => {
            data.figureList.forEach(figure => {
                figure.exteriorRing.forEach(coord => {
                    let offset: Offset = this.mapService.map.convertCoordToOffset(new Offset(coord.longitude, coord.latitude));
                    coord.offsetX = offset.x + containerOffset.x;
                    coord.offsetY = offset.y + containerOffset.y;
                });

                figure.interiorRing.forEach(ring => {
                    ring.forEach(coord => {
                        let offset: Offset = this.mapService.map.convertCoordToOffset(new Offset(coord.longitude, coord.latitude));
                        coord.offsetX = offset.x + containerOffset.x;
                        coord.offsetY = offset.y + containerOffset.y;
                    });
                });
            });
        });

        return shapeFileDto;
    }

    private convertShapeFileListCoordinateToOffset(): void {
        if (!this.changeDetector["destroyed"]) {
            this.changeDetector.detectChanges();
        }

        let containerOffset: Offset = this.mapService.map.getContainerOffset();

        this.shapeFileDtoList.forEach(shapeFileDto => {
            shapeFileDto.list.forEach(data => {
                data.figureList.forEach(figure => {
                    figure.exteriorRing.forEach(coord => {
                        let offset: Offset = this.mapService.map.convertCoordToOffset(new Offset(coord.longitude, coord.latitude));
                        coord.offsetX = offset.x + containerOffset.x;
                        coord.offsetY = offset.y + containerOffset.y;
                    });

                    figure.interiorRing.forEach(ring => {
                        ring.forEach(coord => {
                            let offset: Offset = this.mapService.map.convertCoordToOffset(new Offset(coord.longitude, coord.latitude));
                            coord.offsetX = offset.x + containerOffset.x;
                            coord.offsetY = offset.y + containerOffset.y;
                        });
                    });
                });
            });
        });
    }

    private setShapeFileCanvas(): void {
        if (!this.changeDetector["destroyed"]) {
            this.changeDetector.detectChanges();
        }

        if (this.shapeCanvasContextList != null && this.shapeCanvasContextList.length > 0) {
            this.shapeCanvasContextList.forEach(context => {
                context.clearRect(0, 0, context.canvas.width, context.canvas.height);
            });
        }

        this.shapeCanvasContextList = [];

        this.shapeCanvasWrap.toArray().forEach(element => {
            let context: CanvasRenderingContext2D = (<HTMLCanvasElement>element.nativeElement).getContext("2d");
            context.canvas.width = window.innerWidth;
            context.canvas.height = window.innerHeight;

            this.shapeCanvasContextList.push(context);
        });

        this.drawShapeFileData();
    }

    private drawShapeFileData(): void {
        this.shapeFileDtoList.forEach((shapeFileDto, fileIndex) => {
            if (shapeFileDto.isVisible) {
                shapeFileDto.list.forEach((data, dataIndex) => {
                    if (data.isVisible) {
                        let typeCode: number = +ShapeType[data.typeCode];

                        if (typeCode == ShapeType.POINT) {
                            let colorIndex: number = fileIndex % 10;

                            data.figureList.forEach((figure, figureIndex) => {
                                let coord: CoordinateDto = figure.exteriorRing[0];

                                this.shapeCanvasContextList[fileIndex].beginPath();
                                this.shapeCanvasContextList[fileIndex].moveTo(coord.offsetX, coord.offsetY);

                                if (fileIndex == this.selectedShapeFileIndex && dataIndex == this.selectedShapeIndex) {
                                    this.shapeCanvasContextList[fileIndex].fillStyle = this.shapeColorData.list[colorIndex].selectLine;
                                } else {
                                    this.shapeCanvasContextList[fileIndex].fillStyle = this.shapeColorData.list[colorIndex].normalLine;
                                }

                                this.shapeCanvasContextList[fileIndex].fillRect(coord.offsetX - 4, coord.offsetY - 4, 8, 8);
                            });
                        } else {
                            let isPolygon: boolean = typeCode == ShapeType.POLYGON || typeCode == ShapeType.MULTIPOLYGON;

                            data.figureList.forEach((figure, figureIndex) => {
                                for (let i = 0; i <= figure.exteriorRing.length; i++) {
                                    let coord: CoordinateDto = figure.exteriorRing[i];

                                    if (i == 0) {
                                        if (figureIndex == 0) {
                                            this.shapeCanvasContextList[fileIndex].beginPath();
                                        }

                                        this.shapeCanvasContextList[fileIndex].moveTo(coord.offsetX, coord.offsetY);
                                    } else if (i == figure.exteriorRing.length) {
                                        if (isPolygon) {
                                            let firstCoord: CoordinateDto = figure.exteriorRing[0];
                                            this.shapeCanvasContextList[fileIndex].lineTo(firstCoord.offsetX, firstCoord.offsetY);
                                        }
                                    } else {
                                        this.shapeCanvasContextList[fileIndex].lineTo(coord.offsetX, coord.offsetY);
                                    }
                                }

                                if (isPolygon) {
                                    this.shapeCanvasContextList[fileIndex].closePath();
                                }

                                if (isPolygon && figure.interiorRing.length > 0) {
                                    figure.interiorRing.forEach(ring => {
                                        for (let j = 0; j <= ring.length; j++) {
                                            let ringCoord: CoordinateDto = ring[j];

                                            if (j == 0) {
                                                this.shapeCanvasContextList[fileIndex].moveTo(ringCoord.offsetX, ringCoord.offsetY);
                                            } else if (j == ring.length) {
                                                let firstRingCoord: CoordinateDto = ring[0];
                                                this.shapeCanvasContextList[fileIndex].lineTo(firstRingCoord.offsetX, firstRingCoord.offsetY);
                                            } else {
                                                this.shapeCanvasContextList[fileIndex].lineTo(ringCoord.offsetX, ringCoord.offsetY);
                                            }
                                        }

                                        this.shapeCanvasContextList[fileIndex].closePath();
                                    });
                                }
                            });

                            let colorIndex: number = fileIndex % 10;

                            if (isPolygon) {
                                if (fileIndex == this.selectedShapeFileIndex && dataIndex == this.selectedShapeIndex) {
                                    this.shapeCanvasContextList[fileIndex].fillStyle = this.shapeColorData.list[colorIndex].select;
                                    this.shapeCanvasContextList[fileIndex].fill();
                                } else {
                                    this.shapeCanvasContextList[fileIndex].fillStyle = this.shapeColorData.list[colorIndex].normal;
                                    this.shapeCanvasContextList[fileIndex].fill();
                                }

                                this.shapeCanvasContextList[fileIndex].lineWidth = 1;
                            } else {
                                this.shapeCanvasContextList[fileIndex].lineWidth = 4;
                            }

                            if (fileIndex == this.selectedShapeFileIndex && dataIndex == this.selectedShapeIndex) {
                                this.shapeCanvasContextList[fileIndex].strokeStyle = this.shapeColorData.list[colorIndex].selectLine;
                                this.shapeCanvasContextList[fileIndex].stroke();
                            } else {
                                this.shapeCanvasContextList[fileIndex].strokeStyle = this.shapeColorData.list[colorIndex].normalLine;
                                this.shapeCanvasContextList[fileIndex].stroke();
                            }
                        }
                    }
                });
            }
        });
    }

    public toggleShapeMenuVisible(): void {
        this.isShapeMenuVisible = !this.isShapeMenuVisible;
    }

    public toggleShapeLayerVisible(): void {
        if (!this.isShapeLayerVisible && this.shapeFileDtoList.length == 0) {
            return;
        }

        this.isShapeLayerVisible = !this.isShapeLayerVisible;
    }

    public toggleShapeFileVisible(shapeFileIndex: number): void {
        if (this.shapeFileDtoList[shapeFileIndex] != undefined) {
            this.shapeFileDtoList[shapeFileIndex].isVisible = !this.shapeFileDtoList[shapeFileIndex].isVisible;

            this.setShapeFileCanvas();
        }
    }

    public showAllDbfData(shapeFileIndex: number): void {
        if (this.shapeFileDtoList[shapeFileIndex].dbfHeadList.length > 0) {
            this.targetShapeFileIndex = shapeFileIndex;
            this.isAllDbfDataDialogVisible = true;
        }
    }

    public hideAllDbfData(): void {
        this.isAllDbfDataDialogVisible = false;
        this.targetShapeFileIndex = -1;
    }

    public moveToShapeFileArea(shapeFileIndex: number): void {
        this.selectedShapeFileIndex = shapeFileIndex;

        this.mapService.map.moveToBoundary(
            new Point(
                this.shapeFileDtoList[this.selectedShapeFileIndex].leftBottomCoord.longitude,
                this.shapeFileDtoList[this.selectedShapeFileIndex].leftBottomCoord.latitude
            ),
            new Point(
                this.shapeFileDtoList[this.selectedShapeFileIndex].rightTopCoord.longitude,
                this.shapeFileDtoList[this.selectedShapeFileIndex].rightTopCoord.latitude
            ),
        );

        this.isMapMoved = false;
        this.convertShapeFileListCoordinateToOffset();
        this.setShapeFileCanvas();
    }

    public showDbfData(): void {
        if (this.shapeFileDtoList[this.selectedShapeFileIndex].dbfHeadList.length > 0) {
            this.isDbfDataDialogVisible = true;
        }
    }

    public hideDbfData(): void {
        this.isDbfDataDialogVisible = false;
    }

    public getDbfTableWidth(isAllDbfData: boolean): number {
        let headList: number = 0;

        if (isAllDbfData) {
            headList = this.shapeFileDtoList[this.targetShapeFileIndex].dbfHeadList.length;
        } else {
            headList = this.shapeFileDtoList[this.selectedShapeFileIndex].dbfHeadList.length;
        }

        return headList * 150;
    }

    public deleteShapeFile(shapeFileIndex: number): void {
        this.selectedShapeFileIndex = -1;

        this.shapeFileDtoList.splice(shapeFileIndex, 1);
        if (this.shapeFileDtoList.length == 0) {
            this.isShapeLayerVisible = false;
            this.nowMapControlType = MapControlType.MOVE_MAP;
        }

        this.changeDetector.detectChanges();
    }

    public onShapeFileWrapClick(event: any, isContextMenu: boolean): void {
        if (this.nowMapControlType != MapControlType.SELECT_SHAPE) {
            return;
        }

        let eventPoint: CoordinateDto = new CoordinateDto(0, 0, event.offsetX, event.offsetY);

        let isFind: boolean = false;
        let selectedIndex: number = -1;

        this.shapeFileDtoList.some((shapeFileDto, fileIndex) => {
            let tempShapeList: ShapeFileDataDto[] = Object.assign([], shapeFileDto.list);

            tempShapeList.reverse().some((shape, index) => {
                if (shape.isVisible) {
                    let typeCode: number = +ShapeType[shape.typeCode];
                    let isPolygon: boolean = typeCode == ShapeType.POLYGON || typeCode == ShapeType.MULTIPOLYGON;

                    if (isPolygon) {
                        shape.figureList.some(figure => {
                            if (ShapeFileUtil.isLieOnThePolygon(figure.exteriorRing, eventPoint)) {
                                if (figure.interiorRing.length > 0) {
                                    let isOnTheInteriorRing: boolean = false;

                                    figure.interiorRing.some(ring => {
                                        if (ShapeFileUtil.isLieOnThePolygon(ring, eventPoint)) {
                                            isOnTheInteriorRing = true;
                                            return true;
                                        }
                                    });

                                    if (!isOnTheInteriorRing) {
                                        selectedIndex = Math.abs(index - tempShapeList.length + 1);
                                        isFind = true;
                                        return true;
                                    }
                                } else {
                                    selectedIndex = Math.abs(index - tempShapeList.length + 1);
                                    isFind = true;
                                    return true;
                                }
                            }
                        });
                    } else {
                        shape.figureList.some(figure => {
                            isFind = ShapeFileUtil.isAdjacentTheLine(figure.exteriorRing, eventPoint);

                            if (isFind) {
                                selectedIndex = Math.abs(index - tempShapeList.length + 1);
                                return true;
                            }
                        });
                    }

                    if (isFind) {
                        this.selectedShapeIndex = selectedIndex;
                        return true;
                    }
                }
            });

            if (isFind) {
                this.selectedShapeFileIndex = fileIndex;
                return true;
            }
        });

        if (isFind) {
            this.setShapeFileCanvas();

            if (isContextMenu) {
                this.contextMenuService.show.next({
                    contextMenu: this.shapeFileMenu,
                    event: event,
                    item: null
                });
            }
        } else {
            if (isContextMenu) {
                this.contextMenuService.show.next({
                    contextMenu: this.nonShapeFileMenu,
                    event: event,
                    item: null
                });
            }
        }

        event.preventDefault();
        event.stopPropagation();
    }

    public hideShape(isOthers: boolean): void {
        if (this.selectedShapeIndex == -1) {
            alert("Select the shape");
            return;
        }

        if (isOthers) {
            this.shapeFileDtoList[this.selectedShapeFileIndex].list.forEach((shape, index) => {
                if (index != this.selectedShapeIndex) {
                    shape.isVisible = false;
                }
            });
        } else {
            this.shapeFileDtoList[this.selectedShapeFileIndex].list[this.selectedShapeIndex].isVisible = false;
        }

        this.setShapeFileCanvas();
    }

    public showAllShapes(): void {
        this.shapeFileDtoList[this.selectedShapeFileIndex].list.forEach(shape => {
            shape.isVisible = true;
        });

        this.setShapeFileCanvas();
    }

    // check license
    public hasLivemapLicense(): boolean {
        return this.accountDto.licenseDto.useLivemap;
    }

    public hasStreamingLicense(): boolean {
        return this.accountDto.licenseDto.useStreaming;
    }

    public hasRemoteControlLicense(): boolean {
        return this.accountDto.licenseDto.useRemoteControl;
    }

    public moveTo(machine: MachineDto): void {
        let point: Point = new Point(machine.machineStatusDto.longitude, machine.machineStatusDto.latitude);

        if (point.coordX == 0 && point.coordY == 0) {
            let popupData: PopupDto = new PopupDto();
            popupData.title = this.translateService.instant("drone-list.move.title");
            popupData.message = this.translateService.instant("drone-list.move.message.no-status-guide");
            popupData.isVisible = true;

            this.popupService.publishAlert(popupData);
        } else {
            this.mapService.map.moveTo(point);
            this.machineListComponent.close();

            timer(500).subscribe(() => {
                let machines: MachineDto[] = this.visibleFilterService.getVisibleList().filter(item => item.seq == machine.seq);
                if (0 < machines.length) {
                    this.showPanel(machines[0], false);
                }
            });
        }
    }

    private setSubmenuSubscriber(): void {
        this.submenuService.isDroneMenu$.subscribe(
            () => {
                this.machineListComponent.show();
            }
        );
    }

    private unsetSubmenuSubscriber(): void {
        try {
            this.submenuService.isDroneMenu$.unsubscribe();
        } catch (error) { }
    }

    // control mgmt
    public getDroneStatusClassName(missionControlType: MissionControlType): string {
        switch (+MissionControlType[missionControlType]) {
            case MissionControlType.TAKE_OFF:
                return "takeoff";
            case MissionControlType.LAND:
                return "land";
            case MissionControlType.START:
                return "start";
            case MissionControlType.PAUSE:
                return "pause";
            case MissionControlType.STOP:
                return "stop";
            case MissionControlType.RETURN_TO_HOME:
                return "r_home";
            default:
                return "none";
        }
    }

    // weather
    private getEMFData(): void {
        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<EMFDto> = res;

            if (response.isSuccess) {
                this.emfDto = response.data;
            }
        }.bind(this);

        this.httpService.get(APIUrl.EMF, httpCallback);
    }

    private setWeatherTimer(): void {
        if (this.settingsService.isGoogleMap) {
            return;
        }

        if (this.weatherServiceTimer != null) {
            this.weatherServiceTimer.unsubscribe();
        }

        this.weatherServiceTimer = timer(2000).subscribe(() => {
            this.mapService.map.getAddressOfCenterPoint(function (addressDto: AddressDto): void {
                this.addressDto = addressDto;
                this.getWeatherData();
            }.bind(this));
        });
    }

    public getAddressString(): string {
        if (this.addressDto.area1 == "" && this.addressDto.area2 == "" && this.addressDto.area3 == "") {
            return this.translateService.instant("control.weather.message.loading");
        }

        return this.addressDto.area1 + " " + this.addressDto.area2 + " " + this.addressDto.area3;
    }

    public getWeatherData(): void {
        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<WeatherDto> = res;

            if (response.isSuccess) {
                this.weatherDto = res.data;
                this.weatherDto.skyCode = SkyCode[res.data.skyCode];
                this.weatherDto.precipitationCode = PrecipitationCode[res.data.precipitationCode];
                this.weatherDto.lightningCode = LightningCode[res.data.lightningCode];
                this.weatherDto.windCode = WindCode[res.data.windCode];
            }
        }.bind(this);

        let centerLatLng: Point = this.mapService.map.getCenterPoint();
        this.httpService.get(APIUrl.TODAY_WEATHER(centerLatLng.coordY, centerLatLng.coordX), httpCallback);
    }

    public getWeatherString(): string {
        if (this.weatherDto.lightningCode == LightningCode.HIGH) {
            return LightningCode[this.weatherDto.lightningCode];
        }

        if (this.weatherDto.precipitationCode != PrecipitationCode.NONE) {
            return PrecipitationCode[this.weatherDto.precipitationCode];
        }

        if (this.weatherDto.skyCode != SkyCode.NONE) {
            return SkyCode[this.weatherDto.skyCode];
        }

        return "Unk";
    }

    public getWindString(): string {
        if (this.weatherDto.windCode == WindCode.N2) {
            return "N";
        }

        return WindCode[this.weatherDto.windCode];
    }

    // for mission tab - refactoring
    public changeToDroneTab(changeToDroneTab: boolean): void {
        this.isDroneTabVisible = changeToDroneTab;

        if (this.isDroneTabVisible) {
            this.distancePointList = [];

            this.canvasService.canvas.clear();
            this.figure = null;
            this.missionDto = new MissionDto();
        } else {
            this.canvasService.canvas.clear();
            this.missionAreaList.forEach((missionArea, key) => {
                if (missionArea.machineSeq == this.machine.seq) {
                    this.missionAreaList.splice(key, 1);
                }
            });

            this.drawLine();
        }
    }

    public toggleMachineVisible(): void {
        this.isMachineVisible = !this.isMachineVisible;
    }

    public getStandardZoomLevel(): number {
        return this.mapService.standardZoomLevel;
    }

    public getCornorPointList(): Point[] {
        if (this.figure == undefined) {
            return [];
        }

        return this.figure.getCornorPointList();
    }

    public getCenterPointList(): Offset[] {
        if (this.figure == undefined) {
            return [];
        }

        return this.figure.getCenterPointList();
    }

    private setLatLngPerMeter(): void {
        this.canvasService.canvas.clear();

        let centerPoint: Point = this.mapService.map.getCenterPoint();
        this.latLng.calc(centerPoint);
    }

    private drawMissionLine(): void {
        if (this.figure.getCornorPointList().length <= 1) {
            this.canvasService.canvas.clear();
            return;
        }

        this.canvasService.canvas.draw(this.figure.getCornorPointList(), this.missionDto.typeCode, this.isPointMoving);
    }

    private resetOffset(): void {
        this.figure.resetCornorPointOffset();
        this.drawMissionLine();
    }

    private changeOffsetByDiff(diffX: number, diffY: number): void {
        this.figure.changeCornorPointOffset(diffX, diffY);
        this.drawMissionLine();
    }

    private onCornorPointMoving(index: number): void {
        this.canvasService.canvas.clear();
        this.isPointMoving = true;

        this.figure.onCornorPointMove(index);
        this.drawMissionLine();
        this.setMissionWayPoint();
    }

    private onCornorPointMoveEnd(index: number): void {
        this.canvasService.canvas.clear();
        this.isPointMoving = false;

        this.figure.onCornorPointMoveEnd(index);
        this.drawMissionLine();
        this.setControlPointOffset();
        this.setMissionWayPoint();
        this.setDistancePointList();

        this.calcSpeed();
    }

    private onCenterPointMoving(index: number, event: any): void {
        this.canvasService.canvas.clear();
        this.isPointMoving = true;

        this.figure.onCenterPointMove(index, event);
        this.drawMissionLine();
        this.setMissionWayPoint();
    }

    private onCenterPointMoveEnd(): void {
        this.isPointMoving = false;

        this.figure.onCenterPointMoveEnd();
        this.drawMissionLine();
        this.setControlPointOffset();
        this.setMissionWayPoint();
        this.setDistancePointList();

        this.calcSpeed();
    }

    private setControlPointOffset(): void {
        this.figure.setControlPointOffset();
        let movePointOffset: Offset = this.figure.getMovePointOffset();
        let rotatePointOffset: Offset = this.figure.getRotatePointOffset();

        this.movePoint.offsetX = movePointOffset.x;
        this.movePoint.offsetY = movePointOffset.y;
        this.rotatePoint.offsetX = rotatePointOffset.x;
        this.rotatePoint.offsetY = rotatePointOffset.y;
    }

    public changeControlOffsetByDiff(diffX: number, diffY: number): void {
        this.movePoint.offsetX += diffX;
        this.movePoint.offsetY += diffY;
        this.rotatePoint.offsetX += diffX;
        this.rotatePoint.offsetY += diffY;
    }

    public onMovePointMoving(event: any): void {
        this.canvasService.canvas.clear();
        this.isPointMoving = true;

        let style: any = window.getComputedStyle(this.movePointEl.nativeElement);
        let matrix: any = new WebKitCSSMatrix(style.webkitTransform);
        let translateOffset: Offset = new Offset(matrix.m41, matrix.m42);

        this.figure.onMovePointMoving(translateOffset);
        this.drawMissionLine();

        if (this.missionDto.typeCode == MissionType.GRID || this.missionDto.typeCode == MissionType.POLYGON) {
            this.figure.resetCenterPoint();
            this.setMissionWayPoint();
        }
    }

    public onMovePointMoveEnd(event: any): void {
        this.canvasService.canvas.clear();
        this.isPointMoving = false;

        this.figure.onMovePointMoveEnd();
        this.drawMissionLine();
        this.setControlPointOffset();

        if (this.missionDto.typeCode == MissionType.GRID || this.missionDto.typeCode == MissionType.POLYGON) {
            this.setMissionWayPoint();
        }

        this.setDistancePointList();
    }

    public onRotatePointMoving(event: any): void {
        this.canvasService.canvas.clear();
        this.isPointMoving = true;

        this.figure.onRotatePointMoving(event);
        this.drawMissionLine();
        this.setMissionWayPoint();
    }

    public onRotatePointMoveEnd(): void {
        this.isPointMoving = false;

        this.figure.onRotatePointMoveEnd();
    }

    // waypoint only
    public selectPoint(index: number): void {
        this.figure.selectPoint(index);
    }

    // waypoint only
    public deletePoint(): void {
        this.figure.deletePoint();
        this.canvasService.canvas.clear();

        this.drawMissionLine();
        this.setControlPointOffset();
        this.setDistancePointList();
    }

    private setMissionWayPoint(): void {
        if (this.isMapMoved || this.isMapChanged || this.missionDto.typeCode == MissionType.WAYPOINT) {
            return;
        }

        let pointList: Offset[] = this.figure.getCornorPointListByOffset();

        if (this.missionDto.typeCode == MissionType.GRID) {
            this.figure.setAngle();
        }

        if (this.missionDto.fov == 0) {
            this.missionDto.fov = 84;
        }

        if (this.machine != null && this.machine.seq != 0) {
            this.missionDto.aspectRatioTypeCode = this.machine.machineStatusDto.aspectRatioTypeCode;
            this.missionDto.fov = CameraTypeFov.get(this.machine.machineStatusDto.cameraTypeCode);
        }

        let waypointOption: WaypointOption = new WaypointOption(
            pointList, this.figure.getAngle(), this.missionDto.missionAngle,
            this.missionDto.frontRatio / 100, this.missionDto.sideRatio / 100,
            this.missionDto.altitude, this.missionDto.fov, this.missionDto.aspectRatioTypeCode, this.latLng
        );

        this.waypointList = this.waypointService.make(waypointOption);
        this.canvasService.canvas.drawWayPoint(this.waypointList);
    }

    public changeSpeed(isDecrease: boolean): void {
        if (isDecrease) {
            if (1 <= this.missionDto.speed) {
                --this.missionDto.speed
            }
        } else {
            if (this.missionDto.speed <= 14) {
                ++this.missionDto.speed;
            }
        }

        this.missionDto.speed = +this.missionDto.speed.toFixed(1);
    }

    public changeGimbalAngle(isDecrease: boolean): void {
        if (this.missionDto.typeCode == MissionType.WAYPOINT) {
            if (isDecrease) {
                this.figure.cornorPointList[this.figure.selectedPointIndex].gimbalAngle
                    = (this.figure.cornorPointList[this.figure.selectedPointIndex].gimbalAngle == 0)? 0 : --this.figure.cornorPointList[this.figure.selectedPointIndex].gimbalAngle;
            } else {
                this.figure.cornorPointList[this.figure.selectedPointIndex].gimbalAngle
                    = (this.figure.cornorPointList[this.figure.selectedPointIndex].gimbalAngle == 90)? 90 : ++this.figure.cornorPointList[this.figure.selectedPointIndex].gimbalAngle;
            }
        } else {
            if (isDecrease) {
                this.missionDto.gimbalAngle = (this.missionDto.gimbalAngle == 0) ? 0 : --this.missionDto.gimbalAngle;
            } else {
                this.missionDto.gimbalAngle = (this.missionDto.gimbalAngle == 180) ? 30 : ++this.missionDto.gimbalAngle;
            }
        }
    }

    public onGimbalAngleChanged(): void {
        if (90 < this.missionDto.gimbalAngle) {
            this.missionDto.gimbalAngle = 90;
        }

        if (this.missionDto.gimbalAngle < 0) {
            this.missionDto.gimbalAngle = 0;
        }
    }

    public onWaypointGimbalAngleChanged(): void {
        if (90 < this.figure.cornorPointList[this.figure.selectedPointIndex].gimbalAngle) {
            this.figure.cornorPointList[this.figure.selectedPointIndex].gimbalAngle = 90;
        }

        if (this.figure.cornorPointList[this.figure.selectedPointIndex].gimbalAngle < 0) {
            this.figure.cornorPointList[this.figure.selectedPointIndex].gimbalAngle = 0;
        }
    }

    public changeMissionAngle(isDecrease: boolean): void {
        if (isDecrease) {
            this.missionDto.missionAngle = (this.missionDto.missionAngle == 0)? 359 : --this.missionDto.missionAngle;
        } else {
            this.missionDto.missionAngle = (this.missionDto.missionAngle == 359)? 0 : ++this.missionDto.missionAngle;
        }

        this.updateWayPoint();
    }

    public onMissionAngleChanged(): void {
        if (360 <= this.missionDto.missionAngle) {
            this.missionDto.missionAngle = this.missionDto.missionAngle % 360;
        }

        if (this.missionDto.missionAngle < 0) {
            let angle: number = this.missionDto.missionAngle % 360;

            if (angle == 0) {
                this.missionDto.missionAngle = 0;
            } else {
                this.missionDto.missionAngle = 360 + angle;
            }
        }

        this.updateWayPoint();
    }

    public onRatioKeyUp(event: any): void {
        if (this.missionDto.altitude > 500) {
            this.missionDto.altitude = 500;
        }

        if (this.missionDto.altitude < 10) {
            this.missionDto.altitude = 10;
        }

        if (event.keyCode == 13) {
            this.updateWayPoint();
        }
    }

    public onAltitudeChanged(): void {
        if (this.missionDto.altitude > 500) {
            this.missionDto.altitude = 500;
        }

        if (this.missionDto.altitude < 20) {
            this.missionDto.altitude = 20;
        }

        this.updateWayPoint();
    }

    public onWaypointAltitudeChanged(): void {
        if (this.figure.cornorPointList[this.figure.selectedPointIndex].altitude > 500) {
            this.figure.cornorPointList[this.figure.selectedPointIndex].altitude = 500;
        }

        if (this.figure.cornorPointList[this.figure.selectedPointIndex].altitude < 20) {
            this.figure.cornorPointList[this.figure.selectedPointIndex].altitude = 20;
        }
    }

    public changeAltitude(isDecrease: boolean): void {
        if (isDecrease) {
            this.missionDto.altitude = (this.missionDto.altitude == 20)? 20 : --this.missionDto.altitude;
        } else {
            this.missionDto.altitude = (this.missionDto.altitude == 500)? 500 : ++this.missionDto.altitude;
        }

        this.updateWayPoint();
    }

    public changeWaypointAltitude(isDecrease: boolean): void {
        if (isDecrease) {
            this.figure.cornorPointList[this.figure.selectedPointIndex].altitude
                = (this.figure.cornorPointList[this.figure.selectedPointIndex].altitude == 20)? 20 : --this.figure.cornorPointList[this.figure.selectedPointIndex].altitude;
        } else {
            this.figure.cornorPointList[this.figure.selectedPointIndex].altitude
                = (this.figure.cornorPointList[this.figure.selectedPointIndex].altitude == 500)? 500 : ++this.figure.cornorPointList[this.figure.selectedPointIndex].altitude;
        }

        this.updateWayPoint();
    }

    public changeFrontRatio(isDecrease: boolean): void {
        if (isDecrease) {
            this.missionDto.frontRatio = (this.missionDto.frontRatio == 0) ? 0 : --this.missionDto.frontRatio;
        } else {
            this.missionDto.frontRatio = (this.missionDto.frontRatio == 100) ? 100 : ++this.missionDto.frontRatio;
        }

        this.updateWayPoint();
    }

    public changeSideRatio(isDecrease: boolean): void {
        if (isDecrease) {
            this.missionDto.sideRatio = (this.missionDto.sideRatio == 0) ? 0 : --this.missionDto.sideRatio;
        } else {
            this.missionDto.sideRatio = (this.missionDto.sideRatio == 100) ? 100 : ++this.missionDto.sideRatio;
        }

        this.updateWayPoint();
    }

    private updateWayPoint(): void {
        this.canvasService.canvas.clear();

        this.drawMissionLine();
        this.setMissionWayPoint();
        this.calcSpeed();
    }

    public isControlPointVisible(): boolean {
        if (!this.isMapMoved && !this.isMapChanged
            && this.zoomLevel > this.mapService.standardZoomLevel
            && this.figure != undefined
            && this.figure.getCornorPointList().length > 0
            && this.missionDto.typeCode != MissionType.FREE) {

            return true;
        }

        return false;
    }

    private getDirectionCodeList(): any[] {
        let keys: string[] = Object.keys(this.directionCode);
        return keys.slice(keys.length / 2);
    }

    private getFinishActionCodeList(): any[] {
        let keys: string[] = Object.keys(this.finishActionCode);
        return keys.slice(keys.length / 2);
    }

    private getPointActionCodeList(): any[] {
        let keys: string[] = Object.keys(this.pointActionCode);
        return keys.slice(keys.length / 2);
    }

    public isVisibleWaypointPointActionCode(pointActionCode: PointActionCode): boolean {
        if (pointActionCode == PointActionCode.PHOTO_INTERVAL || pointActionCode == PointActionCode.PHOTO_DISTANCE_INTERVAL) {
            return false;
        }

        if (this.figure.selectedPointIndex == 0) {
            return true;
        }

        if (pointActionCode == PointActionCode.RECORD_STOP || pointActionCode == PointActionCode.RECORD_NON_STOP) {
            return pointActionCode == this.figure.getCornorPointList()[0].pointActionCode;
        }

        if (this.figure.getCornorPointList()[0].pointActionCode == PointActionCode.RECORD_STOP
            || this.figure.getCornorPointList()[0].pointActionCode == PointActionCode.RECORD_NON_STOP) {
            return pointActionCode == this.figure.getCornorPointList()[0].pointActionCode;
        }

        let remainValue: number = this.figure.getCornorPointList()[0].pointActionCode % 2;
        return (pointActionCode % 2 == remainValue) ? true : false;
    }

    public changeWaypointPointActionCode(): void {
        if (this.figure.selectedPointIndex > 0) {
            return;
        }

        this.figure.getCornorPointList().forEach(point => {
            point.pointActionCode = this.figure.cornorPointList[this.figure.selectedPointIndex].pointActionCode;
        });
    }

    private calcSpeed(): void {
        if (this.missionDto.pointActionCode != PointActionCode.PHOTO_INTERVAL && this.missionDto.pointActionCode != PointActionCode.PHOTO_DISTANCE_INTERVAL) {
            return;
        }

        let pointList: TempPointDto[] = Object.assign([], this.getPointList());
        let totalDistance: number = this.getFlightDistance(pointList);

        this.distancePerPoint = +(totalDistance / pointList.length).toFixed(1);

        for (let interval: number = 2; interval <= 1000; interval++) {
            let speed: number = this.distancePerPoint / interval;

            if (speed <= 15) {
                this.interval = interval;
                this.missionDto.speed = +speed.toFixed(1);
                break;
            }
        }
    }

    public changeMissionType(isLoad: boolean): void {
        this.distancePointList = [];

        if (this.machine != null && this.machine.seq != 0) {
            this.missionDto.cameraTypeCode = this.machine.machineStatusDto.cameraTypeCode;
        }

        switch (this.missionDto.typeCode) {
            case MissionType.WAYPOINT:
                this.figure = new WaypointFigure(this.mapService.map, this.latLng, this.cornorPointElList, this.centerPointElList);

                if (isLoad) {
                    this.figure.selectedPointIndex = 0;
                } else {
                    this.figure.setPoint();
                }

                this.canvasService.canvas.clear();
                break;
            case MissionType.GRID:
                this.setLatLngPerMeter();
                this.figure = new GridFigure(this.mapService.map, this.latLng, this.cornorPointElList, this.centerPointElList);

                if (!isLoad) {
                    this.figure.setPoint();
                    this.setControlPointOffset();
                    this.drawMissionLine();
                    this.setMissionWayPoint();
                    this.setDistancePointList();
                }
                break;
            case MissionType.POLYGON:
                this.setLatLngPerMeter();
                this.figure = new PolygonFigure(this.mapService.map, this.latLng, this.cornorPointElList, this.centerPointElList);

                if (!isLoad) {
                    this.figure.setPoint();
                    this.setControlPointOffset();
                    this.drawMissionLine();
                    this.setMissionWayPoint();
                    this.setDistancePointList();
                }
                break;
            default:
                this.figure = null;
                this.canvasService.canvas.clear();
                break;
        }
    }

    private getCornorPointCssName(idx: number): string {
        if (this.missionDto.typeCode == MissionType.WAYPOINT) {
            if (this.figure.getSelectedPointIndex() == idx) {
                return "i_waypoint forMission selected";
            }

            return "i_waypoint forMission";
        } else {
            return "cornorPoint forMission";
        }
    }

    private getCornorPointDiffOffset(idx: number, isTop: boolean): number {
        if (this.missionDto.typeCode == MissionType.WAYPOINT) {
            if (idx == 0 && isTop) {
                return 11;
            }

            if (idx == 0 && !isTop) {
                return 19;
            }

            if (isTop) {
                return 11;
            } else {
                return 14;
            }
        } else {
            return 9;
        }
    }

    public setMissionGroupChecked(group: GroupDto): void {
        group.setChecked(!group.isChecked);
        this.getMgmtMissionList();
    }

    public getMgmtMissionList(): void {
        this.setCheckedGroupSeqList();
        if (this.missionSearchDto.groupSeqList.length == 0) {
            this.mgmtMissionList = [];
            return;
        }

        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<string> = res;

            if (response.isSuccess) {
                this.mgmtMissionList = res.data;
            }
        }.bind(this);

        this.httpService.post(APIUrl.MISSION_SEARCH(this.accountDto.seq), this.missionSearchDto, httpCallback);
    }

    private setCheckedGroupSeqList(): void {
        this.missionSearchDto.groupSeqList = [];

        if (this.treeData.isChecked) {
            this.missionSearchDto.groupSeqList.push(this.treeData.seq);
        }

        this.recursiveGetCheckedGroupSeq(this.treeData);
    }

    private recursiveGetCheckedGroupSeq(group: GroupDto): void {
        group.lowerGroupList.forEach(lowerGroup => {
            if (lowerGroup.isChecked) {
                this.missionSearchDto.groupSeqList.push(lowerGroup.seq);
            }

            this.recursiveGetCheckedGroupSeq(lowerGroup);
        });
    }

    private loadMissionData(missionDto: MissionDto): void {
        if (this.visibleFilterService == null) {
            return;
        }

        this.missionDto = Object.assign(new MissionDto(), missionDto);
        this.changeMissionType(true);

        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<PointListDto> = res;

            if (response.isSuccess) {
                let pointList: PointDto[] = [];
                let cornorPointList: Point[] = [];
                let centerPointList: Offset[] = [];

                if (this.missionDto.typeCode == MissionType.WAYPOINT) {
                    pointList = JSON.parse(response.data.pointData);
                } else {
                    pointList = JSON.parse(response.data.coordinateData);
                }

                let mapContainer: Offset = this.mapService.map.getContainerOffset() || new Offset();

                pointList.forEach((item, key) => {
                    let point: Point = new Point(item.lng, item.lat, 0, 0, 0, 0, item.altitude, item.gimbalAngle, item.point_action);
                    let offset: Offset = this.mapService.map.convertPointToOffset(point);

                    if (this.missionDto.typeCode == MissionType.WAYPOINT) {
                        point.offsetX = offset.x + mapContainer.x;
                        point.offsetY = offset.y + mapContainer.y;

                        cornorPointList.push(point);
                    } else {
                        if (key % 2 == 0) {
                            point.offsetX = offset.x + mapContainer.x;
                            point.offsetY = offset.y + mapContainer.y;

                            cornorPointList.push(point);
                        } else {
                            offset.x = offset.x + mapContainer.x;
                            offset.y = offset.y + mapContainer.y;

                            centerPointList.push(offset);
                        }
                    }
                });

                this.figure.setCornorPointList(cornorPointList);
                this.figure.setCenterPointList(centerPointList);
                this.setControlPointOffset();

                if (this.missionDto.typeCode == MissionType.POLYGON) {
                    this.figure.setPolygonAngle(this.missionDto.angle);
                }

                this.mapService.map.moveTo(this.figure.getCenterCoords());
                this.drawMissionLine();

                this.isMapMoved = false;
                this.isMapChanged = false;
                this.setMissionWayPoint();

                this.setDistancePointList();
                this.isSettingVisible = true;

                if (this.weatherServiceTimer != null) {
                    this.weatherServiceTimer.unsubscribe();
                }

                this.setWeatherTimer();
                this.calcSpeed();

                this.isMissionListVisible = false;
            }
        }.bind(this);

        this.httpService.get(APIUrl.MISSION_DATA(missionDto.seq), httpCallback);
    }

    public createMission(): void {
        if (this.missionDto.name.length == 0) {
            let popupData: PopupDto = new PopupDto();
            popupData.isVisible = true;
            popupData.title = this.translateService.instant("control.mission.create.title");
            popupData.message = this.translateService.instant("control.mission.create.message.enter-mission-name-guide");

            this.popupService.publishAlert(popupData);
            return;
        }

        if (this.missionDto.typeCode == MissionType.WAYPOINT) {
            this.missionDto.pointActionCode = this.figure.cornorPointList[0].pointActionCode;
            this.missionDto.altitude = this.figure.cornorPointList[0].altitude;
            this.missionDto.gimbalAngle = this.figure.cornorPointList[0].gimbalAngle;
        }

        if (this.missionDto.seq == 0) {
            this.requestCreateMission();
        } else {
            let isDuplicatedName: boolean = false;

            this.mgmtMissionList.forEach(mission => {
                if (mission.seq == this.missionDto.seq && mission.name == this.missionDto.name) {
                    isDuplicatedName = true;
                }
            });

            if (isDuplicatedName) {
                let popupData: PopupDto = new PopupDto();
                popupData.isVisible = true;
                popupData.title = this.translateService.instant("control.mission.create.title");
                popupData.message = this.translateService.instant("control.mission.create.message.duplicated-name-guide");

                let responseSubscriber: any = this.popupService.response$.subscribe(
                    response => {
                        if (response) {
                            this.missionDto.isOverwrite = true;
                            this.requestCreateMission();
                        }

                        responseSubscriber.unsubscribe();
                    }
                );
                this.popupService.publishData(popupData);
            } else {
                this.missionDto.isOverwrite = false;
                this.requestCreateMission();
            }
        }
    }

    private requestCreateMission(): void {
        try {
            let coordinateList: PointDto[] = this.getCoordinatePointList();
            let tempPointList: TempPointDto[] = this.getPointList();

            let pointList: PointDto[] = [];

            tempPointList.forEach(tempPoint => {
                let pointDto: PointDto = new PointDto();
                pointDto.title = tempPoint.title;
                pointDto.lat = tempPoint.lat;
                pointDto.lng = tempPoint.lng;
                pointDto.altitude = tempPoint.altitude;
                pointDto.gimbalAngle = tempPoint.gimbalAngle;
                pointDto.point_action = tempPoint.point_action;

                pointList.push(pointDto);
            });

            this.setFlightArea(Object.assign([], coordinateList));
            this.missionDto.totalDistance = this.getFlightDistance(Object.assign([], tempPointList));

            if (this.missionDto.typeCode != MissionType.WAYPOINT) {
                this.setOuterSize(Object.assign([], coordinateList));
            }

            this.missionDto.coordinateData = JSON.stringify(coordinateList);
            this.missionDto.pointData = JSON.stringify(pointList);
            this.missionDto.isMachineData = false;
            this.missionDto.address = this.getAddressString();

            let centerPoint: Point = this.figure.getCenterCoords();
            this.missionDto.centerLatitude = centerPoint.coordY;
            this.missionDto.centerLongitude = centerPoint.coordX;

            if (this.missionDto.typeCode != MissionType.WAYPOINT) {
                let angle: number = (this.figure.getAngle() + this.missionDto.missionAngle) % 360;

                if (angle < 0) {
                    angle = 360 + angle;
                }

                this.missionDto.angle = +angle.toFixed(5);
            }

            let httpCallback: HTTPCallBack = new HTTPCallBack();
            httpCallback.response = function (res: any): void {
                let response: ResponseDto<string> = res;

                if (response.isSuccess) {
                    this.getMissionList();

                    // done dialog
                    let popupData: PopupDto = new PopupDto();
                    popupData.isVisible = true;
                    popupData.title = this.translateService.instant("control.mission.create.title");
                    popupData.message = this.translateService.instant("control.mission.create.message.success");
                    popupData.isCancelButtonVisible = false;

                    this.popupService.publishData(popupData);
                }
            }.bind(this);

            this.httpService.put(APIUrl.MAKE_MISSION(this.accountDto.seq), this.missionDto, httpCallback);
        } catch (error) {
            // error occured
            return;
        }
    }

    public setFlightArea(pointList: PointDto[]): void {
        if (this.missionDto.typeCode == MissionType.WAYPOINT) {
            return;
        }

        let area: number = 0;
        pointList.push(pointList[0]);

        for (let i = 0; i < pointList.length - 1; i++) {
            let point1: PointDto = pointList[i];
            let point2: PointDto = pointList[i + 1];

            area += this.toRadian(point2.lng - point1.lng) * (2 + Math.sin(this.toRadian(point1.lat)) + Math.sin(this.toRadian(point2.lat)));
        }

        this.missionDto.totalArea = Math.abs(area * Math.pow(6378137, 2) / 2);
    }

    private toRadian(degree: number): number {
        return degree * Math.PI / 180;
    }

    public getFlightDistance(pointList: TempPointDto[]): number {
        let distance: number = 0;

        if (this.missionDto.typeCode == MissionType.WAYPOINT) {
            for (let i = 0; i < pointList.length - 1; i++) {
                let point1: Point = new Point(pointList[i].lng, pointList[i].lat);
                let point2: Point = new Point(pointList[i + 1].lng, pointList[i + 1].lat);

                distance += this.mapService.map.getDistance(point1, point2);
            }
        } else {
            let endpointList: PointDto[] = [];

            pointList.forEach(point => {
                if (point.isEndpoint) {
                    endpointList.push(point);
                }
            });

            for (let i = 0; i < endpointList.length - 1; i++) {
                let point1: Point = new Point(endpointList[i].lng, endpointList[i].lat);
                let point2: Point = new Point(endpointList[i + 1].lng, endpointList[i + 1].lat);

                distance += this.mapService.map.getDistance(point1, point2);
            }
        }

        return distance;
    }

    private setOuterSize(pointList: PointDto[]): void {
        let minLat: number = 0;
        let maxLat: number = 0;
        let minLng: number = 0;
        let maxLng: number = 0;

        pointList.forEach((point, index) => {
            if (index == 0) {
                minLat = point.lat;
                maxLat = point.lat;
                minLng = point.lng;
                maxLng = point.lng;
            } else {
                minLat = Math.min(minLat, point.lat);
                maxLat = Math.max(maxLat, point.lat);
                minLng = Math.min(minLng, point.lng);
                maxLng = Math.max(maxLng, point.lng);
            }
        });

        let width: number = this.mapService.map.getDistance(new Point(minLng, minLat), new Point(maxLng, minLat));
        let height: number = this.mapService.map.getDistance(new Point(minLng, minLat), new Point(minLng, maxLat));

        this.missionDto.width = Math.round(width);
        this.missionDto.height = Math.round(height);
    }

    private getCoordinatePointList(): PointDto[] {
        if (this.missionDto.typeCode == MissionType.WAYPOINT) {
            return [];
        }

        let cornorPointList: PointDto[] = [];
        let centerPointList: PointDto[] = [];
        let coordinateList: PointDto[] = [];

        this.figure.getCornorPointList().forEach(item => {
            cornorPointList.push(new PointDto("", item.coordY, item.coordX));
        });

        this.figure.getCenterPointList().forEach((item, key) => {
            let coord1: Point = this.figure.getCornorPointList()[key];
            let coord2: Point = null;

            if (key == this.figure.getCenterPointList().length - 1) {
                coord2 = this.figure.getCornorPointList()[0];
            } else {
                coord2 = this.figure.getCornorPointList()[key + 1];
            }

            let coord: Point = new Point();
            coord.coordX = coord1.coordX + (coord2.coordX - coord1.coordX) / 2;
            coord.coordY = coord1.coordY + (coord2.coordY - coord1.coordY) / 2;

            centerPointList.push(new PointDto("", coord.coordY, coord.coordX));
        });

        for (let i = 0; i < cornorPointList.length; i++) {
            coordinateList.push(cornorPointList[i]);
            coordinateList.push(centerPointList[i]);
        }

        return coordinateList;
    }

    private getPointList(): TempPointDto[] {
        let pointList: TempPointDto[] = [];

        if (this.missionDto.typeCode == MissionType.WAYPOINT) {
            this.figure.getCornorPointList().forEach((item, key) => {
                let pointDto: TempPointDto = new TempPointDto();
                pointDto.title = (key == 0) ? "Start" : "" + key;
                pointDto.lat = item.coordY;
                pointDto.lng = item.coordX;
                pointDto.altitude = item.altitude;
                pointDto.gimbalAngle = item.gimbalAngle;
                pointDto.point_action = item.pointActionCode;

                pointList.push(pointDto);
            });
        } else {
            let mapContainer: Offset = this.mapService.map.getContainerOffset() || new Offset();

            this.waypointList.forEach(item => {
                item.x -= mapContainer.x;
                item.y -= mapContainer.y;

                let coord: Offset = this.mapService.map.convertOffsetToCoord(item);
                pointList.push(new TempPointDto("", coord.y, coord.x, this.missionDto.altitude, this.missionDto.gimbalAngle, PointActionCode.STOP, item.isEndpoint));
            });
        }

        return pointList;
    }

    public isWaypointEmpty(): boolean {
        if (this.missionDto.typeCode == MissionType.WAYPOINT && (!this.figure || this.figure.getCornorPointList().length == 0)) {
            return true;
        }

        return false;
    }

    public isVisibleSetting(missionSettingType: MissionSettingType): boolean {
        if (missionSettingType == MissionSettingType.TYPE_SELECT_INFO) {
            return true;
        }

        if (missionSettingType == MissionSettingType.WAYPOINT_INFO) {
            if (this.missionDto.typeCode == MissionType.WAYPOINT
                && this.figure.getCornorPointList().length == 0) {
                return true;
            }
        }

        if (this.figure == null || this.figure.getCornorPointList().length == 0) {
            return false;
        }

        let isVisible: boolean = false;
        switch (missionSettingType) {
            case MissionSettingType.POINT_ACTION:
            case MissionSettingType.ALTITUDE:
            case MissionSettingType.GIMBAL_ANGLE:
            case MissionSettingType.ASPECT_RATIO:
                if (this.missionDto.typeCode != MissionType.WAYPOINT) {
                    isVisible = true;
                }
                break;
            case MissionSettingType.DELETE_BUTTON:
            case MissionSettingType.POINT_ACTION_WAYPOINT:
            case MissionSettingType.ALTITUDE_WAYPOINT:
            case MissionSettingType.GIMBAL_ANGLE_WAYPOINT:
                if (this.missionDto.typeCode == MissionType.WAYPOINT) {
                    isVisible = true;
                }
                break;
            case MissionSettingType.BUTTONS:
                isVisible = true;
                break;
            case MissionSettingType.TITLE:
            case MissionSettingType.IS_PUBLIC:
            case MissionSettingType.IS_3D:
            case MissionSettingType.REPEAT:
            case MissionSettingType.SPEED:
            case MissionSettingType.DIRECTION_ACTION:
            case MissionSettingType.FINISH_ACTION:
            case MissionSettingType.TOTAL_DISTANCE:
            case MissionSettingType.CAMERA_TYPE:
                if (this.missionDto.typeCode == MissionType.WAYPOINT) {
                    if (this.figure.getSelectedPointIndex() == 0) {
                        isVisible = true;
                    } else {
                        isVisible = false;
                    }
                } else {
                    isVisible = true;
                }
                break;
            case MissionSettingType.FRONT_RATIO:
            case MissionSettingType.SIDE_RATIO:
                if (this.missionDto.typeCode == MissionType.WAYPOINT) {
                    isVisible = false;
                } else {
                    isVisible = true;
                }
                break;
            case MissionSettingType.INTERVAL:
                if (this.missionDto.pointActionCode == PointActionCode.PHOTO_INTERVAL) {
                    isVisible = true;
                } else {
                    isVisible = false;
                }
                break;
            case MissionSettingType.DISTANCE_INTERVAL:
                if (this.missionDto.pointActionCode == PointActionCode.PHOTO_DISTANCE_INTERVAL) {
                    isVisible = true;
                } else {
                    isVisible = false;
                }
                break;
            case MissionSettingType.MISSION_ANGLE:
                if (this.missionDto.typeCode == MissionType.GRID) {
                    isVisible = true;
                } else {
                    isVisible = false;
                }
                break;
            default:
                isVisible = false;
                break;
        }

        return isVisible;
    }

    private setDistancePointList(): void {
        this.distancePointList = [];

        if (this.figure.getCornorPointList().length <= 1 || this.zoomLevel <= this.mapService.standardZoomLevel) {
            return;
        }

        if (this.missionDto.typeCode == MissionType.WAYPOINT) {
            this.setWaypointDistance();
        } else {
            this.setPolygonDistance();
        }
    }

    private setWaypointDistance(): void {
        this.figure.getCornorPointList().forEach((point, index) => {
            if (index < this.figure.getCornorPointList().length - 1) {
                let point1: Point = point;
                let point2: Point = this.figure.getCornorPointList()[index + 1];

                let offset1: Offset = point1.toOffset();
                let offset2: Offset = point2.toOffset();

                let distancePoint: Distance = new Distance();
                distancePoint.setPoint(new Offset(
                    offset1.x + (offset2.x - offset1.x) / 2,
                    (offset1.y + (offset2.y - offset1.y) / 2) - 10
                ));

                distancePoint.setDistance(this.mapService.map.getDistance(point1, point2));
                this.distancePointList.push(distancePoint);
            }
        });
    }

    private setPolygonDistance(): void {
        this.figure.getCornorPointList().forEach((point, index) => {
            let point1: Point = point;
            let point2: Point;

            if (index == this.figure.getCornorPointList().length - 1) {
                point2 = this.figure.getCornorPointList()[0];
            } else {
                point2 = this.figure.getCornorPointList()[index + 1];
            }

            let offset1: Offset = point1.toOffset();
            let offset2: Offset = point2.toOffset();

            let distancePoint: Distance = new Distance();
            distancePoint.setPoint(new Offset(
                offset1.x + (offset2.x - offset1.x) / 2,
                (offset1.y + (offset2.y - offset1.y) / 2) - 40
            ));

            distancePoint.setDistance(this.mapService.map.getDistance(point1, point2));
            this.distancePointList.push(distancePoint);
        });
    }

    public confirmDeleteMission(mission: MissionDto): void {
        let popupData: PopupDto = new PopupDto();
        popupData.isVisible = true;
        popupData.title = this.translateService.instant("control.mission.delete.title");
        popupData.message = this.translateService.instant("control.mission.delete.message.confirm-message");

        let responseSubscriber: any = this.popupService.response$.subscribe(
            response => {
                if (response) {
                    this.deleteMission(mission);
                }

                responseSubscriber.unsubscribe();
            }
        );
        this.popupService.publishData(popupData);
    }

    private deleteMission(mission: MissionDto): void {
        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<string> = res;

            if (response.isSuccess) {
                let popupData: PopupDto = new PopupDto();
                popupData.title = this.translateService.instant("control.mission.delete.title");
                popupData.message = this.translateService.instant("control.mission.delete.message.done-message");
                popupData.isVisible = true;

                this.popupService.publishAlert(popupData);

                this.getMgmtMissionList();
            }
        }.bind(this);

        this.httpService.delete(APIUrl.DELETE_MISSION(mission.seq), httpCallback);
    }

    // check the livemap license
    public isValidPointAction(pointActionCode: PointActionCode): boolean {
        if (pointActionCode == PointActionCode.TAKE_PHOTO_NON_STOP || pointActionCode == PointActionCode.TAKE_PHOTO_STOP) {
            if (this.accountDto.licenseDto.useLivemap) {
                return true;
            }

            return false;
        }

        return true;
    }

    public showMissionSetting(): void {
        this.distancePointList = [];

        this.canvasService.canvas.clear();
        this.figure = null;
        this.missionDto = new MissionDto();

        this.missionAreaList.forEach(missionArea => {
            missionArea.setMissionWayPoint();
        });
    }

    public changeCameraType(): void {
        this.missionDto.fov = CameraTypeFov.get(this.missionDto.cameraTypeCode);
        this.updateWayPoint();
    }

    public changeAspectRatioType(): void {
        this.updateWayPoint();
    }

    public searchPoi(): void {
        if (this.poiSearchData.text == "") {
            return;
        }

        let httpCallback: HTTPCallBack = new HTTPCallBack();
        httpCallback.response = function (res: any): void {
            let response: ResponseDto<PoiResult> = res;

            if (response.isSuccess) {
                this.poiResult = response.data;
            }
        }.bind(this);

        this.httpService.post(APIUrl.GET_POI_LIST, this.poiSearchData, httpCallback);
    }

    public getPoiList(isKeywordList: boolean): PoiItem[] {
        let searchCode: string = "" + this.poiResult.searchCode;

        if (searchCode == "Keyword" && isKeywordList) {
            return this.poiResult.list;
        }

        if (searchCode == "Address" && !isKeywordList) {
            return this.poiResult.list;
        }
    }

    public moveToPoiLocation(poiItem: PoiItem): void {
        let point: Point = new Point(poiItem.longitude, poiItem.latitude);

        if (point.coordX != 0 && point.coordY != 0) {
            this.mapService.map.moveTo(point);
            this.clearPoiList();
        }

        this.poiSearchData.text = "";
    }

    public clearPoiList(): void {
        this.poiResult.list = [];
    }

    public onPoiKeyUp(event: any): void {
        if (event.keyCode == 13) {
            this.searchPoi();
        } else {
            this.poiResult.list = [];
        }
    }
}
