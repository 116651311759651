import { StatisticsChart } from './statisticsChart';
import { Color } from 'ng2-charts';
import { ChartColorList } from 'src/app/constants/chartColorList';

export class LineChart extends StatisticsChart {
    private colorList: ChartColorList = new ChartColorList();
    
    public getChartColors(): Color[] {
        return this.chartColors;
    }

    public setChartColors(): void {
        this.chartColors = [];

        let length: number = this.getChartData().length;

        for (let i = 0;i < length;i++) {
            let idx: number = i % 10;

            if (idx == length - 1) {
                this.chartColors.push({
                    backgroundColor: this.colorList.list[this.colorList.list.length - 1].opacity,
                    borderColor: this.colorList.list[this.colorList.list.length - 1].normal,
                    pointBackgroundColor: this.colorList.list[this.colorList.list.length - 1].opacity,
                    pointBorderColor: this.colorList.list[this.colorList.list.length - 1].normal,
                    pointHoverBackgroundColor: "#FFFFFF",
                    pointHoverBorderColor: this.colorList.list[this.colorList.list.length - 1].normal
                });
            } else {
                this.chartColors.push({
                    backgroundColor: this.colorList.list[idx].opacity,
                    borderColor: this.colorList.list[idx].normal,
                    pointBackgroundColor: this.colorList.list[idx].opacity,
                    pointBorderColor: this.colorList.list[idx].normal,
                    pointHoverBackgroundColor: "#FFFFFF",
                    pointHoverBorderColor: this.colorList.list[idx].normal
                });
            }
        }
    }

    public setChartOptions(): void {}
    public setChartPlugins(): void {}
}