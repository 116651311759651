import { Matrix } from './matrix';
import { MachineDto } from 'src/app/dto/machine/machine.dto';
import { MachineStatusDto } from 'src/app/dto/machine/machineStatus.dto';
import { MapService } from 'src/app/service/map.service';
import { Point } from '../../mission/mission/point';
import { Offset } from '../../mission/mission/offset';
import { SettingsService } from 'src/app/service/settings.service';

// clustering by lat&lng
export class ClusterService {
    private minLat: number = 0;
    private maxLat: number = 0;
    private minLng: number = 0;
    private maxLng: number = 0;

    private zoomLevel: number = 0;
    private verticalValue: number = 2;
    private horizontalValue: number = 4;
    private verticalDivision: number = 0;
    private horizontalDivision: number = 0;

    private verticalGab: number = 0;
    private horizontalGab: number = 0;

    private matrix: Matrix[] = [];
    private machineList: MachineDto[] = [];

    private mapService: MapService;

    constructor(mapService: MapService, settingsService: SettingsService) {
        this.mapService = mapService;

        if (settingsService.isGoogleMap) {
            this.minLat = -90;
            this.maxLat = 90;
            this.minLng = -180;
            this.maxLng = 180;
        } else {
            this.minLat = 33;
            this.maxLat = 39;
            this.minLng = 124;
            this.maxLng = 132;
        }
    }

    public setZoomLevel(zoomLevel: number): void {
        this.zoomLevel = zoomLevel;

        if (this.zoomLevel <= 8) {
            this.verticalDivision = this.verticalValue;
            this.horizontalDivision = this.horizontalValue;
        } else {
            this.verticalDivision = this.zoomLevel * this.verticalValue;
            this.horizontalDivision = this.zoomLevel * this.horizontalValue;
        }

        this.setMatrix();
    }

    private setMatrix(): void {
        this.matrix = [];

        this.verticalGab = (this.maxLat - this.minLat) / this.verticalDivision;
        this.horizontalGab = (this.maxLng - this.minLng) / this.horizontalDivision;

        for (let i = 0;i < this.verticalDivision;i++) {
            for (let j = 0;j < this.horizontalDivision;j++) {
                let latitude: number = this.maxLat - (this.verticalGab * i);
                let longitude: number = this.minLng + (this.horizontalGab * j);

                this.matrix.push(new Matrix(latitude, longitude));
            }
        }
    }

    public setClusterList(machineList: MachineDto[]): void {
        this.machineList = machineList;

        this.machineList.forEach(machine => {
            let status: MachineStatusDto = machine.machineStatusDto;
            status.machineSeq = machine.seq;
            status.isClustered = false;

            this.matrix.forEach(item => {
                this.check(status, item);
            });
        });

        this.setClusterOffset();
    }

    private check(status: MachineStatusDto, matrix: Matrix): void {
        if (status.latitude == 0 || status.longitude == 0 || status.isClustered) {
            return;
        }

        if (status.latitude < matrix.latitude && status.latitude >= (matrix.latitude - this.verticalGab)
            && status.longitude > matrix.longitude && status.longitude <= (matrix.longitude + this.horizontalGab)) {

            status.isClustered = true;
            matrix.machineList.push(status.machineSeq);
            ++matrix.cluster.count;

            if (matrix.cluster.point == null) {
                matrix.cluster.point = new Point(status.longitude, status.latitude);
            } else {
                let point: Point = new Point(
                    matrix.cluster.point.coordX + (status.longitude - matrix.cluster.point.coordX) / 2,
                    matrix.cluster.point.coordY + (status.latitude - matrix.cluster.point.coordY) / 2
                );

                matrix.cluster.point = point;
            }
        }
    }

    public setClusterOffset(): void {
        this.matrix.forEach(item => {
            if (item.cluster.count > 0) {
                let offset: Offset = this.mapService.map.convertPointToOffset(item.cluster.point);
                let containerOffset: Offset = this.mapService.map.getContainerOffset() || new Offset();
                offset.x += containerOffset.x;
                offset.y += containerOffset.y;

                item.cluster.offset = offset;
            }
        });
    }

    public changeClusterOffset(diffX: number, diffY: number): void {
        this.matrix.forEach(item => {
            if (item.cluster.count > 0) {
                item.cluster.offset.x += diffX;
                item.cluster.offset.y += diffY;
            }
        });
    }

    public getMatrix(): Matrix[] {
        return this.matrix;
    }
}
