export enum MachineSearchCode {
    Name, TypeName
}

export namespace MachineSearchCode {
    export function getMachineSearchCodeLanguage(machineSearchCode: MachineSearchCode): string {
        if (machineSearchCode == MachineSearchCode.Name) {
            return "common.label.name";
        }

        return "common.label.type-name";
    }

    export function getAll(): MachineSearchCode[] {
        return [MachineSearchCode.Name, MachineSearchCode.TypeName];
    }
}
