import { Offset } from './offset';
import { PointActionCode } from 'src/app/dto/mission/setting/pointAction.code';

export class Point {
    constructor(
        public coordX: number = 0,
        public coordY: number = 0,
        public offsetX: number = 0,
        public offsetY: number = 0,
        public translateX: number = 0,
        public translateY: number = 0,

        public altitude: number = 0,
        public gimbalAngle: number = 0,
        public pointActionCode: PointActionCode = PointActionCode.NON_STOP
    ) {}

    public getOffset(isMoving: boolean): Offset {
        let x: number = (isMoving)? this.offsetX + this.translateX : this.offsetX;
        let y: number = (isMoving)? this.offsetY + this.translateY : this.offsetY;

        return new Offset(x, y);
    }

    public resetOffsetByTranslate(): void {
        this.offsetX += this.translateX;
        this.offsetY += this.translateY;

        this.translateX = 0;
        this.translateY = 0;
    }

    public toOffset(): Offset {
        return new Offset(this.offsetX + this.translateX, this.offsetY + this.translateY);
    }
}
