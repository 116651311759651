import { MonthShortName } from './monthShortName';

export class FlightRecordOption {
    constructor(
        public year: number = 0,
        public month: MonthShortName = MonthShortName.Jan,
        public groupSeqList: number[] = []) {

        this.initDate();
    }

    public initDate(): void {
        this.year = new Date().getFullYear();
        this.month = new Date().getMonth();
    }
}
