import { ElementRef } from '@angular/core';
import { Offset } from '../mission/offset';
import { Point } from '../mission/point';
import { Figure } from './figure';

declare const isect: any;

export class PolygonFigure extends Figure {
    private diffRotateX: number = 0;

    public changeAngle(angle: number): void {
        this.angle -= angle;
    }

    public preProcessForMoveCornorPoint(index: number): void {
        this.backup();
    }

    public onCornorPointMove(index: number): void {
        let translateValue: Offset = this.getCornorPointTranslateValue(index);
        this.cornorPointList[index].translateX = translateValue.x;
        this.cornorPointList[index].translateY = translateValue.y;

        this.resetCenterPoint();
    }

    public onCornorPointMoveEnd(index: number): void {
        if (this.hasIntersections()) {
            this.restore();
        }

        this.cornorPointElList.toArray()[index].nativeElement.style.transform = "translate(0px, 0px)";
        this.cornorPointList[index].resetOffsetByTranslate();
        this.resetCornorPointCoord(index);
        this.setCenterPoint();
    }

    public preProcessForMoveCenterPoint(index: number): void {
        this.backup();

        let point: Point = new Point();
        point.offsetX = this.centerPointList[index].x;
        point.offsetY = this.centerPointList[index].y;

        this.addCornorPoint(index, point);
    }

    public onCenterPointMove(index: number, event: any): void {
        let pointIndex: number = index + 1;
        let translateValue: Offset = this.getCenterPointTranslateValue(index);

        this.cornorPointList[pointIndex].translateX = translateValue.x;
        this.cornorPointList[pointIndex].translateY = translateValue.y;

        let pointEl: ElementRef = this.cornorPointElList.toArray()[pointIndex];
        pointEl.nativeElement.style.transform
            = "translate(" + this.cornorPointList[pointIndex].translateX + "px, " + this.cornorPointList[pointIndex].translateY + "px)";
    }

    public onCenterPointMoveEnd(): void {
        if (this.hasIntersections()) {
            this.restore();
        }

        this.centerPointElList.toArray().forEach(item => {
            item.nativeElement.style.transform = "translate(0px. 0px)";
        });

        this.cornorPointElList.toArray().forEach(point => {
            point.nativeElement.style.transform = "translate(0px, 0px)";
        });

        this.cornorPointList.forEach((point, index) => {
            point.resetOffsetByTranslate();
        });

        this.resetCornorPointListCoord();
        this.setCenterPoint();
    }

    private hasIntersections(): boolean {
        let lineList: any = [];
        let nextPoint: Point;

        this.cornorPointList.forEach((point, index) => {
            if (index == (this.cornorPointList.length - 1)) {
                nextPoint = this.cornorPointList[0];
            } else {
                nextPoint = this.cornorPointList[index + 1];
            }

            let offset: Offset = point.toOffset();
            let nextOffset: Offset = nextPoint.toOffset();

            lineList.push({
                from: { x: offset.x, y: offset.y },
                to: { x: nextOffset.x, y: nextOffset.y }
            });
        });

        let sweepLine: any = isect.sweep(lineList);
        return (sweepLine.run().length == this.cornorPointList.length) ? false : true;
    }

    public onRotatePointMoving(event: any): void {
        this.diffRotateX = (event.x) - this.diffRotateX;
        this.changeAngle(this.diffRotateX);
        this.diffRotateX = event.x;
    }

    public onRotatePointMoveEnd(): void {
        this.diffRotateX = 0;
        super.onRotatePointMoveEnd();
    }

    // not used
    public selectPoint(index: number): void { }
    public deletePoint(): void { }
    public getSelectedPointIndex(): number { return null; }
}