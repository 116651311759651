import {Injectable} from "@angular/core";
import {ResponseDto} from "../../../../../dto/response.dto";
import {GroupDto} from "../../../../../dto/group/group.dto";
import {Observable} from "rxjs";
import {APIUrl} from "../../../../../constants/api.url";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class GroupService {
    constructor(private httpClient: HttpClient) {}

    public getData(groupSeq: number): Observable<GroupDto> {
        return new Observable<GroupDto>(observer => {
            this.httpClient.get<ResponseDto<GroupDto>>(APIUrl.GROUP_DATA(groupSeq)).subscribe(
                response => {
                    if (response.isSuccess) {
                        observer.next(response.data);
                    } else {
                        observer.next(new GroupDto());
                    }
                }
            );
        });
    }

    public getTreeData(accountSeq: number): Observable<GroupDto> {
        return new Observable<GroupDto>(observer => {
            this.httpClient.get<ResponseDto<GroupDto>>(APIUrl.GROUP_TREE(accountSeq)).subscribe(
                response => {
                    if (response.isSuccess) {
                        observer.next(response.data);
                    }
                }
            );
        });
    }

    public create(groupDto: GroupDto): Observable<void> {
        return new Observable<void>(observer => {
            this.httpClient.put<ResponseDto<string>>(APIUrl.MAKE_GROUP(groupDto.managerSeq), groupDto).subscribe(
                response => {
                    if (response.isSuccess) {
                        observer.next();
                    }
                }
            );
        });
    }

    public edit(groupDto: GroupDto): Observable<void> {
        return new Observable<void>(observer => {
            this.httpClient.post<ResponseDto<string>>(APIUrl.GROUP, groupDto).subscribe(
                response => {
                    if (response.isSuccess) {
                        observer.next();
                    }
                }
            );
        });
    }

    public delete(groupSeq: number): Observable<void> {
        return new Observable<void>(observer => {
            this.httpClient.delete<ResponseDto<string>>(APIUrl.DELETE_GROUP(groupSeq)).subscribe(
                response => {
                    if (response.isSuccess) {
                        observer.next();
                    }
                }
            );
        });
    }
}
