import {NgModule} from "@angular/core";
import {GroupTreeComponent} from "./group-tree.component";
import {CommonModule} from "@angular/common";
import {GroupTreeService} from "./service/group-tree.service";

@NgModule({
    declarations: [GroupTreeComponent],
    imports: [CommonModule],
    providers: [GroupTreeService],
    exports: [GroupTreeComponent]
})
export class GroupTreeModule {}
