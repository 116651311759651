import {Component, EventEmitter, OnInit, Output, ViewChild} from "@angular/core";
import {GroupTreeComponent} from "../../../common/group-tree/group-tree.component";
import {FlightHistoryService} from "./service/flight-history.service";
import {FlightRecordResult} from "../../../../dto/statistics/flightRecord/flightRecordResult";
import {FlightRecordOption} from "../data/flightRecordOption";
import {MonthShortName} from "../data/monthShortName";
import {AccountService} from "../../../../service/account.service";
import {FlightRecordData} from "../../../../dto/statistics/flightRecord/flightRecordData";
import {Day} from "../data/day";
import {MissionType} from "../../../../dto/mission/setting/mission.type";
import {DetailDialogComponent} from "./detail/detail-dialog.component";

@Component({
    selector: "flight-history",
    templateUrl: "./view/flight-history.component.html",
    styleUrls: ["./view/flight-history.component.css"]
})
export class FlightHistoryComponent implements OnInit {
    @ViewChild("groupTree") public groupTreeComponent: GroupTreeComponent;
    @ViewChild("detailDialog") public detailDialogComponent: DetailDialogComponent;

    public flightRecordOption: FlightRecordOption;
    public flightHistory: FlightRecordResult;

    public years: number[];
    public monthShortName: typeof MonthShortName;
    private today: Day;

    @Output() public closeEvent: EventEmitter<void>;

    constructor(
        private flightHistoryService: FlightHistoryService,
        private accountService: AccountService) {

        this.flightRecordOption = new FlightRecordOption();
        this.flightHistory = new FlightRecordResult();

        this.years = [];
        this.monthShortName = MonthShortName;
        this.today = new Day();

        this.closeEvent = new EventEmitter<void>();
    }

    ngOnInit() {
        this.getYearRange();
    }

    public getFlightHistory(): void {
        this.flightRecordOption.groupSeqList = this.groupTreeComponent.getCheckedGroupSeqs();

        this.flightHistoryService.getFlightHistory(this.flightRecordOption).subscribe(
            flightHistory => {
                this.flightHistory = flightHistory;
            }
        );
    }

    private getYearRange(): void {
        this.flightHistoryService.getYearRange(this.accountService.getAccount().seq).subscribe(
            yearRangeData => {
                this.years = [];

                for (let i = yearRangeData.minYear; i <= yearRangeData.maxYear; i++) {
                    this.years.push(i);
                }
            }
        );
    }

    public prevMonth(): void {
        if (this.flightRecordOption.month == 0) {
            if (this.years.indexOf(this.flightRecordOption.year - 1) != -1) {
                this.flightRecordOption.year--;
                this.flightRecordOption.month = 11;
            }
        } else {
            this.flightRecordOption.month--;
        }

        this.getFlightHistory();
    }

    public nextMonth(): void {
        if (this.flightRecordOption.month == 11) {
            if (this.years.indexOf(this.flightRecordOption.year + 1) != -1) {
                this.flightRecordOption.year++;
                this.flightRecordOption.month = 0;
            }
        } else {
            this.flightRecordOption.month++;
        }

        this.getFlightHistory();
    }

    public setToday(): void {
        let today = new Date();
        this.flightRecordOption.year = today.getFullYear();
        this.flightRecordOption.month = today.getMonth();

        this.getFlightHistory();
    }

    public isToday(data: FlightRecordData): boolean {
        if (data.isOtherMonth) {
            return false;
        }

        if (data.day == this.today.day
            && this.flightRecordOption.year == this.today.year
            && this.flightRecordOption.month == this.today.month) {
            return true;
        }

        return false;
    }

    public getMissionListSliceValue(): number {
        return (this.flightHistory.weekSize > 5)? 2 : 3;
    }

    public getMissionTypeClassName(typeCode: number): string {
        return MissionType[typeCode];
    }

    public showDetailDialog(data: FlightRecordData): void {
        if (data.missionDataList.length == 0) {
            return;
        }

        this.detailDialogComponent.setData(this.getSelectedDay(data.day), data.missionDataList);
    }

    private getSelectedDay(day: number): string {
        return this.flightRecordOption.year + "-" + (this.flightRecordOption.month + 1) + "-" + day;
    }

    public close(): void {
        this.closeEvent.emit();
    }
}
