import { Directive, ElementRef, OnInit } from "@angular/core";

declare var $: any;

@Directive({
    selector: 'scrollbar-statistics',
    host: {'class': 'side_wrapper-dialogue'}
})
export class StatisticsScrollbarDirective implements OnInit {
    element: ElementRef;

    constructor(element: ElementRef) {
        this.element = element;
    }

    ngOnInit() {
        $(this.element.nativeElement).mCustomScrollbar({
            theme: "minimal-dark",
            scrollButtons: {enable:true}
        });
    }
}