import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {JoinComponent} from "./base/join.component";
import {TosComponent} from "./phase/tos/tos.component";
import {DoneComponent} from "./phase/done/done.component";
import {JoinInfoModule} from "./phase/info/join-info.module";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [
        JoinComponent,
        TosComponent,
        DoneComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        JoinInfoModule,
        TranslateModule
    ],
    exports: [
        JoinComponent
    ]
})
export class JoinModule {}
