import { AddressDto } from 'src/app/dto/mission/weather/address.dto';
import { Offset } from '../mission/offset';
import { Point } from '../mission/point';
import { MapListener } from './mapListener';

export abstract class Map {
    public map: any;
    // protected map: any;
    protected centerPoint: Point;
    protected addressDto: AddressDto;

    protected mapListener: MapListener;

    constructor() {
        this.mapListener = new MapListener();
    }

    public getCenterPoint(): Point {
        return this.centerPoint;
    }

    public setCenterPoint(centerPoint: Point): void {
        this.centerPoint = centerPoint;
    }

    abstract loadMap(containerId: string, latitude: number, longitude: number, zoomLevel: number): void;
    abstract setMapType(isNormal: boolean): void;
    abstract setCenterOffset(): void;
    abstract moveTo(point: Point): void;
    // temp
    abstract moveToBoundary(leftBottom: Point, rightTop: Point): void;
    abstract getDistance(point1: Point, point2: Point): number;
    abstract clearEventListeners(): void;
    abstract setClickEventListener(callback: any): void;
    abstract setMapMoveEventListener(callback: any): void;
    abstract setZoomingEventListener(callback: any): void;
    abstract setZoomChangedEventListener(callback: any): void;
    abstract getZoomLevel(): number;
    abstract setZoomLevel(level: number): void;
    abstract setMouseUpEventListener(callback: any): void;

    abstract convertPointToOffset(point: Point): Offset;
    abstract convertCoordToOffset(point: Offset): Offset;
    abstract convertOffsetToCoord(offset: Offset): Offset;
    abstract getContainerOffset(): Offset;
    abstract getOffsetXPerMeter(lngPerMeter: number): number;
    abstract getOffsetYPerMeter(latPerMeter: number): number;
    abstract getRectanglePointList(latPerMeter: number, lngPerMeter: number, radius: number): Point[];

    abstract getAddressOfCenterPoint(callback: any): void;
    abstract getAddreessOfPoint(callback: any, latitude: number, longitude: number);

    abstract getMeterPerPixel(): number;
}
