export class MqttConfig {
    public static TOPIC_PREFIX: string = "igis-drone/web/";

    // ent
    public static BROKER_PORT: number = 8883;
    public static BROKER_URL: string = "gov-cs.dfos.kr";

    // dev
    // public static BROKER_PORT: number = 9001;
    // public static BROKER_URL: string = "192.168.0.4";
}
