export enum MissionSearchCode {
    Name, Type
}

export namespace MissionSearchCode {
    export function getMissionSearchCodeLanguage(missionSearchCode: MissionSearchCode): string {
        if (missionSearchCode == MissionSearchCode.Name) {
            return "common.label.name";
        }

        return "common.label.type-name";
    }

    export function getAll(): MissionSearchCode[] {
        return [MissionSearchCode.Name, MissionSearchCode.Type];
    }
}
