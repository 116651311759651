import { Cluster } from './cluster';

export class Matrix {
    constructor(
        public latitude: number = 0,
        public longitude: number = 0,
        public cluster: Cluster = new Cluster(),
        public machineList: number[] = [],

        // temp
        public offsetX: number = 0,
        public offsetY: number = 0,
        public width: number = 0,
        public height: number = 0
    ) {}
}