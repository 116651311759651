import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {FlightRecordOption} from "../../data/flightRecordOption";
import {Observable} from "rxjs";
import {FlightRecordResult} from "../../../../../dto/statistics/flightRecord/flightRecordResult";
import {ResponseDto} from "../../../../../dto/response.dto";
import {APIUrl} from "../../../../../constants/api.url";
import {StatisticsYearRangeData} from "../../../../../dto/statistics/statisticsYearRangeData";

@Injectable()
export class FlightHistoryService {
    constructor(private httpClient: HttpClient) {}

    public getFlightHistory(flightRecordOption: FlightRecordOption): Observable<FlightRecordResult> {
        return new Observable<FlightRecordResult>(observer => {
            this.httpClient.post<ResponseDto<FlightRecordResult>>(APIUrl.FLIGHTRECORD_DATA, flightRecordOption).subscribe(
                response => {
                    if (response.isSuccess) {
                        observer.next(response.data);
                    }
                }
            );
        });
    }

    public getYearRange(accountSeq: number): Observable<StatisticsYearRangeData> {
        return new Observable<StatisticsYearRangeData>(observer => {
            this.httpClient.get<ResponseDto<StatisticsYearRangeData>>(APIUrl.STATISTICS_YEAR_RANGE(accountSeq)).subscribe(
                response => {
                    if (response.isSuccess) {
                        observer.next(response.data);
                    }
                }
            );
        });
    }
}
