import {Map} from "../map/map";
import {CrossLine} from "../mission/crossline";
import {Offset} from "../mission/offset";
import {Rectangle} from "./rectangle";
import {WaypointOption} from "./waypointOption";
import {BufferOp, BufferParameters} from "jsts/org/locationtech/jts/operation/buffer";
import {Coordinate, Geometry, GeometryFactory, LineString, Polygon} from "jsts/org/locationtech/jts/geom";
import {HeightRatio, WidthRatio} from "src/app/dto/mission/setting/aspectRatio.type";

export class WaypointService {
    private mapService: Map;

    private waypointList: Offset[];
    private waypointOption: WaypointOption;
    private outerRectangle: Rectangle;
    private centerOffset: Offset;

    private angle: number;

    private verticalCrossLineList: CrossLine[];
    private horizonCrossLineList: CrossLine[];

    private cameraCoverageWidth: number;
    private cameraCoverageHeight: number;

    private frontGap: number;
    private sideGap: number;

    constructor(mapService: Map) {
        this.mapService = mapService;
    }

    public make(waypointOption: WaypointOption): Offset[] {
        this.waypointList = [];
        this.waypointOption = waypointOption;

        this.setCameraCoverage();
        this.setOuterRectangle();

        this.centerOffset = this.outerRectangle.getCenterOffset();

        this.angle = (this.waypointOption.polygonAngle + this.waypointOption.missionAngle) % 360;
        waypointOption.pointList = this.getRotatedOffset(waypointOption.pointList, -this.angle);

        this.setOuterRectangle();
        this.setCrossLineList();
        this.findWaypoint();

        return this.waypointList;
    }

    private setCameraCoverage(): void {
        let width: number = 2 * (this.waypointOption.altitude / Math.tan((90 - (this.waypointOption.fov / 2)) * Math.PI / 180));
        let height: number = 2 * (this.waypointOption.altitude / Math.tan((90 - (this.waypointOption.fov / 2)) * Math.PI / 180));

        let widthRatio: number = WidthRatio.get(this.waypointOption.aspectRatioType);
        let heightRatio: number = HeightRatio.get(this.waypointOption.aspectRatioType);
        let diagonalRatio: number = Math.sqrt(Math.pow(widthRatio, 2) + Math.pow(heightRatio, 2));

        width *= (widthRatio / diagonalRatio);
        height *= (heightRatio / diagonalRatio);

        let xPerMeter: number = this.mapService.getOffsetXPerMeter(this.waypointOption.latLng.getLngPerMeter());
        let yPerMeter: number = this.mapService.getOffsetYPerMeter(this.waypointOption.latLng.getLatPerMeter());

        this.cameraCoverageWidth = width * xPerMeter;
        this.cameraCoverageHeight = height * yPerMeter;
    }

    private setOuterRectangle(): void {
        let top: number = Number.MAX_VALUE;
        let bottom: number = Number.MIN_VALUE;
        let left: number = Number.MAX_VALUE;
        let right: number = Number.MIN_VALUE;

        this.waypointOption.pointList.forEach(point => {
            top = Math.min(point.y, top);
            bottom = Math.max(point.y, bottom);
            left = Math.min(point.x, left);
            right = Math.max(point.x, right);
        });

        this.outerRectangle = new Rectangle(top, bottom, left, right);
    }

    private getRotatedOffset(pointList: Offset[], angle: number): Offset[] {
        let radian: number = angle * Math.PI / 180;
        let cos: number = +Math.cos(radian).toFixed(15);
        let sin: number = +Math.sin(radian).toFixed(15);

        pointList.forEach(point => {
            let rotateX: number = (point.x - this.centerOffset.x) * cos - (point.y - this.centerOffset.y) * sin + this.centerOffset.x;
            let rotateY: number = (point.x - this.centerOffset.x) * sin + (point.y - this.centerOffset.y) * cos + this.centerOffset.y;

            point.x = rotateX;
            point.y = rotateY;
        });

        return pointList;
    }

    private setCrossLineList(): void {
        this.sideGap = this.cameraCoverageWidth * (1 - this.waypointOption.sidelap);
        let verticalLineCount: number = Math.floor((this.outerRectangle.getWidth() / this.sideGap) + 2);
        let startX: number = this.outerRectangle.left - (Math.abs(this.outerRectangle.getWidth() - (this.sideGap * (verticalLineCount - 1))) / 2.0);
        let endX: number = startX + this.sideGap * (verticalLineCount - 1);

        this.frontGap = this.cameraCoverageHeight * (1 - this.waypointOption.frontlap);
        let horizonLineCount: number = Math.floor((this.outerRectangle.getHeight() / this.frontGap) + 2);
        let startY: number = this.outerRectangle.top - (Math.abs(this.outerRectangle.getHeight() - (this.frontGap * (horizonLineCount - 1))) / 2.0);
        let endY: number = startY + this.frontGap * (horizonLineCount - 1);

        this.verticalCrossLineList = [];
        this.horizonCrossLineList = [];

        for (let i: number = 0;i < verticalLineCount;i++) {
            let x: number = startX + this.sideGap * i;

            this.verticalCrossLineList.push(new CrossLine(
                new Offset(x, endY), new Offset(x, startY)
            ));
        }

        for (let i: number = 0;i < horizonLineCount;i++) {
            let y: number = startY + this.frontGap * i;

            this.horizonCrossLineList.push(new CrossLine(
                new Offset(startX, y), new Offset(endX, y)
            ));
        }
    }

    private findWaypoint(): void {
        let lastWaypoint: Offset = null;
        let bufferedPointList: Offset[] = this.getBufferedPointList();

        this.verticalCrossLineList.forEach(vertical => {
            vertical.checkIntersectPoint(this.horizonCrossLineList);
            vertical.intersectList = this.isValidPoint(bufferedPointList, vertical.intersectList);
            vertical.sort(lastWaypoint);

            if (vertical.intersectList.length > 0) {
                vertical.intersectList[0].isEndpoint = true;
                vertical.intersectList[vertical.intersectList.length - 1].isEndpoint = true;

                this.waypointList = this.waypointList.concat(vertical.intersectList);
                lastWaypoint = this.waypointList[this.waypointList.length - 1];
            }
        });

        this.waypointList = this.getRotatedOffset(this.waypointList, this.angle);
    }

    private getBufferedPointList(): Offset[] {
        let margin: number = Math.max(this.frontGap, this.sideGap);
        let parameter: BufferParameters = new BufferParameters(BufferParameters.DEFAULT_QUADRANT_SEGMENTS, BufferParameters.CAP_FLAT, BufferParameters.JOIN_MITRE, BufferParameters.DEFAULT_MITRE_LIMIT);

        let coordinates: Coordinate[] = this.getCoordinatesList();
        let geometryFactory: GeometryFactory = new GeometryFactory();
        let geometry: Geometry = geometryFactory.createPolygon(coordinates);

        let bufferedGeometry: Geometry = BufferOp.bufferOp(geometry, margin, parameter);
        if (bufferedGeometry instanceof Polygon) {
            let lineString: LineString = bufferedGeometry.getExteriorRing();

            return this.convertToOffsetList(lineString.getCoordinates());
        }

        return null;
    }

    private getCoordinatesList(): Coordinate[] {
        let coordinateList: Coordinate[] = [];

        this.waypointOption.pointList.forEach(point => {
            coordinateList.push(new Coordinate(point.x, point.y));
        });

        let firstPoint: Coordinate = new Coordinate(this.waypointOption.pointList[0].x, this.waypointOption.pointList[0].y);
        coordinateList.push(firstPoint);

        return coordinateList;
    }

    private convertToOffsetList(coordinateList: Coordinate[]): Offset[] {
        let offsetList: Offset[] = [];

        for (let i: number = 0;i < coordinateList.length - 1;i++) {
            offsetList.push(new Offset(coordinateList[i].getX(), coordinateList[i].getY()));
        }

        let resultList: Offset[] = [];
        resultList.push(offsetList.shift());
        resultList = resultList.concat(offsetList.reverse());

        return resultList;
    }

    private isValidPoint(pointList: Offset[], intersectList: Offset[]): Offset[] {
        let resultList: Offset[] = [];

        intersectList.forEach(intersection => {
            if (this.isOnTheLine(pointList, intersection) || this.isOnThePolygon(pointList, intersection)) {
                resultList.push(intersection);
            }
        });

        return resultList;
    }

    private isOnTheLine(pointList: Offset[], intersection: Offset): boolean {
        let tolerance: number = 1e-8;
        let isFind: boolean = false;

        pointList.forEach((point, index) => {
            if (!isFind && index < pointList.length - 1) {
                let nextPoint: Offset = pointList[index + 1];
                let lineDistance: number = this.getDistance(point, nextPoint);
                let itopDistance: number = this.getDistance(intersection, point);
                let itonpDistance: number = this.getDistance(intersection, nextPoint);
                let diff: number = lineDistance - itopDistance - itonpDistance;

                if (Math.abs(diff) <= tolerance) {
                    isFind = true;
                }
            }
        });

        return isFind;
    }

    private getDistance(point1: Offset, point2: Offset): number {
        return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
    }

    private isOnThePolygon(pointList: Offset[], intersection: Offset): boolean {
        let isFirst: boolean = true;
        let isInside: boolean = false;
        let minX: number = 0;
        let minY: number = 0;
        let maxX: number = 0;
        let maxY: number = 0;

        pointList.forEach((point, index) => {
            if (isFirst) {
                isFirst = false;

                minX = point.x;
                minY = point.y;
                maxX = point.x;
                maxY = point.y;
            } else {
                minX = Math.min(minX, point.x);
                minY = Math.min(minY, point.y);
                maxX = Math.max(maxX, point.x);
                maxY = Math.max(maxY, point.y);
            }
        });

        if (intersection.x < minX || intersection.y < minY || intersection.x > maxX || intersection.y > maxY) {
            return false;
        }

        pointList.forEach((point, index) => {
            let nextPoint: Offset;
            if (index < pointList.length - 1) {
                nextPoint = pointList[index + 1];
            } else {
                nextPoint = pointList[0];
            }

            if ((point.y > intersection.y) != (nextPoint.y > intersection.y) && this.isInside(intersection, point, nextPoint)) {
                isInside = !isInside;
            }
        });

        return isInside;
    }

    private isInside(intersection: Offset, point: Offset, nextPoint: Offset): boolean {
        return intersection.x < (nextPoint.x - point.x) * (intersection.y - point.y) / (nextPoint.y - point.y) + point.x;
    }
}
