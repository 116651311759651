import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AccountService} from "../../../../../service/account.service";
import {Observable} from "rxjs";
import {SummaryDto} from "../../../../../dto/statistics/summaryDto";
import {APIUrl} from "../../../../../constants/api.url";
import {ResponseDto} from "../../../../../dto/response.dto";

@Injectable()
export class StatisticsSummaryService {
    constructor(private httpClient: HttpClient, private accountService: AccountService) {}

    public getData(): Observable<SummaryDto> {
        return new Observable<SummaryDto>(observer => {
            this.httpClient.get<ResponseDto<SummaryDto>>(APIUrl.STATISTICS_SUMMARY(this.accountService.getAccount().seq)).subscribe(
                response => {
                    if (response.isSuccess) {
                        observer.next(response.data);
                    }
                }
            )
        });
    }
}

