import { AccountDto } from '../account/account.dto';

export class GroupDto {
    constructor(
        public seq: number = 0,
        public name: string = "",
        public code: string = "",
        public upperGroupSeq: number = 0,

        public managerSeq: number = 0,

        public isChecked: boolean = false,

        public lowerGroupList: GroupDto[] = [],
        public includedUserList: AccountDto[] = [],

        public totalMachineCount: number = 0,
        public onlineMachineCount: number = 0
    ) {}

    public init(): void {
        let tempLowerGroupList: GroupDto[] = Object.assign([], this.lowerGroupList);
        this.lowerGroupList = [];

        tempLowerGroupList.forEach(lowerGroup => {
            let group: GroupDto = Object.assign(new GroupDto(), lowerGroup);
            group.init();

            this.lowerGroupList.push(group);
        });
    }

    public setCheckedOne(): void {
        this.isChecked = !this.isChecked;
    }

    public setChecked(isChecked: boolean, exceptGroupSeq: number = 0): void {
        if (this.seq != exceptGroupSeq) {
            this.isChecked = isChecked;
        }

        if (this.lowerGroupList.length > 0) {
            this.lowerGroupList.forEach(lowerGroup => {
                lowerGroup.setChecked(isChecked, exceptGroupSeq);
            });
        }

        if (this.includedUserList != undefined && this.includedUserList.length > 0) {
            this.includedUserList.forEach(user => {
                user.isChecked = isChecked;
            });
        }
    }

    public getCheckedSeqList(): number[] {
        let result: number[] = [];

        if (this.isChecked) {
            result.push(this.seq);
        }

        this.lowerGroupList.forEach(lowerGroup => {
            result = result.concat(lowerGroup.getCheckedSeqList());
        });

        return result;
    }
}
