import {Component, EventEmitter, Output, ViewChild} from "@angular/core";
import {GroupTreeComponent} from "../../../common/group-tree/group-tree.component";
import {GroupDto} from "../../../../dto/group/group.dto";

@Component({
    selector: "group-select",
    templateUrl: "./view/group-select.component.html"
})
export class GroupSelectComponent {
    public isVisible: boolean;

    @ViewChild(GroupTreeComponent) public groupTreeComponent: GroupTreeComponent;
    @Output() selectedEvent: EventEmitter<GroupDto>

    constructor() {
        this.isVisible = false;
        this.selectedEvent = new EventEmitter<GroupDto>();
    }

    public show(): void {
        this.isVisible = true;
    }

    public select(): void {
        let checkedGroup: GroupDto = this.groupTreeComponent.getCheckedGroup();
        if (checkedGroup) {
            this.selectedEvent.emit(checkedGroup);
            this.close();
        }
    }

    public close(): void {
        this.isVisible = false;
    }
}
