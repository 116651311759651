import { Offset } from '../mission/offset';
import { Point } from '../mission/point';
import { Figure } from './figure';

export class GridFigure extends Figure {
    private nextPointRadian: number;
    private prevLineSlope: number;
    private prevCenterPointOffset: Offset = new Offset();

    public preProcessForMoveCornorPoint(index: number): void {
        let preOffset: Offset = this.cornorPointList[this.getPrePointIndex(index)].toOffset();
        let nextOffset: Offset = this.cornorPointList[this.getNextPointIndex(index)].toOffset();

        let diagonalPoint: Point = this.cornorPointList[this.getNextPointIndex(this.getNextPointIndex(index))];
        let diagonalOffset: Offset = diagonalPoint.toOffset();

        preOffset.y = Math.abs(preOffset.y);
        nextOffset.y = Math.abs(nextOffset.y);
        diagonalOffset.y = Math.abs(diagonalOffset.y);

        this.nextPointRadian = Math.atan2((nextOffset.y - diagonalPoint.offsetY), (nextOffset.x - diagonalPoint.offsetX));
    }

    public onCornorPointMove(index: number): void {
        let translateValue: Offset = this.getCornorPointTranslateValue(index);
        this.cornorPointList[index].translateX = translateValue.x;
        this.cornorPointList[index].translateY = translateValue.y;

        let nowPoint: Point = this.cornorPointList[index];
        let nowOffset: Offset = nowPoint.toOffset();

        let preIndex: number = this.getPrePointIndex(index);
        let prePoint: Point = this.cornorPointList[preIndex];
        let nextIndex: number = this.getNextPointIndex(index);
        let nextPoint: Point = this.cornorPointList[nextIndex];

        let diagonalPoint: Point = this.cornorPointList[this.getNextPointIndex(nextIndex)];
        let diagonalOffset: Offset = diagonalPoint.toOffset();

        let nowRadian: number = Math.atan2((nowOffset.y - diagonalOffset.y), (nowOffset.x - diagonalOffset.x));
        let nowDistance: number = Math.sqrt(Math.pow((nowOffset.x - diagonalOffset.x), 2) + Math.pow((nowOffset.y - diagonalOffset.y), 2));
        let nextDistance: number = nowDistance * Math.cos(nowRadian - this.nextPointRadian);

        let nextOffset: Offset = new Offset();
        nextOffset.x = nextDistance * Math.cos(this.nextPointRadian) + diagonalOffset.x;
        nextOffset.y = nextDistance * Math.sin(this.nextPointRadian) + diagonalOffset.y;

        let midOffset: Offset = new Offset();
        midOffset.x = diagonalOffset.x + (nowOffset.x - diagonalOffset.x) / 2;
        midOffset.y = diagonalOffset.y + (nowOffset.y - diagonalOffset.y) / 2;

        let diffOffset: Offset = new Offset(nextOffset.x - midOffset.x, nextOffset.y - midOffset.y);

        let preOffset: Offset = new Offset();
        preOffset.x = nextOffset.x - diffOffset.x * 2;
        preOffset.y = nextOffset.y - diffOffset.y * 2;

        prePoint.translateX = preOffset.x - prePoint.offsetX;
        prePoint.translateY = preOffset.y - prePoint.offsetY;
        nextPoint.translateX = nextOffset.x - nextPoint.offsetX;
        nextPoint.translateY = nextOffset.y - nextPoint.offsetY;

        this.cornorPointElList.toArray()[preIndex].nativeElement.style.transform
            = "translate(" + prePoint.translateX + "px, " + prePoint.translateY + "px)";
        this.cornorPointElList.toArray()[nextIndex].nativeElement.style.transform
            = "translate(" + nextPoint.translateX + "px, " + nextPoint.translateY + "px)";

        this.resetCenterPoint();
    }

    public onCornorPointMoveEnd(index: number): void {
        this.cornorPointElList.toArray()[index].nativeElement.style.transform = "translate(0px, 0px)";
        this.cornorPointList[index].resetOffsetByTranslate();

        let preIndex: number = this.getPrePointIndex(index);
        let nextIndex: number = this.getNextPointIndex(index);

        this.cornorPointElList.toArray()[preIndex].nativeElement.style.transform = "translate(0px, 0px)";
        this.cornorPointElList.toArray()[nextIndex].nativeElement.style.transform = "translate(0px, 0px)";
        this.cornorPointList[preIndex].resetOffsetByTranslate();
        this.cornorPointList[nextIndex].resetOffsetByTranslate();

        this.resetCornorPointCoord(index);
        this.resetCornorPointCoord(preIndex);
        this.resetCornorPointCoord(nextIndex);
    }

    public preProcessForMoveCenterPoint(index: number): void {
        let preOffset: Offset = this.cornorPointList[this.getPrePointIndex(index)].toOffset();
        let nowOffset: Offset = this.cornorPointList[index].toOffset();

        this.prevLineSlope = (nowOffset.y - preOffset.y) / (nowOffset.x - preOffset.x);
    }

    public onCenterPointMove(index: number, event: any): void {
        let cpMoveDiffX: number = event.x - this.prevCenterPointOffset.x;
        let cpMoveDiffY: number = event.y - this.prevCenterPointOffset.y;

        this.prevCenterPointOffset.x = event.x;
        this.prevCenterPointOffset.y = event.y;

        let leftPoint: Point = this.cornorPointList[index];
        let leftPrePoint: Point = this.cornorPointList[this.getPrePointIndex(index)];

        let nextIndex: number = this.getNextPointIndex(index);
        let nextPoint: Point = this.cornorPointList[nextIndex];

        let leftOffset: Offset = leftPoint.toOffset();
        let leftPreOffset: Offset = leftPrePoint.toOffset();

        if (!isFinite(this.prevLineSlope)) {
            leftOffset.y += cpMoveDiffY;
        } else if (Math.abs(this.prevLineSlope) < 1) {
            leftOffset.x += cpMoveDiffX;
            leftOffset.y = this.prevLineSlope * leftOffset.x - this.prevLineSlope * leftPreOffset.x + leftPreOffset.y;
        } else {
            leftOffset.y += cpMoveDiffY;
            leftOffset.x = (leftOffset.y - leftPreOffset.y + this.prevLineSlope * leftPreOffset.x) / this.prevLineSlope;
        }

        let midOffset: Offset = new Offset();
        let diagonalPoint: Point = this.cornorPointList[this.getNextPointIndex(this.getNextPointIndex(index))];
        let diagonalOffset: Offset = diagonalPoint.toOffset();

        midOffset.x = diagonalOffset.x + (leftOffset.x - diagonalOffset.x) / 2;
        midOffset.y = diagonalOffset.y + (leftOffset.y - diagonalOffset.y) / 2;

        let diffOffset: Offset = new Offset(leftPreOffset.x - midOffset.x, leftPreOffset.y - midOffset.y);
        let nextOffset: Offset = new Offset(leftPreOffset.x - diffOffset.x * 2, leftPreOffset.y - diffOffset.y * 2);

        leftPoint.translateX = leftOffset.x - leftPoint.offsetX;
        leftPoint.translateY = leftOffset.y - leftPoint.offsetY;
        nextPoint.translateX = nextOffset.x - nextPoint.offsetX;
        nextPoint.translateY = nextOffset.y - nextPoint.offsetY;

        this.cornorPointElList.toArray()[index].nativeElement.style.transform
            = "translate(" + leftPoint.translateX + "px, " + leftPoint.translateY + "px)";
        this.cornorPointElList.toArray()[nextIndex].nativeElement.style.transform
            = "translate(" + nextPoint.translateX + "px, " + nextPoint.translateY + "px)";

        this.resetCenterPoint(index);
    }

    public onCenterPointMoveEnd(): void {
        this.prevCenterPointOffset = new Offset();
    
        this.centerPointElList.toArray().forEach(item => {
          item.nativeElement.style.transform = "translate(0px. 0px)";
        });
    
        this.cornorPointElList.toArray().forEach(point => {
          point.nativeElement.style.transform = "translate(0px, 0px)";
        });
    
        this.cornorPointList.forEach(point => {
          point.resetOffsetByTranslate();
        });
    
        this.resetCornorPointListCoord();
        this.resetCenterPoint();
    }

    private getPrePointIndex(index: number): number {
        return (index == 0) ? this.cornorPointList.length - 1 : index - 1;
    }

    private getNextPointIndex(index: number): number {
        return (index == (this.cornorPointList.length - 1)) ? 0 : index + 1;
    }
    
    public onRotatePointMoving(event: any): void {
        super.onRotatePointMoving(event);
        this.resetCenterPoint();
    }

    // not used
    public changeAngle(angle: number): void { }
    public selectPoint(index: number): void { }
    public deletePoint(): void { }
    public getSelectedPointIndex(): number { return null; }
}