export class Day {
    constructor(
        public year: number = 0,
        public month: number = 0,
        public day: number = 0
    ) {
        let today: Date = new Date();
        this.year = today.getFullYear();
        this.month = today.getMonth();
        this.day = today.getDate();
    }
}