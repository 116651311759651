export class StreamingConfig {
    // video live streaming
    // public static url: any = (accountEmail) => `rtmp://dcs.igiskorea.com:1935/live/${accountEmail}`;
    // public static fhdUrl: any = (accountEmail) => `rtmp://dcs.igiskorea.com:1935/live/mp4:${accountEmail}_1080p`;
    // public static hdUrl: any = (accountEmail) => `rtmp://dcs.igiskorea.com:1935/live/mp4:${accountEmail}_720p`;
    // public static sdUrl: any = (accountEmail) => `rtmp://dcs.igiskorea.com:1935/live/mp4:${accountEmail}_360p`;
    public static URL: string = "wss://streaming.dfos.kr:30443/webrtc-session.json";
    public static APPLICATION_NAME: string = "live";

    // public static fullScreenUrl: string = "https://dcs.igiskorea.com/#/streaming/";
    // public static fileUrl: any = (accountEmail) => `https://dcs.igiskorea.com:30081/${accountEmail}/streaming/`;
    public static fullScreenUrl: string = "https://gov-cs.dfos.kr/#/streaming/";
    public static fileUrl: any = (accountEmail) => `https://gov.cs.dfos.kr:30081/${accountEmail}/streaming/`;

    // audio live streaming
    public static audioSendUrl: string = "wss://streaming.dfos.kr:30443/webrtc-session.json";
    public static audioApplicationName: string = "audio";
    public static audioBitrate: number = 128;
    public static audioCodec: string = "Opus";
    public static webSocketBinaryType: string = "arraybuffer";

    public static audioStreamingUrl: any = (streamName) => `rtmp://dcs.igiskorea.com:1935/${StreamingConfig.audioApplicationName}/mp4:${streamName}_aac`;
    public static audioStreamName: any = (streamName) => `mp4:${streamName}_aac`;
}
