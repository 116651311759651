import {NgModule} from "@angular/core";
import {InfoComponent} from "./info.component";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {InfoService} from "./service/info.service";
import {AutoFocusDirective} from "../../../../directive/autoFocus.directive";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [
        InfoComponent,
        AutoFocusDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule
    ],
    providers: [
        InfoService
    ],
    exports: [
        InfoComponent
    ]
})
export class JoinInfoModule {}
