import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {GroupTreeModule} from "../../common/group-tree/group-tree.module";
import {StatisticsComponent} from "./base/statistics.component";
import {HttpService} from "../../../service/http.service";
import {AccountService} from "../../../service/account.service";
import {SettingsService} from "../../../service/settings.service";
import {PopupService} from "../../../service/popup.service";
import {StatisticsScrollbarDirective} from "../../../directive/statisticsScrollbar.directive";
import {StatisticsFlightRecordScrollbarDirective} from "../../../directive/statisticsFlightRecordScrollbar.directive";
import {MatTooltipModule} from "@angular/material";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ChartsModule} from "ng2-charts";
import {ScrollBarSharedModule} from "../../common/scroll-bar.shared.module";
import {TranslateModule} from "@ngx-translate/core";
import {StatisticsSummaryComponent} from "./summary/statistics-summary.component";
import {StatisticsSummaryService} from "./summary/service/statistics-summary.service";
import {SummaryItemComponent} from "./summary/item/summary-item.component";
import {SummaryItemService} from "./summary/item/service/summary-item.service";
import {FlightHistoryComponent} from "./flightHistory/flight-history.component";
import {FlightHistoryService} from "./flightHistory/service/flight-history.service";
import {DetailDialogComponent} from "./flightHistory/detail/detail-dialog.component";
import {DetailDialogService} from "./flightHistory/detail/service/detail-dialog.service";

@NgModule({
    declarations: [
        StatisticsComponent,
        StatisticsSummaryComponent,
        SummaryItemComponent,
        FlightHistoryComponent,
        DetailDialogComponent,
        StatisticsScrollbarDirective,
        StatisticsFlightRecordScrollbarDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        GroupTreeModule,
        ChartsModule,
        MatTooltipModule,
        BrowserAnimationsModule,
        ScrollBarSharedModule,
        TranslateModule
    ],
    providers: [
        StatisticsSummaryService,
        SummaryItemService,
        FlightHistoryService,
        DetailDialogService,
        HttpService,
        AccountService,
        SettingsService,
        PopupService
    ],
    exports: [
        StatisticsComponent
    ]
})
export class StatisticsModule {}
