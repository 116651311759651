import { AspectRatioType } from 'src/app/dto/mission/setting/aspectRatio.type';
import { Offset } from '../mission/offset';
import { LatLng } from './latLng';

export class WaypointOption {
    constructor(
        public pointList: Offset[],
        public polygonAngle: number,
        public missionAngle: number,
        public frontlap: number,
        public sidelap: number,
        public altitude: number,
        public fov: number,
        public aspectRatioType: AspectRatioType,
        public latLng: LatLng
    ) {}
}