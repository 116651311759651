import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {HTTPCallBack} from "./http.callback";
import {Router} from "@angular/router";

@Injectable()
export class HttpService {
    constructor(private http: HttpClient, private router: Router) { }

    private onError(error: any, callback: HTTPCallBack): void {
        if (error.status == 401) {
            this.router.navigateByUrl("");
        } else {
            if (callback.error) {
                callback.error();
            }
        }
    }

    public get(url: string, callback: HTTPCallBack): void {
        this.http
            .get(url)
            .subscribe(
                res => {
                    if (callback.response) {
                        callback.response(res);
                    }
                },
                error => this.onError(error, callback)
            );
    }

    public getWithData(url: string, data: any, callback: HTTPCallBack): void {
        this.http
            .get(url)
            .subscribe(
                res => {
                    if (callback.response) {
                        callback.response(res);
                    }
                },
                error => this.onError(error, callback)
            );
    }

    public getAndDownload(url: string, callback: HTTPCallBack): void {
        let headerOptions: any = {
            responseType: "arraybuffer"
        };

        this.http
            .get(url, headerOptions)
            .subscribe(
                res => {
                    if (callback.response) {
                        callback.response(res);
                    }
                },
                error => this.onError(error, callback)
            );
    }

    public put(url: string, data: any, callback: HTTPCallBack): void {
        this.http
            .put(url, data)
            .subscribe(
                res => {
                    if (callback.response) {
                        callback.response(res);
                    }
                },
                error => this.onError(error, callback)
            );
    }

    public post(url: string, data: any, callback: HTTPCallBack): void {
        this.http
            .post(url, data)
            .subscribe(
                res => {
                    if (callback.response) {
                        callback.response(res);
                    }
                },
                error => this.onError(error, callback)
            );
    }

    public postAndDownload(url: string, data: any, callback: HTTPCallBack): void {
        let headerOptions: any = {
            responseType: "arraybuffer"
        };

        this.http
            .post(url, data, headerOptions)
            .subscribe(
                res => {
                    if (callback.response) {
                        callback.response(res);
                    }
                },
                error => this.onError(error, callback)
            );
    }

    public delete(url: string, callback: HTTPCallBack): void {
        this.http
            .delete(url)
            .subscribe(
                res => {
                    if (callback.response) {
                        callback.response(res);
                    }
                },
                error => this.onError(error, callback)
            );
    }
}
