import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';

@Injectable()
export class AudioElementService {
    private machineSerialList: Subject<string[]> = new Subject<string[]>();
    public machineSerialList$: any;

    constructor() {
        this.machineSerialList$ = this.machineSerialList.asObservable();
    }

    public publishMachineSerialList(machineSerialList: string[]): void {
        this.machineSerialList.next(machineSerialList);
    }
}