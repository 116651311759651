import { RouterModule, Routes } from '@angular/router';
import { MgmtComponent } from '../component/mgmt/mgmt.component';
import { LoginComponent } from '../component/login/login.component';
import { JoinComponent } from '../component/join/base/join.component';
import { StreamingComponent } from '../component/mgmt/control/streaming.component';
import { LivemapComponent } from '../component/mgmt/control/livemap.component';
import { FindPasswordComponent } from '../component/findPassword/findPassword.component';

const routes: Routes = [
    { path: "", component: LoginComponent },
    { path: "join", component: JoinComponent },
    { path: "findPassword", component: FindPasswordComponent },
    { path: "mgmt", component: MgmtComponent },
    { path: "streaming/:source", component: StreamingComponent },
    { path: "livemap/:filepath", component: LivemapComponent },
    { path: "**", component: LoginComponent }
];

export const RouterSetting = RouterModule.forRoot(routes, {useHash: true});
