export enum Phase {
    TOS, Information, Done
}

export class PhaseName {
    public static get(phase: Phase): string {
        switch (phase) {
            case Phase.TOS :
                return "Terms of service";
            case Phase.Information :
                return "Enter information";
            case Phase.Done :
                return "Done";
        }
    }
}
