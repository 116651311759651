import { StatisticsChart } from './statisticsChart';
import { Color } from 'ng2-charts';
import { ChartColorList } from 'src/app/constants/chartColorList';

export class BarChart extends StatisticsChart {
    private colorList: ChartColorList = new ChartColorList();

    public getChartColors(): Color[] {
        return this.chartColors;
    }

    public setChartColors(): void {
        this.chartColors = [];

        let length: number = this.getChartData().length;

        for (let i = 0;i < length;i++) {
            let idx: number = i % 10;

            if (idx == length - 1) {
                this.chartColors.push({
                    backgroundColor: this.colorList.list[this.colorList.list.length - 1].opacity,
                    borderColor: this.colorList.list[this.colorList.list.length - 1].normal,
                    borderWidth: 3
                });
            } else {
                this.chartColors.push({
                    backgroundColor: this.colorList.list[idx].opacity,
                    borderColor: this.colorList.list[idx].normal,
                    borderWidth: 3
                });
            }
        }
    }

    public setChartOptions(): void {
        this.chartOptions = {
            plugins: {
                p1: false
            }
        };
    }

    public setChartPlugins(): void {}
}
