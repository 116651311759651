import {NgModule} from "@angular/core";
import {StatisticsPopupScrollbarDirective} from "../../directive/statisticsPopupScrollbar.directive";
import {LongPopupScrollbarDirective} from "../../directive/longPopupScrollbar.directive";

@NgModule({
    declarations: [
        StatisticsPopupScrollbarDirective,
        LongPopupScrollbarDirective
    ],
    exports: [
        StatisticsPopupScrollbarDirective,
        LongPopupScrollbarDirective
    ]
})
export class ScrollBarSharedModule {}
