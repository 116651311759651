import {GroupDto} from "../../../../../dto/group/group.dto";

export class GroupContext {
    constructor(
        public groupDto: GroupDto,
        public groupLevel: number,
        public offsetX: number,
        public offsetY: number
    ) {}
}
