import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {GroupComponent} from "./base/group.component";
import {GroupService} from "./base/service/group.service";
import {ContextMenuComponent} from "./contextMenu/context-menu.component";
import {GroupCreateComponent} from "./create/group-create.component";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [
        GroupComponent,
        ContextMenuComponent,
        GroupCreateComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule
    ],
    providers: [
        GroupService
    ],
    exports: [
        GroupComponent
    ]
})
export class GroupModule {}
