import { Directive, ElementRef, OnInit } from '@angular/core';

declare var $: any;

@Directive({
    selector: 'scrollbar-statusPopup',
    host: {'class': 'side_wrapper-status'}
})
export class StatusDashboardScrollbarDirective implements OnInit {
    element: ElementRef;

    constructor(element: ElementRef) {
        this.element = element;
    }

    ngOnInit() {
        $(this.element.nativeElement).mCustomScrollbar({
            theme: "minimal-dark",
            scrollButtons: {enable:true},
            scrollInertia: 0,
            advanced: {
                updateOnContentResize: true
            }
        });
    }
}