export enum PoiSearchCode {
    Keyword, Address
}

export namespace PoiSearchCode {
    export function getPoiSearchCodeLanguage(poiSearchCode: PoiSearchCode): string {
        if (poiSearchCode == PoiSearchCode.Keyword) {
            return "control.poi.label.keyword";
        }

        return "control.poi.label.address";
    }

    export function getAll(): PoiSearchCode[] {
        return [PoiSearchCode.Keyword, PoiSearchCode.Address];
    }
}
