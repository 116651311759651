export class ChartColorList {
    constructor(
        public list: RGBA[] = []
    ) {
        list.push(new RGBA("rgba(88,115,234,0.7)", "rgba(88,115,234,0.3)"));
        list.push(new RGBA("rgba(247,103,113,0.7)", "rgba(247,103,113,0.3)"));
        list.push(new RGBA("rgba(152,182,2,0.7)", "rgba(152,182,2,0.3)"));
        list.push(new RGBA("rgba(255,122,0,0.7)", "rgba(255,122,0,0.3)"));
        list.push(new RGBA("rgba(46,136,214,0.7)", "rgba(46,136,214,0.3)"));
        list.push(new RGBA("rgba(170,88,234,0.7)", "rgba(170,88,234,0.3)"));
        list.push(new RGBA("rgba(255,188,0,0.7)", "rgba(255,188,0,0.3)"));
        list.push(new RGBA("rgba(2,182,136,0.7)", "rgba(2,182,136,0.3)"));
        list.push(new RGBA("rgba(141,110,99,0.7)", "rgba(141,110,99,0.3)"));
        list.push(new RGBA("rgba(56,67,82,0.7)", "rgba(56,67,82,0.3)"));
    }
}

export class RGBA {
    constructor(
        public normal: string = "",
        public opacity: string = ""
    ) {}
}