import {Component, EventEmitter, Output} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ProfileService} from "./service/profile.service";
import {AccountDto} from "../../../dto/account/account.dto";
import {AccountService} from "../../../service/account.service";
import {ProfileDto} from "./dto/profile.dto";
import {Router} from "@angular/router";

@Component({
    selector: "profile",
    templateUrl: "./view/profile.component.html",
    styleUrls: ["./view/profile.component.css"]
})
export class ProfileComponent {
    public isValidCurrentPassword: boolean | undefined;
    public isValidPublicKey: boolean | undefined;

    public currentPassword: string;
    public profileDto: ProfileDto;

    @Output() public closeEvent: EventEmitter<void>;

    constructor(
        private accountService: AccountService,
        private profileService: ProfileService,
        private translateService: TranslateService,
        private router: Router) {

        this.isValidCurrentPassword = undefined;
        this.isValidPublicKey = undefined;

        this.currentPassword = "";
        this.profileDto = new ProfileDto();

        this.closeEvent = new EventEmitter<void>();
    }

    public isKoreanLanguage(): boolean {
        return this.translateService.currentLang == "ko";
    }

    public checkPassword(): void {
        this.profileService.getPublicKey().subscribe(
            publicKey => {
                this.isValidPublicKey = true;

                let accountDto: AccountDto = new AccountDto();
                accountDto.email = this.accountService.getAccount().email;
                accountDto.password = this.profileService.encryptPassword(publicKey, this.currentPassword);

                this.profileService.checkPassword(accountDto).subscribe(
                    isSuccess => {
                        this.profileDto.name = this.accountService.getAccount().name;
                        this.isValidCurrentPassword = isSuccess;
                    }, _ => {
                        this.isValidCurrentPassword = false;
                    }
                )
            }, _ => {
                this.isValidPublicKey = false;
            }
        );
    }

    public editProfile(): void {
        this.profileService.getPublicKey().subscribe(
            publicKey => {
                if (0 < this.profileDto.password.length) {
                    this.profileDto.password = this.profileService.encryptPassword(publicKey, this.profileDto.password);
                }

                this.profileService.editProfile(this.accountService.getAccount().seq, this.profileDto).subscribe(
                    isSuccess => {
                        if (isSuccess) {
                            if (this.profileDto.password.length == 0) {
                                this.accountService.publishRefresh(true);
                                this.close();
                            } else {
                                this.router.navigateByUrl("/");
                            }
                        }
                    }
                );
            }
        );
    }

    public close(): void {
        this.closeEvent.emit();
    }
}
