import {ElementRef, QueryList, ViewChildren} from "@angular/core";
import {timer} from "rxjs";
import {APIUrl} from "src/app/constants/api.url";
import {LiveMapDataDto} from "src/app/dto/control/liveMapData.dto";
import {MissionControlType} from "src/app/dto/control/missionControlType";
import {LivemapDto} from "src/app/dto/livemap/livemap.dto";
import {MachineDto} from "src/app/dto/machine/machine.dto";
import {MachineStatusDto} from "src/app/dto/machine/machineStatus.dto";
import {AudioElementService} from "src/app/service/audioElement.service";
import {HTTPCallBack} from "src/app/service/http.callback";
import {HttpService} from "src/app/service/http.service";
import {MapService} from "src/app/service/map.service";
import {MessageService} from "src/app/service/message.service";
import {Area} from "../../mission/mission/area";
import {Offset} from "../../mission/mission/offset";
import {Point} from "../../mission/mission/point";
import {LicenseDto} from "src/app/dto/license/license.dto";

export class VisibleFilterService {
    private containerWidth: number = 0;
    private containerHeight: number = 0;

    private isForControl: boolean = true;

    private totalList: MachineDto[] = [];
    private onlineList: MachineDto[] = [];
    private visibleList: MachineDto[] = [];

    private mapService: MapService;
    private httpService: HttpService;
    private messageService: MessageService;
    private zoomLevel: number = 0;

    @ViewChildren("chatScrollList") private chatScrollList: QueryList<ElementRef>;

    private livemapDataList: LiveMapDataDto[] = [];
    private livemapCallback: any;
    private missionStopCallback: any;

    private audioElementService: AudioElementService;

    private adminLicenseDto: LicenseDto = null;

    constructor(
        isForControl: boolean,
        machineList: MachineDto[],
        mapService: MapService,
        httpService: HttpService,
        messageService: MessageService,
        audioElementService: AudioElementService,
        chatScrollList: QueryList<ElementRef>,
        missionStopCallback: any,
        livemapCallback: any,
        adminLicenseDto: LicenseDto) {

        this.isForControl = isForControl;

        this.mapService = mapService;
        this.httpService = httpService;
        this.messageService = messageService;
        this.chatScrollList = chatScrollList;
        this.livemapCallback = livemapCallback;
        this.missionStopCallback = missionStopCallback;
        this.audioElementService = audioElementService;

        this.adminLicenseDto = adminLicenseDto;

        this.setMachineData(machineList);
        this.setOffset(false);
    }

    public setMachineData(machineList: MachineDto[]): void {
        if (machineList.length == 0) {
            this.totalList = [];
            return;
        }

        machineList.forEach(data => {
            let findItem: MachineDto = this.totalList.find(item => item.seq == data.seq);

            if (findItem == null) {
                let machine: MachineDto = Object.assign(new MachineDto(), data);
                if (machine.mission.seq != 0) {
                    machine.missionControlType = MissionControlType.START;
                }

                machine.isStreaming = false;
                machine.isStreamingPIPMode = false;

                machine.mapService = this.mapService;
                machine.httpService = this.httpService;
                machine.messageService = this.messageService;

                this.totalList.push(machine);
            } else {
                findItem.name = data.name;

                if (!findItem.isPathTracing) {
                    findItem.machineStatusDto = data.machineStatusDto;
                }
            }
        });
    }

    public editMachineName(machine: MachineDto): void {
        let findItem: MachineDto = this.onlineList.find(item => item.seq == machine.seq);

        if (findItem != null) {
            findItem.name = machine.name;
        }
    }

    public deleteMachine(machine: MachineDto): void {
        let index: number = this.onlineList.findIndex(item => item.seq == machine.seq);

        if (0 <= index) {
            this.onlineList.splice(index, 1);
        }
    }

    public setContainerSize(containerWidth: number, containerHeight: number): void {
        this.containerWidth = containerWidth;
        this.containerHeight = containerHeight;
    }

    public setZoomLevel(zoomLevel: number): void {
        this.zoomLevel = zoomLevel;
    }

    public setOffset(isZoomChanged: boolean): void {
        this.onlineList = [];

        this.totalList.forEach(item => {
            if (item.machineStatusDto.longitude == 0 || item.machineStatusDto.latitude == 0) {
                item.machineStatusDto.offsetX = 0;
                item.machineStatusDto.offsetY = 0;
            } else {
                let point: Point = new Point(item.machineStatusDto.longitude, item.machineStatusDto.latitude);
                let offset: Offset = this.mapService.map.convertPointToOffset(point);
                let containerOffset: Offset = this.mapService.map.getContainerOffset() || new Offset();

                item.machineStatusDto.offsetX = offset.x + containerOffset.x;
                item.machineStatusDto.offsetY = offset.y + containerOffset.y;
            }

            if (item.machineStatusDto.isConnect) {
                this.onlineList.push(Object.assign(new MachineDto(), item));
            }
        });

        this.checkVisibleList(isZoomChanged);
        this.resetLivemapDataOffset();
    }

    private checkVisibleList(isZoomChanged: boolean): void {
        if (this.zoomLevel >= 8) {
            let innerList: MachineDto[] = [];

            this.totalList.forEach(machine => {
                let status: MachineStatusDto = machine.machineStatusDto;

                if (status.latitude != 0 && status.longitude != 0) {
                    if (machine.isPathTracing) {
                        innerList.push(machine);
                    } else if (status.offsetX >= 0 && status.offsetX <= this.containerWidth
                        && status.offsetY >= 0 && status.offsetY <= this.containerHeight) {

                        innerList.push(machine);
                    } else {
                        // nothing
                    }
                }
            });

            this.visibleList.forEach(machine => {
                machine.isVisibleChecked = false;
            });

            innerList.forEach(newMachine => {
                if (this.isForControl) {
                    if (!this.isExistInVisibleList(newMachine)) {
                        newMachine.isVisibleChecked = true;
                        newMachine.initMqtt(
                            function(): void {
                                this.chatScrollList.toArray().forEach(element => {
                                    try {
                                        $(element.nativeElement).mCustomScrollbar("update");
                                        $(element.nativeElement).mCustomScrollbar("scrollTo", "last");
                                    } catch (error) {}
                                });
                            }.bind(this),
                            function(machineSeq: number, accountEmail: string): void {
                                this.missionStopCallback(machineSeq);
                                this.saveLivemapData(machineSeq, accountEmail);
                            }.bind(this),
                            function(livemapData: LiveMapDataDto): void {
                                this.livemapDataList.push(livemapData);
                                this.livemapCallback();
                            }.bind(this),
                            this.adminLicenseDto
                        );

                        this.visibleList.push(newMachine);
                        this.setLivemapVisible(newMachine, true);
                    }
                } else {
                    if (!this.isExistInVisibleList(newMachine)) {
                        newMachine.isVisibleChecked = true;
                        this.visibleList.push(newMachine);
                    }
                }
            });

            for (let i = 0;i < this.visibleList.length;i++) {
                let machine: MachineDto = this.visibleList[i];

                if (!machine.isVisibleChecked) {
                    if (this.isForControl) {
                        machine.livemapImageList = [];
                        machine.clearRealTimeInterval();
                        machine.mqttService.disconnect();

                        this.setLivemapVisible(machine, false);
                    }

                    this.visibleList.splice(i, 1);
                    --i;
                }
            }

            if (this.isForControl) {
                this.initChatScrollList();
            }
        } else {
            if (isZoomChanged) {
                this.clearVisibleList();
            }
        }

        let machineSerialList: string[] = [];
        this.visibleList.forEach(machine => {
            machineSerialList.push(machine.serialNumber);
        });

        if (this.audioElementService != null) {
            this.audioElementService.publishMachineSerialList(machineSerialList);
        }
    }

    private isExistInVisibleList(newMachine: MachineDto): boolean {
        let isExist: boolean = false;

        this.visibleList.forEach(machine => {
            if (!isExist) {
                if (machine == newMachine) {
                    machine.isVisibleChecked = true;
                    isExist = true;
                }
            }
        });

        return isExist;
    }

    private initChatScrollList(): void {
        timer(500).subscribe(() => {
            this.chatScrollList.toArray().forEach(element => {
                $(element.nativeElement).mCustomScrollbar({
                    theme: "minimal-dark",
                    scrollButtons: {enable:true},
                    scrollInertia: 0,
                    advanced: {
                        updateOnContentResize: true
                    }
                });
            });
        });
    }

    public getTotalList(): MachineDto[] {
        return this.totalList;
    }

    public getVisibleList(): MachineDto[] {
        return this.visibleList;
    }

    public getOnlineList(): MachineDto[] {
        return this.onlineList;
    }

    public clearVisibleList(): void {
        this.visibleList.forEach(machine => {
            try {
                if (machine.mqttService.isConnected()) {
                    machine.isPathTracing = false;
                    machine.clearRealTimeInterval();
                    machine.clearAudioPlayer();
                    machine.mqttService.disconnect();
                }
            } catch (error) {}
        });

        this.visibleList = [];
    }

    public getLivemapDataList(): LiveMapDataDto[] {
        return this.livemapDataList;
    }

    private resetLivemapDataOffset(): void {
        this.livemapDataList.forEach(liveMapData => {
            let offset: Offset = this.getOffset(liveMapData.latitude, liveMapData.longitude);
            liveMapData.offsetX = offset.y;
            liveMapData.offsetY = offset.x;

            let imageCoverage: Area = this.getImageCoverage(liveMapData.altitude, liveMapData.imageWidth, liveMapData.imageHeight);
            let meterPerPixel: number = this.mapService.map.getMeterPerPixel();

            let mappedWidth: number = imageCoverage.width / meterPerPixel;
            let mappedHeight: number = imageCoverage.height / meterPerPixel;

            liveMapData.imageWidth *= (mappedWidth / liveMapData.imageWidth);
            liveMapData.imageHeight *= (mappedHeight / liveMapData.imageHeight);

            liveMapData.offsetX -= mappedHeight / 2;
            liveMapData.offsetY -= mappedWidth / 2;
        });
    }

    private getOffset(latitude: number, longitude: number): Offset {
        let result: Offset = new Offset();

        if (longitude == 0 || latitude == 0) {
            result.x = 0;
            result.y = 0;
        } else {
            let point: Point = new Point(longitude, latitude);
            let offset: Offset = this.mapService.map.convertPointToOffset(point);
            let containerOffset: Offset = this.mapService.map.getContainerOffset() || new Offset();

            result.x = offset.x + containerOffset.x;
            result.y = offset.y + containerOffset.y;
        }

        return result;
    }

    private getImageCoverage(altitude: number, imageWidth: number, imageHeight: number): Area {
        let width: number = 2 * (altitude / Math.tan(43 * Math.PI / 180)) * (imageWidth / Math.sqrt(Math.pow(imageWidth, 2) + Math.pow(imageHeight, 2)));
        let height: number = 2 * (altitude / Math.tan(43 * Math.PI / 180)) * (imageHeight / Math.sqrt(Math.pow(imageWidth, 2) + Math.pow(imageHeight, 2)));

        return new Area(width, height);
    }

    private setLivemapVisible(machine: MachineDto, isVisible: boolean): void {
        machine.isLivemapVisible = isVisible;
    }

    private saveLivemapData(machineSeq: number, accountEmail: string): void {
        let saveList: LivemapDto[] = [];

        this.livemapDataList.forEach(item => {
            let livemapDto: LivemapDto = new LivemapDto();
            livemapDto.latitude = item.latitude;
            livemapDto.longitude = item.longitude;
            livemapDto.yaw = item.yaw;
            livemapDto.altitude = item.altitude;
            livemapDto.width = item.imageWidth;
            livemapDto.height = item.imageHeight;

            if (item.fileName.length > 0) {
                let chunkList: string[] = item.fileName.split("/");
                livemapDto.fileName = chunkList[chunkList.length - 1];
            }

            saveList.push(livemapDto);
        });

        this.httpService.post(APIUrl.LIVEMAP_SAVE(accountEmail, machineSeq), saveList, new HTTPCallBack());
    }
}
