import {Component, EventEmitter, Output} from "@angular/core";
import {GroupDto} from "../../../../dto/group/group.dto";
import {GroupService} from "../base/service/group.service";

@Component({
    selector: "group-create",
    templateUrl: "./view/group-create.component.html",
    styleUrls: ["./view/group-create.component.css"]
})
export class GroupCreateComponent {
    public isVisible: boolean;
    public isCreate: boolean;
    public groupDto: GroupDto;

    @Output() createEvent: EventEmitter<void>;
    @Output() closedEvent: EventEmitter<void>;

    constructor(private groupService: GroupService) {
        this.isVisible = false;
        this.isCreate = true;

        this.createEvent = new EventEmitter<void>();
        this.closedEvent = new EventEmitter<void>();
    }

    public show(isCreate: boolean, groupDto: GroupDto): void {
        this.groupDto = groupDto;
        this.isCreate = isCreate;
        this.isVisible = true;

        if (!this.isCreate) {
            this.getGroupData();
        }
    }

    private getGroupData(): void {
        this.groupService.getData(this.groupDto.seq).subscribe(
            groupDto => {
                this.groupDto = groupDto;
            }
        );
    }

    public submit(): void {
        if (this.isCreate) {
            this.groupService.create(this.groupDto).subscribe(_ => this.submitDone());
        } else {
            this.groupService.edit(this.groupDto).subscribe(_ => this.submitDone());
        }
    }

    private submitDone(): void {
        this.createEvent.emit();
        this.isVisible = false;
    }

    public close(): void {
        this.isVisible = false;
        this.closedEvent.emit();
    }
}
