import {Component, ElementRef, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {LoginDto} from "src/app/dto/account/login.dto";
import {LoginResponseDto} from "src/app/dto/account/loginResponse.dto";
import {LoginService} from "./service/login.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: "",
    templateUrl: "../../view/html/login.html",
    styleUrls: ["../../view/css/login.css"]
})
export class LoginComponent {
    private publicKey: string | null = null;

    public isSaveTheEmail: boolean = false;
    public loginDto: LoginDto = new LoginDto();
    public password: string = null;

    public failedMessage: string = "";

    @ViewChild("emailField") emailField: ElementRef;
    @ViewChild("passwordField") passwordField: ElementRef;

    private loginLabel: string = "";

    constructor(private router: Router, private loginService: LoginService, private translateService: TranslateService) {}

    ngOnInit() {
        this.checkSavedEmail();
        this.getPublicKey();
    }

    ngAfterViewInit() {
        if (this.loginDto.email == "") {
            this.emailField.nativeElement.focus();
        } else {
            this.passwordField.nativeElement.focus();
        }
    }

    private checkSavedEmail(): void {
        let savedEmail: string = localStorage.getItem("savedId");

        if (savedEmail != null) {
            this.loginDto.email = savedEmail;
            this.isSaveTheEmail = true;
        }
    }

    private getPublicKey(): void {
        this.loginService.getPublicKey().subscribe(
            response => {
                if (response.isSuccess && response.data != null) {
                    this.publicKey = response.data;
                }
            }, _ => {
                this.failedMessage = this.translateService.instant("login.message.try-again");
            }
        );
    }

    public focusToPassword(): void {
        this.passwordField.nativeElement.focus();
    }

    public login(): void {
        if (this.loginDto.email == "" || this.password == null || this.password == "") {
            this.failedMessage = this.translateService.instant("login.message.enter-email-password");
            return;
        }

        this.loginDto.password = this.loginService.encryptPassword(this.publicKey, this.password);
        if (this.loginDto.password == null) {
            this.failedMessage = this.translateService.instant("login.message.try-again");
            return;
        }

        this.loginService.login(this.loginDto).subscribe(
            response => {
                let loginResponseDto: LoginResponseDto = response.data;

                if (!response.isSuccess) {
                    this.failedMessage = this.translateService.instant("login.message.wrong-email-password");
                    return;
                }

                if (!loginResponseDto.isValidLicense) {
                    this.failedMessage = this.translateService.instant("login.message.invalid-license");
                    return;
                }

                if (this.isSaveTheEmail) {
                    localStorage.setItem("savedId", this.loginDto.email);
                } else {
                    localStorage.removeItem("savedId");
                }

                localStorage.setItem("loginId", this.loginDto.email);
                localStorage.setItem("token", loginResponseDto.jwtToken);
                this.router.navigateByUrl("mgmt");
            }
        );
    }

    public moveJoinPage(): void {
        this.router.navigateByUrl("/join");
    }

    public moveFindPasswordPage(): void {
        this.router.navigateByUrl("/findPassword");
    }
}
