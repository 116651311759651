import { Injectable } from "@angular/core";
import { CanActivate, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class JWTService implements CanActivate {
    private jwtHelperService: JwtHelperService

    constructor(private router: Router) {
        this.jwtHelperService = new JwtHelperService();
    }

    canActivate() {
        let token: string = localStorage.getItem("token");

        try {
            if (token == null || this.jwtHelperService.isTokenExpired(token)) {
                this.router.navigateByUrl("login");
                return false;
            }
        } catch(error) {
            this.router.navigateByUrl("login");
                return false;
        }

        return true;
    }
}