import { AspectRatioType } from '../mission/setting/aspectRatio.type';
import { CameraType } from '../mission/setting/camera.type';
import { BatteryStatus } from './batteryStatus.dto';

export class MachineStatusDto {
    constructor (
        public machineSeq: number = 0,
        public batteryStatusList: BatteryStatus[] = [],

        public latitude: number = 0,
        public longitude: number = 0,
        public offsetX: number = 0,
        public offsetY: number = 0,

        public speed: string = "",
        public distance: number = 0,
        public altitude: number = 0,
        public rotate: number = 0,
        public cameraTypeCode: CameraType = CameraType.PHANTOM_3,
        public aspectRatioTypeCode: AspectRatioType = AspectRatioType.RATIO_5TO4,
        public isConnect: boolean = false,

        public isClustered: boolean = false
    ) {}
}