import {Component, EventEmitter, Output} from "@angular/core";
import {GroupContext} from "./data/group-context";
import {ContextEvent} from "./data/context-event";

@Component({
    selector: "group-context-menu",
    templateUrl: "./view/context-menu.component.html"
})
export class ContextMenuComponent {
    public isVisible: boolean;
    public groupContext: GroupContext;

    public contextEvent: typeof ContextEvent;
    @Output() public selectEvent: EventEmitter<ContextEvent>;

    constructor() {
        this.isVisible = false;
        this.contextEvent = ContextEvent;
        this.selectEvent = new EventEmitter<ContextEvent>();
    }

    public show(groupContext: GroupContext): void {
        this.isVisible = true;
        this.groupContext = groupContext;
    }

    public hide(): void {
        this.isVisible = false;
    }

    public preventDefaultContext(event: any): void {
        event.preventDefault();
    }

    public hasAddButton(): boolean {
        return this.groupContext.groupLevel != 3;
    }

    public hasDeleteButton(): boolean {
        return this.groupContext.groupLevel != 1;
    }

    public select(contextEvent: ContextEvent): void {
        this.hide();
        this.selectEvent.emit(contextEvent);
    }
}
