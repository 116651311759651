import { Offset } from './offset';

export class Distance {
    private point: Offset = new Offset();
    private distance: number = 0;
    private unitName: string = "m";

    public setPoint(point: Offset): void {
        this.point = point;
    }

    public getPoint(): Offset {
        return this.point;
    }

    public setDistance(distance: number): void {
        this.distance = distance;
    }

    public getPlainDistance(): number {
        return this.distance;
    }

    public getDistance(): number {
        if (this.distance > 1000) {
            this.unitName = "km";

            return +(this.distance / 1000).toFixed(0);
        }

        return +this.distance.toFixed(0);
    }

    public getUnitName(): string {
        return this.unitName;
    }
}