import { StatisticsChart } from './statisticsChart';
import { ChartColorList } from 'src/app/constants/chartColorList';
import * as piechartOutlabels from 'chartjs-plugin-piechart-outlabels';

export class PieChart extends StatisticsChart {
    private colorList: ChartColorList = new ChartColorList();

    public getChartColors(): any[] {
        return this.chartColors;
    }

    public setChartColors(): void {
        this.chartColors = [{
            backgroundColor: []
        }];

        let length: number = this.getChartLabels().length;

        for (let i = 0;i < length;i++) {
            let idx: number = i % 10;

            if (idx == length - 1) {
                this.chartColors[0].backgroundColor.push(this.colorList.list[this.colorList.list.length - 1].normal);
            } else {
                this.chartColors[0].backgroundColor.push(this.colorList.list[idx].normal);
            }
        }
    }

    public setChartOptions(): void {
        this.chartOptions = {
            responsive: true,
            legend: {
                position: 'left'
            },
            zoomOutPercentage: 30,
            plugins: {
                legend: true,
                outlabels: {
                    text: "%l %p",
                    color: "white",
                    stretch: 45,
                    font: {
                        resizable: true,
                        minSize: 12,
                        maxSize: 14
                    }
                }
            }
        };
    }

    public setChartPlugins(): void {
        this.chartPlugins = [piechartOutlabels];
    }
}