import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WowzaWebRTCPlayer } from '@nutbutterfly/wowza-webrtc-player';
import { interval, timer } from 'rxjs';
import { StreamingConfig } from 'src/app/constants/streaming.config';

@Component({
    selector: '',
    templateUrl: '../../../view/html/streaming.html',
    styleUrls: ['../../../view/css/streaming.css']
})
export class StreamingComponent {
    @ViewChild("streaming") private streamingElement: ElementRef;
    private accountEmail: string = null;

    private player: WowzaWebRTCPlayer;
    private packetsReceived: number;
    private checkInterval: any = null;

    private readonly PLAY_DELAY: number = 500;
    private readonly PACKET_CHECK_INTERVAL: number = 2000;

    constructor(private activatedRoute: ActivatedRoute) {
        this.accountEmail = atob(this.activatedRoute.snapshot.paramMap.get("source"));
    }

    ngAfterViewInit() {
        this.init();
    }

    private init(): void {
        this.player = new WowzaWebRTCPlayer(this.streamingElement.nativeElement, {
            sdpUrl: StreamingConfig.URL,
            applicationName: StreamingConfig.APPLICATION_NAME,
            streamName: this.accountEmail
        });

        timer(this.PLAY_DELAY).subscribe(() => {
            this.play();
        });

        if (this.checkInterval != null) {
            this.checkInterval.unsubscribe();
            this.checkInterval = null;
        }

        this.check();
    }

    private async play() {
        this.packetsReceived = 0;

        try {
            await this.player.playRemote();
        } catch (error) {}
    }

    public check(): void {
        this.checkInterval = interval(this.PACKET_CHECK_INTERVAL).subscribe(() => {
            let peerConnection: RTCPeerConnection = this.player.getPeerConnection();

            if (peerConnection == null) {
                this.init();
            } else {
                peerConnection.getStats(null).then(stats => {
                    stats.forEach(report => {
                        if (report.type == "transport") {
                            if (this.packetsReceived == report.packetsReceived
                                || (report.packetsReceived - this.packetsReceived <= 5)) {

                                this.init();
                            } else {
                                this.packetsReceived = report.packetsReceived;
                            }
                        }
                    });
                });
            }
        });
    }
}
