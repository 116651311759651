import {Injectable} from "@angular/core";
import {PopupService} from "./popup.service";
import {Subscription, timer} from "rxjs";
import {PopupDto} from "../dto/popup.dto";

@Injectable()
export class ProgressService {
    private timer: Subscription;

    constructor(private popupService: PopupService) {}

    public init(title: string, message: string): void {
        this.timer = timer(1000).subscribe(() => {
            let progressData: PopupDto = new PopupDto();
            progressData.title = title;
            progressData.message = message;
            progressData.isVisible = true;

            this.popupService.publishProgress(progressData);
        });
    }

    public stop(): void {
        if (this.timer != null) {
            try {
                this.timer.unsubscribe();
            } catch (error) {}

            this.timer = null;

            let progressData: PopupDto = new PopupDto();
            progressData.isVisible = false;
            this.popupService.publishProgress(progressData);
        }
    }
}
