export class APIUrl {
    // ent
    private static SERVER: string = "https://gov-cs.dfos.kr:30082/web";
    // dev
    // private static SERVER: string = "http://192.168.0.72:40082/web";

    // settings
    public static SETTINGS = APIUrl.SERVER + "/settings";
    public static GET_SETTINGS = (accountSeq) => `${APIUrl.SETTINGS}/${accountSeq}/`;
    public static SET_SETTINGS = (accountSeq) => `${APIUrl.SETTINGS}/${accountSeq}/`;

    // account
    public static JOIN = APIUrl.SERVER + "/join";
    public static REMOVE_MEMBER = (adminSeq) => `${APIUrl.JOIN}/removeMember/${adminSeq}`;
    public static EMAIL_CHECK = (email) => `${APIUrl.JOIN}/${email}/check`;
    public static GET_KEY = APIUrl.SERVER + "/login/getKey";
    public static LOGIN = APIUrl.SERVER + "/login/auth";
    public static FIND_PASSWORD = (email) => `${APIUrl.SERVER}/login/${email}/findPassword`;

    public static ACCOUNT = APIUrl.SERVER + "/account";
    public static ACCOUNT_DATA_BY_EMAIL = (email) => `${APIUrl.ACCOUNT}/${email}/account`;
    public static MEMBER_LIST = (accountSeq) => `${APIUrl.ACCOUNT}/${accountSeq}/memberList`;
    public static PERMISSION_SETTING = APIUrl.ACCOUNT + "/permissionSetting";
    public static CHECK_PASSWORD = APIUrl.ACCOUNT + "/checkPassword";
    public static PROFILE = (accountSeq) => `${APIUrl.ACCOUNT}/profile/${accountSeq}`;

    public static EDIT_MEMBER = APIUrl.ACCOUNT + "/editMember";

    // group
    public static GROUP = APIUrl.SERVER + "/group";
    public static GROUP_DATA = (groupSeq) => `${APIUrl.GROUP}/${groupSeq}`;
    public static MAKE_GROUP = (accountSeq) => `${APIUrl.GROUP}/${accountSeq}`;
    public static DELETE_GROUP = (groupSeq) => `${APIUrl.GROUP}/${groupSeq}`;
    public static GROUP_SEQ_LIST = (accountSeq) => `${APIUrl.GROUP}/seqs/${accountSeq}`
    public static GROUP_TREE = (accountSeq) => `${APIUrl.GROUP}/${accountSeq}/treeData`;
    public static GROUP_SETTING = APIUrl.GROUP + "/groupSetting";

    // machine
    public static MACHINE = APIUrl.SERVER + "/machine";
    public static MACHINE_LIST = (accountSeq) => `${APIUrl.MACHINE}/${accountSeq}/search`;
    public static MACHINE_LIST_FOR_CONTROL = (groupSeq, accountSeq) => `${APIUrl.MACHINE}/${groupSeq}/${accountSeq}/listForControl`;
    public static EDIT_MACHINE = (machineSeq) => `${APIUrl.MACHINE}/${machineSeq}`;
    public static DELETE_MACHINE = (machineSeq) => `${APIUrl.MACHINE}/${machineSeq}`;

    // mission - common
    public static MISSION = APIUrl.SERVER + "/mission";
    public static MAKE_MISSION = (seq) => `${APIUrl.MISSION}/${seq}`;

    // formation flying
    public static MAKE_FORMATION_FLYING_MISSION = (seq) => `${APIUrl.MISSION}/formationFlyingMission/${seq}`;

    public static MISSION_DATA = (seq) => `${APIUrl.MISSION}/${seq}/data`;
    public static DELETE_MISSION = (seq) => `${APIUrl.MISSION}/${seq}`;
    public static MISSION_SEARCH = (seq) => `${APIUrl.MISSION}/${seq}/missionSearch`;
    public static MISSION_SEARCH_OF_MACHINE = (accountSeq) => `${APIUrl.MISSION}/${accountSeq}/search`;
    public static MISSION_START = (accountSeq) => `${APIUrl.MISSION}/${accountSeq}/startMission`;

    // mission - weather
    public static TODAY_WEATHER = (latitude, longitude) => `${APIUrl.MISSION}/${latitude}/${longitude}/weather`;

    // mission - earth magnetic field
    public static EMF = APIUrl.MISSION + "/magneticField";

    // map poi
    public static GET_POI_LIST = APIUrl.SERVER + "/map";

    // statistics
    public static STATISTICS = APIUrl.SERVER + "/statistics";
    public static STATISTICS_SUMMARY = (accountSeq) => `${APIUrl.STATISTICS}/${accountSeq}/summaryData`;
    public static STATISTICS_YEAR_RANGE = (accountSeq) => `${APIUrl.STATISTICS}/${accountSeq}/yearRange`;
    public static STATISTICS_LINE_CHART_DATA = (accountSeq) => `${APIUrl.STATISTICS}/${accountSeq}/lineChartData`;
    public static STATISTICS_BAR_CHART_DATA = (accountSeq) => `${APIUrl.STATISTICS}/${accountSeq}/barChartData`;
    public static STATISTICS_PIE_CHART_DATA = (accountSeq) => `${APIUrl.STATISTICS}/${accountSeq}/pieChartData`;
    public static STATISTICS_IS_UPPER_MANAGER = (accountSeq) => `${APIUrl.STATISTICS}/${accountSeq}/isUpperManager`;
    public static EXPORT_LINE_CHART_DATA = (accountSeq, isLineChart) => `${APIUrl.STATISTICS}/${accountSeq}/${isLineChart}/exportToExcel`;

    public static FLIGHTRECORD = APIUrl.SERVER + "/flightRecord";
    public static FLIGHTRECORD_DATA = APIUrl.FLIGHTRECORD + "/getData";

    // streaming
    public static STREAMING = APIUrl.SERVER + "/streaming";
    public static ACCOUNT_STREAMING_LIST = (accountSeq) => `${APIUrl.STREAMING}/${accountSeq}/listByAccount`;
    public static MACHINE_STREAMING_LIST = (machineSeq) => `${APIUrl.STREAMING}/${machineSeq}/listByMachine`;
    public static STREAMING_STOP = (accountSeq) => `${APIUrl.STREAMING}/${accountSeq}/streamStop`;

    // shapefile
    public static SHAPEFILE = APIUrl.SERVER + "/shapefile";
    public static SHAPEFILE_LIST = (machineSeq) => `${APIUrl.SHAPEFILE}/${machineSeq}/list`;
    public static SHAPEFILE_DOWNLOAD_WITH_STARTEND = (shapeFileSeq, coordinateTypeCode) => `${APIUrl.SHAPEFILE}/${shapeFileSeq}/${coordinateTypeCode}/downloadWithStartEnd`;
    public static SHAPEFILE_DOWNLOAD = (missionSeq, coordinateTypeCode) => `${APIUrl.SHAPEFILE}/${missionSeq}/${coordinateTypeCode}/download`;

    public static SHAPEFILE_LOAD = (epsg, charset) => `${APIUrl.SHAPEFILE}/loadShapeFile/${epsg}/${charset}`;

    // kml
    public static KML = APIUrl.SERVER + "/kml";
    public static KML_LOAD = APIUrl.KML + "/load";
    public static KML_DOWNLOAD = (missionSeq) => `${APIUrl.KML}/download/${missionSeq}`;
    public static KML_DOWNLOAD_WIDTH_STARTEND = (missionHistorySeq) => `${APIUrl.KML}/downloadWithStartEnd/${missionHistorySeq}`;

    // livemap
    public static LIVEMAP = APIUrl.SERVER + "/livemap";
    public static LIVEMAP_LIST = (accountSeq, machineSeq) => `${APIUrl.LIVEMAP}/${accountSeq}/${machineSeq}/list`;
    public static LIVEMAP_SAVE = (accountEmail, machineSeq) => `${APIUrl.LIVEMAP}/${accountEmail}/${machineSeq}/save`;
    public static LIVEMAP_DOWNLOAD = APIUrl.LIVEMAP + "/download";

    // license
    public static LICENSE = APIUrl.SERVER + "/license";
    public static CHECK_USER_LIMIT = (accountSeq) => `${APIUrl.LICENSE}/checkUserLimit/${accountSeq}`;
}
