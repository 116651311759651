import { Map } from './map';
import { Offset } from '../mission/offset';
import { Point } from '../mission/point';
import { AddressDto } from 'src/app/dto/mission/weather/address.dto';

declare const naver: any;

export class NaverMap extends Map {
    public getAddressOfCenterPoint(callback: any): void {
        let coords: any = new naver.maps.LatLng(this.centerPoint.coordY, this.centerPoint.coordX);

        naver.maps.Service.reverseGeocode({coords : coords}, function (status, response) {
            if (status != 200
                || response.v2.status.code != 0
                || response.v2.results.length == 0) {
                // fail
                return;
            }

            let data: any = response.v2.results[response.v2.results.length - 1];
            this.addressDto = new AddressDto();
            this.addressDto.area1 = data.region.area1.name;
            this.addressDto.area2 = data.region.area2.name;
            this.addressDto.area3 = data.region.area3.name;

            callback(this.addressDto);
        }.bind(this));
    }

    public getAddreessOfPoint(callback: any, latitude: number, longitude: number): void {
        let coords: any = new naver.maps.LatLng(latitude, longitude);

        naver.maps.Service.reverseGeocode({coords : coords}, function (status, response) {
            if (status != 200
                || response.v2.status.code != 0
                || response.v2.results.length == 0) {
                // fail
                return;
            }

            let data: any = response.v2.results[response.v2.results.length - 1];
            callback(data.region.area1.name + " " + data.region.area2.name + " " + data.region.area3.name);
        });
    }

    public loadMap(containerId: string, latitude: number, longitude: number, zoomLevel: number): void {
        let mapOpts: any = {
            useStyleMap: true,
            // useStyleMap: false,
            center: new naver.maps.LatLng(latitude, longitude),
            zoom: zoomLevel,
            scaleControl: true,
            scaleControlOptions: {
                position: naver.maps.Position.BOTTOM_CENTER
            }
        };

        this.map = new naver.maps.Map(containerId, mapOpts);
        this.setCenterOffset();
    }

    public setMapType(isNormal: boolean): void {
        if (isNormal) {
            this.map.setMapTypeId(naver.maps.MapTypeId.NORMAL);
        } else {
            this.map.setMapTypeId(naver.maps.MapTypeId.HYBRID);
        }
    }

    public moveTo(point: Point): void {
        let zoomLevel: number = (this.map.getZoom() >= 18)? this.map.getZoom() : 18;
        let newCenter: any = new naver.maps.LatLng(
            +point.coordY.toFixed(7),
            +point.coordX.toFixed(7)
        );

        this.map.setCenter(newCenter);
        this.map.setZoom(zoomLevel, false);

        this.setCenterOffset();
    }

    public moveToBoundary(leftBottom: Point, rightTop: Point): void {
        this.map.fitBounds(new naver.maps.LatLngBounds(
            new naver.maps.LatLng(leftBottom.coordY, leftBottom.coordX),
            new naver.maps.LatLng(rightTop.coordY, rightTop.coordX)
        ));
    }

    public getDistance(point1: Point, point2: Point): number {
        let p1: any = new naver.maps.Point(point1.coordX, point1.coordY);
        let p2: any = new naver.maps.Point(point2.coordX, point2.coordY);
        let distance: number = this.map.getProjection().getDistance(p1, p2);

        return +distance.toFixed(1);
    }

    public convertPointToOffset(point: Point): Offset {
        let coord: any = new naver.maps.Point(point.coordX, point.coordY);
        let offset: any = this.map.getProjection().fromCoordToOffset(coord);

        return new Offset(offset.x, offset.y);
    }

    public convertCoordToOffset(point: Offset): Offset {
        let coord: any = new naver.maps.Point(point.x, point.y);
        let offset: any = this.map.getProjection().fromCoordToOffset(coord);

        return new Offset(offset.x, offset.y);
    }

    public convertOffsetToCoord(offset: Offset): Offset {
        let nOffset: any = new naver.maps.Point(offset.x, offset.y);
        let coord: any = this.map.getProjection().fromOffsetToCoord(nOffset);

        return new Offset(coord.x, coord.y);
    }

    public setCenterOffset(): void {
        let point: any = this.map.getCenter();
        let offset: any = this.map.getProjection().fromCoordToOffset(point);

        this.centerPoint = new Point(point.x, point.y, offset.x, offset.y, 0, 0);
    }

    public getContainerOffset(): Offset {
        let mapContainer: any = this.map.getMapAction().get("containerTopLeft");

        return new Offset(mapContainer.x, mapContainer.y);
    }

    public getOffsetXPerMeter(lngPerMeter: number): number {
        let lng1: any = new naver.maps.Point(this.centerPoint.coordX + lngPerMeter, this.centerPoint.coordY);
        let lng2: any = new naver.maps.Point(this.centerPoint.coordX + lngPerMeter * 2, this.centerPoint.coordY);

        let offset1: any = this.map.getProjection().fromCoordToOffset(lng1);
        let offset2: any = this.map.getProjection().fromCoordToOffset(lng2);

        return Math.abs(offset2.x - offset1.x);
    }

    public getOffsetYPerMeter(latPerMeter: number): number {
        let lat1: any = new naver.maps.Point(this.centerPoint.coordX, this.centerPoint.coordY + latPerMeter);
        let lat2: any = new naver.maps.Point(this.centerPoint.coordX, this.centerPoint.coordY + latPerMeter * 2);

        let offset1: any = this.map.getProjection().fromCoordToOffset(lat1);
        let offset2: any = this.map.getProjection().fromCoordToOffset(lat2);

        return Math.abs(offset2.y - offset1.y);
    }

    public getRectanglePointList(latPerMeter: number, lngPerMeter: number, radius: number): Point[] {
        let resultList: Point[] = [];
        let pointList: any[] = [
            new naver.maps.Point(this.centerPoint.coordX - (lngPerMeter * radius), this.centerPoint.coordY - (latPerMeter * radius)),
            new naver.maps.Point(this.centerPoint.coordX + (lngPerMeter * radius), this.centerPoint.coordY - (latPerMeter * radius)),
            new naver.maps.Point(this.centerPoint.coordX + (lngPerMeter * radius), this.centerPoint.coordY + (latPerMeter * radius)),
            new naver.maps.Point(this.centerPoint.coordX - (lngPerMeter * radius), this.centerPoint.coordY + (latPerMeter * radius))
        ];

        let mapContainer: any = this.getContainerOffset();

        pointList.forEach((point) => {
            let offset: any = this.map.getProjection().fromCoordToOffset(point);
            resultList.push(new Point(point.x, point.y, offset.x + mapContainer.x, offset.y + mapContainer.y, 0, 0));
        });

        return resultList.slice().reverse();
    }

    public clearEventListeners(): void {
        if (this.mapListener.clickListener != null) {
            naver.maps.Event.removeListener(this.mapListener.clickListener);
            this.mapListener.clickListener = null;
        }

        if (this.mapListener.centerChangedListener != null) {
            naver.maps.Event.removeListener(this.mapListener.centerChangedListener);
            this.mapListener.centerChangedListener = null;
        }

        if (this.mapListener.zoomingListener != null) {
            naver.maps.Event.removeListener(this.mapListener.zoomingListener);
            this.mapListener.zoomingListener = null;
        }

        if (this.mapListener.zoomChangedListener != null) {
            naver.maps.Event.removeListener(this.mapListener.zoomChangedListener);
            this.mapListener.zoomChangedListener = null;
        }

        if (this.mapListener.mouseupListener != null) {
            naver.maps.Event.removeListener(this.mapListener.mouseupListener);
            this.mapListener.mouseupListener = null;
        }
    }

    public setClickEventListener(callback: any): void {
        this.mapListener.clickListener = naver.maps.Event.addListener(this.map, "click", function (event: any): void {
            let offset: any = this.map.getProjection().fromCoordToOffset(event.coord);
            let mapContainer: any = this.getContainerOffset();

            offset.x += mapContainer.x;
            offset.y += mapContainer.y;

            callback(event.coord, offset);
        }.bind(this));
    }

    public setMapMoveEventListener(callback: any): void {
        this.mapListener.centerChangedListener = naver.maps.Event.addListener(this.map, "center_changed", function (event: any) {
            let centerPoint: Point = this.getCenterPoint();

            let nowOffset: any = this.map.getProjection().fromCoordToOffset(event);
            let nowPoint: Point = new Point(event.x, event.y, nowOffset.x, nowOffset.y);

            callback(new Offset(centerPoint.offsetX, centerPoint.offsetY), nowPoint);
        }.bind(this));
    }

    public setZoomingEventListener(callback: any): void {
        this.mapListener.zoomingListener = naver.maps.Event.addListener(this.map, "zooming", function (event: any) {
            callback();
        });
    }

    public setZoomChangedEventListener(callback: any): void {
        this.mapListener.zoomChangedListener = naver.maps.Event.addListener(this.map, "zoom_changed", function (event: any) {
            callback(event);
        });
    }

    public getZoomLevel(): number {
        return this.map.getZoom();
    }

    public setZoomLevel(level: number): void {
        this.map.setZoom(level, false);
    }

    public setMouseUpEventListener(callback: any): void {
        this.mapListener.mouseupListener = naver.maps.Event.addListener(this.map, "mouseup", function (event: any) {
            callback();
        });
    }

    public getMeterPerPixel(): number {
        let point1: any = new naver.maps.Point(0, 0);
        let point2: any = new naver.maps.Point(1, 0);

        let coord1: any = this.map.getProjection().fromOffsetToCoord(point1);
        let coord2: any = this.map.getProjection().fromOffsetToCoord(point2);

        return this.map.getProjection().getDistance(coord1, coord2);
    }
}
