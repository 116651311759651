import { PermissionCode } from './permission.code';
import { LicenseDto } from '../license/license.dto';

export class AccountDto {
    constructor(
        public seq: number = 0,
        public email: string = "",
        public name: string = "",
        public password: string = "",
        public groupSeq: number = 0,
        public permissionCode: PermissionCode = null,
        public authCode: number = 0,
        public licenseDto: LicenseDto = null,
        public isChecked: boolean = false,
        public isMonitoringChecked: boolean = false,
        public isControlChecked: boolean = false
    ) {}
}