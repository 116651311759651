import {Component} from "@angular/core";
import {Router} from "@angular/router";

@Component({
    selector: "sign-up-done",
    templateUrl: "./view/done.component.html",
    styleUrls: ["./view/done.component.css"]
})
export class DoneComponent {
    constructor(private router: Router) {}

    public login(): void {
        this.router.navigateByUrl("");
    }
}
