import {Component, EventEmitter, Output, ViewChild} from "@angular/core";
import {AccountDto} from "src/app/dto/account/account.dto";
import {PermissionCode} from "src/app/dto/account/permission.code";
import {GroupDto} from "src/app/dto/group/group.dto";
import {PopupDto} from "src/app/dto/popup.dto";
import {AccountService} from "src/app/service/account.service";
import {Router} from "@angular/router";
import {PopupService} from "src/app/service/popup.service";
import {GroupService} from "./service/group.service";
import {ContextMenuComponent} from "../contextMenu/context-menu.component";
import {GroupContext} from "../contextMenu/data/group-context";
import {ContextEvent} from "../contextMenu/data/context-event";
import {GroupCreateComponent} from "../create/group-create.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: "groupArea",
    templateUrl: "./view/group.html"
})
export class GroupComponent {
    private accountDto: AccountDto;
    public treeData: GroupDto | null;

    @ViewChild(ContextMenuComponent) public contextMenuComponent: ContextMenuComponent;
    @ViewChild(GroupCreateComponent) public groupCreateComponent: GroupCreateComponent;
    @Output() closeEvent: EventEmitter<void>;

    constructor(
        private router: Router,
        private groupService: GroupService,
        private accountService: AccountService,
        private popupService: PopupService,
        private translateService: TranslateService) {

        this.closeEvent = new EventEmitter<void>();
    }

    ngOnInit() {
        this.accountDto = this.accountService.getAccount();
        this.treeData = null;
        this.getTreeData();

        if (this.accountDto.permissionCode == PermissionCode.USER) {
            this.showCreateGroupPopup();
        }
    }

    private showCreateGroupPopup(): void {
        let groupDto: GroupDto = new GroupDto();
        groupDto.managerSeq = this.accountDto.seq;

        this.groupCreateComponent.show(true, groupDto);
    }

    private getTreeData(): void {
        this.groupService.getTreeData(this.accountDto.seq).subscribe(
        data => {
                this.treeData = data;
            }
        );
    }

    public close(): void {
        this.closeEvent.emit();
    }

    public showGroupContextMenu(groupDto: GroupDto, groupLevel: number, event: MouseEvent): void {
        this.contextMenuComponent.show(new GroupContext(
            groupDto,
            groupLevel,
            event.x,
            event.y
        ));
    }

    public hideGroupContextMenu(): void {
        this.contextMenuComponent.hide();
    }

    public preventDefaultContext(event: any): void {
        event.preventDefault();
    }

    public contextMenuSelectListener(contextEvent: ContextEvent): void {
        let groupDto: GroupDto = new GroupDto();
        let groupSeq: number = this.contextMenuComponent.groupContext.groupDto.seq;

        switch (contextEvent) {
            case ContextEvent.ADD :
                groupDto.upperGroupSeq = groupSeq;
                this.groupCreateComponent.show(true, groupDto);
                break;
            case ContextEvent.EDIT :
                groupDto.seq = groupSeq;
                this.groupCreateComponent.show(false, groupDto);
                break;
            case ContextEvent.DELETE :
                this.deleteLowerGroup(groupSeq);
                break;
        }
    }

    public refresh(): void {
        this.accountService.publishRefresh(true);
        this.getTreeData();
    }

    public checkTreeData(): void {
        if (this.treeData == null) {
            this.close();
        }
    }

    public deleteLowerGroup(groupSeq: number): void {
        let responseSubscriber: any = this.popupService.response$.subscribe(
            isConfirm => {
                if (isConfirm) {
                    this.deleteGroup(groupSeq);
                }

                responseSubscriber.unsubscribe();
            }
        );

        let popupData: PopupDto = new PopupDto();
        popupData.isVisible = true;
        popupData.title = this.translateService.instant("group.popup.delete.title");
        popupData.message = this.translateService.instant("group.popup.delete.message");
        this.popupService.publishData(popupData);
    }

    private deleteGroup(groupSeq: number): void {
        this.groupService.delete(groupSeq).subscribe(
            _ => {
                this.refresh();
            }
        );
    }
}
