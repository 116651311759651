export enum PointActionCode {
    STOP = 0,
    NON_STOP = 1,
    TAKE_PHOTO_STOP = 2,
    TAKE_PHOTO_NON_STOP = 3,
    PHOTO_INTERVAL = 4,
    PHOTO_DISTANCE_INTERVAL = 5,
    RECORD_STOP = 6,
    RECORD_NON_STOP = 7
}

export namespace PointActionCode {
    export function getPointActionCodeLanguage(pointActionCode: PointActionCode): string {
        switch (pointActionCode) {
            case PointActionCode.STOP:
                return "control.mission.type.point-action-type.stop";
            case PointActionCode.NON_STOP:
                return "control.mission.type.point-action-type.non-stop";
            case PointActionCode.TAKE_PHOTO_STOP:
                return "control.mission.type.point-action-type.stop-take-photo";
            case PointActionCode.TAKE_PHOTO_NON_STOP:
                return "control.mission.type.point-action-type.non-stop-take-photo";
            case PointActionCode.PHOTO_INTERVAL:
                return "control.mission.type.point-action-type.interval-photo";
            case PointActionCode.PHOTO_DISTANCE_INTERVAL:
                return "control.mission.type.point-action-type.distance-interval-photo";
            case PointActionCode.RECORD_STOP:
                return "control.mission.type.point-action-type.stop-record";
            case PointActionCode.RECORD_NON_STOP:
                return "control.mission.type.point-action-type.non-stop-record";
        }
    }

    export function getAll(): PointActionCode[] {
        return [
            PointActionCode.STOP, PointActionCode.NON_STOP,
            PointActionCode.TAKE_PHOTO_STOP, PointActionCode.TAKE_PHOTO_NON_STOP,
            PointActionCode.PHOTO_INTERVAL, PointActionCode.PHOTO_DISTANCE_INTERVAL,
            PointActionCode.RECORD_STOP, PointActionCode.RECORD_NON_STOP
        ];
    }
}
