import {NgModule} from "@angular/core";
import {UserComponent} from "./base/user.component";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {UserCreateComponent} from "./create/user-create.component";
import {InfoService} from "../../join/phase/info/service/info.service";
import {GroupTreeModule} from "../../common/group-tree/group-tree.module";
import {GroupSelectComponent} from "./group-select/group-select.component";
import {UserCreateService} from "./create/service/user-create.service";
import {GroupSettingComponent} from "./group-setting/group-setting.component";
import {UserService} from "./base/service/user.service";
import {PermissionComponent} from "./permission/permission.component";
import {GroupService} from "../group/base/service/group.service";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [
        UserComponent,
        UserCreateComponent,
        GroupSelectComponent,
        GroupSettingComponent,
        PermissionComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        GroupTreeModule,
        TranslateModule
    ],
    providers: [
        UserService,
        InfoService,
        UserCreateService,
        GroupService
    ],
    exports: [
        UserComponent
    ]
})
export class UserModule {}
