import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {SettingsDto} from "../../../../../../dto/settings/settings.dto";
import {APIUrl} from "../../../../../../constants/api.url";
import {AccountService} from "../../../../../../service/account.service";

@Injectable()
export class SummaryItemService {
    constructor(private accountService: AccountService, private httpClient: HttpClient) {}

    public changeUnit(settingsDto: SettingsDto): void {
        this.httpClient.post<void>(APIUrl.SET_SETTINGS(this.accountService.getAccount().seq), settingsDto).subscribe();
    }
}
