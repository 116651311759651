import { interval } from 'rxjs';

export class AppMessage {
    constructor(
        public fromApp: boolean = false,
        public accountEmail: string = "",
        public accountName: string = "",
        public text: string = "",
        public isAudio: boolean = false,
        public isAudioPlay: boolean = false,
        private hour: number = 0,
        private min: number = 0,
        private sec: number = 0,
        private streamingTimeInterval: any = null,
        public sendTime: string = "",
        public sendUnixTime: number = 0
    ) {}

    public startStreamingTimeInterval(): void {
        if (this.streamingTimeInterval != null) {
            this.stopStreamingTimeInterval();
        }

        this.streamingTimeInterval = interval(1000).subscribe(() => {
            if (this.sec == 59) {
                this.sec = 0;

                if (this.min == 59) {
                    this.min = 0;
                    ++this.hour;
                } else {
                    ++this.min;
                }
            } else {
                ++this.sec;
            }
        });
    }

    public stopStreamingTimeInterval(): void {
        try {
            this.streamingTimeInterval.unsubscribe();
            this.streamingTimeInterval = null;
        } catch (e) {}
    }

    public getStreamingTime(): string {
        let hour: string = (this.hour > 9)? "" + this.hour : "0" + this.hour;
        let min: string = (this.min > 9)? "" + this.min : "0" + this.min;
        let sec: string = (this.sec > 9)? "" + this.sec : "0" + this.sec;

        return hour + ":" + min + ":" + sec;
    }
}