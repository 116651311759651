import { ShapeFileDataDto } from './shapeFileData.dto';
import { CoordinateDto } from './coordinate.dto';

export class ShapeFileDto {
    constructor(
        public fileName: string = "",
        public epsgId: number = 0,
        public centerCoord: CoordinateDto = null,
        public leftBottomCoord: CoordinateDto = null,
        public rightTopCoord: CoordinateDto = null,
        public dbfHeadList: string[] = [],
        public list: ShapeFileDataDto[] = [],
        public isKml: boolean = false,
        public isVisible: boolean = true
    ) {}
}
