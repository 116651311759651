import { MachineDto } from '../machine/machine.dto';

export class LiveMapDataDto {
    constructor(
        public machine: MachineDto = null,
        public latitude: number = 0,
        public longitude: number = 0,
        public offsetX: number = 0,
        public offsetY: number = 0,
        public yaw: number = 0,
        public altitude: number = 0,
        public fileName: string = "",
        public imageWidth: number = 0,
        public imageHeight: number = 0,
        public isVisible: boolean = true
    ) {}
}