import {Component, EventEmitter, Output, ViewChild} from "@angular/core";
import {AccountSearchDto} from "../../../../dto/account/accountSearch.dto";
import {AccountSearchCode} from "../../../../dto/account/accountSearch.code";
import {AccountDto} from "../../../../dto/account/account.dto";
import {UserService} from "../base/service/user.service";
import {GroupTreeComponent} from "../../../common/group-tree/group-tree.component";
import {AccountService} from "../../../../service/account.service";

@Component({
    selector: "user-permission",
    templateUrl: "./view/permission.component.html"
})
export class PermissionComponent {
    public isVisible: boolean;
    public accountSearchDto: AccountSearchDto;
    public accountSearchCode: typeof AccountSearchCode;
    public accounts: AccountDto[];

    @ViewChild(GroupTreeComponent) public groupTreeComponent: GroupTreeComponent;
    @Output() public closeEvent: EventEmitter<void>;

    constructor(private accountService: AccountService, private userService: UserService) {
        this.isVisible = false;
        this.accountSearchDto = new AccountSearchDto();
        this.accountSearchCode = AccountSearchCode;
        this.accounts = [];

        this.closeEvent = new EventEmitter<void>();
    }

    public show(): void {
        this.isVisible = true;
    }

    public getAccountSearchCodeList(): string[] {
        let keys: string[] = Object.keys(AccountSearchCode);
        return keys.slice(keys.length / 2);
    }

    public getAccounts(): void {
        this.accountSearchDto.groupSeqList = this.groupTreeComponent.getCheckedGroupSeqs();

        if (this.accountSearchDto.groupSeqList.length == 0) {
            this.accounts = [];
            return;
        }

        this.userService.get(this.accountService.getAccount().seq, this.accountSearchDto).subscribe(
            accounts => {
                this.accounts = accounts;
            }
        );
    }

    public submit(): void {
        this.userService.updatePermission(this.accounts).subscribe(
            _ => {
                this.close();
            }
        );
    }

    public close(): void {
        this.isVisible = false;
        this.closeEvent.emit();
    }
}
